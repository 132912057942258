import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TransactionStatus } from 'src/app/enums/transaction.status';
import { Transaction } from 'src/app/model/transaction';
import { ModalService } from 'src/app/services/modal.service';
import { AppConstants } from 'src/app/settings/app-constants';
import { ModalConstants } from 'src/app/settings/modal-constants';

@Component({
    selector: 'app-transaction-view-modal',
    templateUrl: './transaction-view-modal.component.html',
    styleUrls: ['./transaction-view-modal.component.css'],
})
export class TransactionViewModalComponent {
    @Input() transaction: Transaction;
    @Input() transferToBank = false;
    @Input() showUpdate = false;
    @Input() update = true;
    @Output() event = new EventEmitter<boolean>();
    @Output() transferUpdateEvent = new EventEmitter();
    transactionStatusEnum = TransactionStatus;
    amountFormat = AppConstants.AMOUNT_FORMAT;
    dateFormat = AppConstants.DATE_FORMAT;
    modalConstants = ModalConstants;

    constructor(private modalService: ModalService) { }

    closeViewModel() {
        this.event.emit(false);
    }

    openTransactionUpdateModal(cancel: boolean) {
        // Cancel Transaction = true
        // Update Amount = false
        this.transferUpdateEvent.emit(cancel);
        this.openModal(this.modalConstants.transactionUpdateModalId);
    }

    openTransferUpdateModel(cancel: boolean) {
        this.transferUpdateEvent.emit(cancel);
    }

    openModal(id: string) {
        this.modalService.open(id);
    }
}
