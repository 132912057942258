<div mat-dialog-content>
    <mat-form-field appearance="fill">
        <mat-label>Switch company</mat-label>
        <mat-select
            [(ngModel)]="selectedCompany"
            [compareWith]="customCompare"
            (selectionChange)="confirmSelectedCompany()">
            <mat-option *ngFor="let company of companies" [value]="company">{{
                company.name
            }}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<!-- <div mat-dialog-actions>
    <button mat-button  mat-raised-button  (click)="onNoClick()">Close</button>
    <button mat-button  mat-raised-button   (click)="confirmSelectedCompany()">Confirm Selected Company</button>
</div> -->
