import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { LoginService } from './login.service';

@Injectable({ providedIn: 'root' })
export class RouteResolver {
    static readonly safeTransactionResolver: ResolveFn<any> = (route: ActivatedRouteSnapshot) => {
        return route.paramMap.get('safeId');
    };

    static readonly safeUserTransactionResolver: ResolveFn<any> = (
        route: ActivatedRouteSnapshot,
    ) => {
        return {
            safeId: route.paramMap.get('safeId') || route.parent.paramMap.get('safeId'),
            userRoleId: route.paramMap.get('userRoleId'),
            name: route.paramMap.get('name'),
        };
    };

    static readonly enterpriseDashboardResolver: ResolveFn<any> = () => {
        return inject(LoginService).sessionUser.value.enterprise.businessName;
    };
}
