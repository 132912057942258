import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FileType } from '../enums/file.type';
import { DocumentFile } from '../model/DocumentFile';
import { ExportLog } from '../model/export.log';
import { GenericResponse } from '../model/generic.response';
import { TermsAndConditions } from '../model/termsAndCondition';
import { Transaction } from '../model/transaction';
import { User } from '../model/user';
import { AppSettings } from '../settings/app-settings';
import { CompanyService } from './company.service';
import { CountryService } from './country.service';

@Injectable({ providedIn: 'root' })
export class CommonService {
    constructor(
        private http: HttpClient,
        private companyService: CompanyService,
        private countryService: CountryService,
    ) { }

    getKycLevel() {
        return this.http.get<GenericResponse<string[]>>(
            AppSettings.API_ENDPOINT + AppSettings.DROPDOWN_KYCLEVEL,
        );
    }

    getGender() {
        return this.http.get<GenericResponse<string[]>>(
            AppSettings.API_ENDPOINT + AppSettings.DROPDOWN_GENDER,
        );
    }

    getMaritalStatus() {
        return this.http.get<GenericResponse<string[]>>(
            AppSettings.API_ENDPOINT + AppSettings.DROPDOWN_MARITAL_STATUS,
        );
    }

    getUserStatus() {
        return this.http.get<GenericResponse<string[]>>(
            AppSettings.API_ENDPOINT + AppSettings.DROPDOWN_USER_STATUS,
        );
    }

    getProvinces(queryParams = {}) {
        const countryCode = this.countryService.getCountryCodeByCompany();
        const path = AppSettings.DROPDOWN_PROVINCE.replace('@@@', countryCode);
        const params = new HttpParams({ fromObject: queryParams });

        return this.http.get<any>(AppSettings.API_ENDPOINT + path, { params });
    }

    getCities(province: string) {
        const path = AppSettings.DROPDOWN_CITY.replace('@1@', province);
        return this.http.get<any>(AppSettings.API_ENDPOINT + path);
    }

    getIdType() {
        return this.http.get<GenericResponse<string[]>>(
            AppSettings.API_ENDPOINT + AppSettings.DROPDOWN_ID_TYPE,
        );
    }

    getAccountType() {
        return this.http.get<GenericResponse<string[]>>(
            AppSettings.API_ENDPOINT + AppSettings.DROPDOWN_ACCOUNT_TYPE,
        );
    }

    getBankAccountType() {
        return this.http.get<GenericResponse<string[]>>(
            AppSettings.API_ENDPOINT + AppSettings.DROPDOWN_BANK_ACCOUNT_TYPE,
        );
    }

    getMobilePhoneType() {
        return this.http.get<GenericResponse<string[]>>(
            AppSettings.API_ENDPOINT + AppSettings.DROPDOWN_PHONE_TYPE,
        );
    }

    getBanks() {
        return this.http.get<any>(AppSettings.API_ENDPOINT + AppSettings.DROPDOWN_BANK);
    }

    updateBank(body: any, userId: any, roleId: any) {
        let path = AppSettings.UPDATE_BANK.replace('@1@', userId);
        path = path.replace('@2@', roleId);

        return this.http.put<GenericResponse<any>>(AppSettings.API_ENDPOINT + path, body);
    }

    getStoreType() {
        return this.http.get<GenericResponse<string[]>>(
            AppSettings.API_ENDPOINT + AppSettings.DROPDOWN_STORE_TYPE,
        );
    }

    getBusinessCategory() {
        return this.http.get<GenericResponse<string[]>>(
            AppSettings.API_ENDPOINT + AppSettings.DROPDOWN_BUSINESS_CATEGORY,
        );
    }

    getFilteredData(form: FormGroup) {
        form.value.companyId = this.companyService.getSelectedCompany().id;
        console.log('manage user search', form.value);
        return this.http.post<GenericResponse<User[]>>(
            AppSettings.API_ENDPOINT + AppSettings.FILTERED_USERS,
            JSON.stringify(form.value),
        );
    }

    addUser(form: FormGroup) {
        return this.http.post<GenericResponse<any>>(
            AppSettings.API_ENDPOINT + AppSettings.SIGNUP,
            JSON.stringify(form.value),
        );
    }

    addAgent(form: FormGroup, userId: string) {
        const path = AppSettings.ADD_AGENT.replace('@@@', userId);
        return this.http.post<GenericResponse<any>>(
            AppSettings.API_ENDPOINT + path,
            JSON.stringify(form.value),
        );
    }

    changeStatus(data: { mobileNumber: string; status: string; type: string }) {
        return this.http.post<GenericResponse<any>>(
            AppSettings.API_ENDPOINT + AppSettings.UPDATE_STATUS,
            data,
        );
    }

    getUserProfile(userId: string) {
        const path = AppSettings.GET_USER_PROFILE.replace('@@@', userId);
        return this.http.get<GenericResponse<any>>(AppSettings.API_ENDPOINT + path);
    }

    updateUserProfile(userId: string, roleId: string, form: FormGroup) {
        let path = AppSettings.UPDATE_USER_PROFILE.replace('@1@', userId);
        path = path.replace('@2@', roleId);
        return this.http.patch<GenericResponse<any>>(
            AppSettings.API_ENDPOINT + path,
            JSON.stringify(form.value),
        );
    }

    changeUserProfileSettings(userId: string, roleId: string, body: any) {
        let path = AppSettings.CHANGE_PROFILE_SETTINGS.replace('@1@', userId);
        path = path.replace('@2@', roleId);
        return this.http.patch<GenericResponse<any>>(AppSettings.API_ENDPOINT + path, body);
    }

    changeUserProfile(userId: string, roleId: string, body: any) {
        let path = AppSettings.CHANGE_PROFILE.replace('@1@', userId);
        path = path.replace('@2@', roleId);
        return this.http.patch<GenericResponse<any>>(AppSettings.API_ENDPOINT + path, body);
    }

    updateUserDocumnets(data: any, userId: string) {
        const path = AppSettings.VERIFY_USER_DOCUMENT.replace('@@@', userId);
        return this.http.patch<GenericResponse<any>>(AppSettings.API_ENDPOINT + path, data);
    }

    updateLogo(data: any, userId: string, roleId) {
        let path = AppSettings.UPDATE_LOGO.replace('@1@', userId);
        path = path.replace('@2@', roleId);
        return this.http.patch<GenericResponse<any>>(AppSettings.API_ENDPOINT + path, data);
    }

    changeMobile(data: any) {
        return this.http.post<any>(AppSettings.API_ENDPOINT + AppSettings.CHANGE_MOBILE, data);
    }

    verifyOtp(data: any) {
        return this.http.post<any>(AppSettings.API_ENDPOINT + AppSettings.VERIFY_OTP, data);
    }

    resendOtp(data: any) {
        return this.http.post<any>(AppSettings.API_ENDPOINT + AppSettings.RESEND_OTP, data);
    }

    getTermsAndConditions() {
        return this.http.get<GenericResponse<TermsAndConditions>>(
            AppSettings.API_ENDPOINT + AppSettings.TERMS_AND_CONDTITIONS,
        );
    }

    updateTermsAndConditions(body: any) {
        return this.http.post<GenericResponse<any>>(
            AppSettings.API_ENDPOINT + AppSettings.TERMS_AND_CONDTITIONS,
            body,
        );
    }

    getUserTransactionHistory(form: FormGroup) {
        form.value.companyId = this.companyService.getSelectedCompany().id;
        console.log(form.value);
        return this.http.post<GenericResponse<Transaction[]>>(
            AppSettings.API_ENDPOINT + AppSettings.USER_TRANSACTION,
            JSON.stringify(form.value),
        );
    }

    createExportLog(body: any) {
        return this.http.post<GenericResponse<any>>(
            AppSettings.API_ENDPOINT + AppSettings.USER_TRANSACTION_EXPORT_LOG,
            body,
        );
    }

    getExportLog(body: any) {
        return this.http.post<GenericResponse<ExportLog[]>>(
            AppSettings.API_ENDPOINT + AppSettings.GET_TRANSACTION_EXPORT_LOG,
            body,
        );
    }

    updateReferralCode(userId, code) {
        let path = AppSettings.UPDATE_REFERRAL_CODE.replace('@1@', userId);
        path = path.replace('@2@', code);
        return this.http.patch<GenericResponse<any>>(AppSettings.API_ENDPOINT + path, {});
    }

    checkUserBalance(body: any) {
        return this.http.post<any>(AppSettings.API_ENDPOINT + AppSettings.CHECK_BALANCE, body);
    }

    getProfileImage(docs: DocumentFile[]) {
        for (const img of docs) {
            if (img.fileType === FileType.profile_picture) {
                return img.filePath + '/' + img.fileName;
            }
        }
        return null;
    }

    getSalesDashboardFilters(queryParams: any) {
        const params = new HttpParams({ fromObject: queryParams });
        return this.http.get<GenericResponse<any[]>>(
            AppSettings.API_ENDPOINT + AppSettings.DROPDOWN_SALES_DASHBOARD,
            { params },
        );
    }

    getProvinceId(province: any, name: string): string {
        const val = province.filter((x) => x.province == name)[0];
        let id = '';

        if (val) {
            id = val.id;
        }
        return id;
    }
}
