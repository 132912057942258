import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { URI } from 'src/app/settings/uri-constants';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
})
export class HeaderComponent {
    @Input() showHeaderButton = false;
    @Input() title = 'Manage Users';
    @Input() location: string;
    @Input() buttonText: string;
    @Input() url: string;

    constructor(private router: Router) {}

    navigate() {
        if (this.url) {
            this.router.navigate([this.url]);
        } else {
            this.router.navigate([URI.SLASHED_ADD_STAFF]);
        }
    }
}
