import {
    Component,
    OnInit,
    Input,
    Renderer2,
    EventEmitter,
    Output,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { HumanizePipe } from 'src/app/pipes/humanize.pipe';

@Component({
    selector: 'app-select-box',
    templateUrl: './select-box.component.html',
    styleUrls: ['./select-box.component.css'],
})
export class SelectBoxComponent implements OnInit, OnChanges {
    @Input() placeholderValue: string;
    @Input() value: string;
    @Input() form: FormGroup;
    @Input() dropDown: string[] = [];
    @Input() invalidForm = false;
    dropDownList: string[] = [];
    borderFocussed = false;
    inputVal: string;
    tempVal: string;

    constructor(
        private renderer: Renderer2,
        private humanizePipe: HumanizePipe,
    ) {
        this.inputVal = '';
    }

    ngOnInit() {
        this.inputVal = this.form.get(this.value).value;
        this.inputVal = this.inputVal !== undefined ? this.inputVal : '';
        this.renderer.listen('window', 'click', (e: Event) => {
            this.borderFocussed = false;
            this.inputVal = this.humanizePipe.transform(this.inputVal);
        });
        this.form.get(this.value).valueChanges.subscribe((newVal) => {
            this.inputVal = newVal;
            this.inputVal = this.inputVal !== undefined ? this.inputVal : '';
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.dropDownList = this.dropDown;
        if (this.invalidForm && this.form.get(this.value).invalid) {
            this.changeBorder();
        }
    }

    changeValue(item: string) {
        this.inputVal = item;
        this.tempVal = item;
        this.form.get(this.value).setValue(item);
    }

    changeBorder() {
        this.borderFocussed = this.borderFocussed === true ? false : true;
    }

    filterList() {
        this.dropDownList = this.dropDown.filter((e) =>
            e.toLowerCase().includes(this.inputVal.toLowerCase()),
        );
        if (this.inputVal == '') {
            this.dropDownList = this.dropDown;
        }
    }

    changeFocus() {
        setTimeout(() => {
            this.inputVal = this.tempVal;
            this.dropDownList = this.dropDown;
        }, 500);
    }

    get controls(): any {
        return this.form.get(this.value);
    }
}
