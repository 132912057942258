<!-- Left side column. contains the sidebar -->
<aside class="main-sidebar">
    <!-- Logo -->
    <a (click)="openCompanyDropDown($event)" class="logo">
        <!-- mini logo for sidebar mini 50x50 pixels -->
        <span class="logo-mini"><img src="assets/images/logo-icon.png" /></span>
        <!-- logo for regular state and mobile devices -->
        <span class="logo-lg">
            <img src="assets/images/{{ companyService.getSelectedCompanyCode() }}-logo-light.png" />
        </span>
    </a>

    <section class="sidebar">
        <ul class="sidebar-menu" data-widget="tree" *ngFor="let navItem of navigationItems">
            <li routerLinkActive="active-sidebar">
                <a [routerLink]="navItem.path" routerLinkActive="active-sidebar-anchor active">
                    <div [class]="navItem.iconClass"></div>
                    <span>{{ navItem.name }}</span>
                </a>
            </li>
        </ul>
        <!-- sidebar menu: : style can be found in sidebar.less -->
    </section>
    <section class="sidebar-footer">
        <div>{{ companyService.getSelectedCompanyName() }} Admin</div>
        <div class="sidebar-menu-footer" (mouseenter)="mouseEnter()" (mouseleave)="mouseEnter()">
            <a (click)="logout()">
                <img src="assets/images/{{ iconName }}.png" />
                <span><u>Logout</u></span>
            </a>
        </div>
        <div class="sidebar-footer-copyright">
            All Rights Reserved © {{ companyService.getSelectedCompanyName() }}
        </div>
        <div class="sidebar-footer-compressed">©</div>
    </section>
    <!-- /.sidebar -->
</aside>
<div id="logoutModal" class="image-modal" [style.display]="logoutModalDisplay">
    <div class="logout-window">
        <div class="section-heading mb-1">Are you sure you want to Logout?</div>
        <div>
            <button type="submit" class="box-outline submit-btn mb-2" (click)="submit()">
                Yes, Log Out
            </button>
            <a class="deny-window-close" (click)="closeModal()"><u>CANCEL</u></a>
        </div>
    </div>
</div>
