import { Component, OnInit } from '@angular/core';
import { URI } from 'src/app/settings/uri-constants';

@Component({
    selector: 'app-eft-cash-in-link',
    templateUrl: './eft-cash-in-link.component.html',
    styleUrls: ['./eft-cash-in-link.component.css'],
})
export class EftCashInLinkComponent {
    uri = URI;

    constructor() {}
}
