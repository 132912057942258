<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="row">
        <div class="col-md-6">
            <div class="tab-heading">
                <span class="tab-heading-title">{{ title }}</span>
                <img
                    alt="Breadcrumb Arrow"
                    class="breadcrumb-separator"
                    src="assets/images/breadcrumb-separator.png" />
                <app-breadcrumb></app-breadcrumb>
            </div>
        </div>
        <div class="col-md-6 d-flex justify-content-end" *ngIf="enterprise?.businessLogo">
            <div id="dashboard-logo">
                <span class="e-avatar">
                    <img
                        alt="{{ enterprise?.businessName }} Logo"
                        src="{{ enterprise.businessLogo }}" />
                </span>
            </div>
        </div>
    </div>
</section>
