import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup } from '@angular/forms';
import { Role } from 'src/app/enums/role';
import { TransferToBank } from 'src/app/model/transfer-to-bank';
import { TransactionStatus } from 'src/app/enums/transaction.status';
import { TransactionType } from 'src/app/enums/transaction.type';
import { Bank } from 'src/app/model/bank';
import { Pagination } from 'src/app/model/Pagination';
import { Transaction } from 'src/app/model/transaction';
import { TransactionLog } from 'src/app/model/transaction.log';
import { CommonService } from 'src/app/services/common.service';
import { CommonUtil } from 'src/app/services/common.util';
import { TransactionService } from 'src/app/services/transaction.service';
import { AppConstants } from 'src/app/settings/app-constants';
import { MessageConstant } from 'src/app/settings/message.constant';

@Component({
    selector: 'app-transfer-to-bank-processed',
    templateUrl: './transfer-to-bank-processed.component.html',
    styleUrls: ['./transfer-to-bank-processed.component.css'],
})
export class TransferToBankProcessedComponent implements OnInit {
    amountFormat = AppConstants.AMOUNT_FORMAT;
    dateFormat = AppConstants.DATE_FORMAT;
    export = false;
    download = false;
    processing = false;
    isLoading = false;
    display = false;
    displayView = false;
    displayUpdate = 'none';
    displaySuccess = false;
    eMessage = MessageConstant.BANK_TRANSFER_UPDATE_REQUEST_FAILED;
    sMessage = MessageConstant.BANK_TRANSFER_UPDATE_REQUEST_SUCCESS;
    id: string;
    searchItems: string[] = [];
    itemList = ['role', 'status'];
    accountTypeDropdown: string[] = [Role.enterprise, Role.business, Role.personal];
    transactionStatusDropdown: string[] = [
        TransactionStatus.success,
        TransactionStatus.failed,
        TransactionStatus.cancelled,
    ];
    updateStatusDropdown: string[] = [];
    form: FormGroup;
    updateForm: FormGroup;
    TTB: TransferToBank;
    transactionList: Array<Transaction>;
    transaction: Transaction = new Transaction();
    pagination = new Pagination<Transaction>();
    showOptions = false;
    search = false;
    progressWidth = 25;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private commonService: CommonService,
        public transactionService: TransactionService,
        public commonUtil: CommonUtil,
    ) {
        this.form = this.formBuilder.group({
            text: [''],
            start: [''],
            length: [''],
            userId: [''],
            role: [''],
            status: [''],
            transactionTypeList: [[TransactionType.transfer_to_bank]],
            transactionStatus: [
                [TransactionStatus.success, TransactionStatus.failed, TransactionStatus.cancelled],
            ],
        });

        this.updateForm = this.formBuilder.group({
            status: [''],
            manualChangeReason: [''],
        });
    }

    ngOnInit() {
        this.getData();
    }

    closeModel(event: any) {
        this.display = false;
    }

    getData() {
        this.search = false;
        this.isLoading = true;
        this.form.get('start').setValue(this.pagination.start);
        this.form.get('length').setValue(this.pagination.length);
        this.setProgressWidth(50);
        this.addTransactionStatus();
        this.commonService.getUserTransactionHistory(this.form).subscribe(
            (res) => {
                this.setProgressWidth(100);
                setTimeout(() => {
                    this.transactionList = res.data;
                    this.pagination.data = res.data;
                    this.TTB = res.settlementData;
                    this.pagination.total = !res.total ? 0 : res.total;
                    this.isLoading = false;
                    this.search = true;
                    this.progressWidth = 25;
                }, 350);

                this.searchItems = [];
                for (const item of this.itemList) {
                    if (this.form.get(item).value !== '') {
                        this.searchItems.push(this.form.get(item).value);
                    }
                }
            },
            (error) => {
                this.isLoading = false;
            },
        );
    }

    addTransactionStatus() {
        const val: string = this.form.get('status').value;
        if (val.length) {
            this.form.get('transactionStatus').setValue([val]);
        } else {
            this.form
                .get('transactionStatus')
                .setValue([
                    TransactionStatus.success,
                    TransactionStatus.failed,
                    TransactionStatus.cancelled,
                ]);
        }
    }

    setProgressWidth(i: number) {
        setTimeout(() => {
            this.progressWidth = i;
        }, 300);
    }

    searchUsers(text: string) {
        this.pagination.text = text;
        this.getData();
    }

    clearText() {
        this.form.get('text').setValue('');
    }

    removeSearchItem(value: string) {
        for (const item of this.itemList) {
            if (this.form.get(item).value === value) {
                this.form.get(item).setValue('');
                this.getData();
                break;
            }
        }
    }

    receiveFilter(data: { name: string; value: number }) {
        this.transactionList = this.pagination.filterData(data);
    }

    changeLength(len: number) {
        this.pagination.length = len;
        this.pagination.start = 0;
        this.getData();
    }

    changePage(page: number) {
        this.pagination.changePage(page);
        this.getData();
    }

    toggleOption() {
        this.showOptions = !this.showOptions;
    }

    openViewModel() {
        this.displayView = true;
    }

    closeViewModel() {
        this.displayView = false;
    }

    openUpdateModel() {
        this.displayUpdate = 'block';
    }

    closeUpdateModel() {
        this.displayUpdate = 'none';
    }

    action(event: { type: string; value: Transaction }) {
        this.transaction = event.value;
        this.updateForm.get('status').setValue(this.transaction.status);

        if (!this.transaction.bankDetails) {
            this.transaction.bankDetails = new Bank();
        }

        if (!this.transaction.transactionLog) {
            this.transaction.transactionLog = new Array<TransactionLog>();
        }

        switch (event.type) {
            case 'view':
                this.openViewModel();
                break;
            case 'update':
                this.openUpdateModel();
                break;
        }
    }

    get controls(): any {
        return this.form.controls;
    }

    submit() {
        this.getData();
    }

    update() {
        this.isLoading = true;
        this.closeUpdateModel();
        this.closeModel(true);

        this.transactionService
            .updateTransferToBank(this.updateForm.value, this.transaction.settlementRequestId)
            .subscribe(
                (res) => {
                    this.isLoading = false;
                    this.getData();

                    if (res.success) {
                        this.displaySuccess = true;
                    } else {
                        this.display = true;
                        if (res.status) {
                            this.eMessage = res.status.message;
                        }
                    }
                },
                (error) => {
                    this.isLoading = false;
                    this.display = true;
                    this.getData();
                },
            );
    }
}
