import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.css'],
})
export class LoaderComponent implements OnInit {
    @Input() className: string;
    @Input() text: string;
    textClass = 'custom-loader-text';

    constructor() {}

    ngOnInit() {
        if (this.className === 'custom-loader') {
            this.textClass = 'custom-loader-text-processing';
        }
    }
}
