import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Banner } from 'src/app/model/banner';

@Component({
    selector: 'app-last-child-admin-banner',
    templateUrl: './last-child-admin-banner.component.html',
    styleUrls: ['./last-child-admin-banner.component.css'],
})
export class LastChildAdminBannerComponent {
    @Input() banner: Banner;
    @Output() event = new EventEmitter<{ banner: Banner }>();

    constructor() {}

    action() {
        this.event.emit({ banner: this.banner });
    }
}
