<div class="edit-link">
    <a data-toggle="dropdown">
        <div class="dot-icon"></div>
    </a>
    <ul class="dropdown-menu" aria-labelledby="menu1">
        <li>
            <a class="dropdown-menu-item" (click)="action()">
                <img src="assets/images/control-icon-update.png" />
                <div *ngIf="banner.active; then deactivateBanner; else activateBanner"></div>
                <ng-template #deactivateBanner>Deactivate</ng-template>
                <ng-template #activateBanner>Activate</ng-template>
            </a>
        </li>
    </ul>
</div>
