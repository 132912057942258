import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup } from '@angular/forms';
import { ExportLog } from 'src/app/model/export.log';
import { Pagination } from 'src/app/model/Pagination';
import { CommonService } from 'src/app/services/common.service';

@Component({
    selector: 'app-transfer-to-bank-log',
    templateUrl: './transfer-to-bank-log.component.html',
    styleUrls: ['./transfer-to-bank-log.component.css'],
})
export class TransferToBankLogComponent implements OnInit {
    isLoading = false;
    form: FormGroup;
    logList: Array<ExportLog>;
    pagination = new Pagination();
    search = true;

    constructor(
        private commonService: CommonService,
        private formBuilder: UntypedFormBuilder,
    ) {
        this.form = this.formBuilder.group({
            start: [''],
            length: [''],
            userId: [''],
        });
    }

    ngOnInit() {
        this.getData();
    }

    getData() {
        this.search = false;
        this.isLoading = true;
        this.form.get('start').setValue(this.pagination.start);
        this.form.get('length').setValue(this.pagination.length);
        this.commonService.getExportLog(this.form.value).subscribe(
            (res) => {
                this.logList = res.data;
                this.pagination.total = !res.total ? 0 : res.total;
                this.isLoading = false;
                this.search = true;
            },
            (error) => {
                this.isLoading = false;
            },
        );
    }

    searchUsers(text: string) {
        this.pagination.text = text;
        this.getData();
    }

    clearText() {
        this.form.get('text').setValue('');
    }

    receiveFilter(data: { name: string; value: number }) {}

    changeLength(len: number) {
        this.pagination.length = len;
        this.pagination.start = 0;
        this.getData();
    }

    changePage(page: number) {
        this.pagination.changePage(page);
        this.getData();
    }
}
