<section class="add-section">
    <form [formGroup]="form">
        <div class="row">
            <div class="col-md-12 section-heading">Product Orders Search</div>
        </div>
        <div class="row pb-3">
            <div class="col-md-6">
                <label class="form-label">SEARCH BY ORDER</label>
                <div class="input-icons">
                    <i class="fa fa-search icon" aria-hidden="true"></i>
                    <input
                        class="form-input"
                        type="orderNumber"
                        placeholder="e.g. 1234"
                        formControlName="orderNumber" />
                    <a *ngIf="form.get('orderNumber').value.length > 0" (click)="clearText()">
                        <img src="assets/images/close-x-purple.png" />
                    </a>
                </div>
            </div>
            <div class="col-md-6">
                <label class="form-label"></label>
                <div>
                    <button class="search-button" (click)="submit()">Search</button>
                    <a class="advanced-search" (click)="toggleOption()">
                        <img src="assets/images/dropdown.png" />
                        <u>ADVANCED SEARCH</u>
                    </a>
                </div>
            </div>
        </div>
        <hr *ngIf="showOptions" />
        <div class="search-form" *ngIf="showOptions">
            <div class="row">
                <!--                 <div class="col-md-4">
                    <label class="form-label"></label>
                    <div>
                        <input class="form-input" type="text" placeholder="eg. Claremont"
                            formControlName="store">
                    </div>
                </div> -->
                <!--                 <div class="col-md-4">
                    <label class="form-label">Enterprise</label>
                    <div>
                        <app-select-box placeholderValue="Select Enterprise" [form]="form" value="enterprise"
                            [invalidForm]="invalidForm" [dropDown]="enterpriseDropDown" >
                        </app-select-box>
                    </div>
                </div> -->
                <div class="col-md-4">
                    <label class="form-label">PAYMENT STATUS</label>
                    <div>
                        <app-select-box
                            placeholderValue="Select Status"
                            [form]="form"
                            value="paymentStatus"
                            [invalidForm]="invalidForm"
                            [dropDown]="paymentStatusDropDown">
                        </app-select-box>
                    </div>
                </div>
                <div class="col-md-4">
                    <label class="form-label">FULFILLMENT STATUS</label>
                    <div>
                        <app-select-box
                            placeholderValue="Select Status"
                            [form]="form"
                            value="fullfilmentStatus"
                            [invalidForm]="invalidForm"
                            [dropDown]="fulfillmentStatusValues">
                        </app-select-box>
                    </div>
                </div>
            </div>
        </div>
        <div class="searched-items">
            <div *ngIf="searchItems.length > 0">
                <div *ngFor="let item of searchItems" class="search-item-value">
                    {{ item | humanize }}
                    <a (click)="removeSearchItem(item)">
                        <img src="assets/images/close-x-white.png" />
                    </a>
                </div>
            </div>
        </div>
    </form>
</section>
<div class="progress-container" *ngIf="isLoading">
    <div class="progress-container-text">
        <div>Searching...</div>
    </div>
    <div>
        <div class="custom-progress-bar">
            <span [style.width.%]="progressWidth"></span>
        </div>
    </div>
</div>
<section *ngIf="search">
    <div class="search-result-header pt-3">
        Search Results: {{ productOrdersList.length }} of {{ pagination.total }}
    </div>
    <hr />
    <div class="container-fluid tab-pane custom-table custom-last-row">
        <br />
        <table class="product-orders">
            <thead>
                <tr class="form-table-row">
                    <th>
                        <app-filter-arrow
                            filter="customer"
                            title="CUSTOMER"
                            (filterValue)="receiveFilter($event)">
                        </app-filter-arrow>
                    </th>
                    <th>
                        <app-filter-arrow
                            filter="order"
                            title="ORDER"
                            (filterValue)="receiveFilter($event)">
                        </app-filter-arrow>
                    </th>
                    <th>
                        <app-filter-arrow
                            filter="date"
                            title="DATE"
                            (filterValue)="receiveFilter($event)">
                        </app-filter-arrow>
                    </th>
                    <th>
                        <app-filter-arrow
                            filter="total"
                            title="TOTAL"
                            (filterValue)="receiveFilter($event)">
                        </app-filter-arrow>
                    </th>

                    <th>
                        <app-filter-arrow
                            filter="paymentStatus"
                            title="PAYMENT STATUS"
                            (filterValue)="receiveFilter($event)">
                        </app-filter-arrow>
                    </th>
                    <th>
                        <app-filter-arrow
                            filter="fulfillmentStatus"
                            title="FULFILLMENT STATUS"
                            (filterValue)="receiveFilter($event)">
                        </app-filter-arrow>
                    </th>
                    <th>
                        <app-filter-arrow
                            filter="items"
                            title="ITEMS"
                            (filterValue)="receiveFilter($event)">
                        </app-filter-arrow>
                    </th>
                    <th *ngIf="loginService.isAdmin()">
                        <app-filter-arrow
                            filter="enterprise"
                            title="ENTERPRISE"
                            (filterValue)="receiveFilter($event)">
                        </app-filter-arrow>
                    </th>
                    <th>
                        <app-filter-arrow title="ACTIONS"> </app-filter-arrow>
                    </th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let po of productOrdersList">
                    <tr class="form-table-row">
                        <td *ngIf="loginService.isAdmin()">
                            <img
                                class="table-user-img"
                                src="{{ po.user?.image || 'assets/images/placeholder.png' }}" />
                            <a (click)="commonUtil.navigateToProfile('profile', po.user)">
                                <strong
                                    ><u
                                        ><span>{{ po.user.name }}</span></u
                                    ></strong
                                >
                            </a>
                        </td>
                        <td *ngIf="loginService.isEnterpriseContact()">
                            <img
                                class="table-user-img"
                                src="{{ po.user?.image || 'assets/images/placeholder.png' }}" />
                            <strong
                                ><span>{{ po.user.name }}</span></strong
                            >
                        </td>
                        <td>{{ po.shopifyOrder.orderNumber }}</td>
                        <td>{{ po.createdAt | date: 'd/M/yyyy - h:mm a' }}</td>
                        <td>{{ po.totalPrice | currency: 'ZAR' : 'symbol-narrow' }}</td>
                        <td>
                            <app-account-status
                                cssClass="product-order-status"
                                type="productOrder"
                                status="{{ po.shopifyOrder.financialStatus }}">
                            </app-account-status>
                        </td>
                        <!-- <td>{{ po.shopifyOrder.financialStatus }}</td> -->
                        <td>
                            <app-account-status
                                cssClass="product-order-status"
                                type="productOrder"
                                status="{{ po.fulfilled ? 'fulfilled' : 'unfulfilled' }}">
                            </app-account-status>
                        </td>
                        <td>{{ po.lineItems?.length | i18nPlural: itemMapping }}</td>
                        <td *ngIf="loginService.isAdmin()">
                            <img
                                class="table-user-img"
                                src="{{
                                    po.enterprise?.image || 'assets/images/placeholder.png'
                                }}" />
                            <a (click)="commonUtil.navigateToProfile('profile', po.enterprise)">
                                <strong
                                    ><u
                                        ><span>{{ po.enterprise.businessName }}</span></u
                                    ></strong
                                >
                            </a>
                        </td>
                        <td>
                            <div class="edit-link">
                                <a data-toggle="dropdown">
                                    <div class="dot-icon"></div>
                                </a>
                                <ul class="dropdown-menu" aria-labelledby="menu">
                                    <li *ngIf="loginService.isAdmin() && !po.fulfilled">
                                        <a
                                            class="dropdown-menu-item"
                                            (click)="openModal(po)"
                                            data-toggle="modal"
                                            data-backdrop="false">
                                            <img
                                                src="assets/images/control-icon-view-details.png" />
                                            Fulfill
                                        </a>
                                    </li>
                                    <li>
                                        <a class="dropdown-menu-item" (click)="viewDetails(po)">
                                            <img
                                                src="assets/images/control-icon-view-details.png" />
                                            View Details
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
            <tfoot *ngIf="pagination.showPagination()">
                <tr>
                    <td>
                        Rows per page:
                        <span
                            ><b>{{ pagination.length }}</b></span
                        >
                        <app-page-length-dropdown (emitLength)="changeLength($event)">
                        </app-page-length-dropdown>
                    </td>
                    <td>
                        <app-page-navigation
                            pageNumber="{{ pagination.getPageNo() }}"
                            totalPages="{{ pagination.getTotalPages() }}"
                            (changePage)="changePage($event)">
                        </app-page-navigation>
                    </td>
                </tr>
            </tfoot>
        </table>

        <!-- modal popup started -->
        <app-modal
            [modalId]="modalId"
            buttonConfirmText="Fulfill"
            buttonCancelText="Cancel"
            (modalEventConfirm)="fulfill()"
            (modalEventCancel)="closeModal()">
            <div class="section-heading mb-3">Are you sure you want to fulfill this order?</div>
        </app-modal>
        <!-- modalp popup ends -->
    </div>
</section>
<ng-container *ngIf="displayView">
    <app-product-order-details [productOrder]="selectedProductOrder" (event)="closeSideViewModal()">
    </app-product-order-details>
</ng-container>
