<div class="wrapper">
    <app-navigation></app-navigation>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <app-content-transition></app-content-transition>
        <app-header
            buttonText="Add New User"
            location="Verify Users"
            [showHeaderButton]="true"></app-header>
        <app-links></app-links>
        <div class="progress-container" *ngIf="isLoading">
            <div class="progress-container-text">
                <div>Searching...</div>
            </div>
            <div>
                <div class="custom-progress-bar">
                    <span [style.width.%]="progressWidth"></span>
                </div>
            </div>
        </div>
        <div>
            <div class="status-description">
                <div class="circle-left circle-green"></div>
                <span class="status-description-text">Verified</span>
            </div>
            <div class="status-description">
                <div class="circle-left circle-yello"></div>
                <span class="status-description-text">Pending Verification</span>
            </div>
            <div class="status-description">
                <div class="circle-left circle-red"></div>
                <span class="status-description-text">Failed Verification</span>
            </div>
        </div>
        <div class="container-fluid tab-pane custom-table custom-last-row" *ngIf="!isLoading">
            <br />
            <table id="tableData">
                <thead>
                    <tr>
                        <th>
                            <app-filter-arrow
                                filter="name"
                                title="NAME & SURNAME"
                                (filterValue)="receiveFilter($event)">
                            </app-filter-arrow>
                        </th>
                        <th>
                            <app-filter-arrow
                                filter="mobileNumber"
                                title="&ensp;&ensp;ACCOUNTS"
                                (filterValue)="receiveFilter($event)">
                            </app-filter-arrow>
                        </th>
                        <th>
                            <app-filter-arrow title="ACTIONS"> </app-filter-arrow>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="tablebox" *ngFor="let user of userList">
                        <td>
                            <img
                                class="table-user-img"
                                src="{{
                                    user.profilePicture || 'assets/images/placeholder.png'
                                }}" />
                            <span>{{ user.firstName + ' ' + user.lastName }}</span>
                        </td>
                        <td>
                            <app-account-status
                                status="{{ user.status }}"
                                type="file"
                                businessStatus="{{ user.businessStatus }}">
                            </app-account-status>
                        </td>
                        <td>
                            <app-last-child-verify-users
                                [user]="user"></app-last-child-verify-users>
                        </td>
                    </tr>
                </tbody>
                <tfoot *ngIf="pagination.showPagination()">
                    <tr>
                        <td>
                            Rows per page:
                            <span
                                ><b>{{ pagination.length }}</b></span
                            >
                            <app-page-length-dropdown (emitLength)="changeLength($event)">
                            </app-page-length-dropdown>
                        </td>
                        <td>
                            <app-page-navigation
                                pageNumber="{{ pagination.getPageNo() }}"
                                totalPages="{{ pagination.getTotalPages() }}"
                                (changePage)="changePage($event)">
                            </app-page-navigation>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
    <div class="control-sidebar-bg"></div>
</div>
