<div class="login-background">
    <div class="alert alert-dismissible custom-alert-success" *ngIf="displaySuccess">
        <button
            type="button"
            (click)="closeModel()"
            class="close close-modified"
            data-dismiss="alert">
            &times;
        </button>
        <div class="login-error-text">
            <div class="error-icon"><img src="assets/images/success.png" /></div>
            <div class="login-error-message">{{ successMessage }}</div>
        </div>
    </div>
    <div class="alert alert-dismissible custom-alert-error" *ngIf="display">
        <button
            type="button"
            (click)="closeModel()"
            class="close close-modified"
            data-dismiss="alert">
            &times;
        </button>
        <div class="login-error-text">
            <div class="error-icon"><img src="assets/images/warning.png" /></div>
            <div class="login-error-message">{{ eMessage }}</div>
        </div>
    </div>
    <div class="d-flex justify-content-center align-items-center" *ngIf="isLoading">
        <div class="spinner-border mt-5 text-white" style="width: 4rem; height: 4rem" role="status">
            <span class="sr-only text-white">Loading...</span>
        </div>
    </div>

    <form #myForm="ngForm" (ngSubmit)="verify()" *ngIf="!isLoading">
        <div class="sign-in-box-shape">
            <img
                class="lovcash-logo"
                src="assets/images/lovcash-logo-dark.png"
                alt="lovcash logo" />
            <div>
                <p class="sign-in-portal-text">2-Factor Authentication</p>
                <p class="label-forgot-instruction">
                    Please enter the verification code sent to your email address.
                </p>
            </div>

            <div class="box-outline" [ngClass]="{ 'box-focus': pinFocused }">
                <img src="assets/images/pin-code.png" class="pin-code-image" />
                <input
                    placeholder="123456"
                    type="text"
                    name="otp"
                    [(ngModel)]="otp"
                    (focus)="pinFocusFunction()"
                    (focusout)="pinFocusFunction()"
                    required
                    class="password-text-box" />
                <img src="" class="password-changer" />
            </div>
            <button type="submit" class="box-outline submit-btn" [disabled]="!myForm.valid">
                Verify
            </button>
            <div class="forgot-password-link">
                <a style="color: #43073d; cursor: pointer" (click)="resendOtp()">
                    <p>Didn’t receive your verification code? Resend</p>
                </a>
            </div>
        </div>
    </form>
</div>
