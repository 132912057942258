<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="row">
        <div class="col-md-8">
            <div class="tab-heading">
                <span class="tab-heading-title">{{ title }}</span>
                <img
                    alt="Breadcrumb Arrow"
                    class="breadcrumb-separator"
                    src="assets/images/breadcrumb-separator.png" />
                <app-breadcrumb></app-breadcrumb>
            </div>
        </div>
        @if (showHeaderButton) {
            <div class="col-md-4">
                <div class="right-section">
                    <button class="custombtn" (click)="navigate()">
                        {{ buttonText }}
                    </button>
                </div>
            </div>
        }
    </div>
    <app-alert></app-alert>
</section>
