import { Status } from './status';
import { Enterprise } from './enterprise';

export class SessionUser {
    public 'X-Auth-Key': string;
    public userId: number;
    public userRoleId: number;
    public walletId: number;
    public name: string;
    public email: string;
    public role: string;
    public success: boolean;
    public status: Status;
    public enterprise: Enterprise;

    constructor() {}

    public setFields(
        authKey: string,
        name: string,
        email: string,
        userId: number,
        userRoleId: number,
        walletId: number,
        role: string,
        enterprise: Enterprise,
    ) {
        this['X-Auth-Key'] = authKey;
        this.name = name;
        this.email = email;
        this.userId = userId;
        this.userRoleId = userRoleId;
        this.walletId = walletId;
        this.role = role;
        this.enterprise = enterprise;
    }
}
