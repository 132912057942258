import { Bank } from './bank';
import { PrimaryContact } from './primary.contact';

export class Enterprise {
    public id: string;
    public userId: string;
    public roleId: string;
    public userRoleId: string;
    public businessName: string;
    public employeeName: string;
    public enterpriseId: string;
    public enterpriseEmployeeId: string;
    public status: string;
    public kycLevel: string;
    public createdAt: string;
    public businessLogo: string;
    public lastLogin: string;
    public availableBalance: string;
    public fullBalance: string;
    public publicKey: string;
    public code: string;
    public visibleInPayList: boolean;
    public directDistributor: boolean;
    public bank: Bank = new Bank();
    public primaryContact: PrimaryContact = new PrimaryContact();
}
