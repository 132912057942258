import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/model/user';
import { Pagination } from 'src/app/model/Pagination';
import { CommonService } from 'src/app/services/common.service';
import { UntypedFormBuilder, FormGroup } from '@angular/forms';
import { UserStatus } from 'src/app/enums/user.status';

@Component({
    selector: 'app-denied-applications',
    templateUrl: './denied-applications.component.html',
    styleUrls: ['./denied-applications.component.css'],
})
export class DeniedApplicationsComponent implements OnInit {
    userList: Array<User>;
    pagination = new Pagination();
    isLoading = false;
    progressWidth = 25;
    form: FormGroup;

    constructor(
        private commonService: CommonService,
        private formBuilder: UntypedFormBuilder,
    ) {
        this.form = this.formBuilder.group({
            role: ['agent'],
            status: [UserStatus.disabled],
            start: ['0'],
            length: ['10'],
            isAgent: [true],
        });
    }

    ngOnInit() {
        this.getData();
    }

    getData() {
        this.isLoading = true;
        this.form.get('start').setValue(this.pagination.start);
        this.form.get('length').setValue(this.pagination.length);
        this.setProgressWidth(50);
        this.commonService.getFilteredData(this.form).subscribe(
            (user) => {
                this.setProgressWidth(100);
                setTimeout(() => {
                    this.userList = user.data;
                    this.pagination.total = user.total;
                    this.isLoading = false;
                    this.progressWidth = 25;
                }, 350);
            },
            (error) => {
                this.isLoading = false;
            },
        );
    }

    setProgressWidth(i: number) {
        setTimeout(() => {
            this.progressWidth = i;
        }, 300);
    }

    receiveFilter(data: { name: string; value: number }) {}

    changeLength(len: number) {
        this.pagination.length = len;
        this.pagination.start = 0;
        this.getData();
    }

    changePage(page: number) {
        this.pagination.changePage(page);
        this.getData();
    }

    get controls(): any {
        return this.form.controls;
    }
}
