export enum SmartSafeTransactionType {
    cash_drop = 'Cash Drop',
    cash_remove = 'Cash Remove',
}

export const SmartSafeTransactionTypeList = Object.keys(SmartSafeTransactionType).map((name) => {
    return {
        name,
        value: SmartSafeTransactionType[name as keyof typeof SmartSafeTransactionType],
    };
});

export function getCashDropKey() {
    return getKeyByValue(SmartSafeTransactionType.cash_drop);
}

function getKeyByValue(value: string) {
    const indexOfS = Object.values(SmartSafeTransactionType).indexOf(
        value as unknown as SmartSafeTransactionType,
    );

    const key = Object.keys(SmartSafeTransactionType)[indexOfS];

    return key;
}
