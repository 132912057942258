<div class="circle-left circle-green" *ngIf="status === statusEnum.active"></div>
<div class="circle-left circle-red" *ngIf="status === statusEnum.disabled"></div>
<div class="circle-left circle-yello" *ngIf="status === statusEnum.pending_review"></div>
{{ status | humanize }}
<div class="user-status-dropdown" *ngIf="userId">
    <a data-toggle="dropdown">
        <app-dropdown-icon></app-dropdown-icon>
    </a>
    <ul class="dropdown-menu">
        <li class="dropdown-menu-item" *ngFor="let item of userStatusDropdown">
            <a (click)="changeStatus(item)">
                {{ item | humanize }}
            </a>
        </li>
    </ul>
</div>
