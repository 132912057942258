<div class="form-select-div" [ngClass]="borderFocussed ? 'border-orange' : 'border-white-faded'">
    <a data-toggle="dropdown" (click)="changeBorder()">
        <input
            class="form-select-div-input"
            [placeholder]="placeholderValue"
            type="text"
            [(ngModel)]="inputVal"
            (ngModelChange)="filterList()"
            (focusout)="changeFocus()" />
        <app-dropdown-icon></app-dropdown-icon>
    </a>
    <div class="form-select-dropdown">
        <ul class="dropdown-menu">
            <a (click)="changeValue(item)" *ngFor="let item of dropDownList">
                <li class="dropdown-menu-item">
                    {{ item | humanize }}
                </li>
            </a>
        </ul>
    </div>
</div>

<small *ngIf="form.get(value).invalid && invalidForm" class="error-text">
    This field is required
</small>
