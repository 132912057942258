import {
    Component,
    OnInit,
    Inject,
    Input,
    OnChanges,
    SimpleChanges,
    AfterViewInit,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Wallet } from 'src/app/model/wallet';
import { User } from 'src/app/model/user';
import { AppConstants } from 'src/app/settings/app-constants';

@Component({
    selector: 'app-user-wallet-data',
    templateUrl: './user-wallet-data.component.html',
    styleUrls: ['./user-wallet-data.component.css'],
})
export class UserWalletDataComponent implements OnChanges, AfterViewInit {
    amountFormat = AppConstants.AMOUNT_FORMAT;
    @Input() user: User = new User();
    @Input() tabName: string;
    personalWallet: Wallet;
    merchantWallet: Wallet;
    agentWallet: Wallet;
    wallet: Wallet = new Wallet();
    tabIds = ['tab1', 'tab2', 'tab3'];
    tabContentIds = ['tabContent1', 'tabContent2', 'tabContent3'];

    constructor(@Inject(DOCUMENT) private document: Document) {
        this.user = new User();
    }

    ngAfterViewInit(): void {
        this.changeTab(this.tabName);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.user.previousValue !== changes.user.currentValue) {
            this.setWallet();
        }
    }

    setWallet() {
        this.personalWallet = this.user.userPersonalProfileModel.walletBalance;
        this.merchantWallet = this.user.userBusinessProfileModel.walletBalance;
        this.agentWallet = this.user.userAgentProfileModel.walletBalance;
        setTimeout(() => {
            this.changeTab(this.tabName);
        }, 100);
    }

    changeTab(tabName: string) {
        switch (tabName) {
            case 'personal':
                if (this.personalWallet) {
                    this.updateElement(0);
                } else {
                    this.selectTab();
                }
                break;
            case 'merchant':
                if (this.merchantWallet) {
                    this.updateElement(1);
                } else {
                    this.selectTab();
                }
                break;
            case 'agent':
                if (this.agentWallet) {
                    this.updateElement(2);
                } else {
                    this.selectTab();
                }
                break;
            default:
                if (this.personalWallet) {
                    this.updateElement(0);
                } else {
                    this.selectTab();
                }
                break;
        }
    }

    selectTab() {
        if (this.personalWallet) {
            this.updateElement(0);
        } else if (this.merchantWallet) {
            this.updateElement(1);
        } else if (this.agentWallet) {
            this.updateElement(2);
        }
    }

    updateElement(index: number) {
        const node = this.document.getElementById(this.tabIds[index]);
        if (node) {
            node.className += ' active';
            this.removeClass(index);
        }
        const contentNode = this.document.getElementById(this.tabContentIds[index]);
        if (contentNode) {
            contentNode.className += ' active';
            this.removeClass(index);
        }
    }

    removeClass(index: number) {
        for (let i = 0; i < 3; i++) {
            if (i !== index) {
                const node = this.document.getElementById(this.tabIds[i]);
                if (node) {
                    node.classList.remove('active');
                }
                const contentNode = this.document.getElementById(this.tabContentIds[i]);
                if (contentNode) {
                    contentNode.classList.remove('active');
                }
            }
        }
    }

    copyText(val: string) {
        const input = this.document.createElement('input');
        this.document.body.appendChild(input);
        if (val) {
            input.value = val;
        } else {
            input.value = 'NA';
        }
        input.select();
        this.document.execCommand('copy');
        input.remove();
    }
}
