<div class="wrapper">
    <app-enterprise-navigation></app-enterprise-navigation>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <app-content-transition></app-content-transition>
        <!-- <app-loader *ngIf="isLoading" className="custom-loader" text="Processing ..."></app-loader> -->
        <app-enterprise-header title="Dashboard"></app-enterprise-header>
        <section class="content mb-4">
            <div class="d-flex align-items-baseline">
                <div class="sales-dashboard">
                    <app-sales-dashboard></app-sales-dashboard>
                </div>
                <!-- <div class="waves-system-balance-box width-50" *ngIf="spinnerWavesBalance">
                    <mat-progress-spinner
                        class="example-margin"
                        color="warn"
                        mode="indeterminate"
                        diameter="30"
                    >
                    </mat-progress-spinner>
                </div> -->
            </div>
        </section>
    </div>
    <div class="control-sidebar-bg"></div>
</div>
