import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppConstants } from 'src/app/settings/app-constants';

@Component({
    selector: 'app-last-child-grv',
    templateUrl: './last-child-grv.component.html',
    styleUrls: ['./last-child-grv.component.css'],
})
export class LastChildGrvComponent {
    @Input() grv: any;
    @Output() event = new EventEmitter<{ type: string; value: any }>();
    appConstants = AppConstants;

    constructor() {}

    action(type: string) {
        this.event.emit({ type: type, value: this.grv });
    }
}
