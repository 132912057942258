import { Component, OnInit } from '@angular/core';
import { FeeCode } from 'src/app/enums/fee.code';
import { Role } from 'src/app/enums/role';
import { Fees } from 'src/app/model/fees';
import { CommonUtil } from 'src/app/services/common.util';
import { LoaderService } from 'src/app/services/loader.service';
import { TransactionService } from 'src/app/services/transaction.service';
import { AppConstants } from 'src/app/settings/app-constants';

@Component({
    selector: 'app-fees',
    templateUrl: './fees.component.html',
    styleUrls: ['./fees.component.css'],
})
export class FeesComponent implements OnInit {
    amountFormat = AppConstants.AMOUNT_FORMAT;
    isLoading = false;
    display = false;
    editPersonal = false;
    editBusiness = false;
    editEnterprise = false;
    editAgent = false;
    editMarketFee = false;
    eMessage = '';
    personalFeeList: Array<Fees> = [];
    businessFeeList: Array<Fees> = [];
    enterpriseFeeList: Array<Fees> = [];
    agentFeeList: Array<Fees> = [];
    marketplaceFeeList: Array<Fees> = [];
    feeCode = FeeCode;

    feeLabel = this.commonUtil.getFeeLabels();

    constructor(
        private transactionService: TransactionService,
        public commonUtil: CommonUtil,
        private loaderService: LoaderService,
    ) { }

    ngOnInit() {
        this.isLoading = true;
        this.loaderService.isLoading = true;
        this.transactionService.getFees().subscribe(
            (res) => {
                this.setFee(res.data || []);
                this.isLoading = false;
                this.loaderService.isLoading = false;
            },
            (err) => {
                this.isLoading = false;
            },
        );
    }

    setFee(feeList: Fees[]) {
        this.personalFeeList = [];
        this.businessFeeList = [];
        this.enterpriseFeeList = [];
        this.agentFeeList = [];
        this.marketplaceFeeList = [];

        feeList.forEach((fee) => {
            fee.percentage = fee.percentage || 0.0;
            fee.amount = fee.amount || 0.0;
            fee.useAmount = fee.useAmount || false;
            fee.percentageValue = fee.percentage || 0.0;
            fee.amountValue = fee.amount || 0.0;
            fee.useAmountValue = fee.useAmount || false;

            if (fee.code.startsWith('marketplace_')) {
                this.marketplaceFeeList.push(fee);
            } else if (fee.code.includes(Role.personal)) {
                this.personalFeeList.push(fee);
            } else if (fee.code.includes(Role.business)) {
                this.businessFeeList.push(fee);
            } else if (fee.code.includes(Role.enterprise)) {
                this.enterpriseFeeList.push(fee);
            } else if (fee.code.includes(Role.agent)) {
                this.agentFeeList.push(fee);
            }
        });
    }

    closeModel(val: boolean) {
        this.display = false;
    }

    resetVal(feeList: Fees[]) {
        feeList.forEach((fee) => {
            fee.percentage = fee.percentage || 0.0;
            fee.amount = fee.amount || 0.0;
            fee.useAmount = fee.useAmount || false;
            fee.percentageValue = fee.percentage || 0.0;
            fee.amountValue = fee.amount || 0.0;
            fee.useAmountValue = fee.useAmount || false;
        });
    }

    switchMarketFeeView() {
        this.editMarketFee = !this.editMarketFee;
    }

    submit(type: string) {
        this.isLoading = true;
        const form = [];

        switch (type) {
            case 'personal':
                this.personalFeeList.forEach((fee) => {
                    const value = {
                        code: fee.code,
                        percentage: fee.percentageValue,
                        amount: fee.amountValue,
                        useAmount: fee.useAmountValue,
                    };
                    form.push(value);
                });
                break;
            case 'business':
                this.businessFeeList.forEach((fee) => {
                    const value = {
                        code: fee.code,
                        percentage: fee.percentageValue,
                        amount: fee.amountValue,
                        useAmount: fee.useAmountValue,
                    };
                    form.push(value);
                });
                break;
            case 'enterprise':
                this.enterpriseFeeList.forEach((fee) => {
                    const value = {
                        code: fee.code,
                        percentage: fee.percentageValue,
                        amount: fee.amountValue,
                        useAmount: fee.useAmountValue,
                    };
                    form.push(value);
                });
                break;
            case 'agent':
                this.agentFeeList.forEach((fee) => {
                    const value = {
                        code: fee.code,
                        percentage: fee.percentageValue,
                        amount: fee.amountValue,
                        useAmount: fee.useAmountValue,
                    };
                    form.push(value);
                });
                break;
            default:
                this.marketplaceFeeList.forEach((fee) => {
                    const value = {
                        code: fee.code,
                        percentage: fee.percentageValue,
                        amount: fee.amountValue,
                        useAmount: fee.useAmountValue,
                    };
                    form.push(value);
                });
                this.switchMarketFeeView();
                break;
        }

        this.submitData(form);
    }

    submitData(form: any) {
        this.transactionService.updateFees(form).subscribe(
            (res) => {
                this.isLoading = false;
                //this.changeView(null, type);

                if (res.success) {
                    this.setFee(res.data);
                } else {
                    this.display = true;
                    this.eMessage = res.status.message;
                }
            },
            (err) => {
                this.isLoading = false;
            },
        );
    }

    changeStatus(val) {
        console.log(val);
    }
}
