<section>
    <div class="row user-type-select-component">
        <div class="col-md-12">
            What type of user do you want to add?
            <div class="user-type-dropdown">
                <span class="type-name">{{ type }}</span>
                <a data-toggle="dropdown">
                    <img src="assets/images/dropdown-white.png" />
                </a>
                <ul class="dropdown-menu">
                    <a (click)="changeType(type)" *ngFor="let type of userTypes">
                        <li class="dropdown-menu-item">
                            {{ type }}
                        </li>
                    </a>
                </ul>
            </div>
        </div>
    </div>
</section>
