<div class="wrapper">
    <app-error-model
        message="{{ eMessage }}"
        [display]="display"
        (event)="closeModel($event)"></app-error-model>
    <app-success-model
        message="{{ sMessage }}"
        [display]="displaySuccess"
        (event)="closeModel($event)"></app-success-model>
    <app-navigation></app-navigation>
    <div class="content-wrapper">
        <app-content-transition></app-content-transition>
        <app-loader *ngIf="isLoading" className="custom-loader" text="Processing ..."></app-loader>
        <app-header location="User Account Profile"></app-header>
        <app-edit-links [id]="id"></app-edit-links>
        <app-user-profile-data
            [user]="user"
            [userType]="role.business"
            (updateCode)="updateReferralCode($event)">
        </app-user-profile-data>
        <app-user-wallet-data tabName="merchant" [user]="user"> </app-user-wallet-data>
        <section class="add-section">
            <div class="row">
                <div class="col-md-12 section-heading">Business Details</div>
            </div>
            <div class="details-view">
                <div class="row">
                    <div class="col-md-3">
                        <label class="form-label">STORE / BUSINESS NAME</label>
                        <div class="user-details-text">
                            {{ user.userBusinessProfileModel.businessName || '-' }}
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">BUSINESS CATEGORY</label>
                        <div class="user-details-text">
                            {{ user.userBusinessProfileModel.businsessCategoryName || '-' }}
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">STORE TYPE</label>
                        <div class="user-details-text">
                            {{ user.userBusinessProfileModel.storeTypeName || '-' }}
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">BUSINESS EMAIL ADDRESS</label>
                        <div class="user-details-text">
                            {{ user.userBusinessProfileModel.businessEmail || '-' }}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <label class="form-label">BUSINESS ADDRESS LINE 1</label>
                        <div class="user-details-text">
                            {{ user.userBusinessProfileModel.address.addressLineOne || '-' }}
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">BUSINESS ADDRESS LINE 2</label>
                        <div class="user-details-text">
                            {{ user.userBusinessProfileModel.address.addressLineTwo || '-' }}
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">BUILDING DETAILS</label>
                        <div class="user-details-text">
                            {{ user.userBusinessProfileModel.address.buildingDetails || '-' }}
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">SUBURB</label>
                        <div class="user-details-text">
                            {{ user.userBusinessProfileModel.address.suburb || '-' }}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <label class="form-label">CITY</label>
                        <div class="user-details-text">
                            {{ user.userBusinessProfileModel.address.cityName || '-' }}
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">PROVINCE</label>
                        <div class="user-details-text">
                            {{ user.userBusinessProfileModel.address.provinceName || '-' }}
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">POSTAL CODE</label>
                        <div class="user-details-text">
                            {{ user.userBusinessProfileModel.address.postalCode || '-' }}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 add-margin-row">
                        <label class="form-label">SHORT BUSINESS DESCRIPTION</label>
                        <div class="user-details-text">
                            {{ user.userBusinessProfileModel.businessDescription || '-' }}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="add-section">
            <div class="row">
                <div class="col-md-12 section-heading">Emergency Contact</div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <label class="form-label">FIRST NAME</label>
                    <div class="user-details-text">
                        {{ user.userBusinessProfileModel.emergencyFirstName || '-' }}
                    </div>
                </div>
                <div class="col-md-3">
                    <label class="form-label">LAST NAME</label>
                    <div class="user-details-text">
                        {{ user.userBusinessProfileModel.emergencyLastName || '-' }}
                    </div>
                </div>
                <div class="col-md-3">
                    <label class="form-label">MOBILE NUMBER</label>
                    <div class="user-details-text">
                        {{ user.userBusinessProfileModel.emergencyMobileNumber || '-' }}
                    </div>
                </div>
            </div>
        </section>
        <section
            class="add-section"
            *ngIf="
                user.userBusinessProfileModel.bankList &&
                user.userBusinessProfileModel.bankList.length > 0
            "
            [style.margin.px]="0"
            [style.padding-bottom.px]="0">
            <div class="row">
                <div class="col-md-8 section-heading">Bank Details</div>
            </div>
        </section>
        <ng-container *ngFor="let bank of user.userBusinessProfileModel.bankList">
            <app-user-bank-data
                [bank]="bank"
                (update)="updateBank($event)"
                [hideHeader]="true"
                [hideEdit]="true">
            </app-user-bank-data>
        </ng-container>
    </div>
</div>
