import { Component, OnInit, Input } from '@angular/core';
import { User } from 'src/app/model/user';
import { Router } from '@angular/router';
import { URI } from 'src/app/settings/uri-constants';

@Component({
    selector: 'app-last-child-verify-users',
    templateUrl: './last-child-verify-users.component.html',
    styleUrls: ['./last-child-verify-users.component.css'],
})
export class LastChildVerifyUsersComponent implements OnInit {
    @Input() user: User;
    urlProfile: string;
    urlFile: string;

    constructor(private router: Router) {}

    ngOnInit() {
        const pathProfile = URI.SLASHED_VIEW_PROFILE_PERSONAL.replace(':id', this.user.id);
        this.urlProfile = pathProfile;
        const fileProfile = URI.SLASHED_VIEW_PROFILE_FILES.replace(':id', this.user.id);
        this.urlFile = fileProfile;
    }
}
