import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { URI } from 'src/app/settings/uri-constants';
import { User } from 'src/app/model/user';
import { StorageConstant } from 'src/app/settings/storage-constants';
import { CommonService } from 'src/app/services/common.service';

@Component({
    selector: 'app-change-mobile',
    templateUrl: './change-mobile.component.html',
    styleUrls: ['./change-mobile.component.css'],
})
export class ChangeMobileComponent {
    display = false;
    isLoading = false;
    eMessage = '';
    name: string;
    oldMobile: string;
    form: FormGroup;
    user: User;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private commonService: CommonService,
    ) {
        this.user = JSON.parse(localStorage.getItem(StorageConstant.CHANGE_MOBILE));
        this.name = this.user.firstName + ' ' + this.user.lastName;
        this.oldMobile = this.user.mobileNumber;

        this.form = this.formBuilder.group({
            mobileNumber: [
                '',
                [
                    Validators.required,
                    Validators.maxLength(11),
                    Validators.minLength(9),
                    Validators.pattern('^[0-9]*$'),
                ],
            ],
            oldMobileNumber: [this.oldMobile],
            otpType: ['change_mobile'],
        });
    }

    cancel() {
        this.router.navigate([URI.SLASHED_SEARCH]);
    }

    verify() {
        this.isLoading = true;
        this.commonService.changeMobile(this.form.value).subscribe(
            (res) => {
                this.isLoading = false;
                if (res.success) {
                    localStorage.setItem(StorageConstant.CHANGE_MOBILE_UUID, res.uuid);
                    this.router.navigate([URI.SLASHED_VERIFY_OTP]);
                } else {
                    this.eMessage = res.status.message;
                    this.display = true;
                }
            },
            (err) => {
                if (err.error.error) {
                    this.eMessage = err.error.error;
                } else {
                    this.eMessage = err.error.errors[0];
                }
                this.isLoading = false;
                this.display = true;
            },
        );
    }

    closeModel(enent: any) {
        this.display = false;
    }
}
