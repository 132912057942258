import { Component, OnInit, inject } from '@angular/core';

import { LoginService } from 'src/app/services/login.service';

@Component({
    selector: 'app-enterprise-dashboard',
    templateUrl: './enterprise-dashboard.component.html',
    styleUrls: ['./enterprise-dashboard.component.css'],
})
export class EnterpriseDashboardComponent implements OnInit {
    enterpriseName?: string = '';

    ngOnInit() {
        this.enterpriseName = inject(LoginService).sessionUser.value.enterprise.businessName;
    }
}
