import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { registerLicense } from '@syncfusion/ej2-base';

// Register Syncfusion license key
registerLicense(
    'Ngo9BigBOggjHTQxAR8/V1NBaF5cXmRCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxdcXVdR2NdVUJzW0I=',
);

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
