import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { DocumentFile } from 'src/app/model/DocumentFile';
import { FileType } from 'src/app/enums/file.type';
import { User } from 'src/app/model/user';
import { Role } from 'src/app/enums/role';
import { CommonService } from 'src/app/services/common.service';

@Component({
    selector: 'app-user-profile-data',
    templateUrl: './user-profile-data.component.html',
    styleUrls: ['./user-profile-data.component.css'],
})
export class UserProfileDataComponent implements OnChanges {
    @Input() user: User;
    @Input() width: boolean;
    @Input() userType: string;
    @Output() documents = new EventEmitter<DocumentFile[]>();
    @Output() updateCode = new EventEmitter<string>();
    referralCode: string;
    document: DocumentFile | null;
    documentList: DocumentFile[] = [];
    edit = false;
    code: string = '';
    status: string = '';
    role = Role;

    constructor() {}

    ngOnChanges(changes: SimpleChanges): void {
        if (this.user.profiles) {
            const profile = this.user.profiles.split(',');
            this.user.profiles = profile.join(', ');
        }
        if (this.user.userAgentProfileModel && this.userType == Role.agent) {
            this.referralCode = this.user.userAgentProfileModel.referralCode;
            this.status = this.user.userAgentProfileModel.agentAccountStatus;
        }
        if (this.user.userBusinessProfileModel && this.userType == Role.business) {
            this.referralCode = this.user.userBusinessProfileModel.referralCode;
            this.status = this.user.userBusinessProfileModel.businessStatus;
        }
        if (this.user.userPersonalProfileModel && this.userType == Role.personal) {
            this.referralCode = this.user.userPersonalProfileModel.referralCode;
            this.status = this.user.status;
        }
    }

    validFileType(file: File) {
        if (file.type === 'image/jpeg' || file.type === 'image/png') {
            return true;
        }
        return false;
    }

    changeProfile(files: any) {
        if (this.validFileType(files[0]) && files[0].size > 0) {
            this.updateFile(FileType.profile_picture, files[0]);
        }
    }

    updateFile(type: string, file: File) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const baseFile = reader.result.toString().split(',')[1];
            this.updateList(type, baseFile, file);
        };
    }

    updateList(type: string, base64File: string, file: File) {
        this.document = new DocumentFile();
        this.document.setFields(type, file, base64File);
        this.documentList = this.documentList.filter((obj) => obj.fileType !== type);
        this.documentList.push(this.document);
        this.documents.emit(this.documentList);
    }

    toggle() {
        this.edit = !this.edit;
    }

    updateReferralCode() {
        this.updateCode.emit(this.code);
        this.toggle();
        this.code = '';
    }
}
