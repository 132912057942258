import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { URI } from '../../settings/uri-constants';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../../services/login.service';
import { StorageConstant } from '../../settings/storage-constants';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent {
    show: boolean;
    showConfirm: boolean;
    display: boolean;
    isLoading: boolean;
    errorMessage: string;
    confirmPasswordFocused: boolean;
    passwordFocused: boolean;
    showPasswordImage: string;
    showConfirmPasswordImage: string;
    confirmPassword = '';
    newPassword = '';
    form: FormGroup;
    id: string;

    constructor(
        private router: Router,
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private loginService: LoginService,
    ) {
        this.show = true;
        this.showConfirm = true;
        this.isLoading = false;
        this.passwordFocused = false;
        this.display = false;
        this.errorMessage = '';
        this.showPasswordImage = 'show-password';
        this.showConfirmPasswordImage = 'show-password';

        this.route.paramMap.subscribe((params) => {
            this.id = params.get('id');
        });

        this.form = this.formBuilder.group({
            newPassword: ['', [Validators.minLength(4)]],
            confirmPassword: ['', [Validators.minLength(4)]],
        });
    }

    changePasswordDisplayType() {
        this.show = !this.show;
        this.showPasswordImage =
            this.showPasswordImage === 'show-password' ? 'show-password-active' : 'show-password';
    }

    changeConfirmPasswordDisplayType() {
        this.showConfirm = !this.showConfirm;
        this.showConfirmPasswordImage =
            this.showConfirmPasswordImage === 'show-password'
                ? 'show-password-active'
                : 'show-password';
    }

    openModel() {
        this.display = true;
    }

    closeModel() {
        this.display = false;
    }

    passwordFocusFunction() {
        this.passwordFocused = !this.passwordFocused;
    }

    confirmPasswordFocusFunction() {
        this.confirmPasswordFocused = !this.confirmPasswordFocused;
    }

    goToSignIn() {
        this.router.navigate([URI.SLASHED_LOGIN]);
    }

    submit() {
        if (this.form.value.newPassword !== this.form.value.confirmPassword) {
            this.errorMessage = 'Passwords do not match.';
            this.openModel();
            return false;
        }

        this.isLoading = true;
        this.loginService.changePassword(this.form.value, this.id).subscribe(
            (response) => {
                this.isLoading = false;
                if (response.success) {
                    localStorage.setItem(StorageConstant.CHANGE_PASSWORD_SUCCESS, 'success');
                    this.router.navigate([URI.SLASHED_LOGIN]);
                } else {
                    this.errorMessage = response.status.message;
                    this.openModel();
                }
            },
            (error) => {
                this.isLoading = false;
                this.errorMessage = 'Server Unavailable';
                if (error.status === 401) {
                    this.errorMessage = 'This link has been expired.';
                }
                this.openModel();
            },
        );
    }
}
