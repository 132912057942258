import { Component, OnInit, Inject } from '@angular/core';
import { User } from 'src/app/model/user';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute } from '@angular/router';
import { DocumentFile } from 'src/app/model/DocumentFile';
import { IdType } from 'src/app/enums/id.type';
import { BusinessType } from 'src/app/enums/business.type';
import { FileType } from 'src/app/enums/file.type';
import { DOCUMENT } from '@angular/common';
import { Agent } from 'src/app/model/agent';
import { Merchant } from 'src/app/model/merchant';
import { Personal } from 'src/app/model/personal';
import { FileStatus } from 'src/app/enums/file.status';
import { VerificationType } from 'src/app/enums/verification.type';
import { StorageService } from 'src/app/services/storage.service';

@Component({
    selector: 'app-user-profile-files',
    templateUrl: './user-profile-files.component.html',
    styleUrls: ['./user-profile-files.component.css'],
})
export class UserProfileFilesComponent implements OnInit {
    isLoading = false;
    denyModalDisplay = 'none';
    verifyModalDisplay = 'none';
    verifyImageDisplay = 'none';
    fileIdentity: DocumentFile | null;
    fileVerification: DocumentFile | null;
    fileBusiness: DocumentFile | null;
    fileLogo: DocumentFile | null;
    fileProfile: DocumentFile | null;
    identityFileName = 'COPY OF SA ID';
    businessFileName = 'PROOF OF BUSINESS ADDRESS';
    imageSrc = '';
    type = 'none';
    files: DocumentFile[] = [];
    user: User;
    id: string;
    messageList = [
        'Image quality poor',
        'Incorrect documentation',
        'Damaged documents',
        'Re-KYC to assign account to new user',
    ];
    userStatus = '';
    businessStatus = '';
    statusEnum = FileStatus;

    constructor(
        private commonService: CommonService,
        private route: ActivatedRoute,
        @Inject(DOCUMENT) private document: Document,
        private storageService: StorageService,
    ) {
        this.user = new User();
    }

    ngOnInit() {
        this.route.paramMap.subscribe((params) => {
            this.id = params.get('id');
            this.setUser(false);
        });
    }

    setUser(reload: boolean) {
        this.isLoading = true;
        if (
            !reload &&
            !!this.storageService.getProfileUser() &&
            this.storageService.getProfileUser().id == this.id
        ) {
            this.user = this.storageService.getProfileUser();
            this.files = this.user.documentList;
            this.initializeUser();
            this.setFiles();
            this.isLoading = false;
        } else {
            this.getProfile();
        }
    }

    getProfile() {
        this.isLoading = true;
        this.commonService.getUserProfile(this.id).subscribe(
            (res) => {
                this.isLoading = false;
                this.user = res.data;
                this.files = this.user.documentList;
                this.initializeUser();
                this.setFiles();
                this.storageService.setProfileUser(this.user);
            },
            (err) => {
                this.isLoading = false;
            },
        );
    }

    initializeUser() {
        if (this.user.userPersonalProfileModel === null) {
            this.user.userPersonalProfileModel = new Personal();
        }
        if (this.user.userBusinessProfileModel === null) {
            this.user.userBusinessProfileModel = new Merchant();
        }
        if (this.user.userAgentProfileModel === null) {
            this.user.userAgentProfileModel = new Agent();
        }
    }

    setFiles() {
        if (this.files.length > 0) {
            for (const file of this.files) {
                if (file.verificationType === VerificationType.business) {
                    if (file.fileType === BusinessType.business_logo) {
                        this.fileLogo = file;
                    } else {
                        this.fileBusiness = file;
                        this.businessFileName = 'COPY OF ' + file.fileType.toUpperCase();
                        this.businessStatus = file.status;
                    }
                } else if (file.verificationType === VerificationType.identity) {
                    if (file.fileType === IdType.verification_image) {
                        this.fileVerification = file;
                    } else {
                        this.fileIdentity = file;
                        this.identityFileName = 'COPY OF ' + file.fileType.toUpperCase();
                        this.userStatus = file.status;
                    }
                }
            }
        }
        if (this.files.length > 0) {
            for (const file of this.files) {
                if (file.fileType === FileType.profile_picture) {
                    this.fileProfile = file;
                }
            }
        }
    }

    openDenyOption(type: string) {
        this.denyModalDisplay = 'block';
        this.type = type;
    }

    closeDenyOption() {
        this.denyModalDisplay = 'none';
    }

    openVerifyModal(type: string) {
        this.verifyModalDisplay = 'block';
        this.type = type;
    }

    closeVerifyModal() {
        this.verifyModalDisplay = 'none';
    }

    openImageModal(file: DocumentFile) {
        this.verifyImageDisplay = 'block';
        this.imageSrc = file.filePath + '/' + file.fileName;
    }

    closeVerifyImage() {
        this.verifyImageDisplay = 'none';
    }

    getVereifyObject(type: string) {
        if (type === VerificationType.identity) {
            return {
                status: FileStatus.verified,
                statusMessage: '',
                verificationType: VerificationType.identity,
            };
        } else if (type === VerificationType.business) {
            return {
                status: FileStatus.verified,
                statusMessage: '',
                verificationType: VerificationType.business,
            };
        } else {
            return {
                status: FileStatus.verified,
                statusMessage: '',
                verificationType: VerificationType.residence,
            };
        }
    }

    getDenyObject(type: string) {
        const messageId = this.document
            .querySelector('input[name="operationType"]:checked')
            .attributes.getNamedItem('value').value;

        if (type === VerificationType.identity) {
            return {
                status: FileStatus.verification_failed,
                statusMessage: this.messageList[messageId],
                verificationType: VerificationType.identity,
            };
        } else if (type === VerificationType.business) {
            return {
                status: FileStatus.verification_failed,
                statusMessage: this.messageList[messageId],
                verificationType: VerificationType.business,
            };
        } else {
            return {
                status: FileStatus.verification_failed,
                statusMessage: this.messageList[messageId],
                verificationType: VerificationType.residence,
            };
        }
    }

    submit() {
        const userId = this.user.id;
        const obj = this.getVereifyObject(this.type);

        this.closeVerifyModal();
        this.isLoading = true;
        this.commonService.updateUserDocumnets(obj, userId.toString()).subscribe(
            (res) => {
                this.setUser(true);
            },
            (err) => {
                this.isLoading = false;
            },
        );
    }

    submitReason() {
        const userId = this.user.id;
        const obj = this.getDenyObject(this.type);

        this.closeDenyOption();
        this.isLoading = true;
        this.commonService.updateUserDocumnets(obj, userId.toString()).subscribe(
            (res) => {
                this.setUser(true);
            },
            (err) => {
                this.isLoading = false;
            },
        );
    }
}
