import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { enableRipple } from '@syncfusion/ej2-base';

enableRipple(true);

@Component({
    selector: 'app-safes-header',
    templateUrl: './safes-header.component.html',
    styleUrls: ['./safes-header.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class SafesHeaderComponent {
    @Input() save = false;
    @Input() locations: string;
    @Input() buttonText: string;

    constructor(private router: Router) {}

    // navigate() {
    //     if (this.url) {
    //         this.router.navigate([this.url]);
    //     } else {
    //         this.router.navigate([URI.getRoutePath('adminSmartSafeSummary')]);
    //     }
    // }
}
