import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { Transaction } from 'src/app/model/transaction';
import { TransactionService } from 'src/app/services/transaction.service';

@Component({
    selector: 'app-last-child-enterprise-ttb',
    templateUrl: './last-child-enterprise-ttb.component.html',
    styleUrls: ['./last-child-enterprise-ttb.component.css'],
})
export class LastChildEnterpriseTtbComponent {
    @Input() transaction: Transaction;
    @Output() event = new EventEmitter<{ type: string; value: Transaction }>();
    txnService = inject(TransactionService);

    action(type: string) {
        this.event.emit({ type: type, value: this.transaction });
    }
}
