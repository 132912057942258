import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-dropdown-icon',
    templateUrl: './dropdown-icon.component.html',
    styleUrls: ['./dropdown-icon.component.css'],
})
export class DropdownIconComponent {
    iconSrc: string;

    constructor() {
        this.iconSrc = 'assets/images/dropdown.png';
    }

    onMouseOver() {
        this.iconSrc = 'assets/images/dropdown-hover.png';
    }

    onMouseOut() {
        this.iconSrc = 'assets/images/dropdown.png';
    }
}
