import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CompanyComponent } from 'src/app/company/company.component';
import { CompanyService } from 'src/app/services/company.service';
import { LoginService } from 'src/app/services/login.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { URI } from 'src/app/settings/uri-constants';
import { BreadcrumbService } from './../../services/breadcrumb.service';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.css'],
})
export class NavigationComponent {
    iconName: string = '';
    logoutModalDisplay = 'none';
    name: string = '';
    uri = URI;
    navigationItems = this.navService.getNavigationItems();

    constructor(
        private router: Router,
        private navService: NavigationService,
        private breadcrumbService: BreadcrumbService,
        private loginService: LoginService,
        private dialog: MatDialog,
        public companyService: CompanyService,
    ) {
        this.iconName = 'logout-icon';
        this.name = this.loginService.sessionUser?.value?.name || '';
    }

    mouseEnter() {
        this.iconName = this.iconName === 'logout-icon' ? 'logout-hover' : 'logout-icon';
    }

    logout() {
        this.logoutModalDisplay = 'block';
    }

    closeModal() {
        this.logoutModalDisplay = 'none';
    }

    submit() {
        this.loginService.logout();
    }

    openCompanyDropDown(event: any) {
        this.dialog.open(CompanyComponent);
    }
}
