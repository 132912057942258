<div class="wrapper">
    <app-navigation></app-navigation>
    <div class="content-wrapper">
        <app-content-transition></app-content-transition>
        <app-header title="Agent Applications"></app-header>
        <app-agent-application-link></app-agent-application-link>
        <div class="progress-container" *ngIf="isLoading">
            <div class="progress-container-text">
                <div>Searching...</div>
            </div>
            <div>
                <div class="custom-progress-bar">
                    <span [style.width.%]="progressWidth"></span>
                </div>
            </div>
        </div>
        <div class="container-fluid tab-pane custom-table custom-last-row" *ngIf="!isLoading">
            <br />
            <table id="tableData">
                <thead>
                    <tr>
                        <th>
                            <app-filter-arrow
                                filter="name"
                                title="NAME & SURNAME"
                                (filterValue)="receiveFilter($event)">
                            </app-filter-arrow>
                        </th>
                        <th>
                            <app-filter-arrow
                                filter="mobileNumber"
                                title="DATE RECEIVED"
                                (filterValue)="receiveFilter($event)">
                            </app-filter-arrow>
                        </th>
                        <th>
                            <app-filter-arrow
                                filter="role"
                                title="MOBILE NO."
                                (filterValue)="receiveFilter($event)">
                            </app-filter-arrow>
                        </th>
                        <th>
                            <app-filter-arrow
                                filter="status"
                                title="LOCATION"
                                (filterValue)="receiveFilter($event)">
                            </app-filter-arrow>
                        </th>
                        <th>
                            <app-filter-arrow
                                filter="status"
                                title="VERIFIED DOCUMENTS"
                                (filterValue)="receiveFilter($event)">
                            </app-filter-arrow>
                        </th>
                        <th>
                            <app-filter-arrow title="ACTIONS"> </app-filter-arrow>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="tablebox" *ngFor="let user of userList">
                        <td>
                            <img
                                class="table-user-img"
                                src="{{
                                    user.profilePicture || 'assets/images/placeholder.png'
                                }}" />
                            <span>{{ user.firstName + ' ' + user.lastName }}</span>
                        </td>
                        <td>
                            {{ user.dateCreated ? (user.dateCreated | date: 'mediumDate') : '-' }}
                        </td>
                        <td>{{ user.mobileNumber }}</td>
                        <td>{{ user.location || '-' }}</td>
                        <td><app-user-status status="{{ user.agentStatus }}"></app-user-status></td>
                        <td>
                            <app-last-child-agent [user]="user" (status)="changeStatus($event)">
                            </app-last-child-agent>
                        </td>
                    </tr>
                </tbody>
                <tfoot *ngIf="pagination.showPagination()">
                    <tr>
                        <td>
                            Rows per page:
                            <span
                                ><b>{{ pagination.length }}</b></span
                            >
                            <app-page-length-dropdown (emitLength)="changeLength($event)">
                            </app-page-length-dropdown>
                        </td>
                        <td>
                            <app-page-navigation
                                pageNumber="{{ pagination.getPageNo() }}"
                                totalPages="{{ pagination.getTotalPages() }}"
                                (changePage)="changePage($event)">
                            </app-page-navigation>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
    <div id="denyModal" class="image-modal" [style.display]="denyModalDisplay">
        <div class="deny-window">
            <div class="section-heading ml-5 mb-3">Reason for application denial?</div>
            <div class="deny-reason">
                <label class="radio-container">
                    <input type="radio" name="operationType" value="0" checked />
                    <span class="radio-lable">{{ messageList[0] }}</span>
                    <span class="checkmark"></span>
                </label>
                <label class="radio-container">
                    <input type="radio" name="operationType" value="1" />
                    <span class="radio-lable">{{ messageList[1] }}</span>
                    <span class="checkmark"></span>
                </label>
                <label class="radio-container">
                    <input type="radio" name="operationType" value="2" />
                    <span class="radio-lable">{{ messageList[2] }}</span>
                    <span class="checkmark"></span>
                </label>
            </div>
            <div>
                <button
                    type="submit"
                    class="box-outline submit-btn mb-2"
                    (click)="denyApplication()">
                    Deny
                </button>
                <a class="deny-window-close" (click)="closeDenyOption()"><u>CANCEL</u></a>
            </div>
        </div>
    </div>
    <div id="verifyModal" class="image-modal" [style.display]="verifyModalDisplay">
        <div class="deny-window">
            <div class="section-heading ml-5 pb-5 pl-4">Approve as an Agent?</div>
            <div class="verify-reason">
                <div class="col-md-12">
                    <label class="form-label">NAME</label>
                    <div class="user-details-text">
                        {{ name || '-' }}
                    </div>
                </div>
            </div>
            <div>
                <button type="submit" class="box-outline submit-btn mb-2" (click)="approve()">
                    Yes, Approve
                </button>
                <a class="deny-window-close" (click)="closeVerifyModal()"><u>CANCEL</u></a>
            </div>
        </div>
    </div>
    <div class="control-sidebar-bg"></div>
</div>
