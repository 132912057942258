import { UserAddress } from './user.address';
import { Bank } from './bank';
import { Wallet } from './wallet';

export class Personal {
    public roleId: string;
    public userRoleId: string;
    public gender: string;
    public maritalStatus: string;
    public saIdNumber: string;
    public dob: string;
    public jobTitle: string;
    public profilePicture: string;
    public address = new UserAddress();
    public bankList: Bank[] = [];
    public walletBalance: Wallet;
    public lastLogin: string;
    public referralCode: string;
    public idType: string;
    constructor() {}
}
