<section class="add-section" *ngIf="edit" [style.margin.px]="0" name="bank-model">
    <div class="row">
        <div class="col-md-8 section-heading">
            <ng-container *ngIf="!hideHeader"> Bank Details </ng-container>
        </div>
        <div class="col-md-4 section-heading" *ngIf="!hideEdit">
            <app-edit-pencil (edit)="changeView($event)"></app-edit-pencil>
        </div>
    </div>
    <form [formGroup]="form">
        <div class="row">
            <div class="col-md-6">
                <label class="form-label">BANK*</label>
                <app-select-box
                    placeholderValue="Select Bank"
                    value="bankName"
                    [form]="form"
                    [invalidForm]="invalidForm"
                    [dropDown]="bankNameList">
                </app-select-box>
            </div>
            <div class="col-md-6">
                <label class="form-label">BRANCH CODE*</label>
                <div>
                    <input
                        class="form-input"
                        type="text"
                        formControlName="branchCode"
                        name="branchCode"
                        placeholder="Bank Branch Code" />
                </div>
                <small *ngIf="form.get('branchCode').invalid && invalidForm" class="error-text">
                    This field is required
                </small>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label class="form-label">LOCATION*</label>
                <div>
                    <input
                        class="form-input"
                        type="text"
                        formControlName="bankLocation"
                        name="bankLocation"
                        placeholder="e.g. South Africa" />
                </div>
                <small *ngIf="form.get('bankLocation').invalid && invalidForm" class="error-text">
                    This field is required
                </small>
            </div>
            <div class="col-md-6">
                <label class="form-label">ACCOUNT HOLDER FULL NAME*</label>
                <div>
                    <input
                        class="form-input"
                        type="text"
                        formControlName="accountHolderName"
                        name="accountHolderName"
                        placeholder="Full Name" />
                </div>
                <small
                    *ngIf="form.get('accountHolderName').invalid && invalidForm"
                    class="error-text">
                    This field is required
                </small>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label class="form-label">ACCOUNT TYPE*</label>
                <app-select-box
                    placeholderValue="Select Type"
                    value="accountType"
                    [form]="form"
                    [invalidForm]="invalidForm"
                    [dropDown]="accountTypeDropdown">
                </app-select-box>
            </div>
            <div class="col-md-6">
                <label class="form-label">ACCOUNT NUMBER*</label>
                <div>
                    <input
                        class="form-input"
                        type="text"
                        formControlName="accountNumber"
                        name="accountNumber"
                        placeholder="e.g. 746837462556" />
                </div>
                <small *ngIf="form.get('accountNumber').invalid && invalidForm" class="error-text">
                    This field is required
                </small>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6"></div>
            <div class="col-md-6">
                <button class="custombtn custombtn-align-right" (click)="submit()">SAVE</button>
                <a class="user-files-deny" (click)="changeView($event)"><u>CANCEL</u></a>
            </div>
        </div>
    </form>
</section>
<section class="add-section" *ngIf="!edit" [style.margin.px]="0" name="bank-model">
    <div class="row">
        <div class="col-md-8 section-heading">
            <ng-container *ngIf="!hideHeader"> Bank Details </ng-container>
        </div>
        <div class="col-md-4 section-heading" *ngIf="!hideEdit">
            <app-edit-pencil (edit)="changeView($event)"></app-edit-pencil>
        </div>
    </div>
    <div class="details-view">
        <div class="row">
            <div class="col-md-3">
                <label class="form-label">BANK</label>
                <div class="user-details-text">
                    {{ bank.bankName || '-' }}
                </div>
            </div>
            <div class="col-md-3">
                <label class="form-label">BRANCH CODE</label>
                <div class="user-details-text">
                    {{ bank.branchCode || '-' }}
                </div>
            </div>
            <div class="col-md-3">
                <label class="form-label">LOCATION</label>
                <div class="user-details-text">
                    {{ bank.bankLocation || '-' }}
                </div>
            </div>
            <div class="col-md-3">
                <label class="form-label">ACCOUNT HOLDER FULL NAME</label>
                <div class="user-details-text">
                    {{ bank.accountHolderName || '-' }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <label class="form-label">ACCOUNT TYPE</label>
                <div class="user-details-text">
                    {{ bank.accountType || '-' }}
                </div>
            </div>
            <div class="col-md-3">
                <label class="form-label">ACCOUNT NUMBER</label>
                <div class="user-details-text">
                    {{ bank.accountNumber || '-' }}
                </div>
            </div>
        </div>
    </div>
</section>
