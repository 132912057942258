<section class="content">
    <!-- Default box -->
    <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item">
            <a
                class="nav-link"
                routerLinkActive="tab-link-active"
                [routerLink]="uri.getRouteNavPath('adminEFTCashInPending')">
                Pending
            </a>
        </li>
        <li class="nav-item">
            <a
                class="nav-link"
                routerLinkActive="tab-link-active"
                [routerLink]="uri.getRouteNavPath('adminEFTCashInReceived')">
                Received
            </a>
        </li>
        <li class="nav-item">
            <a
                class="nav-link"
                routerLinkActive="tab-link-active"
                [routerLink]="uri.getRouteNavPath('adminEFTCashInCancelled')">
                Cancelled
            </a>
        </li>
    </ul>
</section>
