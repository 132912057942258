<section class="content">
    <!-- Default box -->
    <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item">
            <a
                class="nav-link"
                routerLinkActive="tab-link-active"
                [routerLink]="uri.getRouteNavPath('adminAgentApplicationsActive')"
                [routerLinkActiveOptions]="{ exact: true }">
                Active Applications
            </a>
        </li>
        <li class="nav-item">
            <a
                class="nav-link"
                routerLinkActive="tab-link-active"
                [routerLink]="uri.getRouteNavPath('adminAgentApplicationsDenied')">
                Denied Applications
            </a>
        </li>
        <li class="nav-item" *ngIf="display">
            <a
                class="nav-link"
                routerLinkActive="tab-link-active"
                [routerLink]="uri.getRouteNavPath('adminAgentApplicationsActiveFiles')">
                Files
            </a>
        </li>
    </ul>
</section>
