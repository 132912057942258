<div class="login-background">
    <div class="alert alert-dismissible custom-alert-success" *ngIf="displaySuccess">
        <button
            type="button"
            (click)="closeModel()"
            class="close close-modified"
            data-dismiss="alert">
            &times;
        </button>
        <div class="login-error-text">
            <div class="error-icon"><img src="assets/images/success.png" /></div>
            <div class="login-error-message">{{ successMessage }}</div>
        </div>
    </div>
    <div class="alert alert-dismissible custom-alert-error" *ngIf="display">
        <button
            type="button"
            (click)="closeModel()"
            class="close close-modified"
            data-dismiss="alert">
            &times;
        </button>
        <div class="login-error-text">
            <div class="error-icon"><img src="assets/images/warning.png" /></div>
            <div class="login-error-message">{{ errorMessage }}</div>
        </div>
    </div>
    <div class="d-flex justify-content-center align-items-center" *ngIf="isLoading">
        <div class="spinner-border mt-5 text-white" style="width: 4rem; height: 4rem" role="status">
            <span class="sr-only text-white">Loading...</span>
        </div>
    </div>

    <form [formGroup]="form" *ngIf="!isLoading">
        <div class="sign-in-box-shape">
            <img
                class="lovcash-logo"
                src="assets/images/lovcash-logo-dark.png"
                alt="lovcash logo" />
            <div>
                <p class="sign-in-portal-text">Create a Password</p>
            </div>
            <div class="box-outline" [ngClass]="{ 'box-focus': passwordFocused }">
                <img src="assets/images/pin-code.png" class="pin-code-image" />
                <input
                    placeholder="Create a Password (Numbers only)"
                    [type]="show ? 'password' : 'text'"
                    name="password"
                    formControlName="newPassword"
                    (focus)="passwordFocusFunction()"
                    (focusout)="passwordFocusFunction()"
                    required
                    class="password-text-box" />
                <a (click)="changePasswordDisplayType()" class="password-changer">
                    <img class="show-pass-class" src="assets/images/{{ showPasswordImage }}.png" />
                </a>
            </div>
            <div class="box-outline mb-5" [ngClass]="{ 'box-focus': confirmPasswordFocused }">
                <img src="assets/images/pin-code.png" class="pin-code-image" />
                <input
                    placeholder="Confirm Password"
                    [type]="showConfirm ? 'password' : 'text'"
                    name="confirmPassword"
                    formControlName="confirmPassword"
                    (focus)="confirmPasswordFocusFunction()"
                    (focusout)="confirmPasswordFocusFunction()"
                    required
                    class="password-text-box" />
                <a (click)="changeConfirmPasswordDisplayType()" class="password-changer">
                    <img
                        class="show-pass-class"
                        src="assets/images/{{ showConfirmPasswordImage }}.png" />
                </a>
            </div>
            <div class="forgot-password-link" *ngIf="resend">
                <a style="color: #43073d; cursor: pointer" (click)="resendEmail()">
                    <p>Resend Verification Link</p>
                </a>
            </div>
            <button
                type="button"
                (click)="submit()"
                class="box-outline submit-btn"
                [disabled]="form.invalid">
                Continue
            </button>
        </div>
    </form>
</div>
