import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder } from '@angular/forms';

import merge from 'lodash-es/merge';

import { SmartSafeStatusList } from 'src/app/enums/smartsafe.status';
import { Pagination } from 'src/app/model/Pagination';
import { SmartSafeService } from 'src/app/services/smart-safe.service';
import { AppConstants } from 'src/app/settings/app-constants';
import { URI } from 'src/app/settings/uri-constants';

@Component({
    selector: 'app-safe-summary',
    templateUrl: './safe-summary.component.html',
    styleUrls: ['./safe-summary.component.css'],
})
export class SafeSummaryComponent implements OnInit {
    uri = URI;
    showParent = true;
    exactOnSafesRoute = true;
    isLoading: boolean = false;
    loaderText = 'Searching...';
    progressWidth = 25;
    display = false;
    displayView = false;
    displayUpdate = 'none';
    displaySuccess = false;
    selectedSafeSummary: any;
    selectedCurrency: any;
    smartSafeSummaryList: any[];
    pagination = new Pagination();
    showOptions: boolean = false;
    form: FormGroup;
    amountFormat = AppConstants.AMOUNT_FORMAT;
    statusList = SmartSafeStatusList;
    searchItems: string[] = [];
    itemList = ['status'];
    search = false;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private smartSafeService: SmartSafeService,
    ) {}

    ngOnInit() {
        this.form = this.formBuilder.group({
            text: new FormControl(''),
            start: new FormControl(''),
            length: new FormControl(''),
            status: new FormControl(null),
        });
        this.getData();
    }

    /**
     * Clears the text field
     */
    clearText() {
        this.form.get('text').setValue('');
    }

    toggleOption() {
        this.showOptions = !this.showOptions;
    }

    submit() {
        this.getData();
    }

    getData() {
        this.isLoading = true;
        this.search = false;
        this.form.get('start').setValue(this.pagination.start);
        this.form.get('length').setValue(this.pagination.length);

        // Copy form values into a new object
        const params = merge({}, this.form.value);

        // Set status value to empty string if it's null to cater for backend.
        if (params?.status === null) {
            params.status = '';
        }

        this.smartSafeService.getSmartSafesSummary(params).subscribe(
            (res) => {
                this.smartSafeSummaryList = res.responseBody.data;
                this.pagination.total = res.responseBody.total;
                this.isLoading = false;

                this.searchItems = [];
                for (const item of this.itemList) {
                    if (this.form.get(item).value !== null) {
                        this.searchItems.push(this.form.get(item).value);
                    }
                }
            },
            (error) => {
                this.isLoading = false;
            },
        );
    }

    action(safeSummary: any) {
        this.selectedSafeSummary = safeSummary;
        this.openViewModel();
    }

    openViewModel() {
        this.displayView = true;
    }

    closeViewModel() {
        this.displayView = false;
    }

    closeModel(event: any) {
        this.display = false;
    }

    isEmpty(item): boolean {
        return this.form.get(item).value.length <= 0;
    }

    receiveFilter(data: { name: string; value: number }) {}

    removeSearchItem(value: string) {
        for (const item of this.itemList) {
            if (this.form.get(item).value === value) {
                this.form.get(item).setValue('');
                this.getData();
                break;
            }
        }
    }

    changeLength(len: number) {
        this.pagination.length = len;
        this.pagination.start = 0;
        this.getData();
    }

    changePage(page: number) {
        this.pagination.changePage(page);
        this.getData();
    }

    getTransactionsBySafeIdRoute(safeId) {
        return (
            this.uri.getRouteNavPath('adminSmartSafeSummary') +
            '/' +
            this.uri.getRouteNavPath('adminSmartSafeTransactionBySafe')
        ).replace(':safeId', safeId);
    }

    getTransactionsByUserRoute(safeId, userRoleId) {
        let path =
            this.uri.getRouteNavPath('adminSmartSafeSummary') +
            this.uri.getRouteNavPath('adminSmartSafeTransactionByUser');

        path = path.replace(':safeId', safeId).replace(':userRoleId', userRoleId);
        return path;
    }

    onActivate() {
        this.showParent = false;
    }

    onDeactivate() {
        this.showParent = true;
    }
}
