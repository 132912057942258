import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { URI } from 'src/app/settings/uri-constants';
import { StorageConstant } from 'src/app/settings/storage-constants';
import { CashbackSetting } from 'src/app/model/cashback.setting';

@Component({
    selector: 'app-last-child-enterprise-cashback-settings',
    templateUrl: './last-child-enterprise-cashback-settings.component.html',
    styleUrls: ['./last-child-enterprise-cashback-settings.component.css'],
})
export class LastChildEnterpriseCashbackSettingsComponent {
    @Input() cashbackSetting: FormGroup;
    @Output() event = new EventEmitter<{ formGroup: FormGroup }>();
    @Output() deleteEvent = new EventEmitter<{ formGroup: FormGroup }>();

    constructor(private router: Router) {}

    editCashbackSetting() {
        this.event.emit({ formGroup: this.cashbackSetting });
    }

    deleteCashbackSetting() {
        this.deleteEvent.emit({ formGroup: this.cashbackSetting });
    }
}
