import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.css'],
})
export class ModalComponent {
    @Input() modalId: string;
    @Input() modalTitle: string;
    @Input() buttonConfirmText: string;
    @Input() buttonCancelText: string;
    @Output() modalEventConfirm = new EventEmitter();
    @Output() modalEventCancel = new EventEmitter();

    constructor(private modalService: ModalService) { }

    modalConfirmEvent(id: string) {
        this.modalEventConfirm.emit(id);
    }

    modalCancelEvent(id: string) {
        this.modalService.close(id);
        this.modalEventCancel.emit();
    }
}
