import { map } from 'jquery';

export class Pagination<T> {
    public start = 0;
    public length = 10;
    public businessName: string;
    public idNumber: string;
    public idType: string;
    public role: string;
    public status: string;
    public isAgent = false;
    public text = '';
    public total = 0;
    public data: Array<T>;
    public filter = new Map();
    public orderNumber: string;

    constructor() {}

    public showPagination() {
        return this.getTotalPages() > 0;
    }

    // public setFilter(data: {name: string, value: number}) {
    //     switch (data.name) {
    //         case 'businessName' :  this.setName(data.value); break;
    //         case 'idNumber' :  this.setKycLevel(data.value); break;
    //         case 'idType' :  this.setEvaluationScore(data.value); break;
    //         case 'role' :  this.setRole(data.value); break;
    //         case 'status' :  this.setStatus(data.value); break;
    //     }
    // }

    public getPageNo() {
        if (this.total === 0) {
            return 0;
        }

        return this.start / this.length + 1;
    }

    public getTotalPages() {
        if (!this.total || this.total === 0) {
            return 0;
        }

        if (this.total % this.length === 0) {
            return Math.floor(this.total / this.length);
        } else {
            return Math.floor(this.total / this.length + 1);
        }
    }

    public changePage(page: number) {
        switch (page) {
            case -2:
                this.start = 0;
                break;
            case -1:
                this.start = this.start - this.length;
                break;
            case 1:
                this.start = this.start + this.length;
                break;
            case 2:
                this.start = (this.getTotalPages() - 1) * this.length;
                break;
        }
    }

    public updateFilter(event: { name: string; value: number }) {
        this.filter.set(event.name, event.value);
    }

    public filterData(event: { name: string; value: number }) {
        const list = this.data.slice();
        this.updateFilter(event);

        for (const key of [...this.filter.keys()].reverse()) {
            if (this.filter.get(key) == 1) {
                list.sort((a, b) => this.compare(a, b, key));
            }
            if (this.filter.get(key) == 2) {
                list.sort((a, b) => this.compare(b, a, key));
            }
        }
        return list;
    }

    public compare(a, b, filter) {
        if (a[filter] > b[filter]) return 1;
        if (a[filter] < b[filter]) return -1;
        return 0;
    }
}
