import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { URI } from '../settings/uri-constants';
import { LoginService } from './login.service';

@Injectable({ providedIn: 'root' })
export class RoutePermission {
    constructor(
        private loginService: LoginService,
        private router: Router,
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): boolean | UrlTree | Promise<boolean | UrlTree> | Observable<boolean | UrlTree> {
        return this.loginService.sessionUser.pipe(
            map((user) => {
                const isAuth = !!user;
                if (isAuth) {
                    return isAuth;
                }
                return this.router.createUrlTree([URI.SLASHED_LOGIN]);
            }),
        );
    }
}
