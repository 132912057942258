<div class="wrapper">
    <div class="alert alert-dismissible custom-alert-success" *ngIf="mobileChanged">
        <button
            type="button"
            (click)="closeModel($event)"
            class="close close-modified"
            data-dismiss="alert">
            &times;
        </button>
        <div class="login-error-text">
            <div class="error-icon"><img src="assets/images/success.png" /></div>
            <div class="login-error-message">Mobile Number Updated Successfully</div>
        </div>
    </div>
    <app-success-model
        message="{{ createdMessage }}"
        [display]="userCreated"
        (event)="closeModel($event)"></app-success-model>
    <app-navigation></app-navigation>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <app-content-transition></app-content-transition>
        <app-header
            buttonText="Add New User"
            location="Search Users"
            [showHeaderButton]="true"></app-header>
        <app-links></app-links>
        <section class="add-section">
            <form [formGroup]="form">
                <div class="row">
                    <div class="col-md-12 section-heading">User Search</div>
                </div>
                <div class="row pb-3">
                    <div class="col-md-6">
                        <label class="form-label"
                            >SEARCH BY USERNAME, ENTERPRISE OR MOBILE NUMBER</label
                        >
                        <div class="input-icons">
                            <i class="fa fa-search icon" aria-hidden="true"></i>
                            <input
                                class="form-input"
                                type="text"
                                placeholder="eg. Name Surname or 0723117955"
                                formControlName="text" />
                            <a *ngIf="form.get('text').value.length > 0" (click)="clearText()">
                                <img src="assets/images/close-x-purple.png" />
                            </a>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="form-label"></label>
                        <div>
                            <button class="search-button" (click)="submit()">Search</button>
                            <a class="advanced-search" (click)="toggleOption()">
                                <img src="assets/images/dropdown.png" />
                                <u>ADVANCED SEARCH</u>
                            </a>
                        </div>
                    </div>
                </div>
                <hr *ngIf="showOptions" />
                <div class="search-form" *ngIf="showOptions">
                    <div class="row">
                        <div class="col-md-4">
                            <label class="form-label">BUSINESS NAME</label>
                            <div>
                                <input
                                    class="form-input"
                                    type="text"
                                    placeholder="eg. Business Name"
                                    formControlName="businessName" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label class="form-label">ACCOUNT TYPE</label>
                            <div>
                                <app-select-box
                                    placeholderValue="Select Type"
                                    [form]="form"
                                    value="role"
                                    [invalidForm]="invalidForm"
                                    [dropDown]="accountTypeDropdown">
                                </app-select-box>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label class="form-label">ACCOUNT STATUS</label>
                            <div>
                                <app-select-box
                                    placeholderValue="Select Status"
                                    [form]="form"
                                    value="status"
                                    [invalidForm]="invalidForm"
                                    [dropDown]="statusDropdown">
                                </app-select-box>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <label class="form-label">ID TYPE</label>
                            <div>
                                <app-select-box
                                    placeholderValue="Select Status"
                                    [form]="form"
                                    value="idType"
                                    [invalidForm]="invalidForm"
                                    [dropDown]="idTypeDropdown">
                                </app-select-box>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label class="form-label">ID NUMBER</label>
                            <div>
                                <input
                                    class="form-input"
                                    type="text"
                                    placeholder="eg. 8906020107083"
                                    formControlName="idNumber" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label class="form-label">SUBURB</label>
                            <div>
                                <input
                                    class="form-input"
                                    type="text"
                                    placeholder="Suburb"
                                    formControlName="suburb" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <label class="form-label">START DATE</label>
                            <div class="datepicker-width-85">
                                <input
                                    matInput
                                    class="datepicker-input"
                                    [matDatepicker]="picker"
                                    name="fromDate"
                                    formControlName="fromDate"
                                    readonly />
                                <mat-datepicker-toggle
                                    matSuffix
                                    [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label class="form-label">END DATE</label>
                            <div class="datepicker-width-85">
                                <input
                                    matInput
                                    class="datepicker-input"
                                    [matDatepicker]="picker1"
                                    name="toDateTemp"
                                    formControlName="toDateTemp"
                                    readonly />
                                <mat-datepicker-toggle
                                    matSuffix
                                    [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label class="form-label">REFERRED BY</label>
                            <div>
                                <input
                                    class="form-input"
                                    type="text"
                                    placeholder="Name or Business Name"
                                    formControlName="referredBy" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <label class="form-label"></label>
                            <div>
                                <a class="p-3 text-danger line-height-3" (click)="clearDates()"
                                    ><u>Clear Dates</u></a
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="searched-items">
                    <div *ngIf="searchItems.length > 0">
                        <div *ngFor="let item of searchItems" class="search-item-value">
                            {{ item | humanize }}
                            <a (click)="removeSearchItem(item)">
                                <img src="assets/images/close-x-white.png" />
                            </a>
                        </div>
                    </div>
                </div>
            </form>
        </section>
        <div class="progress-container" *ngIf="isLoading">
            <div class="progress-container-text">
                <div>{{ loaderText }}</div>
            </div>
            <div>
                <div class="custom-progress-bar">
                    <span [style.width.%]="progressWidth"></span>
                </div>
            </div>
        </div>
        <section *ngIf="search">
            <div class="row mb-3" *ngIf="userList.length > 0">
                <div class="col-md-6">
                    <a (click)="downLoadExcel()" class="download-transaction">
                        <u>DOWNLOAD USERS</u>
                    </a>
                </div>
            </div>
            <div class="search-result-header">
                Search Results: {{ userList.length }} of {{ pagination.total }}
                <div class="status-description-search">
                    <div class="circle-left circle-red"></div>
                    <span class="status-description-text">Disabled</span>
                </div>
                <div class="status-description-search">
                    <div class="circle-left circle-yello"></div>
                    <span class="status-description-text">Pending Review</span>
                </div>
                <div class="status-description-search">
                    <div class="circle-left circle-green"></div>
                    <span class="status-description-text">Active</span>
                </div>
            </div>
            <hr />
            <div class="container-fluid tab-pane custom-table">
                <br />
                <table id="tableData">
                    <thead>
                        <tr>
                            <th width="25%">
                                <app-filter-arrow
                                    filter="name"
                                    title="NAME & SURNAME"
                                    (filterValue)="receiveFilter($event)">
                                </app-filter-arrow>
                            </th>
                            <th width="22%">
                                <app-filter-arrow
                                    filter="mobileNumber"
                                    title="BUSINESS NAME"
                                    (filterValue)="receiveFilter($event)">
                                </app-filter-arrow>
                            </th>
                            <th width="14%">
                                <app-filter-arrow
                                    filter="mobileNumber"
                                    title="MOBILE NUMBER"
                                    (filterValue)="receiveFilter($event)">
                                </app-filter-arrow>
                            </th>
                            <th width="35%">
                                <app-filter-arrow
                                    filter="role"
                                    title="ACCOUNTS"
                                    (filterValue)="receiveFilter($event)">
                                </app-filter-arrow>
                            </th>
                            <th width="5%">
                                <app-filter-arrow title="ACTIONS"> </app-filter-arrow>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="tablebox" *ngFor="let user of userList">
                            <td width="24%">
                                <img
                                    class="table-user-img"
                                    src="{{
                                        user.profilePicture || 'assets/images/placeholder.png'
                                    }}" />
                                <a (click)="commonUtil.navigate('profile', user)">
                                    <strong
                                        ><u
                                            ><span>{{
                                                user.firstName + ' ' + user.lastName
                                            }}</span></u
                                        ></strong
                                    >
                                </a>
                            </td>
                            <td width="21%">{{ user.businessName }}</td>
                            <td width="13%">{{ user.mobileNumber }}</td>
                            <td width="35%">
                                <app-account-status
                                    type="user"
                                    status="{{ user.status }}"
                                    businessStatus="{{ user.businessStatus }}"
                                    agentStatus="{{ user.agentStatus }}"
                                    enterpriseStatus="{{ user.enterpriseStatus }}"
                                    staffStatus="{{ user.staffStatus }}">
                                </app-account-status>
                            </td>
                            <td width="4%"><app-last-child [user]="user"> </app-last-child></td>
                        </tr>
                    </tbody>
                    <tfoot *ngIf="pagination.showPagination()">
                        <tr>
                            <td>
                                Rows per page:
                                <span
                                    ><b>{{ pagination.length }}</b></span
                                >
                                <app-page-length-dropdown (emitLength)="changeLength($event)">
                                </app-page-length-dropdown>
                            </td>
                            <td>
                                <app-page-navigation
                                    pageNumber="{{ pagination.getPageNo() }}"
                                    totalPages="{{ pagination.getTotalPages() }}"
                                    (changePage)="changePage($event)">
                                </app-page-navigation>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </section>
    </div>
    <div class="control-sidebar-bg"></div>
</div>
