import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { Pagination } from 'src/app/model/Pagination';
import { Fees } from 'src/app/model/fees';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { EnterpriseService } from 'src/app/services/enterprise.service';
import { TransactionService } from 'src/app/services/transaction.service';
import { LoginService } from 'src/app/services/login.service';
import { CompanyService } from 'src/app/services/company.service';

@Component({
    selector: 'app-enterprise-cashback-settings',
    templateUrl: './enterprise-cashback-settings.component.html',
    styleUrls: ['./enterprise-cashback-settings.component.css'],
})
export class EnterpriseCashbackSettingsComponent implements OnInit {
    eMessage = '';
    sMessage = '';
    display = false;
    displaySuccess = false;
    userRoleId: number;
    form: FormGroup;
    cashbackFee = null;
    cashbackFeeCode = 'enterprise_receiver_cbk';
    cashbackSettings: UntypedFormArray;
    invalidForm = false;
    pagination = new Pagination();
    isLoading = false;
    progressWidth = 25;
    settingsId: string;
    modalDisplay = 'none';
    maxSize = 5;

    constructor(
        private router: Router,
        private formBuilder: UntypedFormBuilder,
        private loginService: LoginService,
        private enterpriseService: EnterpriseService,
        private transactionService: TransactionService,
        @Inject(DOCUMENT) private document: Document,
        public companyService: CompanyService,
    ) {
        this.userRoleId = this.loginService.sessionUser.value.userRoleId;
        this.initForm();
    }

    ngOnInit() {
        this.getCashbackSettings();
    }

    setProgressWidth(i: number) {
        setTimeout(() => {
            this.progressWidth = i;
        }, 300);
    }

    closeModel(event: any) {
        this.display = false;
        this.displaySuccess = false;
    }

    createFormItem(cashbackSetting): FormGroup {
        return this.formBuilder.group({
            cashbackSettingId: [cashbackSetting.id],
            minAmount: [cashbackSetting.minAmount],
            maxAmount: [cashbackSetting.maxAmount],
            lovcashFee: [this.cashbackFee],
            percentage: [cashbackSetting.percentage],
            userRoleId: [cashbackSetting.userRoleId],
            isEditable: [false],
            isNewInstance: [false],
        });
    }

    initFormItem(): FormGroup {
        return this.formBuilder.group({
            minAmount: [''],
            maxAmount: [''],
            lovcashFee: [this.cashbackFee],
            percentage: [''],
            userRoleId: [this.userRoleId],
            isEditable: [true],
            isNewInstance: [true],
        });
    }

    initForm() {
        this.form = this.formBuilder.group({
            cashbackSettings: this.formBuilder.array([]),
        });
        this.cashbackSettings = this.form.get('cashbackSettings') as UntypedFormArray;
    }

    initNewCashbackSetting() {
        this.cashbackSettings = this.form.get('cashbackSettings') as UntypedFormArray;
        if (this.newInstanceInForm()) {
            return;
        }
        this.cashbackSettings.push(this.initFormItem());
    }

    populateFormItems(cashbackSettings) {
        this.maxSize = 0;
        for (const cashbackSetting of cashbackSettings) {
            this.cashbackSettings.push(this.createFormItem(cashbackSetting));
            this.maxSize = this.maxSize + 1;
        }
    }

    get formControls(): any {
        return this.form.controls.cashbackSettings;
    }

    mouseOver() {
        let element = this.document.getElementById('add-spend-level');
        element.style.backgroundColor = '#dee0ea';

        element = this.document.getElementById('add-icon');
        element.setAttribute('src', 'assets/images/add-icon-orange.png');
    }

    mouseOut() {
        let element = this.document.getElementById('add-spend-level');
        element.style.backgroundColor = '';

        element = this.document.getElementById('add-icon');
        element.setAttribute('src', 'assets/images/add-icon-dark.png');
    }

    openModal() {
        this.modalDisplay = 'block';
    }

    closeModal() {
        this.modalDisplay = 'none';
    }

    editCashbackSetting(event: { formGroup: FormGroup }) {
        this.removeAnyNewInstanceOnEdit();
        event.formGroup.get('isEditable').setValue(true);
    }

    deleteEvent(event: { formGroup: FormGroup }) {
        this.settingsId = event.formGroup.get('cashbackSettingId').value;
        this.openModal();
    }

    getEnterpriseCashbackFee(formData: any) {
        this.isLoading = true;
        this.setProgressWidth(50);

        this.transactionService.getFeeByCode(this.cashbackFeeCode).subscribe(
            (res) => {
                this.setProgressWidth(100);
                if (res.success) {
                    this.cashbackFee = res.data.percentage;
                    this.setupForm(formData);
                } else {
                    this.display = true;
                    this.eMessage = res.status.message;
                }
                this.isLoading = false;
                this.progressWidth = 25;
            },
            (error) => {
                this.isLoading = false;
            },
        );
    }

    getCashbackSettings() {
        this.isLoading = true;

        const userRoleId = this.userRoleId.toString();
        this.setProgressWidth(50);
        this.enterpriseService.getCashbackSettings(userRoleId).subscribe(
            (res) => {
                if (res.success) {
                    if (this.cashbackFee == null) {
                        this.getEnterpriseCashbackFee(res.data);
                    } else {
                        this.setupForm(res.data);
                    }
                } else {
                    this.display = true;
                    this.eMessage = res.status.message;
                }

                this.setProgressWidth(100);
                this.isLoading = false;
                this.progressWidth = 25;
            },
            (error) => {
                this.isLoading = false;
            },
        );
    }

    setupForm(data: any) {
        this.initForm();
        this.populateFormItems(data);
    }

    touchedRow() {
        return this.formControls.controls.filter((row) => row.touched).map((row) => row.value);
    }

    touchedUpdateRow() {
        return [this.touchedRow().find((row) => !row.isNewInstance)];
    }

    touchedCreateRow() {
        return this.touchedRow().find((row) => row.isNewInstance);
    }

    newFormInstance() {
        return this.cashbackSettings.controls.filter((row) => row.value.isNewInstance);
    }

    newInstanceInForm() {
        return this.newFormInstance().length > 0;
    }

    removeAnyNewInstanceOnEdit() {
        const newInstance = this.newFormInstance;

        if (this.newInstanceInForm()) {
            this.cashbackSettings.controls.pop();
        }
    }

    createCashbackSetting() {
        this.isLoading = true;
        this.setProgressWidth(50);

        this.enterpriseService.addCashbackSettings(this.touchedCreateRow()).subscribe(
            (res) => {
                this.setProgressWidth(100);
                if (res.success) {
                    this.getCashbackSettings();
                } else {
                    this.display = true;
                    this.eMessage = res.status.message;
                }
                this.isLoading = false;
                this.progressWidth = 25;
            },
            (error) => {
                this.isLoading = false;
            },
        );
    }

    updateCashbackSettings() {
        this.isLoading = true;
        this.setProgressWidth(50);

        this.enterpriseService.updateCashbackSettings(this.touchedUpdateRow()).subscribe(
            (res) => {
                this.setProgressWidth(100);
                if (res.success) {
                    this.getCashbackSettings();
                } else {
                    this.display = true;
                    this.eMessage = res.status.message;
                }
                this.isLoading = false;
                this.progressWidth = 25;
            },
            (error) => {
                this.isLoading = false;
            },
        );
    }

    deleteCashbackSettings() {
        this.closeModal();
        this.isLoading = true;
        this.setProgressWidth(50);

        this.enterpriseService.deleteCashbackSettings(this.settingsId).subscribe(
            (res) => {
                this.setProgressWidth(100);
                if (res.success) {
                    this.getCashbackSettings();
                }
                this.isLoading = false;
                this.progressWidth = 25;
            },
            (error) => {
                this.isLoading = false;
            },
        );
    }

    submit() {
        const newform: FormGroup = this.touchedRow();

        if (newform[0] && newform[0]['isNewInstance']) {
            this.createCashbackSetting();
        } else {
            this.updateCashbackSettings();
        }
    }
}
