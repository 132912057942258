import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { URI } from 'src/app/settings/uri-constants';
@Component({
    selector: 'app-safes-link',
    templateUrl: './safes-link.component.html',
    styleUrls: ['./safes-link.component.css'],
})
export class SafesLinkComponent {
    matchExactSummaryPath = true;
    uri = URI;

    constructor(private activatedRoute: ActivatedRoute) {
        this.activatedRoute.data.subscribe((data) => {
            this.matchExactSummaryPath = data?.summaryRouteActive;
        });
    }
}
