import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { AlertService } from 'src/app/services/alert.service';
import { ModalService } from 'src/app/services/modal.service';
import { SmartSafeService } from 'src/app/services/smart-safe.service';
import { MessageConstant } from 'src/app/settings/message.constant';
import { FormUtils } from 'src/app/utils/form.utils';

@Component({
    selector: 'app-smart-safe-modal',
    templateUrl: './smart-safe-modal.component.html',
    styleUrls: ['./smart-safe-modal.component.css'],
})
export class SmartSafeModalComponent implements OnInit {
    @Input() title: string;
    @Input() selectedSafe: any;
    safeForm: FormGroup;
    @Output() event = new EventEmitter<boolean>();
    @Output() fetchDataList = new EventEmitter<void>();
    users: any[] = [];
    userNames: any[] = [];
    safeModel: any[];
    safeModelList: any[] = [];
    statusDropdown: any[] = [];
    idTypeDropdown: string[] = [];
    invalidForm = false;
    currencyDropdown: any[] = ['ZAR', 'USD'];
    updateErrors: string[] = [];
    safeIdShouldReadonly: boolean = false;
    alertOptions = {
        autoClose: true,
        keepAfterRouteChange: true,
    };

    constructor(
        private modalService: ModalService,
        private smartSafeService: SmartSafeService,
        private alertService: AlertService,
        private formBuilder: UntypedFormBuilder,
        private toasterService: ToastrService,
    ) {
        this.smartSafeService.getSmartSafeStatusList().subscribe((res) => {
            res.forEach((val) => {
                this.statusDropdown.push(val.status);
            });
        });
    }

    ngOnInit() {
        this.userNames = [];
        this.users = [];

        if (this.selectedSafe == null) {
            this.createForm();
        } else {
            this.editForm(this.selectedSafe);
        }

        this.smartSafeService.getUnAssignedUserList().subscribe((res) => {
            this.users = this.users.concat(res || []);
            this.users.forEach((val) => {
                this.userNames.push(val.name);
            });
        });

        this.smartSafeService.getSafeModelList().subscribe((res) => {
            this.safeModel = res || [];
            this.safeModel.forEach((val) => {
                this.safeModelList.push(val.model);
            });
        });

        this.safeForm.get('user').valueChanges.subscribe((val) => {
            const selected = this.users.find((user) => user.name === val);
            if (selected) {
                this.safeForm.get('userRoleId').setValue(selected.id || '');
                this.safeForm.get('assignedTo').setValue(selected.id || '');
            }
        });
    }

    editForm(selectedSafe): void {
        this.safeIdShouldReadonly = true;

        const user = {
            id: selectedSafe.userRoleId,
            name: selectedSafe.assignedTo,
        };

        this.users.push(user);
        this.safeForm = this.formBuilder.group({
            safeId: [selectedSafe.safeId, Validators.required],
            safeMacAddress: [selectedSafe.safeMacAddress, Validators.required],
            userRoleId: [user.id],
            assignedTo: [user.id],
            user: [user.name, Validators.required],
            noteCapacity: [selectedSafe.noteCapacity, Validators.required],
            softwareVersion: [selectedSafe.softwareVersion],
            currency: [selectedSafe.currency, Validators.required],
            model: [selectedSafe.model, Validators.required],
            status: [selectedSafe.status, Validators.required],
        });
    }

    createForm(): void {
        this.safeIdShouldReadonly = false;
        this.safeForm = this.formBuilder.group({
            safeId: ['', Validators.required],
            safeMacAddress: ['', Validators.required],
            userRoleId: [''],
            assignedTo: [''],
            user: ['', Validators.required],
            noteCapacity: ['', Validators.required],
            softwareVersion: [''],
            currency: ['ZAR', Validators.required],
            status: ['Active', Validators.required],
            model: ['', Validators.required],
        });
    }

    closeModal() {
        this.event.emit(false);
    }

    openModal(id: string) {
        this.modalService.open(id);
    }

    submit() {
        this.updateErrors = [];

        if (!this.safeForm.valid) {
            FormUtils.validateAllFormFields(this.safeForm);
            return false;
        }

        if (this.selectedSafe === null) {
            // Backend requires status field to be lowercase as it's case sensitive
            this.safeForm.patchValue({ status: this.safeForm.value.status.toLowerCase() });

            this.smartSafeService.createSmartSafe(this.safeForm.value).subscribe(
                (res) => {
                    if (res.success) {
                        this.closeModal();
                        this.toasterService.success(MessageConstant.SMART_SAFE_CREATED, 'Success');
                        this.fetchDataList.emit();
                    } else {
                        const errorMessage = res.status.message;
                        this.updateErrors.push(errorMessage);
                    }
                },
                (error) => {
                    this.updateErrors = error?.error?.errors;
                },
            );
        } else {
            this.smartSafeService.updateSmartSafe(this.safeForm.value).subscribe(
                (res) => {
                    if (res.success) {
                        this.closeModal();
                        this.toasterService.success(
                            MessageConstant.SMART_SAFE_UPDATED.replace(
                                '{}',
                                this.safeForm.value.safeId,
                            ),
                            'Success',
                        );
                        this.fetchDataList.emit();
                    } else {
                        const errorMessage = res.status.message;
                        this.updateErrors.push(errorMessage);
                    }
                },
                (error) => {
                    this.updateErrors = error?.error?.errors;
                },
            );
        }
    }

    isFieldInValid(field: string) {
        return FormUtils.isFieldInValid(this.safeForm, field);
    }

    displayFieldCss(field: string) {
        return FormUtils.displayFieldCss(this.safeForm, field);
    }

    cancel() {
        this.safeForm.get('safeId').setValue('');
        this.safeForm.get('userRoleId').setValue('');
        this.safeForm.get('assignedTo').setValue('');
        this.safeForm.get('user').setValue('');
        this.safeForm.get('status').setValue('');
        this.safeForm.get('model').setValue('');
        this.safeForm.get('currency').setValue('');
        this.safeForm.get('softwareVersion').setValue('');
        this.safeForm.get('noteCapacity').setValue('');
    }

    get controls(): any {
        return this.safeForm.controls;
    }
}
