<div class="wrapper">
    <app-navigation></app-navigation>
    <div class="content-wrapper">
        <app-content-transition></app-content-transition>
        <app-loader *ngIf="isLoading" className="custom-loader" text="Processing ..."></app-loader>
        <app-header location="User Account Profile"></app-header>
        <app-edit-links [id]="id"></app-edit-links>
        <app-user-profile-data
            [user]="user"
            [userType]="role.agent"
            (updateCode)="updateReferralCode($event)">
        </app-user-profile-data>
        <app-user-wallet-data tabName="agent" [user]="user"> </app-user-wallet-data>
        <section class="add-section">
            <div class="row">
                <div class="col-md-12 section-heading">Agent Details</div>
            </div>
            <div class="details-view">
                <div class="row">
                    <div class="col-md-4">
                        <label class="form-label">OPERATIONAL LOCATION</label>
                        <div class="user-details-text">
                            {{ user.userAgentProfileModel.operationalLocation || '-' | humanize }}
                        </div>
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">AGENT ACCOUNT STATUS</label>
                        <div class="user-details-text">
                            {{ user.userAgentProfileModel.agentAccountStatus || '-' | humanize }}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <label class="form-label">ADDRESS LINE 1</label>
                        <div class="user-details-text">
                            {{
                                user.userAgentProfileModel.address.addressLineOne || '-' | humanize
                            }}
                        </div>
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">PROVINCE</label>
                        <div class="user-details-text">
                            {{ user.userAgentProfileModel.address.provinceName || '-' | humanize }}
                        </div>
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">SUBURB</label>
                        <div class="user-details-text">
                            {{ user.userAgentProfileModel.address.suburb || '-' | humanize }}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <label class="form-label">ADDRESS LINE 2</label>
                        <div class="user-details-text">
                            {{
                                user.userAgentProfileModel.address.addressLineTwo || '-' | humanize
                            }}
                        </div>
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">CITY</label>
                        <div class="user-details-text">
                            {{ user.userAgentProfileModel.address.cityName || '-' | humanize }}
                        </div>
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">POSTAL CODE</label>
                        <div class="user-details-text">
                            {{ user.userAgentProfileModel.address.postalCode || '-' | humanize }}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
