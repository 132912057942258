import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';

import { Pagination } from 'src/app/model/Pagination';
import { Banner } from 'src/app/model/banner';
import { AlertService } from 'src/app/services/alert.service';
import { BannerService } from 'src/app/services/banner.service';
import { MessageConstant } from 'src/app/settings/message.constant';
import { URI } from 'src/app/settings/uri-constants';

@Component({
    selector: 'app-banners',
    templateUrl: './banners.component.html',
    styleUrls: ['./banners.component.css'],
})
export class BannersComponent implements OnInit {
    isLoading = false;
    loaderText = 'Searching...';
    display = false;
    displaySuccess = false;
    eMessage = MessageConstant.BANNER_UPDATE_FAILED;
    sMessage = ''; //MessageConstant.BANNER_UPDATE_SUCCESS;
    rows = 0;
    form: FormGroup;
    bannerList = new Array<any>();
    pagination = new Pagination<Banner>();
    progressWidth = 25;
    uri = URI;
    search = false;
    alertOptions = {
        autoClose: false,
        keepAfterRouteChange: true,
    };

    constructor(
        private formBuilder: UntypedFormBuilder,
        private bannerService: BannerService,
        private alertService: AlertService,
        private datePipe: DatePipe,
    ) {
        this.form = this.formBuilder.group({
            text: [''],
            start: [''],
            length: [''],
            startDate: [''],
            endDate: [''],
            getAllRows: [false],
        });
    }

    ngOnInit() {
        this.getBanners();
    }

    receiveFilter(data: { name: string; value: number }) {}

    submit() {
        this.getBanners();
    }

    clearText() {
        this.form.get('text').setValue('');
    }

    transformDateFilterValues() {
        const startDate = this.form.get('startDate').value;
        const endDate = this.form.get('endDate').value;

        if (startDate != '') {
            const sDate = this.datePipe.transform(startDate, 'yyyy-MM-dd');
            this.form.get('startDate').setValue(sDate);
        }

        if (endDate != '') {
            const eDate = this.datePipe.transform(endDate, 'yyyy-MM-dd');
            this.form.get('endDate').setValue(eDate);
        }
    }
    // TODO: Add round loader when items are being fetched
    getBanners() {
        this.search = false;
        this.form.get('start').setValue(this.pagination.start);
        this.form.get('length').setValue(this.pagination.length);
        this.setProgressWidth(50);
        this.transformDateFilterValues();

        this.bannerService.getBanners(this.form.value).subscribe(
            (res) => {
                this.setProgressWidth(100);
                setTimeout(() => {
                    this.search = true;
                    this.progressWidth = 25;
                    this.rows = 0;
                    this.bannerList =
                        res.responseBody == null
                            ? []
                            : res.responseBody.data.map((t) => new Banner().deserialize(t));
                    this.pagination.total = res.responseBody ? res.responseBody.total : 0;
                }, 350);
            },
            (error) => {
                this.alertService.error('Failed to fetch banners', this.alertOptions);
            },
        );
    }

    changeLength(len: number) {
        this.pagination.length = len;
        this.pagination.start = 0;
        this.getBanners();
    }

    changePage(page: number) {
        this.pagination.changePage(page);
        this.getBanners();
    }

    setProgressWidth(i: number) {
        setTimeout(() => {
            this.progressWidth = i;
        }, 300);
    }

    action(event: { banner: Banner }) {
        this.bannerService.updateBanner(event.banner).subscribe(
            (res) => {
                this.alertService.clear();
                this.getBanners();

                if (res.success) {
                    this.sMessage = event.banner.active
                        ? MessageConstant.BANNER_DEACTIVATED
                        : MessageConstant.BANNER_ACTIVATED;

                    this.alertOptions.autoClose = true;
                    this.alertService.success(this.sMessage, this.alertOptions);
                } else {
                    this.display = true;
                    if (res.status) {
                        this.eMessage = res.status.message;
                    }
                }
            },
            (error) => {
                if (error && error.error && error.error.errors) {
                    this.eMessage = 'Update Failed: ' + error.error.errors.toString();
                    this.alertService.error(this.eMessage, this.alertOptions);
                }
            },
        );
    }
}
