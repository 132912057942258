<div class="wrapper">
    <div class="alert alert-dismissible custom-alert-success" *ngIf="otpResent">
        <button
            type="button"
            (click)="closeModel($event)"
            class="close close-modified"
            data-dismiss="alert">
            &times;
        </button>
        <div class="login-error-text">
            <div class="error-icon"><img src="assets/images/success.png" /></div>
            <div class="login-error-message">OTP resend Successfully</div>
        </div>
    </div>
    <div class="alert alert-dismissible custom-alert-error" *ngIf="display">
        <button
            type="button"
            (click)="closeModel($event)"
            class="close close-modified"
            data-dismiss="alert">
            &times;
        </button>
        <div class="login-error-text">
            <div class="error-icon"><img src="assets/images/warning.png" /></div>
            <div class="login-error-message">{{ eMessage }}</div>
        </div>
    </div>
    <app-navigation></app-navigation>
    <app-loader *ngIf="isLoading" className="custom-loader" text="Processing ..."></app-loader>
    <div class="content-wrapper">
        <app-content-transition></app-content-transition>
        <section class="content-header">
            <div class="row">
                <div class="col-md-12">
                    <div class="tab-heading">
                        <span class="tab-heading-title">Manage Users</span>
                        <img
                            class="breadcrumb-separator"
                            src="assets/images/breadcrumb-separator.png" />
                        <span class="breadcrumb-custom">Search Users</span>
                        <img
                            class="breadcrumb-separator"
                            src="assets/images/breadcrumb-separator.png" />
                        <span class="breadcrumb-custom">Results</span>
                        <img
                            class="breadcrumb-separator"
                            src="assets/images/breadcrumb-separator.png" />
                        <span class="breadcrumb-custom">Change Mobile Number</span>
                        <img
                            class="breadcrumb-separator"
                            src="assets/images/breadcrumb-separator.png" />
                        <span class="breadcrumb-custom">Verify OTP</span>
                    </div>
                </div>
            </div>
        </section>
        <form [formGroup]="form">
            <section class="add-section">
                <div class="row">
                    <div class="col-md-12 section-heading">
                        Please verify One Time Pin sent to User
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label class="form-label">ONE TIME PIN*</label>
                        <div>
                            <input
                                class="form-input"
                                type="text"
                                placeholder="e.g. 654782"
                                name="code"
                                formControlName="code" />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="form-label"></label>
                        <div>
                            <a
                                class="user-files-deny"
                                style="float: left; margin-left: -38px"
                                (click)="resend()">
                                <u>RESEND OTP</u>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6"></div>
                    <div class="col-md-6">
                        <button
                            class="custombtn custombtn-align-right"
                            (click)="verify()"
                            [disabled]="form.invalid">
                            Verify
                        </button>
                        <a class="user-files-deny" (click)="cancel()"><u>CANCEL</u></a>
                    </div>
                </div>
            </section>
        </form>
        <section class="add-section-footer">*Required</section>
    </div>
</div>
