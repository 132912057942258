import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Pagination } from 'src/app/model/Pagination';
import { SmartSafeService } from 'src/app/services/smart-safe.service';

@Component({
    selector: 'app-safes-configuration',
    templateUrl: './safes-configuration.component.html',
    styleUrls: ['./safes-configuration.component.css'],
})
export class SafesConfigurationComponent implements OnInit {
    form: FormGroup;
    isLoading: boolean = false;
    switchStateOn: boolean = false;
    loaderText = 'Searching...';
    progressWidth = 25;
    smartSafeList: any[];
    pagination = new Pagination();
    selectedSmartSafe: any;
    displayView: boolean = false;
    display: boolean = false;
    sideViewWindowTitle: string;
    pencilEditStyle = { float: 'none' };
    search = false;
    changeBackground: boolean[] = [];
    selectedItems: number[] = [];
    item: { [key: string]: [] };

    constructor(
        private formBuilder: UntypedFormBuilder,
        private smartSafeService: SmartSafeService,
        private toasterService: ToastrService,
    ) { }

    ngOnInit() {
        this.form = this.formBuilder.group({
            text: [''],
            start: [''],
            length: [''],
            archived: [false],
        });
        this.getData();
    }

    clearText() {
        this.form.get('text').setValue('');
    }

    // TODO: Move this to a util method. It is called in multiple places.
    isEmpty(item): boolean {
        return this.form.get(item).value.length <= 0;
    }

    submit() {
        this.getData();
    }

    getData() {
        this.isLoading = true;
        this.search = false;
        this.form.get('start').setValue(this.pagination.start);
        this.form.get('length').setValue(this.pagination.length);

        this.smartSafeService.getSmartSafesList(this.form.value).subscribe(
            (res) => {
                this.smartSafeList = res.data;
                this.pagination.total = res.total;
                this.isLoading = false;
            },
            (error) => {
                this.isLoading = false;
            },
        );
    }

    receiveFilter(data: { name: string; value: number }) { }

    changeLength(len: number) {
        this.pagination.length = len;
        this.pagination.start = 0;
        this.getData();
    }

    changePage(page: number) {
        this.pagination.changePage(page);
        this.getData();
    }

    editSmartSafe(event: { type: string; value: any }) {
        this.selectedSmartSafe = event.value;
        switch (event.type) {
            case 'view':
                this.sideViewWindowTitle = 'Update Smart Safe';
                this.openSideViewWindow();
                break;
        }
    }

    openSideViewWindow() {
        this.displayView = true;
    }

    closeViewModel() {
        this.displayView = false;
    }

    closeModel(event: any) {
        this.display = false;
    }

    addSmartSafe() {
        this.sideViewWindowTitle = 'New Smart Safe';
        this.selectedSmartSafe = null;
        this.openSideViewWindow();
    }

    archiveSmartSafes() {
        const payload = { safeIds: this.selectedItems };
        this.smartSafeService.archiveSmartSafes(payload).subscribe({
            next: (res) => {
                if (res.success) {
                    this.toasterService.success(
                        this.getSuccessfulArchiveMessage(res?.data?.safeIds),
                        'Success',
                    );
                    this.getData();
                }
            },
            error: () => { },
            complete() {
                this.isLoading = false;
            },
        });
    }

    getSuccessfulArchiveMessage(safeIds: any) {
        return `Smart ${safeIds.length > 1 ? 'Safes' : 'Safe'} ${safeIds.toString()} archived successfully`;
    }

    onSwitchChange() {
        this.switchStateOn = !this.switchStateOn;
        this.form.get('archived').setValue(this.switchStateOn);
        this.getData();
    }

    OnChange(id: number, $event: any, index: number) {
        if ($event) {
            this.changeBackground[index] = true;
        } else {
            this.changeBackground[index] = false;
        }

        if (this.selectedItems.includes(id)) {
            this.selectedItems = this.selectedItems.filter((item) => item !== id);
        } else {
            this.selectedItems.push(id);
        }
    }
}
