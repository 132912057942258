import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/model/user';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute } from '@angular/router';
import { Merchant } from 'src/app/model/merchant';
import { Agent } from 'src/app/model/agent';
import { Personal } from 'src/app/model/personal';
import { FormGroup } from '@angular/forms';
import { StorageService } from 'src/app/services/storage.service';
import { MessageConstant } from 'src/app/settings/message.constant';
import { Role } from 'src/app/enums/role';
import { AppConstants } from 'src/app/settings/app-constants';

@Component({
    selector: 'app-user-merchant-profile',
    templateUrl: './user-merchant-profile.component.html',
    styleUrls: ['./user-merchant-profile.component.css'],
})
export class UserMerchantProfileComponent implements OnInit {
    display = false;
    isLoading = false;
    user: User;
    role = Role;
    id: string;
    displaySuccess = false;
    sMessage = '';
    eMessage = '';

    constructor(
        private commonService: CommonService,
        private route: ActivatedRoute,
        private storageService: StorageService,
    ) {
        this.user = new User();
    }

    ngOnInit() {
        this.route.paramMap.subscribe((params) => {
            this.id = params.get('id');
            this.getData();
        });
    }

    getData() {
        this.isLoading = true;
        if (
            !!this.storageService.getProfileUser() &&
            this.storageService.getProfileUser().id == this.id
        ) {
            this.user = this.storageService.getProfileUser();
            this.initializeUser();
            this.isLoading = false;
        } else {
            this.getProfile();
        }
    }

    getProfile() {
        this.isLoading = true;
        this.commonService.getUserProfile(this.id).subscribe(
            (res) => {
                this.isLoading = false;
                this.user = res.data;
                this.initializeUser();
                this.storageService.setProfileUser(this.user);
            },
            (err) => {
                this.isLoading = false;
            },
        );
    }

    initializeUser() {
        if (this.user.userPersonalProfileModel === null) {
            this.user.userPersonalProfileModel = new Personal();
        }
        if (this.user.userBusinessProfileModel === null) {
            this.user.userBusinessProfileModel = new Merchant();
        }
        if (this.user.userAgentProfileModel === null) {
            this.user.userAgentProfileModel = new Agent();
        }
        this.user.lastLogin = this.user.userBusinessProfileModel.lastLogin;
        this.user.profilePicture = this.user.userBusinessProfileModel.businessLogo;
    }

    closeModel(val: boolean) {
        this.display = false;
        this.displaySuccess = false;
    }

    updateBank(bank: FormGroup) {
        this.isLoading = true;
        this.closeModel(true);

        bank.get('userRoleId').setValue(this.user.userBusinessProfileModel.userRoleId);
        this.commonService
            .updateBank(bank.value, this.user.id, this.user.userBusinessProfileModel.roleId)
            .subscribe(
                (res) => {
                    this.isLoading = false;
                    if (res.success) {
                        this.displaySuccess = true;
                        this.sMessage = MessageConstant.BANK_UPDATE_SUCCESS;
                        this.refreshBankList(bank.value);
                    } else {
                        this.eMessage = res.status.message;
                        this.display = true;
                    }
                },
                (err) => {
                    if (err.error.errors) {
                        this.eMessage = err.error.errors[0];
                    } else {
                        this.eMessage = err.error.error;
                    }
                    this.display = true;
                    this.isLoading = false;
                },
            );
    }

    refreshBankList(bank: any) {
        const list = this.user.userBusinessProfileModel.bankList;

        for (let i = 0; i < list.length; i++) {
            if (list[i].bankDetailId == bank.bankDetailId) {
                list[i] = bank;
            }
        }

        this.user.userBusinessProfileModel.bankList = list;
    }

    updateReferralCode(code: string) {
        if (code.length < AppConstants.REFERRAL_CODE_MIN_LEGTH) {
            this.eMessage = 'Invalid referral code.';
            this.display = true;
        } else {
            this.isLoading = true;
            this.commonService.updateReferralCode(this.user.id, code).subscribe(
                (res) => {
                    this.isLoading = false;
                    if (res.success) {
                        this.getProfile();
                    } else {
                        this.eMessage = res.status.message;
                        this.display = true;
                    }
                },
                (error) => {
                    this.isLoading = false;
                    this.eMessage = MessageConstant.REFERRAL_CODE_UPDATE_REQUEST_FAILED;
                    this.display = true;
                },
            );
        }
    }
}
