import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';

import { Fees } from 'src/app/model/fees';
import { AppConstants } from 'src/app/settings/app-constants';
import { CommonUtil } from 'src/app/services/common.util';
import { FeeCode } from 'src/app/enums/fee.code';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
    selector: 'app-personal-user-fees',
    templateUrl: './personal-user-fees.component.html',
    styleUrls: ['./personal-user-fees.component.css'],
})
export class PersonalUserFeesComponent {
    @Input() personalFeeList: Array<Fees>;
    @Input() editPersonal = false;
    @Input() showEditPencil = true;
    @Input() parentToUpdate = false;
    @Input() title = 'Personal User';
    @Output() updateFee = new EventEmitter();
    amountFormat = AppConstants.AMOUNT_FORMAT;
    feeCode = FeeCode;
    feeLabel = this.commonUtil.getFeeLabels();

    constructor(public commonUtil: CommonUtil) {}

    changeView() {
        this.editPersonal = !this.editPersonal;
    }

    submit() {
        this.updateFee.emit('personal');
        this.changeView();
    }

    changeStatus(val) {
        console.log(val);
    }
}
