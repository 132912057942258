import { Component, OnInit } from '@angular/core';
import { URI } from 'src/app/settings/uri-constants';

@Component({
    selector: 'app-links',
    templateUrl: './links.component.html',
    styleUrls: ['./links.component.css'],
})
export class LinksComponent {
    uri = URI;

    constructor() {}
}
