<section class="edit-section-profile" [style.width.%]="width ? '98' : ''">
    <div>
        <div class="profile-placeholder">
            <a><img src="{{ user.profilePicture }}" *ngIf="user.profilePicture" /></a>
            <a>
                <img src="assets/images/placeholder-large.png" *ngIf="!user.profilePicture" />
            </a>
            <!-- <input type="file" (change)="changeProfile($event.target.files)" hidden #fileInput
                accept="image/jpeg,image/png"> -->
        </div>
        <div class="profile-content">
            <div class="row">
                <div class="col-md-12 section-heading">
                    {{ user.firstName ? user.firstName : '-' }} {{ ' ' }}
                    {{ user.lastName ? user.lastName : '' }}
                </div>
            </div>
            <div class="row">
                <div class="col-md-5">
                    <label>MOBILE NUMBER:</label>
                    <span class="user-details-text">{{ user.mobileNumber || '-' }}</span>
                </div>
                <div class="col-md-7">
                    <label>STATUS:</label>
                    <span class="user-details-text">{{ status || '' }}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-md-5">
                    <label>DATE CREATED:</label>
                    <span class="user-details-text">{{
                        user.dateCreated ? (user.dateCreated | date: 'mediumDate') : '-'
                    }}</span>
                </div>
                <div class="col-md-7">
                    <label>LAST LOGIN:</label>
                    <span class="user-details-text">{{
                        user.lastLogin ? (user.lastLogin | date: 'medium') : 'NEVER'
                    }}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-md-5">
                    <label>REFERRAL CODE:</label>
                    <span class="user-details-text">{{ referralCode || '' | humanize }}</span>
                </div>
                <div class="col-md-7">
                    <label>REFERRED BY:</label>
                    <ng-container *ngIf="!edit">
                        <span class="user-details-text">{{
                            user.referredBy || '' | humanize
                        }}</span>
                    </ng-container>
                    <ng-container>
                        <ng-container *ngIf="edit">
                            <input
                                class="form-input-half"
                                type="text"
                                [(ngModel)]="code"
                                name="referralCode"
                                placeholder="AGXX10"
                                maxlength="7" />
                            <a (click)="updateReferralCode()">
                                <img src="assets/images/icon-check-mark-normal.png" />
                            </a>
                        </ng-container>
                        <a (click)="toggle()" class="overlay-container" *ngIf="!edit">
                            <img src="assets/images/edit-pencil.png" class="pl-2" />
                        </a>
                    </ng-container>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <label>ASSOCIATED ACCOUNTS:</label>
                    <span class="user-details-text">{{ user.profiles || '' | humanize }}</span>
                </div>
            </div>
        </div>
    </div>
</section>
