<!-- Content Wrapper. Contains page content -->
<div class="content-wrapper">
    <app-navigation></app-navigation>
    <app-content-transition></app-content-transition>
    <app-safes-header></app-safes-header>
    <app-safes-link></app-safes-link>

    <router-outlet (activate)="onActivate()" (deactivate)="onDeactivate()"> </router-outlet>

    @if (showParent) {
        <div>
            <section class="add-section">
                <form [formGroup]="form">
                    <div class="row">
                        <div class="col-md-12 section-heading">Search</div>
                    </div>
                    <div class="row">
                        <div class="col-md-5">
                            <div class="input-icons">
                                <i class="fa fa-search icon" aria-hidden="true"></i>
                                <input
                                    class="form-input"
                                    type="text"
                                    placeholder="Enter a Name, Safe ID"
                                    formControlName="text" />
                                @if (!isEmpty('text')) {
                                    <a (click)="clearText()">
                                        <img src="assets/images/close-x-purple.png" />
                                    </a>
                                }
                            </div>
                        </div>
                        <div class="col-md-7">
                            <button class="search-button" (click)="submit()">Search</button>
                            <a class="advanced-search" (click)="toggleOption()">
                                <img src="assets/images/dropdown.png" />
                                <u>ADVANCED SEARCH</u>
                            </a>
                        </div>
                    </div>
                    @if (showOptions) {
                        <hr />
                        <div class="search-form">
                            <div class="row">
                                <div class="col-md-4">
                                    <label class="form-label">STATUS</label>
                                    <div>
                                        <ng-select
                                            [items]="statusList"
                                            bindLabel="value"
                                            placeholder="Select Status"
                                            formControlName="status"
                                            bindValue="name"
                                            searchable="false"
                                            name="status"
                                            [multiple]="false">
                                        </ng-select>
                                    </div>
                                </div>
                                @if (searchItems.length > 0) {
                                    <div class="col-md-8">
                                        <div class="searched-items">
                                            @for (item of searchItems; track $index) {
                                                <div class="search-item-value">
                                                    {{ item | humanize }}
                                                    <a (click)="removeSearchItem(item)">
                                                        <img
                                                            src="assets/images/close-x-white.png" />
                                                    </a>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </form>
            </section>

            @if (smartSafeSummaryList) {
                <section>
                    <div class="container-fluid tab-pane">
                        <br />
                        <div class="row">
                            <div class="col-md-10 section-heading">Safes Summary</div>
                            <div class="col-md-2">
                                <button class="custombtn custombtn-align-right">
                                    <i class="fa fa-arrow-up" aria-hidden="true"></i>Export
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
            }

            <hr />

            @if (
                !isLoading &&
                smartSafeSummaryList?.length !== null &&
                smartSafeSummaryList?.length > 0
            ) {
                <div class="row">
                    <div class="search-result-header">
                        <div class="col-md-12">
                            Search Results: {{ smartSafeSummaryList.length }} of
                            {{ pagination.total }}
                        </div>
                    </div>
                </div>
            }

            @if (isLoading) {
                <div class="progress-container">
                    <div class="progress-container-text">
                        <div>{{ loaderText }}</div>
                    </div>
                    <div>
                        <div class="custom-progress-bar">
                            <span [style.width.%]="progressWidth"></span>
                        </div>
                    </div>
                </div>
            }

            @if (smartSafeSummaryList) {
                <section>
                    <div class="container-fluid tab-pane custom-table smart-safes">
                        <br />
                        <table>
                            <thead>
                                <tr class="form-table-row">
                                    <th>
                                        <app-filter-arrow
                                            filter="assignedTo"
                                            title="NAME"
                                            (filterValue)="receiveFilter($event)">
                                        </app-filter-arrow>
                                    </th>
                                    <th>
                                        <app-filter-arrow
                                            filter="location"
                                            title="LOCATION"
                                            (filterValue)="receiveFilter($event)">
                                        </app-filter-arrow>
                                    </th>
                                    <th>
                                        <app-filter-arrow
                                            filter="id"
                                            title="ID"
                                            (filterValue)="receiveFilter($event)">
                                        </app-filter-arrow>
                                    </th>
                                    <th>
                                        <app-filter-arrow
                                            filter="currentTotal"
                                            title="CURRENT TOTAL"
                                            (filterValue)="receiveFilter($event)">
                                        </app-filter-arrow>
                                    </th>

                                    <th>
                                        <app-filter-arrow
                                            filter="full"
                                            title="FULL"
                                            (filterValue)="receiveFilter($event)">
                                        </app-filter-arrow>
                                    </th>
                                    <th>
                                        <app-filter-arrow
                                            filter="status"
                                            title="STATUS"
                                            (filterValue)="receiveFilter($event)">
                                        </app-filter-arrow>
                                    </th>
                                    <th>
                                        <app-filter-arrow title="ACTIONS"> </app-filter-arrow>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                @for (
                                    safeSummary of smartSafeSummaryList;
                                    track safeSummary.safeId
                                ) {
                                    <ng-container>
                                        <tr class="form-table-row view-form">
                                            <td>
                                                <a
                                                    routerLinkActive="tab-link-active"
                                                    [routerLink]="[
                                                        getTransactionsByUserRoute(
                                                            safeSummary.safeId,
                                                            safeSummary.userRoleId
                                                        ),
                                                        { name: safeSummary.assignedTo }
                                                    ]">
                                                    <b>{{ safeSummary.assignedTo }}</b>
                                                </a>
                                            </td>
                                            <td>{{ safeSummary.location }}</td>
                                            <td>
                                                <a
                                                    routerLinkActive="tab-link-active"
                                                    [routerLink]="
                                                        getTransactionsBySafeIdRoute(
                                                            safeSummary.safeId
                                                        )
                                                    ">
                                                    <b>{{ safeSummary.safeId }}</b>
                                                </a>
                                            </td>
                                            <td>
                                                {{ safeSummary.currency | currencyFormat }}
                                                {{
                                                    safeSummary.currentTotal.aggregateTotal ||
                                                        '0.00' | number: amountFormat
                                                }}
                                            </td>
                                            <td>{{ safeSummary.full }}%</td>
                                            <td>
                                                <app-account-status
                                                    smartSafeStatus="{{ safeSummary.status }}">
                                                </app-account-status>
                                            </td>
                                            <td>
                                                <div class="edit-link">
                                                    <a data-toggle="dropdown">
                                                        <div class="dot-icon"></div>
                                                    </a>
                                                    <ul
                                                        class="dropdown-menu"
                                                        aria-labelledby="menu1">
                                                        <li>
                                                            <a
                                                                class="dropdown-menu-item"
                                                                (click)="action(safeSummary)">
                                                                <img
                                                                    src="assets/images/control-icon-view-details.png" />
                                                                View Details
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-container>
                                } @empty {
                                    <ng-container>
                                        <tr>
                                            <div class="data-empty">
                                                <div class="col-md-12">No data to display</div>
                                            </div>
                                        </tr>
                                    </ng-container>
                                }
                            </tbody>
                            @if (pagination.showPagination()) {
                                <tfoot>
                                    <tr>
                                        <td>
                                            Rows per page:
                                            <span>
                                                <b>{{ pagination.length }}</b>
                                            </span>
                                            <app-page-length-dropdown
                                                (emitLength)="changeLength($event)">
                                            </app-page-length-dropdown>
                                        </td>
                                        <td>
                                            <app-page-navigation
                                                pageNumber="{{ pagination.getPageNo() }}"
                                                totalPages="{{ pagination.getTotalPages() }}"
                                                (changePage)="changePage($event)">
                                            </app-page-navigation>
                                        </td>
                                    </tr>
                                </tfoot>
                            }
                        </table>
                    </div>
                </section>
            }
            @if (displayView) {
                <ng-container>
                    <app-safe-summary-details
                        [safeSummary]="selectedSafeSummary"
                        (event)="closeViewModel()">
                    </app-safe-summary-details>
                </ng-container>
            }
        </div>
    }
</div>
