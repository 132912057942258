import { FormControl, FormGroup } from '@angular/forms';

export const FormUtils = {
    validateAllFormFields(formGroup: FormGroup) {
        Object.entries(formGroup.controls).forEach(([field, value]) => {
            if (value instanceof FormGroup) {
                this.validateAllFormFields(value);
            }
            this.validateFormField(formGroup, field);
        });
    },

    validateFormField(formGroup: FormGroup, field: string) {
        if (formGroup instanceof FormGroup) {
            const control = formGroup.get(field);
            if (control) {
                if (control instanceof FormControl) {
                    control.markAsTouched({ onlySelf: true });
                } else if (control instanceof FormGroup) {
                    this.validateFormField(control);
                }
            }
        }
    },

    isNestedFieldValid(formGroup: FormGroup, field: string) {
        if (formGroup.get(field)) {
            return formGroup.get(field).valid;
        }
    },

    isFieldInValid(formGroup: FormGroup, field: string) {
        if (formGroup.get(field)) {
            return (
                formGroup.get(field).invalid &&
                (formGroup.get(field).dirty || formGroup.get(field).touched)
            );
        }
    },

    isFieldPatternValid(formGroup: FormGroup, field: string) {
        if (formGroup.get(field)) {
            return this.isFieldValid(formGroup, field) && formGroup.get(field).errors.pattern;
        }
    },

    displayFieldCss(formGroup: FormGroup, field: string) {
        const fieldInValid = this.isFieldInValid(formGroup, field);

        return {
            valid: !fieldInValid,
            invalid: fieldInValid,
        };
    },
};
