import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { Pagination } from 'src/app/model/Pagination';
import { CommonUtil } from 'src/app/services/common.util';
import { SmartSafeService } from 'src/app/services/smart-safe.service';
import { URI } from 'src/app/settings/uri-constants';

@Component({
    selector: 'app-cit-companies',
    templateUrl: './cit-companies.component.html',
    styleUrls: ['./cit-companies.component.css'],
})
export class CitCompaniesComponent implements OnInit {
    form: FormGroup;
    isLoading: boolean = false;
    loaderText = 'Searching...';
    progressWidth = 25;
    citList: any[];
    pagination = new Pagination();
    selectedSmartSafe: any;
    displayView: boolean = false;
    display: boolean = false;
    sideViewWindowTitle: string;
    pencilEditStyle = { float: 'none' };
    showParent = true;

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private smartSafeService: SmartSafeService,
        public commonUtil: CommonUtil,
    ) {}

    ngOnInit() {
        this.form = this.formBuilder.group({
            text: [''],
            start: [''],
            length: [''],
        });
        this.getData();
    }

    clearText() {
        this.form.get('text').setValue('');
    }

    isEmpty(item): boolean {
        return this.form.get(item).value.length <= 0;
    }

    submit() {
        this.getData();
    }

    getData() {
        this.isLoading = true;

        this.smartSafeService.getCITList().subscribe({
            next: (res) => {
                this.citList = res?.responseBody?.data;
                this.pagination.total = res?.responseBody?.total || 0;
                this.isLoading = false;
            },
            error: (error) => {
                this.isLoading = false;
            },
        });
    }

    receiveFilter(data: { name: string; value: number }) {}

    changeLength(len: number) {
        this.pagination.length = len;
        this.pagination.start = 0;
        this.getData();
    }

    changePage(page: number) {
        this.pagination.changePage(page);
        this.getData();
    }

    editSmartSafe(event: { type: string; value: any }) {
        this.selectedSmartSafe = event.value;
        switch (event.type) {
            case 'view':
                this.sideViewWindowTitle = 'Update Smart Safe';
                this.openSideViewWindow();
                break;
        }
    }

    openSideViewWindow() {
        this.displayView = true;
    }

    closeViewModel() {
        this.displayView = false;
    }

    closeModel(event: any) {
        this.display = false;
    }

    addCompany() {
        this.router.navigate([URI.getChildPathFromParent('cashInTransitCompany', 'new')]);
    }

    onActivate() {
        this.showParent = false;
    }

    onDeactivate() {
        this.showParent = true;
    }
}
