<div class="wrapper">
    <app-navigation></app-navigation>
    <div class="content-wrapper">
        <app-content-transition></app-content-transition>
        <app-loader *ngIf="isLoading" className="custom-loader" text="Processing ..."></app-loader>
        <app-header location="Eft and ATM Cash In" title="Pending"></app-header>
        <app-eft-cash-in-link></app-eft-cash-in-link>
        <div>
            <section class="add-section">
                <form [formGroup]="form">
                    <div class="row">
                        <div class="col-md-12 section-heading">Search</div>
                    </div>
                    <div class="row pb-3">
                        <div class="col-md-6">
                            <label class="form-label">SEARCH BY SENDER OR BUSINESS</label>
                            <div class="input-icons">
                                <i class="fa fa-search icon" aria-hidden="true"></i>
                                <input
                                    class="form-input"
                                    type="text"
                                    placeholder="eg. Zakihiti’s Spaza"
                                    formControlName="text" />
                                <a *ngIf="form.get('text').value.length > 0" (click)="clearText()">
                                    <img src="assets/images/close-x-purple.png" />
                                </a>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="form-label"></label>
                            <div>
                                <button class="search-button" (click)="submit()">Search</button>
                                <a class="advanced-search" (click)="toggleOption()">
                                    <img src="assets/images/dropdown.png" />
                                    <u>ADVANCED SEARCH</u>
                                </a>
                            </div>
                        </div>
                    </div>
                    <hr *ngIf="showOptions" />
                    <div class="search-form" *ngIf="showOptions">
                        <div class="row">
                            <div class="col-md-4">
                                <label class="form-label">ACCOUNT TYPE</label>
                                <div>
                                    <app-select-box
                                        placeholderValue="Select Type"
                                        [form]="form"
                                        value="role"
                                        [dropDown]="accountTypeDropdown">
                                    </app-select-box>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="searched-items">
                        <div *ngIf="searchItems.length > 0">
                            <div *ngFor="let item of searchItems" class="search-item-value">
                                {{ item | humanize }}
                                <a (click)="removeSearchItem(item)">
                                    <img src="assets/images/close-x-white.png" />
                                </a>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
            <div class="progress-container" *ngIf="isLoading">
                <div class="progress-container-text">
                    <div>Searching...</div>
                </div>
                <div>
                    <div class="custom-progress-bar">
                        <span [style.width.%]="progressWidth"></span>
                    </div>
                </div>
            </div>
            <section *ngIf="search">
                <div class="search-result-header">
                    Search Results: {{ transactionList.length }} of {{ pagination.total }}
                </div>
                <hr />
                <div class="container-fluid tab-pane custom-table custom-last-row">
                    <br />
                    <table id="tableData">
                        <thead>
                            <tr>
                                <th width="25%">
                                    <app-filter-arrow
                                        filter="fromUserName"
                                        title="USER OR BUSINESS NAME"
                                        (filterValue)="receiveFilter($event)">
                                    </app-filter-arrow>
                                </th>
                                <th width="12%">
                                    <app-filter-arrow
                                        filter="toUserCode"
                                        title="REFERENCE CODE"
                                        (filterValue)="receiveFilter($event)">
                                    </app-filter-arrow>
                                </th>
                                <th width="12%">
                                    <app-filter-arrow
                                        filter="fromRole"
                                        title="USER TYPE"
                                        (filterValue)="receiveFilter($event)">
                                    </app-filter-arrow>
                                </th>
                                <th width="12%">
                                    <app-filter-arrow
                                        filter="TransactionType"
                                        title="TRANSACTION TYPE"
                                        (filterValue)="receiveFilter($event)">
                                    </app-filter-arrow>
                                </th>
                                <th width="11%">
                                    <app-filter-arrow
                                        filter="receivedAt"
                                        title="RECEIVED"
                                        (filterValue)="receiveFilter($event)">
                                    </app-filter-arrow>
                                </th>
                                <th width="11%">
                                    <app-filter-arrow
                                        filter="status"
                                        title=" STATUS"
                                        (filterValue)="receiveFilter($event)">
                                    </app-filter-arrow>
                                </th>
                                <th width="11%">
                                    <app-filter-arrow
                                        filter="amount"
                                        title=" AMOUNT"
                                        (filterValue)="receiveFilter($event)">
                                    </app-filter-arrow>
                                </th>
                                <th width="6%">
                                    <app-filter-arrow title="ACTIONS"> </app-filter-arrow>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="tablebox" *ngFor="let tran of transactionList">
                                <td width="25%">
                                    <img
                                        class="table-user-img"
                                        src="{{
                                            tran.profilePicture || 'assets/images/placeholder.png'
                                        }}" />
                                    <a (click)="commonUtil.goToSenderProfile(tran)">
                                        <strong
                                            ><u
                                                ><span>{{ tran.toUserName }}</span></u
                                            ></strong
                                        >
                                    </a>
                                </td>
                                <td width="12%">{{ tran.toUserCode }}</td>
                                <td width="12%">{{ tran.toRole | humanize }}</td>
                                <td width="12%">
                                    {{ tran.type | humanize }}
                                    @if (tran.isShopifyCashIn) {
                                        <img
                                            alt="S"
                                            class="table-td-icon"
                                            src="assets/images/shopify_glyph.svg" />
                                    }
                                </td>
                                <td width="11%">{{ tran.date | date: 'd/M/yyyy - h:mm a' }}</td>
                                <td width="11%">
                                    <app-account-status
                                        type="bank_transfer"
                                        transactionStatus="{{ tran.status }}">
                                    </app-account-status>
                                </td>
                                <td width="11%">{{ tran.amount }}</td>
                                <td width="6%" class="pr-5">
                                    <app-edit-pencil
                                        (edit)="
                                            action({ type: 'view', value: tran })
                                        "></app-edit-pencil>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot *ngIf="pagination.showPagination()">
                            <tr>
                                <td>
                                    Rows per page:
                                    <span
                                        ><b>{{ pagination.length }}</b></span
                                    >
                                    <app-page-length-dropdown (emitLength)="changeLength($event)">
                                    </app-page-length-dropdown>
                                </td>
                                <td>
                                    <app-page-navigation
                                        pageNumber="{{ pagination.getPageNo() }}"
                                        totalPages="{{ pagination.getTotalPages() }}"
                                        (changePage)="changePage($event)">
                                    </app-page-navigation>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </section>
            <ng-container *ngIf="displayView">
                <app-transaction-view-modal
                    [transaction]="transaction"
                    [showUpdate]="showUpdate"
                    (event)="closeSideViewModal()"
                    (transferUpdateEvent)="initTransactionUpdateAction($event)">
                </app-transaction-view-modal>
            </ng-container>

            <!-- modalConstants.bankTransferReceiveModalId modal popup started -->
            <div class="add-section">
                <app-modal
                    modalId="{{ modalConstants.bankTransferReceiveModalId }}"
                    modalTitle="Transaction Received"
                    buttonConfirmText="Submit"
                    buttonCancelText="Cancel"
                    (modalEventConfirm)="processCashInTypeTransaction()"
                    (modalEventCancel)="resetFormGroup(processCashInForm)">
                    <form [formGroup]="processCashInForm">
                        <!-- Switch off margin of `form-input-container` wrapper for the first
                         element of every form when using this is a modal content  -->

                        <div class="form-input-container m-0">
                            <div class="form-label">Name: {{ transaction.toUserName }}</div>
                        </div>
                        <div class="form-input-container">
                            <div class="form-label">
                                Transaction Code: {{ transaction.transactionCode }}
                            </div>
                        </div>
                        <div class="form-input-container">
                            <div class="form-label">
                                Request Date: {{ transaction.date | date: dateTimeFormat }}
                            </div>
                        </div>
                        <div class="form-input-container">
                            <div class="form-label">
                                Current Amount: {{ transaction.amount | number: amountFormat }}
                            </div>
                        </div>
                        <div class="form-input-container">
                            <div class="d-inline-block mr-3">Update Amount?</div>
                            <label class="switch-container">
                                <input
                                    type="checkbox"
                                    (change)="onChange()"
                                    [checked]="shouldUpdateAmount" />
                                <span class="slider round"></span>
                            </label>
                        </div>
                        @if (shouldUpdateAmount) {
                            <div class="form-input-container">
                                <div class="row">
                                    <div class="col-md-12">
                                        <input
                                            required
                                            class="form-input mb-1"
                                            type="text"
                                            formControlName="newAmount"
                                            name="newAmount"
                                            placeholder="Enter new EFT amount" />
                                        <app-field-error-display
                                            [displayError]="
                                                formUtils.isFieldInValid(
                                                    processCashInForm,
                                                    'newAmount'
                                                )
                                            "
                                            errorMsg="New EFT amount is required">
                                        </app-field-error-display>
                                    </div>
                                </div>
                            </div>
                        }

                        <div class="form-input-container">
                            <div class="form-label required mt-3">Date Received</div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="datepicker">
                                        <input
                                            matInput
                                            class="form-input"
                                            [matDatepicker]="dateReceivedpicker"
                                            name="dateReceivedInBank"
                                            formControlName="dateReceivedInBank"
                                            readonly />

                                        <mat-datepicker-toggle
                                            matIconSuffix
                                            [for]="dateReceivedpicker"></mat-datepicker-toggle>
                                        <mat-datepicker #dateReceivedpicker></mat-datepicker>
                                    </div>
                                    <app-field-error-display
                                        [displayError]="
                                            formUtils.isFieldInValid(
                                                processCashInForm,
                                                'dateReceivedInBank'
                                            )
                                        "
                                        errorMsg="Date received is required">
                                    </app-field-error-display>
                                </div>
                            </div>
                        </div>
                        <div class="form-input-container">
                            <div class="form-label required">Note</div>
                            <div class="row">
                                <div class="col-md-12">
                                    <textarea formControlName="note"></textarea>
                                    <app-field-error-display
                                        [displayError]="
                                            formUtils.isFieldInValid(processCashInForm, 'note')
                                        "
                                        errorMsg="You must provide a reason for updating this transaction">
                                    </app-field-error-display>
                                </div>
                            </div>
                        </div>
                    </form>
                </app-modal>
            </div>
            <!-- modalp popup ends -->

            <!-- modalConstants.updateAmountModalId modal popup started  -->
            <div class="add-section">
                <app-modal
                    modalId="{{ modalConstants.transactionUpdateModalId }}"
                    [modalTitle]="
                        cancellingTransaction
                            ? 'Cancel EFT Transaction'
                            : 'Update EFT Transaction Amount'
                    "
                    buttonConfirmText="Submit"
                    buttonCancelText="Cancel"
                    (modalEventConfirm)="requestTransactionUpdate()"
                    (modalEventCancel)="resetFormGroup(updateTransactionForm)">
                    <form [formGroup]="updateTransactionForm">
                        <!-- Switch off margin of `form-input-container` wrapper for the first
                         element of every form when using this is a modal content  -->

                        <div class="form-input-container m-0">
                            <div class="form-label">Name: {{ transaction.toUserName }}</div>
                        </div>
                        <div class="form-input-container">
                            <div class="form-label">
                                Transaction Code: {{ transaction.transactionCode }}
                            </div>
                        </div>
                        <div class="form-input-container">
                            <div class="form-label">
                                Request Date: {{ transaction.date | date: dateTimeFormat }}
                            </div>
                        </div>
                        <div class="form-input-container">
                            <div class="form-label">
                                Current Amount: {{ transaction.amount | number: amountFormat }}
                            </div>
                        </div>

                        @if (!cancellingTransaction) {
                            <div class="form-input-container">
                                <div class="form-label required">New Amount</div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <input
                                            appFloatOnly
                                            class="form-input"
                                            type="text"
                                            formControlName="amount"
                                            name="amount"
                                            placeholder="Enter new EFT amount" />
                                        <app-field-error-display
                                            [displayError]="
                                                formUtils.isFieldInValid(
                                                    updateTransactionForm,
                                                    'amount'
                                                )
                                            "
                                            errorMsg="New EFT amount is required">
                                        </app-field-error-display>
                                    </div>
                                </div>
                            </div>
                        }

                        <div class="form-input-container">
                            <div class="form-label required">Note</div>
                            <div class="row">
                                <div class="col-md-12">
                                    <textarea formControlName="note"></textarea>
                                    <app-field-error-display
                                        [displayError]="
                                            formUtils.isFieldInValid(updateTransactionForm, 'note')
                                        "
                                        errorMsg="You must provide a reason for updating this transaction">
                                    </app-field-error-display>
                                </div>
                            </div>
                        </div>
                    </form>
                </app-modal>
            </div>
            <!-- modalp popup ends -->

            <!-- modalConstants.bankTransferSendCommsModalId modal popup started -->
            <app-modal
                modalId="{{ modalConstants.bankTransferSendCommsModalId }}"
                buttonConfirmText="Send"
                buttonCancelText="Cancel"
                (modalEventConfirm)="sendCommsToUser($event)"
                (modalEventCancel)="resetFormGroup(sendCommsForm)">
                <div class="section-heading mb-2">Enter message to user</div>
                <form [formGroup]="sendCommsForm">
                    <div class="row mt-3">
                        <div class="col-md-12">
                            <textarea formControlName="message" required></textarea>
                            <app-field-error-display
                                [displayError]="formUtils.isFieldInValid(sendCommsForm, 'message')"
                                errorMsg="Message is required">
                            </app-field-error-display>
                        </div>
                    </div>
                </form>
            </app-modal>
            <!-- modalp popup ends -->
        </div>
    </div>
    <div class="control-sidebar-bg"></div>
</div>
