import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { get } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class ErrorHandlerService {
    extraErrorMeta = {};
    disableTimeout = true;
    code = '\u2022';

    constructor(private toasterService: ToastrService) { }

    public handleError(error: HttpErrorResponse) {
        let errorTitle: string;
        let errorMessage: string;

        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occured. Handle it accordingly
            errorTitle = `Client Side Error`;
            errorMessage = `An error occured: ${error.error.message}`;
        } else {
            // The backend returned unsuccessfull response code.
            errorTitle = `${error.status}`;
            switch (error.status) {
                case 400:
                    errorTitle = errorTitle + ' - Bad request';
                    errorMessage = this.code + ' HTTP Error 400 Bad Request<br/ >';
                    break;
                case 401:
                    this.disableTimeout = false;
                    errorTitle = errorTitle + ' - Unauthorized';
                    errorMessage =
                        this.code + ' Access is denied due to invalid crendentials.<br/ >';
                    break;
                case 403:
                    this.disableTimeout = false;
                    errorMessage =
                        this.code +
                        'You do not have permission to access the requested resource.<br/ >';
                    break;
                case 404:
                    errorTitle = errorTitle + ' - Not Found';
                    errorMessage = this.code + ' The requested resource does not exist.<br/ >';
                    break;
                case 406:
                    errorTitle = '406 - Not Acceptable';
                    errorMessage = this.code + ' Not accepted by server.<br/ >';
                    break;
                case 412:
                    errorMessage = this.code + ' Precondition Failed.<br/ >';
                    break;
                case 500:
                    errorMessage = this.code + ' Internal Server Error.<br/ >';
                    break;
                case 503:
                    errorMessage = this.code + ' The requested service is not available.<br/ >';
                    break;
                default: {
                    this.disableTimeout = false;
                    errorTitle = 'Unknown Server Error';
                    errorMessage = this.code + ' Something went wrong. Try again later!!!<br/ >';
                }
            }
        }

        if (get(error, 'error.message')) {
            errorMessage = errorMessage + this.code + ' ' + get(error, 'error.message');
            this.setErrorMetaData();
        }

        if (get(error, 'error.status.message')) {
            errorMessage = errorMessage + this.code + ' ' + get(error, 'error.status.message');
            this.setErrorMetaData();
        }

        if (get(error, 'error.errors')) {
            errorMessage =
                errorMessage +
                this.code +
                ' ' +
                get(error, 'error.errors').join('<br/ >' + this.code);
            this.setErrorMetaData();
        }

        this.toasterService.error(errorMessage, errorTitle, this.extraErrorMeta);
    }

    private setErrorMetaData() {
        this.extraErrorMeta = {
            disableTimeOut: this.disableTimeout,
            tapToDismiss: true,
            closeButton: true,
        };
    }
}
