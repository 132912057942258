import { Component, OnInit, Input } from '@angular/core';
import { UserStatus } from 'src/app/enums/user.status';
import { User } from 'src/app/model/user';
import { FileStatus } from 'src/app/enums/file.status';
import { TransactionStatus } from 'src/app/enums/transaction.status';
import { SagepayExportLogStatus } from 'src/app/enums/sagepay.export.log.status';

@Component({
    selector: 'app-account-status',
    templateUrl: './account-status.component.html',
    styleUrls: ['./account-status.component.css'],
})
export class AccountStatusComponent {
    @Input() type = 'user';
    @Input() cssClass = '';
    @Input() status: string;
    @Input() businessStatus: string;
    @Input() agentStatus: string;
    @Input() enterpriseStatus: string;
    @Input() employeeStatus: string;
    @Input() staffStatus: string;
    @Input() smartSafeStatus: string;
    @Input() safeStatus: string;
    @Input() transactionStatus: string;
    userStatusEnum = UserStatus;
    fileStatusEnum = FileStatus;
    transactionStatusEnum = TransactionStatus;
    sagepayExportLogStatus = SagepayExportLogStatus;

    constructor() {}
}
