import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { URI } from 'src/app/settings/uri-constants';
import { StorageConstant } from 'src/app/settings/storage-constants';
import { CommonService } from 'src/app/services/common.service';

@Component({
    selector: 'app-verify-otp',
    templateUrl: './verify-otp.component.html',
    styleUrls: ['./verify-otp.component.css'],
})
export class VerifyOtpComponent {
    display = false;
    otpResent = false;
    isLoading = false;
    eMessage = '';
    form: FormGroup;
    uuid: string;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private commonService: CommonService,
    ) {
        this.uuid = localStorage.getItem(StorageConstant.CHANGE_MOBILE_UUID);
        this.form = this.formBuilder.group({
            code: ['', [Validators.required, Validators.maxLength(6), Validators.minLength(6)]],
            uuid: [this.uuid],
        });
    }

    closeModel(enent: any) {
        this.display = false;
        this.otpResent = false;
    }

    cancel() {
        localStorage.removeItem(StorageConstant.CHANGE_MOBILE_UUID);
        this.router.navigate([URI.SLASHED_CHANGE_MOBILE]);
    }

    verify() {
        this.isLoading = true;
        this.commonService.verifyOtp(this.form.value).subscribe(
            (res) => {
                this.isLoading = false;
                if (res.success) {
                    localStorage.setItem(StorageConstant.CHANGE_MOBILE_SUCCESS, 'success');
                    localStorage.removeItem(StorageConstant.CHANGE_MOBILE);
                    localStorage.removeItem(StorageConstant.CHANGE_MOBILE_UUID);
                    this.router.navigate([URI.SLASHED_SEARCH]);
                } else {
                    this.eMessage = res.status.message;
                    this.display = true;
                }
            },
            (err) => {
                if (err.error.error) {
                    this.eMessage = err.error.error;
                } else {
                    this.eMessage = err.error.errors[0];
                }
                this.isLoading = false;
                this.display = true;
            },
        );
    }

    resend() {
        this.isLoading = true;
        this.commonService.resendOtp(this.form.value).subscribe(
            (res) => {
                this.isLoading = false;
                if (res.success) {
                    this.otpResent = true;
                } else {
                    this.eMessage = res.status.message;
                    this.display = true;
                }
            },
            (err) => {
                if (err.error.error) {
                    this.eMessage = err.error.error;
                } else {
                    this.eMessage = err.error.errors[0];
                }
                this.isLoading = false;
                this.display = true;
            },
        );
    }
}
