import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { getCashDropKey } from 'src/app/enums/smartsafe.transaction.type';
import { Pagination } from 'src/app/model/Pagination';
import { CompanyService } from 'src/app/services/company.service';
import { SmartSafeService } from 'src/app/services/smart-safe.service';
import { AppConstants } from 'src/app/settings/app-constants';
import { URI } from 'src/app/settings/uri-constants';
import { transformDateToApiFormat } from 'src/app/utils/date-util';

@Component({
    selector: 'app-safe-user-details',
    templateUrl: './safe-user-details.component.html',
    styleUrls: ['./safe-user-details.component.css'],
})
export class SafeUserDetailsComponent implements OnInit {
    isLoading: boolean = false;
    loaderText = 'Searching...';
    progressWidth = 25;
    uri: URI;
    name: string;
    userRoleId: string;
    safeId: string;
    isGrandChild = false;
    safeTransactions: any[];
    form: FormGroup;
    pagination = new Pagination();
    locations: any[] = ['Safes'];
    amountFormat = AppConstants.AMOUNT_FORMAT;
    dateTimeFormat = AppConstants.DATE_TIME_FORMAT;
    showOptions: boolean = false;
    displayView: boolean = false;
    selectedSafeTransaction: any;
    search = false;

    constructor(
        private smartSafeService: SmartSafeService,
        private activatedRoute: ActivatedRoute,
        public companyService: CompanyService,
        private formBuilder: UntypedFormBuilder,
    ) {
        this.uri = URI;
    }

    ngOnInit() {
        this.activatedRoute.data.subscribe((data) => {
            this.isGrandChild = data?.isChildOfChild;
            this.name = data?.childData?.name;
            this.safeId = data?.childData?.safeId;
            this.userRoleId = data?.childData?.userRoleId;
        });

        this.form = this.formBuilder.group({
            text: new FormControl(''),
            start: new FormControl(''),
            length: new FormControl(''),
            userRoleId: new FormControl(this.userRoleId),
            safeId: new FormControl(this.safeId),
            type: new FormControl(getCashDropKey()),
            fromDate: new FormControl(''),
            toDate: new FormControl(''),
            fromDateFilter: new FormControl(''),
            toDateFilter: new FormControl(''),
        });

        this.getData();
    }

    clearText() {
        this.form.get('text').setValue('');
    }

    toggleOption() {
        this.showOptions = !this.showOptions;
    }

    // TODO: Move this to a util method. It is called in multiple places.
    isEmpty(item): boolean {
        return this.form.get(item).value.length <= 0;
    }

    submit() {
        this.getData();
    }

    getData() {
        this.isLoading = true;
        this.search = false;
        this.processFormValues();

        this.smartSafeService.getSmartSafesTransaction(this.form.value).subscribe(
            (res) => {
                this.safeTransactions = res.responseBody.data;
                this.pagination.total = res.responseBody.total;
                this.isLoading = false;
            },
            (error) => {
                this.isLoading = false;
            },
        );
    }

    processFormValues() {
        this.form.get('start').setValue(this.pagination.start);
        this.form.get('length').setValue(this.pagination.length);
        const fromDate = this.form.get('fromDateFilter').value;
        const toDate = this.form.get('toDateFilter').value;

        if (fromDate !== '') this.form.get('fromDate').setValue(transformDateToApiFormat(fromDate));
        if (toDate !== '') this.form.get('toDate').setValue(transformDateToApiFormat(toDate));
    }

    receiveFilter(data: { name: string; value: number }) {}

    changeLength(len: number) {
        this.pagination.length = len;
        this.pagination.start = 0;
        this.getData();
    }

    clearFilters() {
        this.form.get('fromDate').setValue('');
        this.form.get('toDate').setValue('');
        this.form.get('fromDateFilter').setValue('');
        this.form.get('toDateFilter').setValue('');
        this.form.get('text').setValue('');
        this.getData();
    }

    changePage(page: number) {
        this.pagination.changePage(page);
        this.getData();
    }

    openViewModel() {
        this.displayView = true;
    }

    closeViewModel() {
        this.displayView = false;
    }

    action(safeTransaction: any) {
        this.selectedSafeTransaction = safeTransaction;
        this.openViewModel();
    }
}
