<router-outlet (activate)="onActivate()" (deactivate)="onDeactivate()"> </router-outlet>

@if (showParent) {
    <div>
        <section class="add-section">
            <form [formGroup]="form">
                <div class="row">
                    <div class="col-md-12 section-heading">Search</div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        <div class="input-icons">
                            <i class="fa fa-search icon" aria-hidden="true"></i>
                            <input
                                class="form-input"
                                type="text"
                                placeholder="Enter a Name"
                                formControlName="text" />
                            @if (!isEmpty('text')) {
                                <a (click)="clearText()">
                                    <img src="assets/images/close-x-purple.png" />
                                </a>
                            }
                        </div>
                    </div>
                    <div class="col-md-7">
                        <button class="search-button" (click)="submit()">Search</button>
                        <a class="advanced-search" (click)="toggleOption()">
                            <u
                                ><img class="mr-2" src="assets/images/dropdown.png" />ADVANCED
                                SEARCH</u
                            >
                        </a>
                        <a class="clear-filters" (click)="clearFilters()"><u>Clear Filters</u></a>
                    </div>
                </div>
                @if (showOptions) {
                    <hr />
                    <div class="search-form">
                        <div class="row">
                            <div class="col-md-4">
                                <div>
                                    <label class="form-label date-label m-0">START DATE</label>
                                </div>
                                <div class="datepicker">
                                    <input
                                        matInput
                                        class="datepicker-input"
                                        [matDatepicker]="fromDatePicker"
                                        name="fromDate"
                                        formControlName="fromDateFilter"
                                        readonly />
                                    <mat-datepicker-toggle
                                        matSuffix
                                        [for]="fromDatePicker"></mat-datepicker-toggle>
                                    <mat-datepicker #fromDatePicker></mat-datepicker>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div>
                                    <label class="form-label date-label m-0">END DATE</label>
                                </div>
                                <div class="datepicker">
                                    <input
                                        matInput
                                        class="datepicker-input"
                                        [matDatepicker]="toDatePicker"
                                        name="toDate"
                                        formControlName="toDateFilter"
                                        readonly />
                                    <mat-datepicker-toggle
                                        matSuffix
                                        [for]="toDatePicker"></mat-datepicker-toggle>
                                    <mat-datepicker #toDatePicker></mat-datepicker>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label class="form-label">TRANSACTION TYPE</label>
                                <div>
                                    <ng-select
                                        [items]="transactionTypeList"
                                        bindLabel="value"
                                        placeholder="Select Transaction Type"
                                        formControlName="type"
                                        bindValue="name"
                                        searchable="false"
                                        name="type"
                                        [multiple]="false">
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                    </div>
                    @if (searchItems.length > 0) {
                        <div class="searched-items">
                            @for (item of searchItems; track $index) {
                                <div class="search-item-value">
                                    {{ item | humanize }}
                                    <a (click)="removeSearchItem(item)">
                                        <img src="assets/images/close-x-white.png" />
                                    </a>
                                </div>
                            }
                        </div>
                    }
                }
            </form>
        </section>
        <section class="add-section">
            <div class="row">
                <div class="col-md-12">
                    <span class="section-heading">{{ safeId }}</span>
                    <br />
                    <span>{{ companyService.getSelectedCompanyName() }} Safe ID</span>
                </div>
            </div>
        </section>

        @if (!isLoading && safeTransactions?.length !== null && safeTransactions?.length > 0) {
            <div class="row">
                <div class="search-result-header">
                    <div class="col-md-12">
                        Search Results: {{ safeTransactions.length }} of
                        {{ pagination.total }}
                    </div>
                </div>
            </div>
        }

        @if (isLoading) {
            <div class="progress-container">
                <div class="progress-container-text">
                    <div>{{ loaderText }}</div>
                </div>
                <div>
                    <div class="custom-progress-bar">
                        <span [style.width.%]="progressWidth"></span>
                    </div>
                </div>
            </div>
        }

        <section>
            <div class="container-fluid tab-pane custom-table smart-safes">
                <br />
                <table>
                    <thead>
                        <tr class="form-table-row">
                            <th>
                                <app-filter-arrow
                                    filter="businessName"
                                    title="CUSTOMER"
                                    (filterValue)="receiveFilter($event)">
                                </app-filter-arrow>
                            </th>
                            <th>
                                <app-filter-arrow
                                    filter="sealNumber"
                                    title="SEAL NUMBER"
                                    (filterValue)="receiveFilter($event)">
                                </app-filter-arrow>
                            </th>
                            <th>
                                <app-filter-arrow
                                    filter="date"
                                    title="TIMESTAMP"
                                    (filterValue)="receiveFilter($event)">
                                </app-filter-arrow>
                            </th>
                            <th>
                                <app-filter-arrow
                                    filter="type"
                                    title="TRANSACTION TYPE"
                                    (filterValue)="receiveFilter($event)">
                                </app-filter-arrow>
                            </th>
                            <th>
                                <app-filter-arrow
                                    filter="amount"
                                    title="TOTAL"
                                    (filterValue)="receiveFilter($event)">
                                </app-filter-arrow>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        @for (safeTransaction of safeTransactions; track safeTransaction.safeId) {
                            <ng-container>
                                <tr class="form-table-row view-form">
                                    <td>
                                        <a
                                            [routerLink]="[
                                                getTransactionsByUserRoute(
                                                    safeTransaction.safeId,
                                                    safeTransaction.userRoleId
                                                ),
                                                { name: safeTransaction.businessName }
                                            ]">
                                            <b>{{ safeTransaction.businessName }}</b>
                                        </a>
                                    </td>
                                    <td>{{ safeTransaction.sealNumber }}</td>
                                    <td>{{ safeTransaction.date | date: dateTimeFormat }}</td>
                                    <td>{{ safeTransaction.type }}</td>
                                    <td>
                                        {{ safeTransaction.currency | currencyFormat }}
                                        {{
                                            safeTransaction.amount || '0.00' | number: amountFormat
                                        }}
                                    </td>
                                </tr>
                            </ng-container>
                        } @empty {
                            <ng-container>
                                <tr>
                                    <div class="data-empty">
                                        <div class="col-md-12">No data to display</div>
                                    </div>
                                </tr>
                            </ng-container>
                        }
                    </tbody>
                    @if (pagination.showPagination()) {
                        <tfoot>
                            <tr>
                                <td>
                                    Rows per page:
                                    <span>
                                        <b>{{ pagination.length }}</b>
                                    </span>
                                    <app-page-length-dropdown (emitLength)="changeLength($event)">
                                    </app-page-length-dropdown>
                                </td>
                                <td>
                                    <app-page-navigation
                                        pageNumber="{{ pagination.getPageNo() }}"
                                        totalPages="{{ pagination.getTotalPages() }}"
                                        (changePage)="changePage($event)">
                                    </app-page-navigation>
                                </td>
                            </tr>
                        </tfoot>
                    }
                </table>
            </div>
        </section>
    </div>
}
