<div id="adminTransactionViewModal" class="transaction-details-window">
    <div class="transaction-details-header">
        <div class="section-heading">
            Request Details
            <a (click)="closeViewModel()"
                ><img class="float-right" src="assets/images/close-x-purple.png"
            /></a>
        </div>
        <hr />
        <div class="transaction-details-header-body">
            <div class="row">
                <div class="col-md-6">
                    <label class="form-label">USER OR BUSINESS NAME</label>
                    <ng-container *ngIf="transferToBank">
                        <div class="user-details-text">
                            <strong>{{ transaction.fromUserName }}</strong>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!transferToBank">
                        <div class="user-details-text">
                            <strong>{{ transaction.toUserName }}</strong>
                        </div>
                    </ng-container>
                </div>
                <div class="col-md-6">
                    <label class="form-label"></label>
                    <app-account-status
                        type="transaction"
                        transactionStatus="{{ transaction.status }}">
                    </app-account-status>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label class="form-label">MOBILE/ENTERPRISE NUMBER</label>
                    <ng-container *ngIf="transferToBank">
                        <div class="user-details-text">
                            {{ transaction.fromMobileNumber }}
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!transferToBank">
                        <div class="user-details-text">
                            {{ transaction.toMobileNumber }}
                        </div>
                    </ng-container>
                </div>
                <div class="col-md-6">
                    <ng-container *ngIf="transferToBank">
                        <label class="form-label">RECEIVED</label>
                        <div class="user-details-text">
                            {{ transaction.receivedAt | date: dateFormat }}
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!transferToBank">
                        <label class="form-label">Date</label>
                        <div class="user-details-text">
                            {{ transaction.date | date: dateFormat }}
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label class="form-label">USER TYPE</label>
                    <ng-container *ngIf="transferToBank">
                        <div class="user-details-text">
                            {{ transaction.fromRole | humanize }}
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!transferToBank">
                        <div class="user-details-text">
                            {{ transaction.toRole | humanize }}
                        </div>
                    </ng-container>
                </div>
                <ng-container *ngIf="transferToBank">
                    <div class="col-md-6">
                        <label class="form-label">DUE</label>
                        <div class="user-details-text">
                            {{ transaction.settleOn | date: dateFormat }}
                        </div>
                    </div>
                </ng-container>
                <div class="col-md-6">
                    <ng-container
                        *ngIf="!transferToBank && transaction.bankDetails.dateReceivedInBank">
                        <label class="form-label">Date Received in Bank</label>
                        <div class="user-details-text">
                            {{ transaction.bankDetails.dateReceivedInBank }}
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="row">
                <ng-container *ngIf="!transferToBank && transaction.bankDetails.note">
                    <div class="col-md-12">
                        <label class="form-label">Description</label>
                        <div class="user-details-text">
                            {{ transaction.bankDetails.note }}
                        </div>
                    </div>
                </ng-container>
            </div>
            <hr />
            <ng-container *ngIf="transaction?.bankDetails !== null">
                <div class="section-heading">Bank Details</div>
                <div class="row">
                    <div class="col-md-6">
                        <label class="form-label">BANK</label>
                        <div class="user-details-text">
                            {{ transaction.bankDetails.bankName }}
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="form-label">BRANCH</label>
                        <div class="user-details-text">
                            {{ transaction.bankDetails.bankLocation }}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label class="form-label">ACCOUNT NUMBER</label>
                        <div class="user-details-text">
                            {{ transaction.bankDetails.accountNumber }}
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="form-label">BRANCH NUMBER</label>
                        <div class="user-details-text">
                            {{ transaction.bankDetails.branchCode }}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label class="form-label">ACCOUNT HOLDER</label>
                        <div class="user-details-text">
                            {{ transaction.bankDetails.accountHolderName }}
                        </div>
                    </div>
                </div>
                <hr />
            </ng-container>
            <ng-container *ngIf="showUpdate">
                <div class="row">
                    <div class="col-md-4">
                        <button
                            type="submit"
                            (click)="openTransactionUpdateModal(true)"
                            class="transaction-details-custombtn background-inherit text-orange border-orange">
                            Cancel
                        </button>
                    </div>
                    <div class="col-md-4">
                        <button
                            type="submit"
                            data-backdrop="false"
                            data-toggle="modal"
                            (click)="
                                transaction?.isShopifyCashIn
                                    ? openTransactionUpdateModal(false)
                                    : openModal(modalConstants.bankTransferReceiveModalId)
                            "
                            class="transaction-details-custombtn border-none background-gradient-orange">
                            {{ transaction?.isShopifyCashIn ? 'Update Amount' : 'Received' }}
                        </button>
                    </div>
                    <div class="col-md-4">
                        <button
                            type="submit"
                            data-backdrop="false"
                            class="transaction-details-custombtn background-inherit text-orange border-orange"
                            data-toggle="modal"
                            (click)="openModal(modalConstants.bankTransferSendCommsModalId)">
                            Send Comms
                        </button>
                    </div>
                </div>
                <hr />
            </ng-container>
            <div class="section-heading">Logs</div>
            <table>
                <tbody>
                    <tr *ngFor="let tran of transaction.transactionLog">
                        <td class="pb-2">
                            <div class="transaction-details-log-date">
                                <label class="form-label mb-0">
                                    {{ tran.createdAt | date: 'd/M/yyyy - h:mm a' }}
                                </label>
                            </div>
                            <div
                                class="circle-left circle-green"
                                *ngIf="tran.status === transactionStatusEnum.success"></div>
                            <div
                                class="circle-left circle-blue"
                                *ngIf="tran.status === transactionStatusEnum.onhold"></div>
                            <div
                                class="circle-left circle-red"
                                *ngIf="tran.status === transactionStatusEnum.failed"></div>
                            <div
                                class="circle-left circle-red"
                                *ngIf="tran.status === transactionStatusEnum.cancelled"></div>
                            <div
                                class="circle-left circle-yello"
                                *ngIf="tran.status === transactionStatusEnum.pending"></div>
                            <div
                                class="circle-left circle-orange"
                                *ngIf="tran.status === transactionStatusEnum.processing"></div>
                            {{ tran.message }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="transaction-details-footer">
        Amount:
        <span class="float-right pr-3"
            >R{{ transaction.amount || '0.00' | number: amountFormat }}</span
        >
    </div>
</div>
