import { Component, OnInit, Input } from '@angular/core';
import { UserStatus } from 'src/app/enums/user.status';

@Component({
    selector: 'app-user-status',
    templateUrl: './user-status.component.html',
    styleUrls: ['./user-status.component.css'],
})
export class UserStatusComponent {
    @Input() status: string;
    @Input() userId: string;
    statusEnum = UserStatus;
    userStatusDropdown: string[] = [];

    constructor() {}

    changeStatus(item: string) {}
}
