import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from './services/login.service';
import { URI } from './settings/uri-constants';
declare let $: any;
declare let bootstrap: any;
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
    title = 'admin-portal-ui';

    constructor(
        private loginService: LoginService,
        private router: Router,
    ) {
        // TODO Bootstrap 5 Tooltip - Not working
        // var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-toggle="tooltip"]'));
        // var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        //     return new bootstrap.Tooltip(tooltipTriggerEl);
        // });
        //
        // // Bootstrap 4 Tooltip
        $('body').tooltip({
            selector: '[data-toggle="tooltip"]',
            template:
                '<div class="tooltip" role="tooltip">' +
                '<div class="tooltip-arrow"></div>' +
                '<div class="tooltip-inner custom-tooltip-inner"></div>' +
                '</div>',
        });
    }

    ngOnInit() {
        this.loginService.autoLogin();

        this.loginService.sessionUser.subscribe((user) => {
            const skipAuth = this.loginService.skipUrl(this.router.url);

            if (!user && !skipAuth) {
                this.router.navigate([URI.SLASHED_LOGIN]);
            }
        });
    }

    isLoggedIn(): boolean {
        return this.loginService.isLoggedIn();
    }
}
