import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { TransactionStatus } from 'src/app/enums/transaction.status';
import { Transaction } from 'src/app/model/transaction';
import { CommonUtil } from 'src/app/services/common.util';
import { CompanyService } from 'src/app/services/company.service';

@Component({
    selector: 'app-transaction-body',
    templateUrl: './transaction-body.component.html',
    styleUrls: ['./transaction-body.component.css'],
})
export class TransactionBodyComponent {
    @Input() transactionList: Array<Transaction>;
    status = TransactionStatus;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        public commonUtil: CommonUtil,
        public companyService: CompanyService,
    ) {}

    showDetails(val: string, logo: string) {
        let element = this.document.getElementById(val);
        if (element.style.maxHeight) {
            element.style.maxHeight = null;
            element.style.border = 'none';
        } else {
            element.style.maxHeight = element.scrollHeight + 'px';
            element.style.border = '6px';
        }

        element = this.document.getElementById(logo);
        let src = element.getAttribute('src');
        src =
            src === 'assets/images/default-transaction-detail.png'
                ? 'assets/images/hover-transaction-detail.png'
                : 'assets/images/default-transaction-detail.png';

        element.setAttribute('src', src);
    }

    copyText(val: string) {
        const input = this.document.createElement('input');
        this.document.body.appendChild(input);
        if (val) {
            input.value = val;
        } else {
            input.value = 'NA';
        }
        input.select();
        this.document.execCommand('copy');
        input.remove();
    }
}
