import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { URI } from 'src/app/settings/uri-constants';

@Component({
    selector: 'app-select-user-type',
    templateUrl: './select-type.component.html',
    styleUrls: ['./select-type.component.css'],
})
export class SelectTypeComponent {
    @Input() type: string;
    userTypes = ['Staff User', 'Enterprise'];

    constructor(private router: Router) {}

    changeType(val: string) {
        switch (val) {
            // case this.userTypes[0]  :   this.router.navigate([URI.SLASHED_ADD_GENERAL_USER]);
            //                             break;
            // case this.userTypes[1]  :   this.router.navigate([URI.SLASHED_ADD_MERCHANT]);
            //                             break;
            // case this.userTypes[0]  :   this.router.navigate([URI.SLASHED_ADD_AGENT]);
            //                             break;
            case this.userTypes[0]:
                this.router.navigate([URI.SLASHED_ADD_STAFF]);
                break;
            case this.userTypes[1]:
                this.router.navigate([URI.SLASHED_ADD_ENTERPRISE]);
                break;
        }
    }
}
