export class TransferToBank {
    public totalAmount: number;
    public processDate: string;
    public lastExportOn: string;
    public exportedBy: string;
    public isExportDay: boolean;
    public nextExportReferenceNumber: number = 0;

    constructor() {}
}
