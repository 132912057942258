<div class="login-background">
    <div class="alert alert-dismissible custom-alert-success" *ngIf="displaySuccess">
        <button
            type="button"
            (click)="closeModel()"
            class="close close-modified"
            data-dismiss="alert">
            &times;
        </button>
        <div class="login-error-text">
            <div class="error-icon"><img src="assets/images/success.png" /></div>
            <div class="login-error-message">{{ successMessage }}</div>
        </div>
    </div>
    <div class="alert alert-dismissible custom-alert-error" *ngIf="display">
        <button
            type="button"
            (click)="closeModel()"
            class="close close-modified"
            data-dismiss="alert">
            &times;
        </button>
        <div class="login-error-text">
            <div class="error-icon"><img src="assets/images/warning.png" /></div>
            <div class="login-error-message">{{ errorMessage }}</div>
        </div>
    </div>
    <div class="d-flex justify-content-center align-items-center" *ngIf="isLoading">
        <div class="spinner-border mt-5 text-white" style="width: 4rem; height: 4rem" role="status">
            <span class="sr-only text-white">Loading...</span>
        </div>
    </div>

    <form #myForm="ngForm" (ngSubmit)="onSubmit()" *ngIf="!isLoading">
        <div class="sign-in-box-shape">
            <img
                class="lovcash-logo"
                src="assets/images/lovcash-logo-dark.png"
                alt="lovcash logo" />
            <div *ngIf="!forgotPassword">
                <p class="sign-in-portal-text">Sign in to the Admin Portal</p>
            </div>
            <div *ngIf="forgotPassword">
                <p class="sign-in-portal-text">Forgot your Password?</p>
                <p class="label-forgot-instruction">
                    Enter your email address and a reset link will be sent to you.
                </p>
            </div>

            <div class="box-outline" [ngClass]="{ 'box-focus': emailFocused }">
                <img src="assets/images/email.png" class="pin-code-image" />
                <input
                    placeholder="{{ placeholder }}"
                    type="email"
                    name="email"
                    [(ngModel)]="loginForm.email"
                    (focus)="emailFocusFunction()"
                    (focusout)="emailFocusFunction()"
                    required
                    class="password-text-box" />
                <img src="" class="password-changer" />
            </div>
            <div
                class="box-outline"
                *ngIf="!forgotPassword"
                [ngClass]="{ 'box-focus': passwordFocused }">
                <img src="assets/images/pin-code.png" class="pin-code-image" />
                <input
                    placeholder="Password"
                    [type]="show ? 'password' : 'text'"
                    name="password"
                    [(ngModel)]="loginForm.password"
                    (focus)="passwordFocusFunction()"
                    (focusout)="passwordFocusFunction()"
                    required
                    class="password-text-box" />
                <a (click)="changePasswordDisplayType()" class="password-changer">
                    <img class="show-pass-class" src="assets/images/{{ showPasswordImage }}.png" />
                </a>
            </div>
            <div class="forgot-password-link">
                <a style="color: #43073d; cursor: pointer" (click)="forgetPassChange()">
                    <p>{{ forgotText }}</p>
                </a>
            </div>
            <button type="submit" class="box-outline submit-btn" [disabled]="!myForm.valid">
                {{ submitText }}
            </button>
        </div>
    </form>
</div>
