<div class="wrapper">
    <app-error-model
        message="{{ eMessage }}"
        [display]="display"
        (event)="closeModel($event)"></app-error-model>
    <app-navigation></app-navigation>
    <div class="content-wrapper">
        <app-content-transition></app-content-transition>
        <app-loader *ngIf="isLoading" className="custom-loader" text="Processing ..."></app-loader>
        <form [formGroup]="form">
            <app-header location="Add User"></app-header>
            <app-select-user-type type="Staff User"></app-select-user-type>
            <section class="add-section">
                <div class="row">
                    <div class="col-md-12 section-heading">Staff User Details</div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label class="form-label">NAME*</label>
                        <div>
                            <input
                                class="form-input"
                                type="text"
                                formControlName="firstName"
                                name="firstName"
                                placeholder="User First Name" />
                        </div>
                        <small *ngIf="controls.firstName.invalid && invalidForm" class="error-text">
                            This field is required
                        </small>
                    </div>
                    <div class="col-md-6">
                        <label class="form-label">SURNAME*</label>
                        <div>
                            <input
                                class="form-input"
                                type="text"
                                formControlName="lastName"
                                name="lastName"
                                placeholder="User Last Name" />
                        </div>
                        <small *ngIf="controls.lastName.invalid && invalidForm" class="error-text">
                            This field is required
                        </small>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label class="form-label">EMAIL ADDRESS*</label>
                        <div>
                            <input
                                class="form-input"
                                type="email"
                                placeholder="e.g. email@gmail.com"
                                name="email"
                                formControlName="email"
                                email />
                        </div>
                        <small
                            *ngIf="(controls.email.invalid && invalidForm) || emailExist"
                            class="error-text"
                            >{{ emailErrorMsg }}
                        </small>
                    </div>
                    <div class="col-md-6">
                        <label class="form-label">MOBILE NUMBER*</label>
                        <div>
                            <input
                                class="form-input"
                                type="text"
                                placeholder="e.g. 8978978999"
                                name="mobileNumber"
                                formControlName="mobileNumber" />
                        </div>
                        <small
                            *ngIf="(controls.mobileNumber.invalid && invalidForm) || mobileExist"
                            class="error-text"
                            >{{ mobileErrorMsg }}
                        </small>
                    </div>
                </div>
            </section>
            <section class="add-section">
                <div class="row">
                    <div class="col-md-12 section-heading">Administrative Settings</div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label class="form-label">ADMINISTRATIVE ROLE*</label>
                        <app-select-box
                            placeholderValue="Set Role"
                            value="role"
                            [form]="form"
                            [invalidForm]="invalidForm"
                            [dropDown]="accountTypeDropdown">
                        </app-select-box>
                    </div>
                    <div class="col-md-6">
                        <label class="form-label">STATUS*</label>
                        <app-select-box
                            placeholderValue="Set Status"
                            value="status"
                            [form]="form"
                            [invalidForm]="invalidForm"
                            [dropDown]="statusDropdown">
                        </app-select-box>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6"></div>
                    <div class="col-md-6">
                        <button class="custombtn custombtn-align-right" (click)="submit()">
                            Save
                        </button>
                    </div>
                </div>
            </section>
            <section class="add-section-footer">*Required</section>
        </form>
    </div>
</div>
