import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { URI } from 'src/app/settings/uri-constants';

@Component({
    selector: 'app-eft-cash-in',
    templateUrl: './eft-cash-in.component.html',
    styleUrls: ['./eft-cash-in.component.css'],
})
export class EftCashInComponent implements OnInit {
    constructor(private router: Router) {}

    ngOnInit() {
        this.router.navigate([URI.getRoutePath('adminEFTCashInPending')]);
    }
}
