import { Component, OnInit } from '@angular/core';
import { URI } from 'src/app/settings/uri-constants';

@Component({
    selector: 'app-settings-link',
    templateUrl: './settings-link.component.html',
    styleUrls: ['./settings-link.component.css'],
})
export class SettingsLinkComponent {
    uri = URI;

    constructor() {}
}
