<div class="row">
    <div class="col-md-8 section-heading">
        {{ title }}
    </div>
    <div class="col-md-4 section-heading" *ngIf="showEditPencil">
        <app-edit-pencil (edit)="changeView()"></app-edit-pencil>
    </div>
</div>
<div *ngIf="!editAgent">
    <div class="row">
        <div class="col-md-12">
            <table class="fees-table">
                <thead>
                    <tr>
                        <th></th>
                        <th>% FEE</th>
                        <th>USE %</th>
                        <th>AMOUNT</th>
                        <th>USE AMOUNT</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="commonUtil.showFeeRow(agentFeeList, feeCode.sender_cin)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(agentFeeList, feeCode.sender_cin).description
                                }}">
                                {{ feeLabel[feeCode.sender_cin] }}
                            </span>
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(agentFeeList, feeCode.sender_cin).percentage
                                    | number: amountFormat
                            }}{{ ' %' }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnlyAgent_{{ feeCode.sender_cin }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_cin).useAmount ==
                                    false
                                "
                                disabled />
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(agentFeeList, feeCode.sender_cin).amount
                                    | number: amountFormat
                            }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnlyAgent_{{ feeCode.sender_cin }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_cin).useAmount ==
                                    true
                                "
                                disabled />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(agentFeeList, feeCode.receiver_cot)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cot)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.receiver_cot] }}
                            </span>
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(agentFeeList, feeCode.receiver_cot).percentage
                                    | number: amountFormat
                            }}{{ ' %' }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnlyAgent_{{ feeCode.receiver_cot }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cot)
                                        .useAmount == false
                                "
                                disabled />
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(agentFeeList, feeCode.receiver_cot).amount
                                    | number: amountFormat
                            }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnlyAgent_{{ feeCode.receiver_cot }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cot)
                                        .useAmount == true
                                "
                                disabled />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(agentFeeList, feeCode.receiver_cin)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cin)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.receiver_cin] }}
                            </span>
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(agentFeeList, feeCode.receiver_cin).percentage
                                    | number: amountFormat
                            }}{{ ' %' }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnlyAgent_{{ feeCode.receiver_cin }}"
                                [value]="false"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cin)
                                        .useAmount == false
                                "
                                disabled />
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(agentFeeList, feeCode.receiver_cin).amount
                                    | number: amountFormat
                            }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnlyAgent_{{ feeCode.receiver_cin }}"
                                [value]="true"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cin)
                                        .useAmount == true
                                "
                                disabled />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(agentFeeList, feeCode.sender_age)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(agentFeeList, feeCode.sender_age).description
                                }}">
                                {{ feeLabel[feeCode.sender_age] }}
                            </span>
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(agentFeeList, feeCode.sender_age).percentage
                                    | number: amountFormat
                            }}{{ ' %' }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnlyAgent_{{ feeCode.sender_age }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_age).useAmount ==
                                    false
                                "
                                disabled />
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(agentFeeList, feeCode.sender_age).amount
                                    | number: amountFormat
                            }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnlyAgent_{{ feeCode.sender_age }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_age).useAmount ==
                                    true
                                "
                                disabled />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(agentFeeList, feeCode.sender_cin_earning)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(agentFeeList, feeCode.sender_cin_earning)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.sender_cin_earning] }}
                            </span>
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(agentFeeList, feeCode.sender_cin_earning)
                                    .percentage | number: amountFormat
                            }}{{ ' %' }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnlyAgent_{{ feeCode.sender_cin_earning }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_cin_earning)
                                        .useAmount == false
                                "
                                disabled />
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(agentFeeList, feeCode.sender_cin_earning).amount
                                    | number: amountFormat
                            }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnlyAgent_{{ feeCode.sender_cin_earning }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_cin_earning)
                                        .useAmount == true
                                "
                                disabled />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(agentFeeList, feeCode.receiver_cdp)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cdp)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.receiver_cdp] }}
                            </span>
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(agentFeeList, feeCode.receiver_cdp).percentage
                                    | number: amountFormat
                            }}{{ ' %' }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnlyAgent_{{ feeCode.receiver_cdp }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cdp)
                                        .useAmount == false
                                "
                                disabled />
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(agentFeeList, feeCode.receiver_cdp).amount
                                    | number: amountFormat
                            }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnlyAgent_{{ feeCode.receiver_cdp }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cdp)
                                        .useAmount == true
                                "
                                disabled />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(agentFeeList, feeCode.receiver_cin_eft)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cin_eft)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.receiver_cin_eft] }}
                            </span>
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(agentFeeList, feeCode.receiver_cin_eft).percentage
                                    | number: amountFormat
                            }}{{ ' %' }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnlyAgent_{{ feeCode.receiver_cin_eft }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cin_eft)
                                        .useAmount == false
                                "
                                disabled />
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(agentFeeList, feeCode.receiver_cin_eft).amount
                                    | number: amountFormat
                            }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnlyAgent_{{ feeCode.receiver_cin_eft }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cin_eft)
                                        .useAmount == true
                                "
                                disabled />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(agentFeeList, feeCode.sender_svc)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(agentFeeList, feeCode.sender_svc).description
                                }}">
                                {{ feeLabel[feeCode.sender_svc] }}
                            </span>
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(agentFeeList, feeCode.sender_svc).percentage
                                    | number: amountFormat
                            }}{{ ' %' }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnlyAgent_{{ feeCode.sender_svc }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_svc).useAmount ==
                                    false
                                "
                                disabled />
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(agentFeeList, feeCode.sender_svc).amount
                                    | number: amountFormat
                            }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnlyAgent_{{ feeCode.sender_svc }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_svc).useAmount ==
                                    true
                                "
                                disabled />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(agentFeeList, feeCode.receiver_svc)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_svc)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.receiver_svc] }}
                            </span>
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(agentFeeList, feeCode.receiver_svc).percentage
                                    | number: amountFormat
                            }}{{ ' %' }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnlyAgent_{{ feeCode.receiver_svc }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_svc)
                                        .useAmount == false
                                "
                                disabled />
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(agentFeeList, feeCode.receiver_svc).amount
                                    | number: amountFormat
                            }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnlyAgent_{{ feeCode.receiver_svc }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_svc)
                                        .useAmount == true
                                "
                                disabled />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(agentFeeList, feeCode.sender_susdv)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(agentFeeList, feeCode.sender_susdv)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.sender_susdv] }}
                            </span>
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(agentFeeList, feeCode.sender_susdv).percentage
                                    | number: amountFormat
                            }}{{ ' %' }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnlyAgent_{{ feeCode.sender_susdv }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_susdv)
                                        .useAmount == false
                                "
                                disabled />
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(agentFeeList, feeCode.sender_susdv).amount
                                    | number: amountFormat
                            }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnlyAgent_{{ feeCode.sender_susdv }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_susdv)
                                        .useAmount == true
                                "
                                disabled />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(agentFeeList, feeCode.receiver_busdv)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_busdv)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.receiver_busdv] }}
                            </span>
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(agentFeeList, feeCode.receiver_busdv).percentage
                                    | number: amountFormat
                            }}{{ ' %' }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnlyAgent_{{ feeCode.receiver_busdv }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_busdv)
                                        .useAmount == false
                                "
                                disabled />
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(agentFeeList, feeCode.receiver_busdv).amount
                                    | number: amountFormat
                            }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnlyAgent_{{ feeCode.receiver_busdv }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_busdv)
                                        .useAmount == true
                                "
                                disabled />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div *ngIf="editAgent">
    <div class="row">
        <div class="col-md-12">
            <table class="fees-table">
                <thead>
                    <tr>
                        <th></th>
                        <th>% FEE</th>
                        <th>USE %</th>
                        <th>AMOUNT</th>
                        <th>USE AMOUNT</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="commonUtil.showFeeRow(agentFeeList, feeCode.sender_cin)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(agentFeeList, feeCode.sender_cin).description
                                }}">
                                {{ feeLabel[feeCode.sender_cin] }}
                            </span>
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [maxVal]="100"
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_cin)
                                        .percentageValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_agent_{{ feeCode.sender_cin }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_cin).useAmount ==
                                    false
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_cin)
                                        .useAmountValue
                                "
                                [value]="false" />
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_cin).amountValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_agent_{{ feeCode.sender_cin }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_cin).useAmount ==
                                    true
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_cin)
                                        .useAmountValue
                                "
                                [value]="true" />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(agentFeeList, feeCode.receiver_cot)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cot)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.receiver_cot] }}
                            </span>
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [maxVal]="100"
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cot)
                                        .percentageValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_agent_{{ feeCode.receiver_cot }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cot)
                                        .useAmount == false
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cot)
                                        .useAmountValue
                                "
                                [value]="false" />
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cot)
                                        .amountValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_agent_{{ feeCode.receiver_cot }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cot)
                                        .useAmount == true
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cot)
                                        .useAmountValue
                                "
                                [value]="true" />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(agentFeeList, feeCode.receiver_cin)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cin)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.receiver_cin] }}
                            </span>
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [maxVal]="100"
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cin)
                                        .percentageValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_agent_{{ feeCode.receiver_cin }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cin)
                                        .useAmount == false
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cin)
                                        .useAmountValue
                                "
                                [value]="false" />
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cin)
                                        .amountValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_agent_{{ feeCode.receiver_cin }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cin)
                                        .useAmount == true
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cin)
                                        .useAmountValue
                                "
                                [value]="true" />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(agentFeeList, feeCode.sender_age)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(agentFeeList, feeCode.sender_age).description
                                }}">
                                {{ feeLabel[feeCode.sender_age] }}
                            </span>
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [maxVal]="100"
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_age)
                                        .percentageValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_agent_{{ feeCode.sender_age }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_age).useAmount ==
                                    false
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_age)
                                        .useAmountValue
                                "
                                [value]="false" />
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_age).amountValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_agent_{{ feeCode.sender_age }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_age).useAmount ==
                                    true
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_age)
                                        .useAmountValue
                                "
                                [value]="true" />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(agentFeeList, feeCode.sender_cin_earning)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(agentFeeList, feeCode.sender_cin_earning)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.sender_cin_earning] }}
                            </span>
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [maxVal]="100"
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_cin_earning)
                                        .percentageValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_agent_{{ feeCode.sender_cin_earning }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_cin_earning)
                                        .useAmount == false
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_cin_earning)
                                        .useAmountValue
                                "
                                [value]="false" />
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_cin_earning)
                                        .amountValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_agent_{{ feeCode.sender_cin_earning }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_cin_earning)
                                        .useAmount == true
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_cin_earning)
                                        .useAmountValue
                                "
                                [value]="true" />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(agentFeeList, feeCode.receiver_cdp)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cdp)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.receiver_cdp] }}
                            </span>
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [maxVal]="100"
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cdp)
                                        .percentageValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_agent_{{ feeCode.receiver_cdp }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cdp)
                                        .useAmount == false
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cdp)
                                        .useAmountValue
                                "
                                [value]="false" />
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cdp)
                                        .amountValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_agent_{{ feeCode.receiver_cdp }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cdp)
                                        .useAmount == true
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cdp)
                                        .useAmountValue
                                "
                                [value]="true" />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(agentFeeList, feeCode.receiver_cin_eft)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cin_eft)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.receiver_cin_eft] }}
                            </span>
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [maxVal]="100"
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cin_eft)
                                        .percentageValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_agent_{{ feeCode.receiver_cin_eft }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cin_eft)
                                        .useAmount == false
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cin_eft)
                                        .useAmountValue
                                "
                                [value]="false" />
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cin_eft)
                                        .amountValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_agent_{{ feeCode.receiver_cin_eft }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cin_eft)
                                        .useAmount == true
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_cin_eft)
                                        .useAmountValue
                                "
                                [value]="true" />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(agentFeeList, feeCode.sender_svc)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(agentFeeList, feeCode.sender_svc).description
                                }}">
                                {{ feeLabel[feeCode.sender_svc] }}
                            </span>
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [maxVal]="100"
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_svc)
                                        .percentageValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_agent_{{ feeCode.sender_svc }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_svc).useAmount ==
                                    false
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_svc)
                                        .useAmountValue
                                "
                                [value]="false" />
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_svc).amountValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_agent_{{ feeCode.sender_svc }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_svc).useAmount ==
                                    true
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_svc)
                                        .useAmountValue
                                "
                                [value]="true" />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(agentFeeList, feeCode.receiver_svc)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_svc)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.receiver_svc] }}
                            </span>
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [maxVal]="100"
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_svc)
                                        .percentageValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_agent_{{ feeCode.receiver_svc }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_svc)
                                        .useAmount == false
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_svc)
                                        .useAmountValue
                                "
                                [value]="false" />
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_svc)
                                        .amountValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_agent_{{ feeCode.receiver_svc }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_svc)
                                        .useAmount == true
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_svc)
                                        .useAmountValue
                                "
                                [value]="true" />
                        </td>
                    </tr>

                    <tr *ngIf="commonUtil.showFeeRow(agentFeeList, feeCode.sender_susdv)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(agentFeeList, feeCode.sender_susdv)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.sender_susdv] }}
                            </span>
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [maxVal]="100"
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_susdv)
                                        .percentageValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_agent_{{ feeCode.sender_susdv }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_susdv)
                                        .useAmount == false
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_susdv)
                                        .useAmountValue
                                "
                                [value]="false" />
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_susdv)
                                        .amountValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_agent_{{ feeCode.sender_susdv }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_susdv)
                                        .useAmount == true
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.sender_susdv)
                                        .useAmountValue
                                "
                                [value]="true" />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(agentFeeList, feeCode.receiver_busdv)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_busdv)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.receiver_busdv] }}
                            </span>
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [maxVal]="100"
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_busdv)
                                        .percentageValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_agent_{{ feeCode.receiver_busdv }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_busdv)
                                        .useAmount == false
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_busdv)
                                        .useAmountValue
                                "
                                [value]="false" />
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_busdv)
                                        .amountValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_agent_{{ feeCode.receiver_busdv }}"
                                [checked]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_busdv)
                                        .useAmount == true
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(agentFeeList, feeCode.receiver_busdv)
                                        .useAmountValue
                                "
                                [value]="true" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row" *ngIf="!parentToUpdate">
        <div class="col-md-6"></div>
        <div class="col-md-6">
            <button class="custombtn custombtn-align-right" (click)="submit()">SAVE</button>
            <a class="user-files-deny" (click)="changeView()"><u>CANCEL</u></a>
        </div>
    </div>
</div>
