<div class="wrapper">
    <app-error-model
        message="{{ eMessage }}"
        [display]="display"
        (event)="closeModel()"></app-error-model>
    <app-navigation></app-navigation>
    <div class="content-wrapper">
        <app-content-transition></app-content-transition>
        <app-loader *ngIf="isLoading" className="custom-loader" text="Processing ..."></app-loader>
        <section class="content-header">
            <div class="row">
                <div class="col-md-8">
                    <div class="tab-heading">
                        <span class="tab-heading-title">Goods Received Voucher</span>
                        <img
                            class="breadcrumb-separator"
                            src="assets/images/breadcrumb-separator.png" />
                        <span class="breadcrumb-custom" *ngIf="!addGrv">Search</span>
                        <span class="breadcrumb-custom" *ngIf="addGrv">Add</span>
                        <img
                            class="breadcrumb-separator"
                            *ngIf="orderId !== ''"
                            src="assets/images/breadcrumb-separator.png" />
                        <span class="breadcrumb-custom" *ngIf="orderId !== ''">{{ orderId }}</span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="right-section">
                        <button
                            class="custombtn"
                            [disabled]="disableComplete"
                            (click)="openAddGrvPage()">
                            {{ buttonText }}
                        </button>
                    </div>
                </div>
            </div>
        </section>
        <section class="add-section">
            <form [formGroup]="form">
                <div class="row">
                    <div class="col-md-12 section-heading" *ngIf="!addGrv">GRV Search</div>
                    <div class="col-md-12 section-heading" *ngIf="addGrv">Add new GRV</div>
                </div>
                <div class="row pb-3">
                    <div class="col-md-6">
                        <label class="form-label" *ngIf="!addGrv"
                            >SEARCH BY SUPPLIER, PURCHASE ORDER ID</label
                        >
                        <label class="form-label" *ngIf="addGrv">ENTER PURCHASE ORDER ID </label>
                        <div class="input-icons">
                            <i class="fa fa-search icon" aria-hidden="true"></i>
                            <input
                                class="form-input"
                                type="text"
                                placeholder="eg. SUPPLIER or 117955"
                                formControlName="text"
                                *ngIf="!addGrv" />
                            <input
                                class="form-input"
                                type="text"
                                placeholder="eg. 117955"
                                formControlName="text"
                                *ngIf="addGrv" />
                            <a *ngIf="form.get('text').value.length > 0" (click)="clearText()">
                                <img src="assets/images/close-x-purple.png" />
                            </a>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="form-label"></label>
                        <div>
                            <button class="search-button" (click)="submit()">Search</button>
                            <a class="advanced-search" (click)="toggleOption()" *ngIf="!addGrv">
                                <img src="assets/images/dropdown.png" />
                                <u>ADVANCED SEARCH</u>
                            </a>
                        </div>
                    </div>
                </div>
                <hr *ngIf="showOptions && !addGrv" />
                <div class="search-form" *ngIf="showOptions && !addGrv">
                    <div class="row">
                        <div class="col-md-4">
                            <label class="form-label">HAS PRICE DIFFERENCE CLAIM</label>
                            <div>
                                <label class="switch-container">
                                    <input
                                        type="checkbox"
                                        formControlName="hasPriceDifferenceClaim" />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label class="form-label">HAS SHORT DELIVERY CLAIM</label>
                            <div>
                                <label class="switch-container">
                                    <input
                                        type="checkbox"
                                        formControlName="hasShortDeliveryClaim" />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label class="form-label">DATE CREATED</label>
                            <div class="datepicker-width-85">
                                <input
                                    matInput
                                    class="datepicker-input"
                                    [matDatepicker]="picker"
                                    name="dateCreated"
                                    formControlName="dateCreated"
                                    readonly />
                                <mat-datepicker-toggle
                                    matSuffix
                                    [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <label class="form-label"></label>
                            <div>
                                <a class="p-3 text-danger line-height-3" (click)="clearDates()"
                                    ><u>Clear Date</u></a
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </section>
        <div class="progress-container" *ngIf="isLoading">
            <div class="progress-container-text">
                <div>{{ loaderText }}</div>
            </div>
            <div>
                <div class="custom-progress-bar">
                    <span [style.width.%]="progressWidth"></span>
                </div>
            </div>
        </div>
        <section *ngIf="!addGrv">
            <div class="search-result-header">
                Search Results: {{ grvList.length }} of {{ pagination.total }}
            </div>
            <hr />
            <div class="container-fluid tab-pane custom-table default-width">
                <br />
                <table id="tableData">
                    <thead>
                        <tr>
                            <th width="25%">
                                <app-filter-arrow title="SUPPLIER"> </app-filter-arrow>
                            </th>
                            <th width="25%">
                                <app-filter-arrow title="PURCHASE ORDER ID"> </app-filter-arrow>
                            </th>
                            <th width="22%">
                                <app-filter-arrow title="DATE CREATED"> </app-filter-arrow>
                            </th>
                            <th width="23%" class="text-right">
                                <app-filter-arrow title="TOTAL INVOICE PRICE"> </app-filter-arrow>
                            </th>
                            <th width="5">
                                <app-filter-arrow title="ACTIONS"> </app-filter-arrow>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="form-table-row" *ngFor="let grv of grvList">
                            <td width="27%">
                                {{ grv.purchase_order ? grv.purchase_order.supplier_name : '' }}
                            </td>
                            <td width="27%">
                                {{ grv.purchase_order ? grv.purchase_order.id : '' }}
                            </td>
                            <td width="20%">{{ grv.createdAt | date }}</td>
                            <td width="21%" class="text-right">
                                {{ grv.grandTotal | number: amountFormat | replace: ' ' }}
                            </td>
                            <td>
                                <app-last-child-grv [grv]="grv" (event)="openGrvModel($event)">
                                </app-last-child-grv>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot *ngIf="pagination.showPagination()">
                        <tr>
                            <td>
                                Rows per page:
                                <span
                                    ><b>{{ pagination.length }}</b></span
                                >
                                <app-page-length-dropdown (emitLength)="changeLength($event)">
                                </app-page-length-dropdown>
                            </td>
                            <td>
                                <app-page-navigation
                                    pageNumber="{{ pagination.getPageNo() }}"
                                    totalPages="{{ pagination.getTotalPages() }}"
                                    (changePage)="changePage($event)">
                                </app-page-navigation>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </section>
        <section *ngIf="addGrv && addGrvList.length > 0">
            <form [formGroup]="addForm">
                <div class="container-fluid tab-pane custom-table">
                    <br />
                    <table id="tableData">
                        <thead>
                            <tr>
                                <th width="16%">
                                    <app-filter-arrow filter="name" title="PRODUCT">
                                    </app-filter-arrow>
                                </th>
                                <th width="12%">
                                    <app-filter-arrow filter="mobileNumber" title="SKU">
                                    </app-filter-arrow>
                                </th>
                                <th width="15%">
                                    <app-filter-arrow filter="mobileNumber" title="BARCODE">
                                    </app-filter-arrow>
                                </th>
                                <th width="10%">
                                    <app-filter-arrow filter="role" title="COST(BASE)">
                                    </app-filter-arrow>
                                </th>
                                <th width="10%">
                                    <app-filter-arrow filter="role" title="PRICE INVOICE">
                                    </app-filter-arrow>
                                </th>
                                <th width="10%">
                                    <app-filter-arrow filter="role" title="TOTAL BASE">
                                    </app-filter-arrow>
                                </th>
                                <th width="9%">
                                    <app-filter-arrow filter="role" title="TAX RATE">
                                    </app-filter-arrow>
                                </th>
                                <th width="10%">
                                    <app-filter-arrow filter="role" title="QTY RECEIVED">
                                    </app-filter-arrow>
                                </th>
                                <th width="9%">
                                    <app-filter-arrow filter="role" title="QUANTITY">
                                    </app-filter-arrow>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container formArrayName="grvItem">
                                <tr
                                    class="form-table-row"
                                    [formGroupName]="i"
                                    *ngFor="let grv of addGrvList; index as i">
                                    <td width="16%" class="scroll-td">{{ grv.product_title }}</td>
                                    <td width="12%">{{ grv.sku }}</td>
                                    <td width="15%" class="scroll-td">{{ grv.barcode }}</td>
                                    <td width="10%" class="text-right">
                                        {{ grv.cost_price | number: amountFormat | replace: ' ' }}
                                    </td>
                                    <td width="10%">
                                        <input
                                            class="text-right"
                                            type="number"
                                            formControlName="invoice_price" />
                                    </td>
                                    <td width="10%" class="text-right">
                                        {{ grv.total_price | number: amountFormat | replace: ' ' }}
                                    </td>
                                    <td width="9%">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <app-select-box
                                                    placeholderValue=""
                                                    value="grvItem.{{ i }}.tax_rate"
                                                    [form]="addForm"
                                                    [invalidForm]="invalidForm"
                                                    [dropDown]="taxOptions">
                                                </app-select-box>
                                            </div>
                                        </div>
                                    </td>
                                    <td width="10%">
                                        <input
                                            type="text"
                                            class="text-right"
                                            appIntegerOnly
                                            [maxVal]="1000000000"
                                            formControlName="quantity_received" />
                                    </td>
                                    <td width="10%" class="text-right">{{ grv.quantity }}</td>
                                </tr>
                            </ng-container>
                            <tr class="form-table-row" style="margin: 0">
                                <td width="8%" class="background-inherit"></td>
                                <td width="8%" class="background-inherit"></td>
                                <td width="10%" class="background-inherit"></td>
                                <td width="10%" class="background-inherit"></td>
                                <td width="10%" class="background-inherit"></td>
                                <td width="10%" class="background-inherit"></td>
                                <td width="10%" class="background-inherit"></td>
                                <td width="12%">Subtotal</td>
                                <td width="12%" class="text-right">
                                    {{ subTotal | number: amountFormat | replace: ' ' }}
                                </td>
                            </tr>
                            <tr class="form-table-row" style="margin: 0">
                                <td width="8%" class="background-inherit"></td>
                                <td width="8%" class="background-inherit"></td>
                                <td width="10%" class="background-inherit"></td>
                                <td width="10%" class="background-inherit"></td>
                                <td width="10%" class="background-inherit"></td>
                                <td width="10%" class="background-inherit"></td>
                                <td width="10%" class="background-inherit"></td>
                                <td width="12%">Tax</td>
                                <td width="12%" class="text-right">
                                    {{ totalTax | number: amountFormat | replace: ' ' }}
                                </td>
                            </tr>
                            <tr class="form-table-row" style="margin: 0">
                                <td width="8%" class="background-inherit"></td>
                                <td width="8%" class="background-inherit"></td>
                                <td width="10%" class="background-inherit"></td>
                                <td width="10%" class="background-inherit"></td>
                                <td width="10%" class="background-inherit"></td>
                                <td width="10%" class="background-inherit"></td>
                                <td width="10%" class="background-inherit"></td>
                                <td width="12%">Total</td>
                                <td width="12%" class="text-right">
                                    {{ total | number: amountFormat | replace: ' ' }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </form>
        </section>
    </div>
    <div class="control-sidebar-bg"></div>
</div>
<div id="grvModal" class="image-modal" *ngIf="viewGrvDetails">
    <div class="grv-details-window">
        <mat-tab-group mat-align-tabs="start">
            <mat-tab label="Short Delivery Claim" *ngIf="viewShortDelivery">
                <section>
                    <div class="container-fluid tab-pane custom-table grv">
                        <br />
                        <table id="tableData">
                            <thead>
                                <tr>
                                    <th width="25%">PRODUCT</th>
                                    <th>SKU</th>
                                    <th>BARCODE</th>
                                    <th class="text-right">QUANTITY RECEIVED</th>
                                    <th class="text-right">DIFFERENCE</th>
                                    <th class="text-right">INVOICE PRICE</th>
                                    <th class="text-right">TOTAL</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    class="tablebox"
                                    *ngFor="let item of shortDeliveryObject.line_items">
                                    <td width="25%">{{ item.product_title }}</td>
                                    <td>{{ item.sku }}</td>
                                    <td>{{ item.barcode }}</td>
                                    <td class="text-right">{{ item.quantity_received }}</td>
                                    <td class="text-right">{{ item.quantity_difference }}</td>
                                    <td class="text-right">
                                        {{
                                            item.invoice_price | number: amountFormat | replace: ' '
                                        }}
                                    </td>
                                    <td class="text-right">
                                        {{
                                            item.sd_difference_total
                                                | number: amountFormat
                                                | replace: ' '
                                        }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="mr-3 mt-4">
                        <div class="row align-items-start">
                            <div class="col"></div>
                            <div class="col"></div>
                            <div class="col"></div>
                            <div class="aggrigate">
                                <div class="col data">Subtotal</div>
                                <div class="col data text-right">
                                    {{
                                        shortDeliveryObject.subTotal
                                            | number: amountFormat
                                            | replace: ' '
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="row align-items-center">
                            <div class="col"></div>
                            <div class="col"></div>
                            <div class="col"></div>
                            <div class="aggrigate">
                                <div class="col data">Tax</div>
                                <div class="col data text-right">
                                    {{
                                        shortDeliveryObject.vatTotal
                                            | number: amountFormat
                                            | replace: ' '
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="row align-items-end">
                            <div class="col"></div>
                            <div class="col"></div>
                            <div class="col"></div>
                            <div class="aggrigate">
                                <div class="col data">Total</div>
                                <div class="col data text-right">
                                    {{
                                        shortDeliveryObject.grandTotal
                                            | number: amountFormat
                                            | replace: ' '
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="row align-items-center mt-4">
                            <div class="col">
                                <a
                                    class="user-files-deny"
                                    (click)="closeGrvDetails(appConstants.SDC)"
                                    ><u>CANCEL</u></a
                                >
                            </div>
                            <div class="col">
                                <button class="custombtn" (click)="generatePdf(appConstants.SDC)">
                                    PRINT
                                </button>
                            </div>
                            <div class="col-md-2"></div>
                        </div>
                    </div>
                </section>
            </mat-tab>
            <mat-tab label="Price Difference Claim" *ngIf="viewPriceDifference">
                <section>
                    <div class="container-fluid tab-pane custom-table grv">
                        <br />
                        <table id="tableData">
                            <thead>
                                <tr>
                                    <th width="25%">PRODUCT</th>
                                    <th>SKU</th>
                                    <th>BARCODE</th>
                                    <th class="text-right">AGREED PRICE</th>
                                    <th class="text-right">INVOICE PRICE</th>
                                    <th class="text-right">DIFFERENCE</th>
                                    <th class="text-right">QUANTITY</th>
                                    <th class="text-right">TOTAL</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    class="tablebox"
                                    *ngFor="let item of priceDifferenceObject.line_items">
                                    <td width="25%">{{ item.product_title }}</td>
                                    <td>{{ item.sku }}</td>
                                    <td>{{ item.barcode }}</td>
                                    <td class="text-right">
                                        {{ item.cost_price | number: amountFormat | replace: ' ' }}
                                    </td>
                                    <td class="text-right">
                                        {{
                                            item.invoice_price | number: amountFormat | replace: ' '
                                        }}
                                    </td>
                                    <td class="text-right">
                                        {{
                                            item.price_difference
                                                | number: amountFormat
                                                | replace: ' '
                                        }}
                                    </td>
                                    <td class="text-right">{{ item.quantity_received }}</td>
                                    <td class="text-right">
                                        {{
                                            item.pd_difference_total
                                                | number: amountFormat
                                                | replace: ' '
                                        }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="mr-3 mt-4">
                        <div class="row align-items-start">
                            <div class="col"></div>
                            <div class="col"></div>
                            <div class="col"></div>
                            <div class="aggrigate">
                                <div class="col data">Subtotal</div>
                                <div class="col data text-right">
                                    {{
                                        priceDifferenceObject.subTotal
                                            | number: amountFormat
                                            | replace: ' '
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="row align-items-center">
                            <div class="col"></div>
                            <div class="col"></div>
                            <div class="col"></div>
                            <div class="aggrigate">
                                <div class="col data">Tax</div>
                                <div class="col data text-right">
                                    {{
                                        priceDifferenceObject.vatTotal
                                            | number: amountFormat
                                            | replace: ' '
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="row align-items-end">
                            <div class="col"></div>
                            <div class="col"></div>
                            <div class="col"></div>
                            <div class="aggrigate">
                                <div class="col data">Total</div>
                                <div class="col data text-right">
                                    {{
                                        priceDifferenceObject.grandTotal
                                            | number: amountFormat
                                            | replace: ' '
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="row align-items-center mt-4">
                            <div class="col">
                                <a
                                    class="user-files-deny"
                                    (click)="closeGrvDetails(appConstants.PDC)"
                                    ><u>CANCEL</u></a
                                >
                            </div>
                            <div class="col">
                                <button class="custombtn" (click)="generatePdf(appConstants.PDC)">
                                    PRINT
                                </button>
                            </div>
                            <div class="col-md-2"></div>
                        </div>
                    </div>
                </section>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>

<div class="wrapper" *ngIf="displayShortDeliveryModel" id="shortDelivery">
    <div class="grv-print-window" id="shortDelivery" #shortDelivery>
        <br /><br /><br />
        <div class="row">
            <div class="col-md-6">
                <img
                    src="assets/images/{{
                        companyService.getSelectedCompanyCode()
                    }}-logo-dark.png" />
            </div>
            <div class="col-md-6 grv-print-heading mt-3 text-right">
                <span class="text-heading pr-2"> SHORT DELIVERY CLAIM :</span>
                <span class="text-orange">{{ grvData.short_delivery_claim.grvClaimNo }}</span>
            </div>
        </div>
        <hr />
        <div class="row">
            <div class="col-md-8"></div>
            <div class="col-md-4 text-right">
                <strong
                    >DATE :
                    <span class="text-orange">{{ grvData.createdAt | date }}</span>
                </strong>
            </div>
        </div>
        <hr />
        <div class="row">
            <div class="col-md-6">
                <strong>Bill to:</strong><br />
                {{ grvData.purchase_order.supplier_name }}<br /><br /><br /><br />
            </div>
        </div>
        <hr />
        <section>
            <div class="container-fluid tab-pane custom-table grv-print">
                <br />
                <table id="tableData">
                    <thead>
                        <tr>
                            <th width="25%">PRODUCT</th>
                            <th>SKU</th>
                            <th>BARCODE</th>
                            <th class="text-right">QUANTITY RECEIVED</th>
                            <th class="text-right">DIFFERENCE</th>
                            <th class="text-right">INVOICE PRICE</th>
                            <th class="text-right">TOTAL</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="tablebox" *ngFor="let item of shortDeliveryObject.line_items">
                            <td width="25%">{{ item.product_title }}</td>
                            <td>{{ item.sku }}</td>
                            <td>{{ item.barcode }}</td>
                            <td class="text-right">{{ item.quantity_received }}</td>
                            <td class="text-right">{{ item.quantity_difference }}</td>
                            <td class="text-right">
                                {{ item.invoice_price | number: amountFormat | replace: ' ' }}
                            </td>
                            <td class="text-right">
                                {{ item.sd_difference_total | number: amountFormat | replace: ' ' }}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="mr-3 mt-4">
                    <div class="row align-items-start">
                        <div class="col"></div>
                        <div class="col"></div>
                        <div class="col"></div>
                        <div class="aggrigate">
                            <div class="col data">Subtotal</div>
                            <div class="col data text-right">
                                {{
                                    grvData.short_delivery_claim.subTotal
                                        | number: amountFormat
                                        | replace: ' '
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col"></div>
                        <div class="col"></div>
                        <div class="col"></div>
                        <div class="aggrigate">
                            <div class="col data">Tax</div>
                            <div class="col data text-right">
                                {{
                                    grvData.short_delivery_claim.vatTotal
                                        | number: amountFormat
                                        | replace: ' '
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-end">
                        <div class="col"></div>
                        <div class="col"></div>
                        <div class="col"></div>
                        <div class="aggrigate">
                            <div class="col data">Total</div>
                            <div class="col data text-right">
                                {{
                                    grvData.short_delivery_claim.grandTotal
                                        | number: amountFormat
                                        | replace: ' '
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>

<div class="wrapper" *ngIf="displayPriceDifferenceModel" id="priceDifference">
    <div class="grv-print-window" #priceDifference>
        <br /><br /><br />
        <div class="row">
            <div class="col-md-6">
                <img
                    src="assets/images/{{
                        companyService.getSelectedCompanyCode()
                    }}-logo-dark.png" />
            </div>
            <div class="col-md-6 grv-print-heading mt-3 text-right">
                <span class="text-heading pr-2"> PRICE DIFFERENCE CLAIM :</span>
                <span class="text-orange">{{ grvData.price_difference_claim.grvClaimNo }}</span>
            </div>
        </div>
        <hr />
        <div class="row">
            <div class="col-md-8"></div>
            <div class="col-md-4 text-right pl-3">
                <strong
                    >DATE :
                    <span class="text-orange">{{ grvData.createdAt | date }}</span>
                </strong>
            </div>
        </div>
        <hr />
        <div class="row">
            <div class="col-md-6">
                <strong>Bill to:</strong><br />
                {{ grvData.purchase_order.supplier_name }}<br /><br /><br /><br />
            </div>
        </div>
        <hr />
        <section>
            <div class="container-fluid tab-pane custom-table grv-print">
                <br />
                <table id="tableData">
                    <thead>
                        <tr>
                            <th width="25%">PRODUCT</th>
                            <th>SKU</th>
                            <th>BARCODE</th>
                            <th class="text-right">AGREED PRICE</th>
                            <th class="text-right">INVOICE PRICE</th>
                            <th class="text-right">DIFFERENCE</th>
                            <th class="text-right">QUANTITY</th>
                            <th class="text-right">TOTAL</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="tablebox" *ngFor="let item of priceDifferenceObject.line_items">
                            <td width="25%">{{ item.product_title }}</td>
                            <td>{{ item.sku }}</td>
                            <td>{{ item.barcode }}</td>
                            <td class="text-right">
                                {{ item.cost_price | number: amountFormat | replace: ' ' }}
                            </td>
                            <td class="text-right">
                                {{ item.invoice_price | number: amountFormat | replace: ' ' }}
                            </td>
                            <td class="text-right">
                                {{ item.price_difference | number: amountFormat | replace: ' ' }}
                            </td>
                            <td class="text-right">{{ item.quantity_received }}</td>
                            <td class="text-right">
                                {{ item.pd_difference_total | number: amountFormat | replace: ' ' }}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="mr-3 mt-4">
                    <div class="row align-items-start">
                        <div class="col"></div>
                        <div class="col"></div>
                        <div class="col"></div>
                        <div class="aggrigate">
                            <div class="col data">Subtotal</div>
                            <div class="col data text-right">
                                {{
                                    grvData.price_difference_claim.subTotal
                                        | number: amountFormat
                                        | replace: ' '
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col"></div>
                        <div class="col"></div>
                        <div class="col"></div>
                        <div class="aggrigate">
                            <div class="col data">Tax</div>
                            <div class="col data text-right">
                                {{
                                    grvData.price_difference_claim.vatTotal
                                        | number: amountFormat
                                        | replace: ' '
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-end">
                        <div class="col"></div>
                        <div class="col"></div>
                        <div class="col"></div>
                        <div class="aggrigate">
                            <div class="col data">Total</div>
                            <div class="col data text-right">
                                {{
                                    grvData.price_difference_claim.grandTotal
                                        | number: amountFormat
                                        | replace: ' '
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
