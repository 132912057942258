import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'currencyFormat' })
export class CurrencyFormatPipe implements PipeTransform {
    transform(value: string): any {
        if (value === 'ZAR') {
            return 'R';
        } else if (value === 'USD') {
            return '$';
        } else {
            return '';
        }
    }
}
