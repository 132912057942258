<div class="page-length-dropdown">
    <a data-toggle="dropdown">
        <app-dropdown-icon></app-dropdown-icon>
    </a>
    <ul class="dropdown-menu" aria-labelledby="menu1">
        <li class="dropdown-menu-item" *ngFor="let len of pageLength">
            <a (click)="changeLength(len)"> {{ len }} </a>
        </li>
    </ul>
</div>
