<div class="row">
    <div class="col-md-8 section-heading">Enterprise User</div>
    <div class="col-md-4 section-heading" *ngIf="showEditPencil">
        <app-edit-pencil (edit)="changeView()"></app-edit-pencil>
    </div>
</div>
<div *ngIf="!editEnterprise">
    <div class="row">
        <div class="col-md-12">
            <table class="fees-table">
                <thead>
                    <tr>
                        <th></th>
                        <th>% FEE</th>
                        <th>USE %</th>
                        <th>AMOUNT</th>
                        <th>USE AMOUNT</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="commonUtil.showFeeRow(enterpriseFeeList, feeCode.receiver_pay)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(enterpriseFeeList, feeCode.receiver_pay)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.receiver_pay] }}
                            </span>
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(enterpriseFeeList, feeCode.receiver_pay)
                                    .percentage | number: amountFormat
                            }}{{ ' %' }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_enterprise_{{ feeCode.receiver_pay }}"
                                [checked]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.receiver_pay)
                                        .useAmount == false
                                "
                                disabled />
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(enterpriseFeeList, feeCode.receiver_pay).amount
                                    | number: amountFormat
                            }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_enterprise_{{ feeCode.receiver_pay }}"
                                [checked]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.receiver_pay)
                                        .useAmount == true
                                "
                                disabled />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(enterpriseFeeList, feeCode.sender_cot)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(enterpriseFeeList, feeCode.sender_cot)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.sender_cot] }}
                            </span>
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(enterpriseFeeList, feeCode.sender_cot).percentage
                                    | number: amountFormat
                            }}{{ ' %' }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_enterprise_{{ feeCode.sender_cot }}"
                                [checked]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.sender_cot)
                                        .useAmount == false
                                "
                                disabled />
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(enterpriseFeeList, feeCode.sender_cot).amount
                                    | number: amountFormat
                            }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_enterprise_{{ feeCode.sender_cot }}"
                                [checked]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.sender_cot)
                                        .useAmount == true
                                "
                                disabled />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(enterpriseFeeList, feeCode.receiver_cin)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(enterpriseFeeList, feeCode.receiver_cin)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.receiver_cin] }}
                            </span>
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(enterpriseFeeList, feeCode.receiver_cin)
                                    .percentage | number: amountFormat
                            }}{{ ' %' }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_enterprise_{{ feeCode.receiver_cin }}"
                                [checked]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.receiver_cin)
                                        .useAmount == false
                                "
                                disabled />
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(enterpriseFeeList, feeCode.receiver_cin).amount
                                    | number: amountFormat
                            }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_enterprise_{{ feeCode.receiver_cin }}"
                                [checked]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.receiver_cin)
                                        .useAmount == true
                                "
                                disabled />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(enterpriseFeeList, feeCode.sender_ttb)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(enterpriseFeeList, feeCode.sender_ttb)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.sender_ttb] }}
                            </span>
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(enterpriseFeeList, feeCode.sender_ttb).percentage
                                    | number: amountFormat
                            }}{{ ' %' }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_enterprise_{{ feeCode.sender_ttb }}"
                                [checked]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.sender_ttb)
                                        .useAmount == false
                                "
                                disabled />
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(enterpriseFeeList, feeCode.sender_ttb).amount
                                    | number: amountFormat
                            }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_enterprise_{{ feeCode.sender_ttb }}"
                                [checked]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.sender_ttb)
                                        .useAmount == true
                                "
                                disabled />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(enterpriseFeeList, feeCode.receiver_cbk)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(enterpriseFeeList, feeCode.receiver_cbk)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.receiver_cbk] }}
                            </span>
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(enterpriseFeeList, feeCode.receiver_cbk)
                                    .percentage | number: amountFormat
                            }}{{ ' %' }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_enterprise_{{ feeCode.receiver_cbk }}"
                                [checked]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.receiver_cbk)
                                        .useAmount == false
                                "
                                disabled />
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(enterpriseFeeList, feeCode.receiver_cbk).amount
                                    | number: amountFormat
                            }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_enterprise_{{ feeCode.receiver_cbk }}"
                                [checked]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.receiver_cbk)
                                        .useAmount == true
                                "
                                disabled />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div *ngIf="editEnterprise">
    <div class="row">
        <div class="col-md-12">
            <table class="fees-table">
                <thead>
                    <tr>
                        <th></th>
                        <th>% FEE</th>
                        <th>USE %</th>
                        <th>AMOUNT</th>
                        <th>USE AMOUNT</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="commonUtil.showFeeRow(enterpriseFeeList, feeCode.receiver_pay)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(enterpriseFeeList, feeCode.receiver_pay)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.receiver_pay] }}
                            </span>
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [maxVal]="100"
                                [(ngModel)]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.receiver_pay)
                                        .percentageValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_enterprise_{{ feeCode.receiver_pay }}"
                                [checked]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.receiver_pay)
                                        .useAmount == false
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.receiver_pay)
                                        .useAmountValue
                                "
                                [value]="false" />
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [(ngModel)]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.receiver_pay)
                                        .amountValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_enterprise_{{ feeCode.receiver_pay }}"
                                [checked]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.receiver_pay)
                                        .useAmount == true
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.receiver_pay)
                                        .useAmountValue
                                "
                                [value]="true" />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(enterpriseFeeList, feeCode.sender_cot)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(enterpriseFeeList, feeCode.sender_cot)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.sender_cot] }}
                            </span>
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [maxVal]="100"
                                [(ngModel)]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.sender_cot)
                                        .percentageValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_enterprise_{{ feeCode.sender_cot }}"
                                [checked]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.sender_cot)
                                        .useAmount == false
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.sender_cot)
                                        .useAmountValue
                                "
                                [value]="false" />
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [(ngModel)]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.sender_cot)
                                        .amountValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_enterprise_{{ feeCode.sender_cot }}"
                                [checked]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.sender_cot)
                                        .useAmount == true
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.sender_cot)
                                        .useAmountValue
                                "
                                [value]="true" />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(enterpriseFeeList, feeCode.receiver_cin)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(enterpriseFeeList, feeCode.receiver_cin)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.receiver_cin] }}
                            </span>
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [maxVal]="100"
                                [(ngModel)]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.receiver_cin)
                                        .percentageValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_enterprise_{{ feeCode.receiver_cin }}"
                                [checked]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.receiver_cin)
                                        .useAmount == false
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.receiver_cin)
                                        .useAmountValue
                                "
                                [value]="false" />
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [(ngModel)]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.receiver_cin)
                                        .amountValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_enterprise_{{ feeCode.receiver_cin }}"
                                [checked]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.receiver_cin)
                                        .useAmount == true
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.receiver_cin)
                                        .useAmountValue
                                "
                                [value]="true" />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(enterpriseFeeList, feeCode.sender_ttb)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(enterpriseFeeList, feeCode.sender_ttb)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.sender_ttb] }}
                            </span>
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [maxVal]="100"
                                [(ngModel)]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.sender_ttb)
                                        .percentageValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_enterprise_{{ feeCode.sender_ttb }}"
                                [checked]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.sender_ttb)
                                        .useAmount == false
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.sender_ttb)
                                        .useAmountValue
                                "
                                [value]="false" />
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [(ngModel)]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.sender_ttb)
                                        .amountValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_enterprise_{{ feeCode.sender_ttb }}"
                                [checked]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.sender_ttb)
                                        .useAmount == true
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.sender_ttb)
                                        .useAmountValue
                                "
                                [value]="true" />
                        </td>
                    </tr>

                    <tr *ngIf="commonUtil.showFeeRow(enterpriseFeeList, feeCode.receiver_cbk)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(enterpriseFeeList, feeCode.receiver_cbk)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.receiver_cbk] }}
                            </span>
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [maxVal]="100"
                                [(ngModel)]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.receiver_cbk)
                                        .percentageValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_enterprise_{{ feeCode.receiver_cbk }}"
                                [checked]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.receiver_cbk)
                                        .useAmount == false
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.receiver_cbk)
                                        .useAmountValue
                                "
                                [value]="false" />
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [(ngModel)]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.receiver_cbk)
                                        .amountValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_enterprise_{{ feeCode.receiver_cbk }}"
                                [checked]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.receiver_cbk)
                                        .useAmount == true
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(enterpriseFeeList, feeCode.receiver_cbk)
                                        .useAmountValue
                                "
                                [value]="true" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row" *ngIf="!parentToUpdate">
        <div class="col-md-6"></div>
        <div class="col-md-6">
            <button class="custombtn custombtn-align-right" (click)="submit()">SAVE</button>
            <a class="user-files-deny" (click)="changeView()"><u>CANCEL</u></a>
        </div>
    </div>
</div>
