<div class="edit-link">
    <a data-toggle="dropdown">
        <div class="dot-icon"></div>
    </a>
    <ul class="dropdown-menu" aria-labelledby="menu1">
        <li>
            <a class="dropdown-menu-item" (click)="editCashbackSetting()"> Edit Spend Level </a>
        </li>
        <li>
            <a class="dropdown-menu-item" (click)="deleteCashbackSetting()"> Delete Spend Level </a>
        </li>
    </ul>
</div>
