import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { UntypedFormBuilder, Validators, FormGroup } from '@angular/forms';
import { URI } from 'src/app/settings/uri-constants';
import { Router } from '@angular/router';
import { StorageConstant } from 'src/app/settings/storage-constants';
import { Role } from 'src/app/enums/role';
import { MessageConstant } from 'src/app/settings/message.constant';

@Component({
    selector: 'app-staff-user',
    templateUrl: './staff-user.component.html',
    styleUrls: ['./staff-user.component.css'],
})
export class StaffUserComponent implements OnInit {
    display = false;
    invalidForm = false;
    isLoading = false;
    eMessage = '';
    emailExist: boolean;
    emailErrorMsg: string;
    mobileErrorMsg: string;
    mobileExist: boolean;
    accountTypeDropdown: string[] = [Role.staff];
    statusDropdown: string[] = [];
    form: FormGroup;

    constructor(
        private commonService: CommonService,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
    ) {
        this.emailExist = false;
        this.mobileExist = false;
        this.emailErrorMsg = MessageConstant.INVALID_EMAIL;
        this.mobileErrorMsg = MessageConstant.INVALID_MOBILE;

        this.form = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: ['', Validators.required],
            mobileNumber: [
                '',
                [
                    Validators.required,
                    Validators.maxLength(11),
                    Validators.minLength(9),
                    Validators.pattern('^[0-9]*$'),
                ],
            ],
            role: [Role.staff, Validators.required],
            status: ['', Validators.required],
        });

        this.commonService.getUserStatus().subscribe((res) => {
            this.statusDropdown = res.data;
        });
    }

    ngOnInit() {
        this.form.get('email').valueChanges.subscribe((newVal) => {
            this.emailExist = false;
            this.emailErrorMsg = MessageConstant.INVALID_EMAIL;
        });

        this.form.get('mobileNumber').valueChanges.subscribe((newVal) => {
            this.mobileExist = false;
            this.mobileErrorMsg = MessageConstant.INVALID_MOBILE;
        });
    }

    closeModel(val: boolean) {
        this.display = false;
    }

    submit() {
        this.invalidForm = this.form.invalid;
        if (this.form.invalid) {
            this.display = true;
            this.eMessage = MessageConstant.INCOMPLETE_FORM;
            return false;
        }

        this.isLoading = true;

        this.commonService.addUser(this.form).subscribe(
            (res) => {
                this.isLoading = false;
                if (res.success) {
                    this.router.navigate([URI.SLASHED_SEARCH]);
                    localStorage.setItem(StorageConstant.STAFF_CREATED_MESSAGE, 'success');
                } else {
                    if (res.status.message.toLocaleLowerCase().includes('email')) {
                        this.emailExist = true;
                        this.emailErrorMsg = res.status.message;
                    } else if (res.status.message.toLocaleLowerCase().includes('mobile')) {
                        this.mobileExist = true;
                        this.mobileErrorMsg = res.status.message;
                    }
                }
            },
            (error) => {
                this.isLoading = false;
                this.eMessage = error;
                this.display = true;
            },
        );
    }

    get controls(): any {
        return this.form.controls;
    }
}
