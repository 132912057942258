import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import merge from 'lodash-es/merge';

import { SmartSafeTransactionTypeList } from 'src/app/enums/smartsafe.transaction.type';
import { Pagination } from 'src/app/model/Pagination';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { CompanyService } from 'src/app/services/company.service';
import { SmartSafeService } from 'src/app/services/smart-safe.service';
import { AppConstants } from 'src/app/settings/app-constants';
import { URI } from 'src/app/settings/uri-constants';
import { transformDateToApiFormat } from 'src/app/utils/date-util';

@Component({
    selector: 'app-safe-transaction-details',
    templateUrl: './safe-transaction-details.component.html',
    styleUrls: ['./safe-transaction-details.component.css'],
})
export class SafeTransactionDetailsComponent implements OnInit {
    isLoading: boolean = false;
    loaderText = 'Searching...';
    progressWidth = 25;
    uri = URI;
    safeId: string;
    safeTransactions: any[];
    form: FormGroup;
    pagination = new Pagination();
    amountFormat = AppConstants.AMOUNT_FORMAT;
    dateTimeFormat = AppConstants.DATE_TIME_FORMAT;
    showOptions: boolean = false;
    displayView: boolean = false;
    selectedSafeTransaction: any;
    searchItems: string[] = [];
    itemList = ['type'];
    transactionTypeList = SmartSafeTransactionTypeList;
    childActive = false;
    search: boolean = false;
    showParent: boolean = true;

    constructor(
        private smartSafeService: SmartSafeService,
        public companyService: CompanyService,
        private route: ActivatedRoute,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private breadcrumbService: BreadcrumbService,
        private readonly activatedRoute: ActivatedRoute,
    ) {}

    ngOnInit() {
        this.activatedRoute.data.subscribe((data) => {
            this.safeId = data?.safeId;
            this.childActive = data?.childActive;
        });

        this.form = this.formBuilder.group({
            text: new FormControl(''),
            start: new FormControl(''),
            length: new FormControl(''),
            safeId: new FormControl(this.safeId),
            type: new FormControl(null),
            fromDate: new FormControl(''),
            toDate: new FormControl(''),
            fromDateFilter: new FormControl(''),
            toDateFilter: new FormControl(''),
        });

        this.getData();
    }

    clearText() {
        this.form.get('text').setValue('');
    }

    toggleOption() {
        this.showOptions = !this.showOptions;
    }

    isEmpty(item): boolean {
        return this.form.get(item).value.length <= 0;
    }

    submit() {
        this.getData();
    }

    getData() {
        this.isLoading = true;
        this.search = false;
        this.processFormValues();

        // Copy form values into a new object
        const params = merge({}, this.form.value);

        // Set status value to empty string if it's null to cater for backend.
        if (params?.type === null) {
            params.type = '';
        }

        this.smartSafeService.getSmartSafesTransaction(params).subscribe({
            next: (res) => {
                this.search = true;
                this.safeTransactions = res.responseBody.data;
                this.pagination.total = res.responseBody.total;
                this.isLoading = false;

                this.searchItems = [];
                for (const item of this.itemList) {
                    if (this.form.get(item).value !== null) {
                        this.searchItems.push(this.form.get(item).value);
                    }
                }
            },
            error: (error) => {
                this.isLoading = false;
            },
        });
    }

    processFormValues() {
        this.form.get('start').setValue(this.pagination.start);
        this.form.get('length').setValue(this.pagination.length);
        const fromDate = this.form.get('fromDateFilter').value;
        const toDate = this.form.get('toDateFilter').value;

        if (fromDate !== '') this.form.get('fromDate').setValue(transformDateToApiFormat(fromDate));
        if (toDate !== '') this.form.get('toDate').setValue(transformDateToApiFormat(toDate));
    }

    receiveFilter(data: { name: string; value: number }) {}

    removeSearchItem(value: string) {
        for (const item of this.itemList) {
            if (this.form.get(item).value === value) {
                this.form.get(item).setValue(null);
                this.getData();
                break;
            }
        }
    }

    clearFilters() {
        this.form.get('fromDate').setValue('');
        this.form.get('toDate').setValue('');
        this.form.get('fromDateFilter').setValue('');
        this.form.get('toDateFilter').setValue('');
        this.form.get('text').setValue('');
        this.form.get('type').setValue(null);
        this.searchItems = [];
        this.getData();
    }

    changeLength(len: number) {
        this.pagination.length = len;
        this.pagination.start = 0;
        this.getData();
    }

    changePage(page: number) {
        this.pagination.changePage(page);
        this.getData();
    }

    getTransactionsByUserRoute(safeId, userRoleId) {
        let path =
            this.uri.getRouteNavPath('adminSmartSafeSummary') +
            this.uri.getRouteNavPath('adminSmartSafeTransactionBySafe') +
            this.uri.getRouteNavPath('adminSmartSafeTransactionBySafeByUser');

        path = path.replace(':safeId', safeId).replace(':userRoleId', userRoleId);
        return path;
    }

    onActivate() {
        this.showParent = false;
        this.childActive = true;
    }

    onDeactivate() {
        this.showParent = true;
        this.childActive = false;
    }
}
