import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';

import { Fees } from 'src/app/model/fees';
import { AppConstants } from 'src/app/settings/app-constants';
import { CommonUtil } from 'src/app/services/common.util';
import { FeeCode } from 'src/app/enums/fee.code';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
    selector: 'app-agent-fees',
    templateUrl: './agent-fees.component.html',
    styleUrls: ['./agent-fees.component.css'],
})
export class AgentFeesComponent {
    @Input() agentFeeList: Array<Fees>;
    @Input() editAgent = false;
    @Input() showEditPencil = true;
    @Input() parentToUpdate = false;
    @Input() title = 'Agent User';
    @Output() updateFee = new EventEmitter();
    amountFormat = AppConstants.AMOUNT_FORMAT;
    feeCode = FeeCode;
    feeLabel = this.commonUtil.getFeeLabels();

    constructor(public commonUtil: CommonUtil) {}

    changeView() {
        this.editAgent = !this.editAgent;
    }

    submit() {
        this.updateFee.emit('agent');
        this.changeView();
    }

    changeStatus(val) {
        console.log(val);
    }
}
