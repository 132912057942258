import { Component } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AlertService } from 'src/app/services/alert.service';
import { BannerService } from 'src/app/services/banner.service';
import { MessageConstant } from 'src/app/settings/message.constant';
import { URI } from 'src/app/settings/uri-constants';
import { FormUtils } from 'src/app/utils/form.utils';

@Component({
    selector: 'app-new-banner',
    templateUrl: './new-banner.component.html',
    styleUrls: ['./new-banner.component.css'],
})
export class NewBannerComponent {
    display = false;
    invalidForm = false;
    isLoading = false;
    eMessage = '';
    msg = '';
    imageErrorMsg = 'Image is required';
    emailExist: boolean;
    emailErrorMsg: string;
    mobileErrorMsg: string;
    startDateErrorMsg: string;
    endDateErrorMsg: string;
    mobileExist: boolean;
    statusDropdown: string[] = [];
    form: FormGroup;
    imageSrc: string;
    now: Date;
    alertOptions = {
        autoClose: true,
        keepAfterRouteChange: true,
    };

    constructor(
        private router: Router,
        private formBuilder: UntypedFormBuilder,
        private bannerService: BannerService,
        private alertService: AlertService,
    ) {
        this.now = new Date();
        this.form = this.formBuilder.group({
            name: ['', Validators.required],
            image: ['', Validators.required],
            startDate: [this.now, Validators.required],
            endDate: [this.now, Validators.required],
        });
    }

    get controls(): any {
        return this.form.controls;
    }

    validateDate() {
        if (this.form) {
            const ldStartDate = this.form.value['startDate'];
            const ldEndDate = this.form.value['endDate'];
            const startnew = new Date(ldStartDate);
            const endnew = new Date(ldEndDate);

            if (startnew > endnew) {
                if (!FormUtils.isFieldInValid(this.form, 'startDate')) {
                    this.startDateErrorMsg = 'Start date cannot be greater than end date';
                    this.form.controls['startDate'].setErrors({ invalid: true });
                }
                return;
            }
            this.form.controls['startDate'].setErrors(null);
        }
    }

    isInvalidStartDate() {
        return this.form.controls['startDate'].getError('invalid');
    }

    // TODO: Move this to a File Util class.
    onImageSelect(event) {
        const mimeType = event.target.files[0].type;

        if (mimeType.match(/image\/*/) == null) {
            this.imageErrorMsg = 'Only images are supported';
            FormUtils.validateFormField(this.form, 'image');
            return;
        }

        const reader = new FileReader();

        const [file] = event.target.files;
        reader.readAsDataURL(file);

        reader.onload = () => {
            this.imageSrc = reader.result as string;

            this.form.patchValue({
                image: reader.result,
            });
        };
    }

    // TODO: Move this to a File Util class.
    getExtension() {
        if (this.form && this.form.value && this.form.value.image) {
            return '.' + this.form.value.image.split(';')[0].split('/')[1];
        } else {
            return '';
        }
    }

    // TODO: Move this to a File Util class.
    getRawBase64Data() {
        if (this.form && this.form.value && this.form.value.image) {
            return this.form.value.image.split(',')[1];
        }
    }

    // TODO: Move this to a File Util class
    // convert base64/URLEncoded data component to raw binary data
    dataURItoBlob(dataURI) {
        if (dataURI) {
            return unescape(dataURI.split(',')[1]);
        }
    }

    isFieldInValid(field: string) {
        return FormUtils.isFieldInValid(this.form, field);
    }

    displayFieldCss(field: string) {
        return FormUtils.displayFieldCss(this.form, field);
    }

    submit() {
        if (this.form.valid && !this.isInvalidStartDate()) {
            this.form.patchValue({
                name: this.form.value.name + this.getExtension(),
                image: this.dataURItoBlob(this.form.value.image),
            });

            this.bannerService.createBanner(this.form.value).subscribe(
                (res) => {
                    this.isLoading = false;
                    if (res.success) {
                        this.alertService.success(
                            MessageConstant.BANNER_CREATED,
                            this.alertOptions,
                        );
                        setTimeout(() => {
                            this.router.navigate([URI.getRouteNavPath('adminBanners')]);
                        }, 1500);
                    } else {
                        this.display = true;
                        if (res.status) {
                            this.eMessage = res.status.message;
                        }
                    }
                },
                (error) => {
                    this.isLoading = false;
                    this.display = true; /*this.getBanners()*/
                },
            );
        } else {
            FormUtils.validateAllFormFields(this.form);
        }
    }
}
