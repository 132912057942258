import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Role } from '../../enums/role';
import { LoginForm } from '../../model/login.form';
import { CommonService } from '../../services/common.service';
import { LoginService } from '../../services/login.service';
import { StorageConstant } from '../../settings/storage-constants';
import { URI } from '../../settings/uri-constants';

@Component({
    selector: 'app-two-factor-auth',
    templateUrl: './two-factor-auth.component.html',
    styleUrls: ['./two-factor-auth.component.css'],
})
export class TwoFactorAuthComponent {
    show: boolean;
    display: boolean;
    displaySuccess: boolean;
    isLoading: boolean;
    eMessage: string;
    pinFocused: boolean;
    successMessage: string;
    loginForm: LoginForm;
    otp: string;
    uuid: string;

    constructor(
        private loginService: LoginService,
        private commonService: CommonService,
        private router: Router,
    ) {
        this.uuid = localStorage.getItem(StorageConstant.TFA_UUID);
        this.loginForm = JSON.parse(localStorage.getItem(StorageConstant.LOGIN_CREDENTIALS));
        this.show = true;
        this.isLoading = false;
        this.pinFocused = false;
        this.display = false;
        this.eMessage = '';
        this.otp = '';
    }

    resendOtp() {
        const body = { code: this.otp, uuid: this.uuid };

        this.isLoading = true;
        this.commonService.resendOtp(body).subscribe(
            (res) => {
                this.isLoading = false;
                if (res.success) {
                    this.successMessage = res.status.message;
                } else {
                    this.eMessage = res.status.message;
                    this.display = true;
                }
            },
            (err) => {
                if (err.error.error) {
                    this.eMessage = err.error.error;
                } else {
                    this.eMessage = err.error.errors[0];
                }
                this.isLoading = false;
                this.display = true;
            },
        );
    }

    verify() {
        this.isLoading = true;
        const body = { code: this.otp, uuid: this.uuid };

        this.commonService.verifyOtp(body).subscribe(
            (res) => {
                this.isLoading = false;
                if (res.success) {
                    localStorage.clear();
                    this.onSubmit();
                } else {
                    this.eMessage = res.status.message;
                    this.display = true;
                }
            },
            (err) => {
                if (err.error.error) {
                    this.eMessage = err.error.error;
                } else {
                    this.eMessage = err.error.errors[0];
                }
                this.isLoading = false;
                this.display = true;
            },
        );
    }

    openModel() {
        this.display = true;
    }

    openSuccessModel() {
        this.displaySuccess = true;
    }

    closeModel() {
        this.display = false;
        this.displaySuccess = false;
    }

    pinFocusFunction() {
        this.pinFocused = !this.pinFocused;
    }

    onSubmit() {
        this.isLoading = true;

        this.loginService.signIn(this.loginForm).subscribe(
            (response) => {
                this.isLoading = false;
                this.otp = '';
                if (response.success) {
                    this.loginService.routeByRoleOnLogin(response.role);
                } else {
                    this.eMessage = response.status.message;
                    this.openModel();
                }
            },
            (error) => {
                this.isLoading = false;
                this.otp = '';
                if (error.error) {
                    this.eMessage = 'Invalid email or password';
                } else {
                    this.eMessage = 'Server Unavailable. Try again later.';
                }
                this.openModel();
            },
        );
    }
}
