<div class="edit-link">
    <a data-toggle="dropdown">
        <div class="dot-icon"></div>
    </a>
    <ul class="dropdown-menu" aria-labelledby="menu1">
        <li>
            <a class="dropdown-menu-item" (click)="action('view')" alt="View Details">
                <img src="assets/images/control-icon-view-details.png" />
                View Details
            </a>
        </li>
        @if (!this.txnService.txnComplete(this.transaction.status)) {
            <li>
                <a class="dropdown-menu-item" (click)="action('cancel')">
                    <img alt="Cancel Request" src="assets/images/control-icon-cancel-request.png" />
                    Cancel Request
                </a>
            </li>
        }
    </ul>
</div>
