<div class="wrapper">
    <app-navigation *ngIf="loginService.isAdmin()"></app-navigation>
    <app-enterprise-navigation
        *ngIf="loginService.isEnterpriseContact()"></app-enterprise-navigation>
    <div class="content-wrapper">
        <app-content-transition></app-content-transition>
        <app-loader *ngIf="isLoading" className="custom-loader" text="Processing ..."></app-loader>
        <app-header location="User Account Profile"></app-header>
        <app-enterprise-links [id]="id" [isAdmin]="loginService.isAdmin()"></app-enterprise-links>
        <section class="content mt-4">
            <div class="row">
                <div class="col-md-12 section-heading">
                    User Wallet & Transaction Totals over time
                    <hr class="hr-margin-none" />
                </div>
            </div>
            <div class="alert alert-danger alert-dismissible fade show mt-4" *ngIf="display">
                Balances are not reconciling.
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="d-flex align-items-baseline">
                <div class="waves-system-balance-box width-33">
                    <div class="section-heading">Money In</div>
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-label">RECEIVED TOTAL</label>
                            <div class="dashboard-amount green">
                                {{
                                    summary?.totalReceived || '0.00'
                                        | number: amountFormat
                                        | replace: ' '
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-label">CASH IN TOTAL</label>
                            <div class="dashboard-amount green">
                                {{
                                    summary?.totalCashin || '0.00'
                                        | number: amountFormat
                                        | replace: ' '
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-label">EARNINGS TOTAL</label>
                            <div class="dashboard-amount green">
                                {{
                                    summary?.totalEarnings || '0.00'
                                        | number: amountFormat
                                        | replace: ' '
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-label">TOTAL CASHBACK EARNED</label>
                            <div class="dashboard-amount green">
                                {{
                                    summary?.totalCashbackEarned || '0.00'
                                        | number: amountFormat
                                        | replace: ' '
                                }}
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-label">SUBTOTAL</label>
                            <div class="dashboard-amount green">
                                {{
                                    summary?.totalMoneyIn || '0.00'
                                        | number: amountFormat
                                        | replace: ' '
                                }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="waves-system-balance-box width-33">
                    <div class="section-heading">Money Out</div>
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-label">SENT TOTAL</label>
                            <div class="dashboard-amount light-red">
                                {{
                                    summary?.totalSent || '0.00'
                                        | number: amountFormat
                                        | replace: ' '
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-label">FEES PAID TOTAL</label>
                            <div class="dashboard-amount light-red">
                                {{
                                    summary?.totalFees || '0.00'
                                        | number: amountFormat
                                        | replace: ' '
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-label">TOTAL TRANSFER TO BANK</label>
                            <div class="dashboard-amount light-red">
                                {{
                                    summary?.totalSettlement || '0.00'
                                        | number: amountFormat
                                        | replace: ' '
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-label">TOTAL CASHBACK GIVEN</label>
                            <div class="dashboard-amount light-red">
                                {{
                                    summary?.totalCashbackGiven || '0.00'
                                        | number: amountFormat
                                        | replace: ' '
                                }}
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-label">SUBTOTAL</label>
                            <div class="dashboard-amount light-red">
                                {{
                                    summary?.totalMoneyOut || '0.00'
                                        | number: amountFormat
                                        | replace: ' '
                                }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="waves-system-balance-box width-33">
                    <div class="section-heading">Balance</div>
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-label">ALL WALLET BALANCE TOTALS</label>
                            <div class="dashboard-amount purple">
                                {{
                                    summary?.totalWalletBalance || '0.00'
                                        | number: amountFormat
                                        | replace: ' '
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
