export class StorageConstant {
    public static LOGIN_CREDENTIALS = 'login_credentials';
    public static AUTH_USER = 'auth-user';
    public static USER_PROFILE = 'user_';
    public static CHANGE_MOBILE = 'change_mobile';
    public static CHANGE_MOBILE_UUID = 'change_mobile_uuid';
    public static TFA_UUID = 'tfa_uuid';
    public static CHANGE_MOBILE_SUCCESS = 'change_mobile_success';
    public static CHANGE_PASSWORD_SUCCESS = 'change_password_success';
    public static CREATE_PASSWORD_SUCCESS = 'create_password_success';
    public static AGENT_FILES = 'agent_files';
    public static AGENT_APPROVE = 'agent_approve';
    public static EMPLOYEE_NUMBER = 'employee_number';
    public static STAFF_CREATED_MESSAGE = 'STAFF_CREATED_MESSAGE';
    public static AGENT_CREATED_MESSAGE = 'AGENT_CREATED_MESSAGE';
    public static ENTERPRISE_CREATED_MESSAGE = 'ENTERPRISE_CREATED_MESSAGE';
    public static COMPANIES = 'COMPANIES';
    public static SELECTED_COMPANY = 'SELECTED_COMPANY';
}
