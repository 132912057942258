<div class="row">
    <div class="col-md-8 section-heading">
        {{ title }}
    </div>
    <div class="col-md-4 section-heading" *ngIf="showEditPencil">
        <app-edit-pencil (edit)="changeView()"></app-edit-pencil>
    </div>
</div>
<div *ngIf="!editPersonal">
    <div class="row">
        <div class="col-md-12">
            <table class="fees-table">
                <thead>
                    <tr>
                        <th></th>
                        <th>% FEE</th>
                        <th>USE %</th>
                        <th>AMOUNT</th>
                        <th>USE AMOUNT</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="commonUtil.showFeeRow(personalFeeList, feeCode.sender_cot)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(personalFeeList, feeCode.sender_cot)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.sender_cot] }}
                            </span>
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(personalFeeList, feeCode.sender_cot).percentage
                                    | number: amountFormat
                            }}{{ ' %' }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_personal_{{ feeCode.sender_cot }}"
                                [checked]="
                                    !commonUtil.getFee(personalFeeList, feeCode.sender_cot)
                                        .useAmount
                                "
                                disabled />
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(personalFeeList, feeCode.sender_cot).amount
                                    | number: amountFormat
                            }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_personal_{{ feeCode.sender_cot }}"
                                [checked]="
                                    commonUtil.getFee(personalFeeList, feeCode.sender_cot).useAmount
                                "
                                disabled />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(personalFeeList, feeCode.receiver_cin)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.receiver_cin] }}
                            </span>
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(personalFeeList, feeCode.receiver_cin).percentage
                                    | number: amountFormat
                            }}{{ ' %' }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_personal_{{ feeCode.receiver_cin }}"
                                [value]="false"
                                [checked]="
                                    !commonUtil.getFee(personalFeeList, feeCode.receiver_cin)
                                        .useAmount
                                "
                                disabled />
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(personalFeeList, feeCode.receiver_cin).amount
                                    | number: amountFormat
                            }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_personal_{{ feeCode.receiver_cin }}"
                                [value]="true"
                                [checked]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin)
                                        .useAmount
                                "
                                disabled />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(personalFeeList, feeCode.sender_scz)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(personalFeeList, feeCode.sender_scz)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.sender_scz] }}
                            </span>
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(personalFeeList, feeCode.sender_scz).percentage
                                    | number: amountFormat
                            }}{{ ' %' }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_personal_{{ feeCode.sender_scz }}"
                                [value]="false"
                                [checked]="
                                    !commonUtil.getFee(personalFeeList, feeCode.sender_scz)
                                        .useAmount
                                "
                                disabled />
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(personalFeeList, feeCode.sender_scz).amount
                                    | number: amountFormat
                            }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_personal_{{ feeCode.sender_scz }}"
                                [value]="true"
                                [checked]="
                                    commonUtil.getFee(personalFeeList, feeCode.sender_scz).useAmount
                                "
                                disabled />
                        </td>
                    </tr>

                    <tr *ngIf="commonUtil.showFeeRow(personalFeeList, feeCode.receiver_scz)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_scz)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.receiver_scz] }}
                            </span>
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(personalFeeList, feeCode.receiver_scz).percentage
                                    | number: amountFormat
                            }}{{ ' %' }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_personal_{{ feeCode.receiver_scz }}"
                                [checked]="
                                    !commonUtil.getFee(personalFeeList, feeCode.receiver_scz)
                                        .useAmount
                                "
                                disabled />
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(personalFeeList, feeCode.receiver_scz).amount
                                    | number: amountFormat
                            }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_personal_{{ feeCode.receiver_scz }}"
                                [checked]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_scz)
                                        .useAmount
                                "
                                disabled />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(personalFeeList, feeCode.sender_ttb)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(personalFeeList, feeCode.sender_ttb)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.sender_ttb] }}
                            </span>
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(personalFeeList, feeCode.sender_ttb).percentage
                                    | number: amountFormat
                            }}{{ ' %' }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_personal_{{ feeCode.sender_ttb }}"
                                [checked]="
                                    !commonUtil.getFee(personalFeeList, feeCode.sender_ttb)
                                        .useAmount
                                "
                                disabled />
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(personalFeeList, feeCode.sender_ttb).amount
                                    | number: amountFormat
                            }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_personal_{{ feeCode.sender_ttb }}"
                                [checked]="
                                    commonUtil.getFee(personalFeeList, feeCode.sender_ttb).useAmount
                                "
                                disabled />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(personalFeeList, feeCode.sender_mpb_pkg)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(personalFeeList, feeCode.sender_mpb_pkg)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.sender_mpb_pkg] }}
                            </span>
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(personalFeeList, feeCode.sender_mpb_pkg)
                                    .percentage | number: amountFormat
                            }}{{ ' %' }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_personal_{{ feeCode.sender_mpb_pkg }}"
                                [checked]="
                                    !commonUtil.getFee(personalFeeList, feeCode.sender_mpb_pkg)
                                        .useAmount
                                "
                                disabled />
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(personalFeeList, feeCode.sender_mpb_pkg).amount
                                    | number: amountFormat
                            }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_personal_{{ feeCode.sender_mpb_pkg }}"
                                [checked]="
                                    commonUtil.getFee(personalFeeList, feeCode.sender_mpb_pkg)
                                        .useAmount
                                "
                                disabled />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(personalFeeList, feeCode.receiver_cin_eft)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_eft)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.receiver_cin_eft] }}
                            </span>
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(personalFeeList, feeCode.receiver_cin_eft)
                                    .percentage | number: amountFormat
                            }}{{ ' %' }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_personal_{{ feeCode.receiver_cin_eft }}"
                                [checked]="
                                    !commonUtil.getFee(personalFeeList, feeCode.receiver_cin_eft)
                                        .useAmount
                                "
                                disabled />
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(personalFeeList, feeCode.receiver_cin_eft).amount
                                    | number: amountFormat
                            }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_personal_{{ feeCode.receiver_cin_eft }}"
                                [checked]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_eft)
                                        .useAmount
                                "
                                disabled />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(personalFeeList, feeCode.receiver_cin_atm)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_atm)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.receiver_cin_atm] }}
                            </span>
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(personalFeeList, feeCode.receiver_cin_atm)
                                    .percentage | number: amountFormat
                            }}{{ ' %' }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_personal_{{ feeCode.receiver_cin_atm }}"
                                [checked]="
                                    !commonUtil.getFee(personalFeeList, feeCode.receiver_cin_atm)
                                        .useAmount
                                "
                                disabled />
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(personalFeeList, feeCode.receiver_cin_atm).amount
                                    | number: amountFormat
                            }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_personal_{{ feeCode.receiver_cin_atm }}"
                                [checked]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_atm)
                                        .useAmount
                                "
                                disabled />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(personalFeeList, feeCode.receiver_cin_cod)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_cod)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.receiver_cin_cod] }}
                            </span>
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(personalFeeList, feeCode.receiver_cin_cod)
                                    .percentage | number: amountFormat
                            }}{{ ' %' }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_personal_{{ feeCode.receiver_cin_cod }}"
                                [checked]="
                                    !commonUtil.getFee(personalFeeList, feeCode.receiver_cin_cod)
                                        .useAmount
                                "
                                disabled />
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(personalFeeList, feeCode.receiver_cin_cod).amount
                                    | number: amountFormat
                            }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_personal_{{ feeCode.receiver_cin_cod }}"
                                [checked]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_cod)
                                        .useAmount
                                "
                                disabled />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(personalFeeList, feeCode.receiver_cin_store)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_store)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.receiver_cin_store] }}
                            </span>
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(personalFeeList, feeCode.receiver_cin_store)
                                    .percentage | number: amountFormat
                            }}{{ ' %' }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_personal_{{ feeCode.receiver_cin_store }}"
                                [checked]="
                                    !commonUtil.getFee(personalFeeList, feeCode.receiver_cin_store)
                                        .useAmount
                                "
                                disabled />
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(personalFeeList, feeCode.receiver_cin_store)
                                    .amount | number: amountFormat
                            }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_personal_{{ feeCode.receiver_cin_store }}"
                                [checked]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_store)
                                        .useAmount
                                "
                                disabled />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(personalFeeList, feeCode.receiver_cin_card)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_card)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.receiver_cin_card] }}
                            </span>
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(personalFeeList, feeCode.receiver_cin_card)
                                    .percentage | number: amountFormat
                            }}{{ ' %' }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_personal_{{ feeCode.receiver_cin_card }}"
                                [checked]="
                                    !commonUtil.getFee(personalFeeList, feeCode.receiver_cin_card)
                                        .useAmount
                                "
                                disabled />
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(personalFeeList, feeCode.receiver_cin_card).amount
                                    | number: amountFormat
                            }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_personal_{{ feeCode.receiver_cin_card }}"
                                [checked]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_card)
                                        .useAmount
                                "
                                disabled />
                        </td>
                    </tr>
                    <tr
                        *ngIf="
                            commonUtil.showFeeRow(personalFeeList, feeCode.receiver_cin_instant_eft)
                        ">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(
                                        personalFeeList,
                                        feeCode.receiver_cin_instant_eft
                                    ).description
                                }}">
                                {{ feeLabel[feeCode.receiver_cin_instant_eft] }}
                            </span>
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(personalFeeList, feeCode.receiver_cin_instant_eft)
                                    .percentage | number: amountFormat
                            }}{{ ' %' }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_personal_{{ feeCode.receiver_cin_instant_eft }}"
                                [checked]="
                                    !commonUtil.getFee(
                                        personalFeeList,
                                        feeCode.receiver_cin_instant_eft
                                    ).useAmount
                                "
                                disabled />
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(personalFeeList, feeCode.receiver_cin_instant_eft)
                                    .amount | number: amountFormat
                            }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_personal_{{ feeCode.receiver_cin_instant_eft }}"
                                [checked]="
                                    commonUtil.getFee(
                                        personalFeeList,
                                        feeCode.receiver_cin_instant_eft
                                    ).useAmount
                                "
                                disabled />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(personalFeeList, feeCode.sender_svc)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(personalFeeList, feeCode.sender_svc)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.sender_svc] }}
                            </span>
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(personalFeeList, feeCode.sender_svc).percentage
                                    | number: amountFormat
                            }}{{ ' %' }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_personal_{{ feeCode.sender_svc }}"
                                [checked]="
                                    !commonUtil.getFee(personalFeeList, feeCode.sender_svc)
                                        .useAmount
                                "
                                disabled />
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(personalFeeList, feeCode.sender_svc).amount
                                    | number: amountFormat
                            }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_personal_{{ feeCode.sender_svc }}"
                                [checked]="
                                    commonUtil.getFee(personalFeeList, feeCode.sender_svc).useAmount
                                "
                                disabled />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(personalFeeList, feeCode.receiver_svc)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_svc)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.receiver_svc] }}
                            </span>
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(personalFeeList, feeCode.receiver_svc).percentage
                                    | number: amountFormat
                            }}{{ ' %' }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_personal_{{ feeCode.receiver_svc }}"
                                [checked]="
                                    !commonUtil.getFee(personalFeeList, feeCode.receiver_svc)
                                        .useAmount
                                "
                                disabled />
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(personalFeeList, feeCode.receiver_svc).amount
                                    | number: amountFormat
                            }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_personal_{{ feeCode.receiver_svc }}"
                                [checked]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_svc)
                                        .useAmount
                                "
                                disabled />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(personalFeeList, feeCode.sender_busdv)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(personalFeeList, feeCode.sender_busdv)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.sender_busdv] }}
                            </span>
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(personalFeeList, feeCode.sender_busdv).percentage
                                    | number: amountFormat
                            }}{{ ' %' }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_personal_{{ feeCode.sender_busdv }}"
                                [checked]="
                                    !commonUtil.getFee(personalFeeList, feeCode.sender_busdv)
                                        .useAmount
                                "
                                disabled />
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(personalFeeList, feeCode.sender_busdv).amount
                                    | number: amountFormat
                            }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_personal_{{ feeCode.sender_busdv }}"
                                [checked]="
                                    commonUtil.getFee(personalFeeList, feeCode.sender_busdv)
                                        .useAmount
                                "
                                disabled />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(personalFeeList, feeCode.receiver_susdv)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_susdv)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.receiver_susdv] }}
                            </span>
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(personalFeeList, feeCode.receiver_susdv)
                                    .percentage | number: amountFormat
                            }}{{ ' %' }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_personal_{{ feeCode.receiver_susdv }}"
                                [checked]="
                                    !commonUtil.getFee(personalFeeList, feeCode.receiver_susdv)
                                        .useAmount
                                "
                                disabled />
                        </td>
                        <td>
                            {{
                                commonUtil.getFee(personalFeeList, feeCode.receiver_susdv).amount
                                    | number: amountFormat
                            }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="viewOnly_personal_{{ feeCode.receiver_susdv }}"
                                [checked]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_susdv)
                                        .useAmount
                                "
                                disabled />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div *ngIf="editPersonal">
    <div class="row">
        <div class="col-md-12">
            <table class="fees-table">
                <thead>
                    <tr>
                        <th></th>
                        <th>% FEE</th>
                        <th>USE %</th>
                        <th>AMOUNT</th>
                        <th>USE AMOUNT</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="commonUtil.showFeeRow(personalFeeList, feeCode.sender_cot)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(personalFeeList, feeCode.sender_cot)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.sender_cot] }}
                            </span>
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [maxVal]="100"
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.sender_cot)
                                        .percentageValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_personal_{{ feeCode.sender_cot }}"
                                [checked]="
                                    !commonUtil.getFee(personalFeeList, feeCode.sender_cot)
                                        .useAmount
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.sender_cot)
                                        .useAmountValue
                                "
                                [value]="false" />
                        </td>

                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.sender_cot)
                                        .amountValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_personal_{{ feeCode.sender_cot }}"
                                [checked]="
                                    commonUtil.getFee(personalFeeList, feeCode.sender_cot).useAmount
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.sender_cot)
                                        .useAmountValue
                                "
                                [value]="true" />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(personalFeeList, feeCode.receiver_cin)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.receiver_cin] }}
                            </span>
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [maxVal]="100"
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin)
                                        .percentageValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_personal_{{ feeCode.receiver_cin }}"
                                [checked]="
                                    !commonUtil.getFee(personalFeeList, feeCode.receiver_cin)
                                        .useAmount
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin)
                                        .useAmountValue
                                "
                                [value]="false" />
                        </td>

                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin)
                                        .amountValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_personal_{{ feeCode.receiver_cin }}"
                                [checked]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin)
                                        .useAmount
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin)
                                        .useAmountValue
                                "
                                [value]="true" />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(personalFeeList, feeCode.sender_scz)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(personalFeeList, feeCode.sender_scz)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.sender_scz] }}
                            </span>
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [maxVal]="100"
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.sender_scz)
                                        .percentageValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_personal_{{ feeCode.sender_scz }}"
                                [checked]="
                                    !commonUtil.getFee(personalFeeList, feeCode.sender_scz)
                                        .useAmount
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.sender_scz)
                                        .useAmountValue
                                "
                                [value]="false" />
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.sender_scz)
                                        .amountValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_personal_{{ feeCode.sender_scz }}"
                                [checked]="
                                    commonUtil.getFee(personalFeeList, feeCode.sender_scz).useAmount
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.sender_scz)
                                        .useAmountValue
                                "
                                [value]="true" />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(personalFeeList, feeCode.receiver_scz)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_scz)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.receiver_scz] }}
                            </span>
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [maxVal]="100"
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_scz)
                                        .percentageValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_personal_{{ feeCode.receiver_scz }}"
                                [checked]="
                                    !commonUtil.getFee(personalFeeList, feeCode.receiver_scz)
                                        .useAmount
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_scz)
                                        .useAmountValue
                                "
                                [value]="false" />
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_scz)
                                        .amountValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_personal_{{ feeCode.receiver_scz }}"
                                [checked]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_scz)
                                        .useAmount
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_scz)
                                        .useAmountValue
                                "
                                [value]="true" />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(personalFeeList, feeCode.sender_ttb)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(personalFeeList, feeCode.sender_ttb)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.sender_ttb] }}
                            </span>
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [maxVal]="100"
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.sender_ttb)
                                        .percentageValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_personal_{{ feeCode.sender_ttb }}"
                                [checked]="
                                    !commonUtil.getFee(personalFeeList, feeCode.sender_ttb)
                                        .useAmount
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.sender_ttb)
                                        .useAmountValue
                                "
                                [value]="false" />
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.sender_ttb)
                                        .amountValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_personal_{{ feeCode.sender_ttb }}"
                                [checked]="
                                    commonUtil.getFee(personalFeeList, feeCode.sender_ttb).useAmount
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.sender_ttb)
                                        .useAmountValue
                                "
                                [value]="true" />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(personalFeeList, feeCode.sender_mpb_pkg)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(personalFeeList, feeCode.sender_mpb_pkg)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.sender_mpb_pkg] }}
                            </span>
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [maxVal]="100"
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.sender_mpb_pkg)
                                        .percentageValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_personal_{{ feeCode.sender_mpb_pkg }}"
                                [checked]="
                                    !commonUtil.getFee(personalFeeList, feeCode.sender_mpb_pkg)
                                        .useAmount
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.sender_mpb_pkg)
                                        .useAmountValue
                                "
                                [value]="false" />
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.sender_mpb_pkg)
                                        .amountValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_personal_{{ feeCode.sender_mpb_pkg }}"
                                [checked]="
                                    commonUtil.getFee(personalFeeList, feeCode.sender_mpb_pkg)
                                        .useAmount
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.sender_mpb_pkg)
                                        .useAmountValue
                                "
                                [value]="true" />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(personalFeeList, feeCode.receiver_cin_eft)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_eft)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.receiver_cin_eft] }}
                            </span>
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [maxVal]="100"
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_eft)
                                        .percentageValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_personal_{{ feeCode.receiver_cin_eft }}"
                                [checked]="
                                    !commonUtil.getFee(personalFeeList, feeCode.receiver_cin_eft)
                                        .useAmount
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_eft)
                                        .useAmountValue
                                "
                                [value]="false" />
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_eft)
                                        .amountValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_personal_{{ feeCode.receiver_cin_eft }}"
                                [checked]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_eft)
                                        .useAmount
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_eft)
                                        .useAmountValue
                                "
                                [value]="true" />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(personalFeeList, feeCode.receiver_cin_atm)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_atm)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.receiver_cin_atm] }}
                            </span>
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [maxVal]="100"
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_atm)
                                        .percentageValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_personal_{{ feeCode.receiver_cin_atm }}"
                                [checked]="
                                    !commonUtil.getFee(personalFeeList, feeCode.receiver_cin_atm)
                                        .useAmount
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_atm)
                                        .useAmountValue
                                "
                                [value]="false" />
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_atm)
                                        .amountValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_personal_{{ feeCode.receiver_cin_atm }}"
                                [checked]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_atm)
                                        .useAmount
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_atm)
                                        .useAmountValue
                                "
                                [value]="true" />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(personalFeeList, feeCode.receiver_cin_cod)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_cod)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.receiver_cin_cod] }}
                            </span>
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [maxVal]="100"
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_cod)
                                        .percentageValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_personal_{{ feeCode.receiver_cin_cod }}"
                                [checked]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_cod)
                                        .useAmount
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_cod)
                                        .useAmountValue
                                "
                                [value]="false" />
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_cod)
                                        .amountValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_personal_{{ feeCode.receiver_cin_cod }}"
                                [checked]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_cod)
                                        .useAmount
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_cod)
                                        .useAmountValue
                                "
                                [value]="true" />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(personalFeeList, feeCode.receiver_cin_store)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_store)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.receiver_cin_store] }}
                            </span>
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [maxVal]="100"
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_store)
                                        .percentageValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_personal_{{ feeCode.receiver_cin_store }}"
                                [checked]="
                                    !commonUtil.getFee(personalFeeList, feeCode.receiver_cin_store)
                                        .useAmount
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_store)
                                        .useAmountValue
                                "
                                [value]="false" />
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_store)
                                        .amountValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_personal_{{ feeCode.receiver_cin_store }}"
                                [checked]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_store)
                                        .useAmount
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_store)
                                        .useAmountValue
                                "
                                [value]="true" />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(personalFeeList, feeCode.receiver_cin_card)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_card)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.receiver_cin_card] }}
                            </span>
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [maxVal]="100"
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_card)
                                        .percentageValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_personal_{{ feeCode.receiver_cin_card }}"
                                [checked]="
                                    !commonUtil.getFee(personalFeeList, feeCode.receiver_cin_card)
                                        .useAmount
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_card)
                                        .useAmountValue
                                "
                                [value]="false" />
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_card)
                                        .amountValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_personal_{{ feeCode.receiver_cin_card }}"
                                [checked]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_card)
                                        .useAmount
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_cin_card)
                                        .useAmountValue
                                "
                                [value]="true" />
                        </td>
                    </tr>
                    <tr
                        *ngIf="
                            commonUtil.showFeeRow(personalFeeList, feeCode.receiver_cin_instant_eft)
                        ">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(
                                        personalFeeList,
                                        feeCode.receiver_cin_instant_eft
                                    ).description
                                }}">
                                {{ feeLabel[feeCode.receiver_cin_instant_eft] }}
                            </span>
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [maxVal]="100"
                                [(ngModel)]="
                                    commonUtil.getFee(
                                        personalFeeList,
                                        feeCode.receiver_cin_instant_eft
                                    ).percentageValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_personal_{{ feeCode.receiver_cin_instant_eft }}"
                                [checked]="
                                    !commonUtil.getFee(
                                        personalFeeList,
                                        feeCode.receiver_cin_instant_eft
                                    ).useAmount
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(
                                        personalFeeList,
                                        feeCode.receiver_cin_instant_eft
                                    ).useAmountValue
                                "
                                [value]="false" />
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [(ngModel)]="
                                    commonUtil.getFee(
                                        personalFeeList,
                                        feeCode.receiver_cin_instant_eft
                                    ).amountValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_personal_{{ feeCode.receiver_cin_instant_eft }}"
                                [checked]="
                                    commonUtil.getFee(
                                        personalFeeList,
                                        feeCode.receiver_cin_instant_eft
                                    ).useAmount
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(
                                        personalFeeList,
                                        feeCode.receiver_cin_instant_eft
                                    ).useAmountValue
                                "
                                [value]="true" />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(personalFeeList, feeCode.sender_svc)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(personalFeeList, feeCode.sender_svc)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.sender_svc] }}
                            </span>
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [maxVal]="100"
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.sender_svc)
                                        .percentageValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_personal_{{ feeCode.sender_svc }}"
                                [checked]="
                                    !commonUtil.getFee(personalFeeList, feeCode.sender_svc)
                                        .useAmount
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.sender_svc)
                                        .useAmountValue
                                "
                                [value]="false" />
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.sender_svc)
                                        .amountValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_personal_{{ feeCode.sender_svc }}"
                                [checked]="
                                    commonUtil.getFee(personalFeeList, feeCode.sender_svc).useAmount
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.sender_svc)
                                        .useAmountValue
                                "
                                [value]="true" />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(personalFeeList, feeCode.receiver_svc)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_svc)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.receiver_svc] }}
                            </span>
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [maxVal]="100"
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_svc)
                                        .percentageValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_personal_{{ feeCode.receiver_svc }}"
                                [checked]="
                                    !commonUtil.getFee(personalFeeList, feeCode.receiver_svc)
                                        .useAmount
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_svc)
                                        .useAmountValue
                                "
                                [value]="false" />
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_svc)
                                        .amountValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_personal_{{ feeCode.receiver_svc }}"
                                [checked]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_svc)
                                        .useAmount
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_svc)
                                        .useAmountValue
                                "
                                [value]="true" />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(personalFeeList, feeCode.sender_busdv)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(personalFeeList, feeCode.sender_busdv)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.sender_busdv] }}
                            </span>
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [maxVal]="100"
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.sender_busdv)
                                        .percentageValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_personal_{{ feeCode.sender_busdv }}"
                                [checked]="
                                    !commonUtil.getFee(personalFeeList, feeCode.sender_busdv)
                                        .useAmount
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.sender_busdv)
                                        .useAmountValue
                                "
                                [value]="false" />
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.sender_busdv)
                                        .amountValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_personal_{{ feeCode.sender_busdv }}"
                                [checked]="
                                    commonUtil.getFee(personalFeeList, feeCode.sender_busdv)
                                        .useAmount
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.sender_busdv)
                                        .useAmountValue
                                "
                                [value]="true" />
                        </td>
                    </tr>
                    <tr *ngIf="commonUtil.showFeeRow(personalFeeList, feeCode.receiver_susdv)">
                        <td>
                            <span
                                data-toggle="tooltip"
                                data-placement="right"
                                title="{{
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_susdv)
                                        .description
                                }}">
                                {{ feeLabel[feeCode.receiver_susdv] }}
                            </span>
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [maxVal]="100"
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_susdv)
                                        .percentageValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_personal_{{ feeCode.receiver_susdv }}"
                                [checked]="
                                    !commonUtil.getFee(personalFeeList, feeCode.receiver_susdv)
                                        .useAmount
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_susdv)
                                        .useAmountValue
                                "
                                [value]="false" />
                        </td>
                        <td>
                            <input
                                type="text"
                                appFloatOnly
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_susdv)
                                        .amountValue
                                " />
                        </td>
                        <td>
                            <input
                                type="radio"
                                name="edit_personal_{{ feeCode.receiver_susdv }}"
                                [checked]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_susdv)
                                        .useAmount
                                "
                                [(ngModel)]="
                                    commonUtil.getFee(personalFeeList, feeCode.receiver_susdv)
                                        .useAmountValue
                                "
                                [value]="true" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6"></div>
        <div class="col-md-6" *ngIf="!parentToUpdate">
            <button class="custombtn custombtn-align-right" (click)="submit()">SAVE</button>
            <a class="user-files-deny" (click)="changeView()"><u>CANCEL</u></a>
        </div>
    </div>
</div>
