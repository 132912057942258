export class Bank {
    public bankId: number;
    public userId: string;
    public bankDetailId: string;
    public bankName: string;
    public bankLogo: string;
    public bankCode: string;
    public branchCode: string;
    public bankLocation: string;
    public accountNumber: string;
    public accountHolderName: string;
    public accountType: string;
    public accountTypeCode: string;
    public accountReference: string;
    public userRoleId: string;
    public note: string;
    public dateReceivedInBank: string;
    constructor() {}
}
