<section class="content">
    <!-- Default box -->
    <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item" *ngIf="isAdmin">
            <a
                class="nav-link"
                routerLinkActive="tab-link-active"
                [routerLink]="['/manage-users/enterprise/summary']">
                Summary</a
            >
        </li>
        <li class="nav-item">
            <a
                class="nav-link"
                routerLinkActive="tab-link-active"
                [routerLink]="['/enterprise/profile']">
                Enterprise Details</a
            >
        </li>
        <li class="nav-item">
            <a
                class="nav-link"
                routerLinkActive="tab-link-active"
                [routerLink]="['/manage-users/enterprise/employee']">
                Employees</a
            >
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLinkActive="tab-link-active"> Files</a>
        </li>
        <!-- [routerLink]="['/manage-users/view/profile/',id,'files']" -->
        <li class="nav-item" *ngIf="isAdmin">
            <a
                class="nav-link"
                routerLinkActive="tab-link-active"
                [routerLink]="['/manage-users/enterprise/settings']">
                Administrative Settings</a
            >
        </li>

        <li class="nav-item">
            <a
                class="nav-link"
                routerLinkActive="tab-link-active"
                [routerLink]="[getTransactionsRouterLink()]">
                Transactions</a
            >
        </li>
        <li class="nav-item">
            <a
                class="nav-link"
                routerLinkActive="tab-link-active"
                [routerLink]="['/enterprise/product-orders']">
                Product Orders</a
            >
        </li>
    </ul>
</section>
