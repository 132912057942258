<div class="edit-link">
    <a data-toggle="dropdown">
        <div class="dot-icon"></div>
    </a>
    <ul class="dropdown-menu" aria-labelledby="menu1">
        <li>
            <a class="dropdown-menu-item" [routerLink]="urlFile">
                <img src="assets/images/view-files.png" />
                View Files
            </a>
        </li>
        <li>
            <a class="dropdown-menu-item" [routerLink]="urlProfile">
                <img src="assets/images/view-profile.png" />
                View Profile
            </a>
        </li>
    </ul>
</div>
