import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Bank } from '../model/bank';
import { Enterprise } from '../model/enterprise';
import { User } from '../model/user';

@Injectable({ providedIn: 'root' })
export class StorageService {
    public user: BehaviorSubject<User> = new BehaviorSubject(null);
    public profileUser: BehaviorSubject<User> = new BehaviorSubject(null);
    public profileEnterprise: BehaviorSubject<Enterprise> = new BehaviorSubject(null);

    // dropdowns
    public bankList: BehaviorSubject<Bank[]> = new BehaviorSubject(null);
    public bankNameList: BehaviorSubject<string[]> = new BehaviorSubject(null);
    public cityDropdown: BehaviorSubject<string[]> = new BehaviorSubject(null);
    public idTypeDropdown: BehaviorSubject<string[]> = new BehaviorSubject(null);
    public statusDropdown: BehaviorSubject<string[]> = new BehaviorSubject(null);
    public provinceDropdown: BehaviorSubject<string[]> = new BehaviorSubject(null);
    public accountTypeDropdown: BehaviorSubject<string[]> = new BehaviorSubject(null);
    public transactionTypeDropdown: BehaviorSubject<string[]> = new BehaviorSubject(null);
    public transactionStatusDropdown: BehaviorSubject<string[]> = new BehaviorSubject(null);

    constructor() {}

    getItem(key: string) {
        return JSON.parse(localStorage.getItem(key));
    }

    setItem(key: string, val: any) {
        localStorage.setItem(key, JSON.stringify(val));
    }

    initializeProfileUser() {
        this.profileUser = new BehaviorSubject(null);
    }

    setProfileUser(user: User) {
        this.profileUser.next(user);
    }

    getProfileUser() {
        return this.profileUser.value;
    }

    setEnterpriseProfile(enterprise: Enterprise) {
        this.profileEnterprise.next(enterprise);
    }

    getEnterpriseProfile() {
        return this.profileEnterprise.value;
    }
}
