import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Transaction } from 'src/app/model/transaction';
import { Pagination } from 'src/app/model/Pagination';
import { TransactionStatus } from 'src/app/enums/transaction.status';
import { Router, ActivatedRoute } from '@angular/router';
import { DOCUMENT, DatePipe } from '@angular/common';
import { StorageConstant } from 'src/app/settings/storage-constants';
import { EnterpriseService } from 'src/app/services/enterprise.service';
import { LoginService } from 'src/app/services/login.service';
import { Employee } from 'src/app/model/employee';
import { Role } from 'src/app/enums/role';
import { CommonUtil } from 'src/app/services/common.util';
import { CompanyService } from 'src/app/services/company.service';

@Component({
    selector: 'app-enterprise-transactions',
    templateUrl: './enterprise-transactions.component.html',
    styleUrls: ['./enterprise-transactions.component.css'],
})
export class EnterpriseTransactionsComponent implements OnInit {
    id: string;
    display = false;
    displaySuccess = false;
    displayRefund = 'none';
    eMessage = '';
    sMessage = '';
    staffId: string;
    pin: string;
    transaction: Transaction = new Transaction();
    accountTypeDropdown: string[] = [];
    transactionStatusDropdown: string[] = [];
    transactionTypeDropdown: string[] = [];
    form: FormGroup;
    invalidForm = false;
    transactionList: Array<Transaction>;
    excelList = new Array<any>();
    pagination = new Pagination();
    isLoading = false;
    showOptions = false;
    search = false;
    progressWidth = 25;
    searchItems: string[] = [];
    status = TransactionStatus;
    employee: Employee;
    userRole = Role.enterprise_contact.toString();
    role = Role;

    constructor(
        private router: Router,
        private formBuilder: UntypedFormBuilder,
        public commonUtil: CommonUtil,
        public loginService: LoginService,
        @Inject(DOCUMENT) private document: Document,
        private enterpriseService: EnterpriseService,
        private datePipe: DatePipe,
        public companyService: CompanyService,
    ) {
        this.userRole = this.loginService.sessionUser.value.role;
        this.id = this.loginService.sessionUser.value.enterprise.enterpriseId;
        this.employee = JSON.parse(localStorage.getItem(StorageConstant.EMPLOYEE_NUMBER));

        localStorage.removeItem(StorageConstant.EMPLOYEE_NUMBER);
        this.form = this.formBuilder.group({
            enterpriseId: [''],
            enterpriseStaffId: [''],
            text: [''],
            start: [''],
            length: [''],
        });
    }

    ngOnInit() {
        this.getData();
    }

    getData() {
        this.search = false;
        this.isLoading = true;
        this.form.get('enterpriseId').setValue(this.id);
        if (this.employee) {
            this.form.get('enterpriseStaffId').setValue(this.employee.enterpriseStaffId);
        }
        this.form.get('start').setValue(this.pagination.start);
        this.form.get('length').setValue(this.pagination.length);
        this.setProgressWidth(50);
        this.enterpriseService.getEmployeeTransactionHistory(this.form).subscribe(
            (res) => {
                this.setProgressWidth(100);
                setTimeout(() => {
                    this.transactionList =
                        res && res.data
                            ? res.data.map((t) => new Transaction().deserialize(t))
                            : [];

                    this.excelList = new Array<any>();

                    if (this.transactionList) {
                        for (const tran of this.transactionList) {
                            this.excelList.push(this.getExcel(tran));
                        }
                    }
                    this.pagination.total = !res.total ? 0 : res.total;
                    this.isLoading = false;
                    this.search = true;
                    this.progressWidth = 25;
                }, 350);
            },
            (error) => {
                this.isLoading = false;
                this.search = true;
            },
        );
    }

    public getExcel(tran: Transaction) {
        const excel = {
            'From User': tran.fromUserName,
            'To User': tran.toUserName,
            'Transaction Code': tran.transactionCode,
            Type: tran.type,
            Amount: tran.amount,
            Fee: tran.fees,
            Cashback: tran.cashback,
            'Cashback Fee': tran.cashbackFees,
            Status: tran.status,
            Date: this.datePipe.transform(tran.date, 'yyyy-MM-dd'),
            Description: tran.description,
        };

        return excel;
    }

    setProgressWidth(i: number) {
        setTimeout(() => {
            this.progressWidth = i;
        }, 300);
    }

    searchUsers(text: string) {
        this.pagination.text = text;
        this.getData();
    }

    clearText() {
        this.form.get('text').setValue('');
    }

    receiveFilter(data: { name: string; value: number }) {}

    changeLength(len: number) {
        this.pagination.length = len;
        this.pagination.start = 0;
        this.getData();
    }

    changePage(page: number) {
        this.pagination.changePage(page);
        this.getData();
    }

    closeModel(event: any) {
        this.display = false;
        this.displaySuccess = false;
    }

    get controls(): any {
        return this.form.controls;
    }

    submit() {
        this.getData();
    }

    showDetails(val: string, logo: string) {
        let element = this.document.getElementById(val);
        if (element.style.maxHeight) {
            element.style.maxHeight = null;
            element.style.border = 'none';
        } else {
            element.style.maxHeight = element.scrollHeight + 'px';
            element.style.border = '6px';
        }

        element = this.document.getElementById(logo);
        let src = element.getAttribute('src');
        src =
            src === 'assets/images/default-transaction-detail.png'
                ? 'assets/images/hover-transaction-detail.png'
                : 'assets/images/default-transaction-detail.png';

        element.setAttribute('src', src);
    }

    copyText(val: string) {
        const input = this.document.createElement('input');
        this.document.body.appendChild(input);
        if (val) {
            input.value = val;
        } else {
            input.value = 'NA';
        }
        input.select();
        this.document.execCommand('copy');
        input.remove();
    }

    openRefundModel(transaction: Transaction) {
        this.displayRefund = 'block';
        this.transaction = transaction;
    }

    closeRefundModel() {
        this.displayRefund = 'none';
    }

    authenticate() {
        this.closeRefundModel();
        this.isLoading = true;
        this.loginService.verifyPin({ pin: this.pin }).subscribe(
            (res) => {
                this.isLoading = false;
                this.pin = '';
                if (res.success) {
                    this.refund();
                } else {
                    this.display = true;
                    this.eMessage = 'Invalid Password.';
                }
            },
            (err) => {
                this.isLoading = false;
                this.pin = '';
                this.display = true;
                this.eMessage = 'Invalid Password.';
            },
        );
    }

    refund() {
        this.isLoading = true;
        this.enterpriseService.refund(this.transaction.transactionCode).subscribe(
            (res) => {
                this.isLoading = false;
                if (res.success) {
                    this.getData();
                    this.displaySuccess = true;
                    this.sMessage = res.status.message;
                } else {
                    this.display = true;
                    this.eMessage = res.status.message;
                }
            },
            (err) => {
                this.isLoading = false;
                this.display = true;
                this.eMessage = 'Refund Failed';
            },
        );
    }
}
