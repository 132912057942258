import { Base } from 'src/app/model/base';

export class Banner extends Base {
    public id: number;
    public name: string;
    public path: string;
    public active: boolean;

    constructor() {
        super();
    }
}
