<div class="edit-link" *ngIf="!processed">
    <a data-toggle="dropdown">
        <div class="dot-icon"></div>
    </a>
    <ul class="dropdown-menu" aria-labelledby="menu1">
        <li>
            <a class="dropdown-menu-item" (click)="action('view')">
                <img src="assets/images/control-icon-view-details.png" />
                View Details
            </a>
        </li>
        <li>
            <a
                class="dropdown-menu-item"
                data-toggle="modal"
                data-backdrop="false"
                (click)="action('update')">
                <img src="assets/images/control-icon-update.png" />
                {{ netcashEnabled ? 'Update Status' : 'Process Transaction' }}
            </a>
        </li>
    </ul>
</div>
<div class="edit-link" *ngIf="processed">
    <a data-toggle="dropdown">
        <div class="dot-icon"></div>
    </a>
    <ul class="dropdown-menu" aria-labelledby="menu1">
        <li>
            <a class="dropdown-menu-item" (click)="action('view')">
                <img src="assets/images/control-icon-view-details.png" />
                View Details
            </a>
        </li>
    </ul>
</div>
