import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
    UntypedFormArray,
    UntypedFormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { Pagination } from 'src/app/model/Pagination';
import { CommonUtil } from 'src/app/services/common.util';
import { URI } from 'src/app/settings/uri-constants';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { GrvService } from 'src/app/services/grv.service';
import { AppConstants } from 'src/app/settings/app-constants';
import { MessageConstant } from 'src/app/settings/message.constant';
import { CompanyService } from 'src/app/services/company.service';
@Component({
    selector: 'app-grv',
    templateUrl: './grv.component.html',
    styleUrls: ['./grv.component.css'],
})
export class GrvComponent implements OnInit {
    appConstants = AppConstants;
    amountFormat = AppConstants.AMOUNT_FORMAT;
    form: FormGroup;
    addForm: FormGroup;
    invalidForm = false;
    disableComplete = false;
    showOptions = false;
    isLoading = false;
    addGrv = false;
    addGrvDetails = false;
    viewGrvDetails = false;
    viewShortDelivery = false;
    viewPriceDifference = false;
    progressWidth = 25;
    pagination = new Pagination();
    grvList = new Array<any>();
    addGrvList = new Array<any>();
    shortDeliveryObject: any;
    priceDifferenceObject: any;
    grvData: any;
    loaderText = 'Searching...';
    buttonText = 'Add New GRV';
    purchaseOrderId = '';
    uri = URI;
    taxOptions = ['', 'VAT'];
    subTotal = 0;
    totalTax = 0;
    total = 0;
    eMessage = '';
    orderId = '';
    display = false;
    displayPriceDifferenceModel = false;
    displayShortDeliveryModel = false;
    @ViewChild('priceDifference', { static: false }) priceDifference: ElementRef;
    @ViewChild('shortDelivery', { static: false }) shortDelivery: ElementRef;

    constructor(
        private formBuilder: UntypedFormBuilder,
        public commonUtil: CommonUtil,
        private datePipe: DatePipe,
        private grvService: GrvService,
        public companyService: CompanyService,
    ) {
        this.form = this.formBuilder.group({
            text: [''],
            dateCreated: [''],
            hasPriceDifferenceClaim: [false],
            hasShortDeliveryClaim: [false],
            start: [''],
            length: [''],
        });

        this.addForm = this.formBuilder.group({
            grvItem: this.formBuilder.array([]),
        });
    }

    ngOnInit() {
        this.getData();

        this.addForm.get('grvItem').valueChanges.subscribe((changes) => {
            this.totalTax = 0;
            this.disableComplete = false;
            for (let i = 0; i < changes.length; i++) {
                if (changes[i].tax_rate == 'VAT') {
                    this.addGrvList[i].tax_rate = 0.15;
                    this.addGrvList[i].tax_price =
                        Math.round(this.addGrvList[i].total_price * 0.15 * 100) / 100;
                    this.totalTax =
                        Math.round((this.totalTax + this.addGrvList[i].tax_price) * 100) / 100;
                    this.addGrvList[i].vatable = true;
                } else {
                    this.addGrvList[i].tax_price = 0.0;
                    this.addGrvList[i].vatable = false;
                    this.addGrvList[i].tax_rate = 0.0;
                }
                if (
                    (this.addGrv && changes[i].quantity_received == undefined) ||
                    changes[i].invoice_price == undefined
                ) {
                    this.disableComplete = true;
                }
                this.addGrvList[i].quantity_received = changes[i].quantity_received;
                this.addGrvList[i].invoice_price = changes[i].invoice_price;
            }
            this.total = this.subTotal + this.totalTax;
        });
    }

    createTableRow(row: any): FormGroup {
        return this.formBuilder.group({
            tax_rate: new FormControl(row.tax_rate),
            tax_price: new FormControl(row.tax_price),
            invoice_price: new FormControl(row.invoice_price, {
                validators: [Validators.required, Validators.maxLength(12)],
            }),
            quantity_received: new FormControl(row.quantity_received, {
                validators: [Validators.required, Validators.maxLength(12)],
            }),
        });
    }

    getData() {
        this.isLoading = true;
        this.form.get('start').setValue(this.pagination.start);
        this.form.get('length').setValue(this.pagination.length);
        this.setProgressWidth(50);
        this.transformDate();

        this.grvService.getGrvList(this.form.value).subscribe(
            (res) => {
                this.setProgressWidth(100);
                setTimeout(() => {
                    this.grvList = res.responseBody == null ? [] : res.responseBody.data;
                    this.pagination.total = !res.responseBody ? 0 : res.responseBody.total;

                    this.isLoading = false;
                    this.progressWidth = 25;
                }, 350);
            },
            (error) => {
                this.clearText();
                this.isLoading = false;
                this.eMessage = MessageConstant.SERVER_ERROR;
                this.display = true;
            },
        );
    }

    setProgressWidth(i: number) {
        setTimeout(() => {
            this.progressWidth = i;
        }, 300);
    }

    transformDate() {
        let date = this.form.get('dateCreated').value;
        if (date != '') {
            date = this.datePipe.transform(date, 'yyyy-MM-dd');
            this.form.get('dateCreated').setValue(date);
        }
    }

    clearText() {
        this.form.get('text').setValue('');
    }

    clearDates() {
        this.form.get('dateCreated').setValue('');
    }

    toggleOption() {
        this.showOptions = !this.showOptions;
    }

    receiveFilter(data: { name: string; value: number }) {}

    changeLength(len: number) {
        this.pagination.length = len;
        this.pagination.start = 0;
        this.getData();
    }

    changePage(page: number) {
        this.pagination.changePage(page);
        this.getData();
    }

    closeModel() {
        this.display = false;
    }

    openAddGrvPage() {
        if (this.addGrv) {
            this.createGrv();
        } else {
            this.addGrv = true;
            this.buttonText = 'Complete';
        }
    }

    submit() {
        if (this.addGrv) {
            const val = this.form.get('text').value;
            if (val == '') {
                return false;
            }
            this.isLoading = true;
            this.grvService.getGrvByPurchaseOrderId(this.form.get('text').value).subscribe(
                (res) => {
                    if (res.success) {
                        this.grvData = res;
                        this.addGrvList = this.grvData.purchase_order.purchase_items;

                        this.subTotal = this.grvData.subTotal;
                        this.total = 0;
                        this.totalTax = 0;

                        for (let i = 0; i < this.addGrvList.length; i++) {
                            if (this.addGrvList[i].variant_title != 'Default Title') {
                                this.addGrvList[i].product_title =
                                    this.addGrvList[i].product_title +
                                    ' ' +
                                    this.addGrvList[i].variant_title;
                            }
                            this.grvItem.push(this.createTableRow(this.addGrvList[i]));
                        }
                        this.orderId = this.form.get('text').value;
                    } else {
                        this.eMessage = res.status.message;
                        this.display = true;
                    }

                    this.clearText();
                    this.isLoading = false;
                },
                (error) => {
                    this.clearText();
                    this.isLoading = false;
                    this.eMessage = MessageConstant.SERVER_ERROR;
                    this.display = true;
                },
            );
        } else {
            this.getData();
        }
    }

    createGrv() {
        if (this.grvItem.length == 0) {
            return false;
        }

        for (let i = 0; i < this.addGrvList.length; i++) {
            if (this.addGrvList[i].invoice_price == null) {
                this.eMessage = 'Please fill Invoice Price for all items.';
                this.display = true;
                return false;
            } else if (this.addGrvList[i].quantity_received == null) {
                this.eMessage = 'Please fill Quantity Received for all items.';
                this.display = true;
                return false;
            }
        }
        this.grvData.purchase_order.purchase_items = this.addGrvList;
        this.grvData.grandTotal = this.total;
        this.grvData.subTotal = this.subTotal;
        this.grvData.vatTotal = this.totalTax;
        this.isLoading = true;

        this.grvService.createGrv(this.grvData).subscribe(
            (res) => {
                this.isLoading = false;
                if (res.success) {
                    this.addGrv = false;
                    this.buttonText = 'Add New GRV';
                    this.subTotal = 0;
                    this.total = 0;
                    this.totalTax = 0;
                    this.addGrvList = [];
                    this.grvItem.clear();
                    this.grvData = res.GRV;
                    this.priceDifferenceObject = res.GRV.price_difference_claim;
                    this.shortDeliveryObject = res.GRV.short_delivery_claim;
                    this.viewShortDelivery = res.GRV.short_delivery_claim ? true : false;
                    this.viewPriceDifference = res.GRV.price_difference_claim ? true : false;
                    this.getData();
                    this.openGrvDetails();
                    this.orderId = '';
                }
            },
            (error) => {
                this.isLoading = false;
                this.eMessage = MessageConstant.SERVER_ERROR;
                this.display = true;
            },
        );
    }

    openGrvDetails() {
        this.viewGrvDetails = this.viewPriceDifference || this.viewShortDelivery;
    }

    closeGrvDetails(type: string) {
        if (type == this.appConstants.SDC) {
            this.viewShortDelivery = false;
        }
        if (type == this.appConstants.PDC) {
            this.viewPriceDifference = false;
        }
        this.viewGrvDetails = this.viewPriceDifference || this.viewShortDelivery;
    }

    openGrvModel(event: any) {
        this.grvData = event.value;

        if (event.type == this.appConstants.SDC) {
            this.viewShortDelivery = true;
            this.shortDeliveryObject = event.value.short_delivery_claim;
            this.openGrvDetails();
        } else if (event.type == this.appConstants.PDC) {
            this.viewPriceDifference = true;
            this.priceDifferenceObject = event.value.price_difference_claim;
            this.openGrvDetails();
        }
    }

    generatePdf(type: string) {
        this.viewGrvDetails = false;

        if (type == this.appConstants.SDC) {
            this.displayShortDeliveryModel = true;

            setTimeout(() => {
                const DATA: any = document.getElementById('shortDelivery');
                html2canvas(DATA).then((canvas) => {
                    const PDF = new jsPDF('p', 'mm', 'a4');
                    const fileWidth = PDF.internal.pageSize.getWidth();
                    const fileHeight = (canvas.height * fileWidth) / canvas.width;
                    const FILEURI = canvas.toDataURL('image/png');
                    PDF.addImage(FILEURI, 'PNG', 0, 0, fileWidth, fileHeight);
                    PDF.save('short-delivery-claim.pdf');
                    this.displayShortDeliveryModel = false;
                    this.viewShortDelivery = false;
                    this.openGrvDetails();
                });
            }, 500);
        } else if (type == this.appConstants.PDC) {
            this.displayPriceDifferenceModel = true;

            setTimeout(() => {
                const DATA: HTMLElement = document.getElementById('priceDifference');
                DATA.style.visibility = 'show';
                console.log(DATA);
                html2canvas(DATA).then((canvas) => {
                    const PDF = new jsPDF('p', 'mm', 'a4');
                    const fileWidth = PDF.internal.pageSize.getWidth();
                    const fileHeight = (canvas.height * fileWidth) / canvas.width;
                    const FILEURI = canvas.toDataURL('image/png');
                    PDF.addImage(FILEURI, 'PNG', 0, 0, fileWidth, fileHeight);
                    PDF.save('price-difference-claim.pdf');
                    this.displayPriceDifferenceModel = false;
                    this.viewPriceDifference = false;
                    this.openGrvDetails();
                });
            }, 500);
        }
    }

    get controls(): any {
        return this.form.controls;
    }

    get grvItem(): UntypedFormArray {
        return this.addForm.get('grvItem') as UntypedFormArray;
    }
}
