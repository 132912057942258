import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-success-model',
    templateUrl: './success-model.component.html',
    styleUrls: ['./success-model.component.css'],
})
export class SuccessModelComponent {
    @Input() message: string;
    @Input() display: boolean;
    @Output() event = new EventEmitter<boolean>();

    constructor() {}

    closeModel() {
        this.event.emit(false);
    }
}
