import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { Role } from 'src/app/enums/role';
import { TransactionStatus } from 'src/app/enums/transaction.status';
import { TransactionType } from 'src/app/enums/transaction.type';
import { Bank } from 'src/app/model/bank';
import { Pagination } from 'src/app/model/Pagination';
import { Transaction } from 'src/app/model/transaction';
import { TransactionLog } from 'src/app/model/transaction.log';
import { TransferToBank } from 'src/app/model/transfer-to-bank';
import { CommonService } from 'src/app/services/common.service';
import { CommonUtil } from 'src/app/services/common.util';
import { ModalService } from 'src/app/services/modal.service';
import { TransactionService } from 'src/app/services/transaction.service';
import { AppConstants } from 'src/app/settings/app-constants';
import { MessageConstant } from 'src/app/settings/message.constant';
import { ModalConstants } from 'src/app/settings/modal-constants';
import { FormUtils } from 'src/app/utils/form.utils';

@Component({
    selector: 'app-eft-cash-in-data',
    templateUrl: './eft-cash-in-data.component.html',
    styleUrls: ['./eft-cash-in-data.component.css'],
})
export class EftCashInDataComponent implements OnInit {
    amountFormat = AppConstants.AMOUNT_FORMAT;
    dateTimeFormat = AppConstants.DATE_TIME_FORMAT;
    isLoading = false;
    display = false;
    displayView = false;
    displayCancel = 'none';
    displayReceived = 'none';
    displaySuccess = false;
    eMessage = 'Failed to update Transfer to Bank status';
    sMessage = 'Status updated successfully';
    sageFile = '';
    todayDate = '';
    rows = 0;
    totalAmount = 0;
    id: string;
    searchItems: string[] = [];
    itemList = ['role', 'status'];
    accountTypeDropdown: string[] = [Role.enterprise, Role.business, Role.personal];
    transactionStatus = '';
    form: FormGroup;
    processCashInForm: FormGroup;
    sendCommsForm: FormGroup;
    updateTransactionForm: FormGroup;
    TTB: TransferToBank;
    transactionList: Array<Transaction>;
    transaction: Transaction = new Transaction();
    pagination = new Pagination<Transaction>();
    showOptions = false;
    showUpdate = false;
    search = false;
    progressWidth = 25;
    shouldUpdateAmount = false;
    formUtils = FormUtils;
    modalConstants = ModalConstants;
    cancellingTransaction = false;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private commonService: CommonService,
        private transactionService: TransactionService,
        private datePipe: DatePipe,
        public commonUtil: CommonUtil,
        private activatedRoute: ActivatedRoute,
        private modalService: ModalService,
        private toasterService: ToastrService,
    ) {
        const date = new Date();
        this.todayDate = this.datePipe.transform(date, 'yyyy-MM-dd');

        this.form = this.formBuilder.group({
            text: [''],
            start: [''],
            length: [''],
            userId: [''],
            role: [''],
            status: [''],
            transactionTypeList: [[TransactionType.eft_cash_in, TransactionType.atm_cash_in]],
            transactionStatus: [[]],
        });

        this.processCashInForm = this.formBuilder.group({
            status: [''],
            transactionCode: [''],
            dateReceivedInBank: ['', Validators.required],
            note: ['', Validators.required],
            newAmount: [''],
            shouldUpdateAmount: [this.shouldUpdateAmount],
        });

        /*
         * TODO:
         * Make this a global form that can be used by any component
         * to specifically call updateTransaction() api
         */
        this.updateTransactionForm = this.formBuilder.group({
            transactionCode: [''],
            status: [''],
            amount: [''],
            note: ['', Validators.required],
        });

        this.sendCommsForm = this.formBuilder.group({
            message: new FormControl('', Validators.required),
        });
    }

    ngOnInit() {
        this.activatedRoute.data.subscribe((data) => {
            this.transactionStatus = data.transactionStatus;
            if (this.transactionStatus == TransactionStatus.pending) {
                this.showUpdate = true;
            }
            this.form.get('transactionStatus').setValue([this.transactionStatus]);
            this.getData();
        });
    }

    closeModel(event: any) {
        this.display = false;
        this.displaySuccess = false;
    }

    getData() {
        this.search = false;
        this.isLoading = true;
        this.form.get('start').setValue(this.pagination.start);
        this.form.get('length').setValue(this.pagination.length);
        this.setProgressWidth(50);
        this.commonService.getUserTransactionHistory(this.form).subscribe(
            (res) => {
                this.setProgressWidth(100);
                setTimeout(() => {
                    this.transactionList = res.data;
                    this.pagination.data = res.data;
                    this.TTB = res.settlementData;
                    this.pagination.total = !res.total ? 0 : res.total;
                    this.isLoading = false;
                    this.search = true;
                    this.progressWidth = 25;
                }, 350);

                this.searchItems = [];
                for (const item of this.itemList) {
                    if (this.form.get(item).value !== '') {
                        this.searchItems.push(this.form.get(item).value);
                    }
                }
            },
            (error) => {
                this.isLoading = false;
            },
        );
    }

    onChange() {
        this.shouldUpdateAmount = !this.shouldUpdateAmount;

        if (this.shouldUpdateAmount) {
            this.processCashInForm.get('newAmount')?.enable();
        } else {
            this.processCashInForm.get('newAmount')?.disable();
        }
    }

    setProgressWidth(i: number) {
        setTimeout(() => {
            this.progressWidth = i;
        }, 300);
    }

    searchUsers(text: string) {
        this.pagination.text = text;
        this.getData();
    }

    removeSearchItem(value: string) {
        for (const item of this.itemList) {
            if (this.form.get(item).value === value) {
                this.form.get(item).setValue('');
                this.getData();
                break;
            }
        }
    }

    clearText() {
        this.form.get('text').setValue('');
    }

    receiveFilter(data: { name: string; value: number }) {
        this.transactionList = this.pagination.filterData(data);
    }

    changeLength(len: number) {
        this.pagination.length = len;
        this.pagination.start = 0;
        this.getData();
    }

    changePage(page: number) {
        this.pagination.changePage(page);
        this.getData();
    }

    toggleOption() {
        this.showOptions = !this.showOptions;
    }

    openSideViewModal() {
        this.displayView = true;
    }

    closeSideViewModal() {
        this.displayView = false;
    }

    initTransactionUpdateAction(cancel: boolean) {
        if (cancel) {
            this.cancellingTransaction = true;
            this.updateTransactionForm.get('status').setValue(TransactionStatus.cancelled);
        } else {
            this.updateTransactionForm.get('status').setValue('');
            this.cancellingTransaction = false;
        }
        this.modalService.open(this.modalConstants.transactionUpdateModalId);
    }

    openCancelModel() {
        this.displayCancel = 'block';
    }

    closeCancelModel() {
        this.displayCancel = 'none';
    }

    action(event: { type: string; value: Transaction }) {
        this.transaction = event.value;

        if (!this.transaction.bankDetails) {
            this.transaction.bankDetails = new Bank();
        }

        if (!this.transaction.transactionLog) {
            this.transaction.transactionLog = new Array<TransactionLog>();
        }

        switch (event.type) {
            case 'view':
                this.openSideViewModal();
                break;
        }
    }

    get controls(): any {
        return this.form.controls;
    }

    submit() {
        this.getData();
    }

    sendCommsToUser(modalId: string) {
        if (this.sendCommsForm.valid) {
            this.modalService.close(modalId);
            this.isLoading = true;
            this.sMessage = 'An SMS has been successfully sent to the user';

            this.transactionService
                .sendEFTComms(
                    this.transaction.transactionCode,
                    this.sendCommsForm.get('message').value,
                )
                .subscribe(
                    (res) => {
                        if (res.success) {
                            this.resetFormGroup(this.sendCommsForm);
                            this.isLoading = false;
                            this.closeSideViewModal();
                            this.closeModel(true);

                            setTimeout(() => { }, 1000);
                            this.displaySuccess = true;
                        } else {
                            this.display = true;
                            this.isLoading = false;
                            if (res.status) {
                                this.eMessage = res.status.message;
                            }
                        }
                    },
                    (error) => {
                        this.isLoading = false;
                        this.display = true;
                        this.eMessage = error.error;
                        this.resetFormGroup(this.sendCommsForm);
                    },
                );
        } else {
            FormUtils.validateAllFormFields(this.sendCommsForm);
        }
    }

    resetFormGroup(form: FormGroup) {
        if (form) {
            form.reset();
        }
    }

    processCashInTypeTransaction() {
        this.processCashInForm.get('status').setValue(TransactionStatus.success);
        this.processCashInForm.get('shouldUpdateAmount').setValue(this.shouldUpdateAmount);

        if (this.processCashInForm.invalid) {
            FormUtils.validateAllFormFields(this.processCashInForm);
            return false;
        }

        // TODO: Show loader inside the Modal and only dismiss
        // this modal when the update is successful
        this.modalService.close(this.modalConstants.bankTransferReceiveModalId);

        const date = this.datePipe.transform(
            this.processCashInForm.get('dateReceivedInBank').value,
            'yyyy-MM-dd',
        );
        this.processCashInForm.get('dateReceivedInBank').setValue(date);
        this.processCashInForm.get('transactionCode').setValue(this.transaction.transactionCode);

        this.isLoading = true;

        this.transactionService.updateBankTransfer(this.processCashInForm.value).subscribe(
            (res) => {
                if (res.success) {
                    this.resetFormGroup(this.processCashInForm);
                    this.isLoading = false;
                    this.closeSideViewModal();
                    this.getData();
                    this.toasterService.success(
                        MessageConstant.TRANSACTION_UPDATE_SUCCESS,
                        'Notification',
                    );
                    setTimeout(() => { }, 1000);
                } else {
                    this.display = true;
                    this.isLoading = false;
                    if (res.status) {
                        this.eMessage = res.status.message;
                    }
                }
            },
            (error) => {
                this.isLoading = false;
                this.display = true;
                this.eMessage = error.error;
                this.resetFormGroup(this.processCashInForm);
            },
        );
    }

    requestTransactionUpdate() {
        if (this.updateTransactionForm.invalid) {
            FormUtils.validateAllFormFields(this.updateTransactionForm);
            return false;
        }

        // TODO: Show loader inside the Modal and only dismiss
        // this modal when the update is successful

        this.updateTransactionForm
            .get('transactionCode')
            .setValue(this.transaction.transactionCode);

        this.isLoading = true;
        this.toasterService.clear();

        if (this.cancellingTransaction) {
            this.transactionService
                .updateTransactionStatus(this.updateTransactionForm.value)
                .subscribe(
                    (res) => {
                        if (res.success) {
                            this.processSucessfulTxnUpdate();
                        } else {
                            this.processFailedTxnUpdate(res);
                        }
                    },
                    () => {
                        this.isLoading = false;
                    },
                );
        } else {
            this.transactionService
                .updateTransactionAmount(this.updateTransactionForm.value)
                .subscribe(
                    (res) => {
                        if (res.success) {
                            this.processSucessfulTxnUpdate();
                        } else {
                            this.processFailedTxnUpdate(res);
                        }
                    },
                    () => {
                        this.isLoading = false;
                    },
                );
        }
    }

    private processSucessfulTxnUpdate() {
        this.modalService.close(this.modalConstants.bankTransferReceiveModalId);
        this.resetFormGroup(this.updateTransactionForm);
        this.closeSideViewModal();
        this.isLoading = false;
        this.getData();
        this.toasterService.success(MessageConstant.TRANSACTION_UPDATE_SUCCESS, 'Notification');
    }

    private processFailedTxnUpdate(res: any) {
        this.isLoading = false;
        this.eMessage = 'Failed to update transaction';
        if (res.status) {
            this.eMessage = res.status.message;
        } else {
            this.eMessage = res.errors.join('\n');
        }
        this.toasterService.error(this.eMessage, 'Notification', {
            disableTimeOut: true,
            tapToDismiss: true,
            closeButton: true,
        });
    }
}
