import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-error-model',
    templateUrl: './error-model.component.html',
    styleUrls: ['./error-model.component.css'],
})
export class ErrorModelComponent {
    @Input() message: string;
    @Input() display: boolean;
    @Output() event = new EventEmitter<boolean>();

    constructor() {}

    closeModel() {
        this.event.emit(false);
    }
}
