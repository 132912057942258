import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';

import { Browser } from '@syncfusion/ej2-base';
import { DashboardLayoutComponent } from '@syncfusion/ej2-angular-layouts';
import { ChartComponent, AccumulationTheme } from '@syncfusion/ej2-angular-charts';
import { AnimationModel } from '@syncfusion/ej2-progressbar';

import { CompanyService } from 'src/app/services/company.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { LoginService } from 'src/app/services/login.service';
import { CommonService } from 'src/app/services/common.service';
import { LoaderService } from 'src/app/services/loader.service';
import {
    getFirstDayOfCurrentWeek,
    getLastDayOfCurrentWeek,
    transformDateToApiFormat,
} from 'src/app/utils/date-util';

@Component({
    selector: 'app-sales-dashboard',
    templateUrl: './sales-dashboard.component.html',
    styleUrls: ['./sales-dashboard.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class SalesDashboardComponent implements OnInit {
    @ViewChild('defaultLayout')
    public dashboard: DashboardLayoutComponent;

    @ViewChild('chart')
    public svdChart: ChartComponent;

    @ViewChild('chart')
    public topProductsLineChart?: ChartComponent;

    @ViewChild('piechart')
    public salesPerStorePieChart: ChartComponent;

    // General Chart settings
    public widthValue = '100%';
    public heightValue = '100%';

    // Sales Vs Date Line and Column Chart settings
    public svdPrimaryXAxis?: Object;
    public svdPrimaryYAxis?: Object;
    public svdChartData?: Object[];
    public svdTooltip?: Object;
    public lineSeriesMarker?: Object;
    public svdLegendSettings?: Object;
    public svdColumnSeriesFillColour?: string;
    public svdLineSeriesFillColour?: string;

    // PieChart settings
    public center?: Object;
    public pieChartData?: Object[];
    public pieDataLabel?: Object;
    public explode: boolean = false;
    public pieChartLegendSettings?: Object;
    public enableSmartLabel: boolean = true;
    public startAngle: number = 0;
    public endAngle: number = 360;
    public aspectRatio?: any;
    public centerTitle?: any;

    // Cumulative line chart settings (Top 5 sales)
    public topProductList?: string[] = [];
    public cumulativeLineData?: Object[] = [];
    public primaryXAxis?: Object = {};
    public primaryYAxis?: Object = {};
    public chartArea?: Object = {};
    public width: string = Browser.isDevice ? '100%' : '75%';
    public markerTypes?: Object[] = [];
    public cumulativeLineLegend?: Object = {};
    public topProductsChartTitle?: string = '';
    public topProductsTooltip?: Object;
    public topProductsTitleMapping: { [k: string]: string } = {
        '=0': 'No Items to display',
        '=1': 'Top product sold',
        other: 'Top # products sold',
    };

    // Panel settings : # TODO
    // public cellAspectRatio: number = Browser.isDevice ? 1 : 0.8;
    // //public columns: number = Browser.isDevice ? 2 : 8;
    // public columnSizeX: number = Browser.isDevice ? 1: 5;
    // public columnSizeY: number = Browser.isDevice ? 1 : 2;
    // public pieColumn: number = Browser.isDevice ? 1 : 5;
    // public pieSizeX: number = Browser.isDevice ? 1 : 3;
    // public pieSizeY: number = Browser.isDevice ? 1 : 2;
    // public splineRow: number = Browser.isDevice ? 1 : 4;
    // public splineSizeX: number = Browser.isDevice ? 2 : 8;
    // public splineSizeY: number = Browser.isDevice ? 1 : 3;

    // Dashboard settings
    public cellSpacing?: number[];
    public columns?: string = '';
    public allowDragging: boolean = false;

    // Progress bar settings
    public animation?: AnimationModel;
    public isIndeterminate?: boolean;
    public progressBarType?: string;
    public progressBarHeight?: string;
    public progressBarValue?: number;
    public progressColor?: string;

    // Filters setup
    productList: string[] = [];
    storeList: string[] = [];
    enterpriseList: string[] = [];
    businessUnitList: string[] = [];
    selectedProduct?: number;
    selectedStore?: number;
    selectedEnterprises?: Object;
    form = this.formBuilder.group({
        productIds: new FormControl([]),
        userRoleIds: new FormControl([]),
        enterpriseIds: new FormControl([]),
        businessUnitIds: new FormControl([]),
        fromDate: new FormControl(''),
        toDate: new FormControl(''),
        fromDateFilter: new FormControl(''),
        toDateFilter: new FormControl(''),
    });
    showAdvancedFilters = false;
    enterpriseFieldKey = 'enterpriseIds';
    productFieldKey = 'productIds';
    userRoleFieldKey = 'userRoleIds';
    businessFieldKey = 'businessUnitIds';
    searchFieldsList = [
        this.enterpriseFieldKey,
        this.productFieldKey,
        this.userRoleFieldKey,
        this.businessFieldKey,
    ];
    events: Event[] = [];
    searchItemsObj?: { [key: string]: string[] } = {};
    searchItems?: { [key: string]: string[] } = {};

    constructor(
        public companyService: CompanyService,
        private dashboardService: DashboardService,
        public loginService: LoginService,
        private formBuilder: FormBuilder,
        private commonService: CommonService,
        public loaderService: LoaderService,
    ) {
        this.setupProgressBar();
    }

    ngOnInit(): void {
        this.getFilters();
        this.setupDashboard();
        this.getData(true);
    }

    setupProgressBar() {
        this.animation = { enable: true, duration: 2000, delay: 0 };
        this.isIndeterminate = true;
        this.progressBarType = 'Circular';
        this.progressBarHeight = '60';
        this.progressBarValue = 20;
        this.progressColor = '#ee8031';
    }

    submit() {
        // Pass componentInit:false to this.getData(). We want to refresh chart series
        this.getData(false);
    }

    getData(componentInit = true) {
        this.isLoading(true);
        this.dashboardService.getSalesData(this.getDashboardParams()).subscribe(
            (res) => {
                this.svdChartData = this.getSalesVsDate(res?.responseBody?.data['salesVsDate']);
                this.pieChartData = res?.responseBody?.data['salesPerStore'];
                this.cumulativeLineData = this.getTop5Sales(
                    res?.responseBody?.data['topSalesInCurrentWeek'],
                );
                this.topProductList = res?.responseBody?.data['topProductsInCurrentWeek'];

                this.isLoading(false);
                // If this method (this.getData(true)) is called from within ngOnInit
                // setup charts other refresh the graphs as they are already initialized.
                // Only refreshing TopProducts Line Chart through adding series.
                if (componentInit) {
                    this.setupSvdChart();
                    this.setupPieChart();
                    this.setupCumulativeLineChart();
                } else {
                    // For some reason, the top products line chart is not refreshing. Clear series and add new ones
                    this.refreshCumulativeLine();
                }

                for (const [key, value] of Object.entries(this.searchItemsObj)) {
                    if (this.form.get(key).value?.length > 0) {
                        this.searchItems[key] = this.searchItemsObj[key];
                    }
                }
            },
            (err) => {
                this.isLoading(false);
                console.log('Unable to fetch data');
            },
        );
    }

    isLoading(value: boolean) {
        this.loaderService.isLoading = value;
    }

    onChange(event, key) {
        switch (key) {
            case this.enterpriseFieldKey:
                this.searchItemsObj[this.enterpriseFieldKey] = event.map((element) => {
                    return element.businessName;
                });
                break;
            case this.productFieldKey:
                this.searchItemsObj[this.productFieldKey] = event.map((element) => {
                    return element.name;
                });
                break;
            case this.userRoleFieldKey:
                this.searchItemsObj[this.userRoleFieldKey] = event.map((element) => {
                    return element.name;
                });
                break;
            case this.businessFieldKey:
                this.searchItemsObj[this.businessFieldKey] = event.map((element) => {
                    return element[this.getBusinessFilterName()];
                });
                break;
        }
    }

    removeSearchItem(key: string, value: string) {
        switch (key) {
            case this.enterpriseFieldKey:
                this.doRemove(
                    this.enterpriseList,
                    value,
                    this.enterpriseFieldKey,
                    'businessName',
                    'id',
                );
                break;
            case this.productFieldKey:
                this.doRemove(this.productList, value, this.productFieldKey, 'name', 'id');
                break;
            case this.userRoleFieldKey:
                this.doRemove(this.storeList, value, this.userRoleFieldKey, 'name', 'userRoleId');
                break;
            case this.businessFieldKey:
                this.doRemove(
                    this.businessUnitList,
                    value,
                    this.businessFieldKey,
                    this.getBusinessFilterName(),
                    'id',
                );
                break;
        }
    }

    doRemove(
        dropDownList: any,
        filterValue: string,
        fieldKey: string,
        fieldName: string,
        formValueFilter: string,
    ): void {
        this.searchItemsObj[fieldKey] = this.searchItemsObj[fieldKey].filter(
            (item) => item !== filterValue,
        );

        const newFormValues = dropDownList?.reduce((arr, item: any) => {
            if (this.searchItemsObj[fieldKey].includes(item[fieldName])) {
                arr.push(item[formValueFilter]);
            }
            return arr;
        }, []);

        this.form.get(fieldKey).patchValue(newFormValues);

        if (this.searchItemsObj[fieldKey]?.length < 1) {
            delete this.searchItemsObj[fieldKey];
            delete this.searchItems[fieldKey];
        }

        this.getData(false);
    }

    onClear(key) {
        this.doRemove([], '', key, '', '');
    }

    getBusinessFilterName(): string {
        return this.loginService.isAdmin() ? 'displayName' : 'name';
    }

    refreshCumulativeLine() {
        if (this.topProductsLineChart) {
            this.topProductsLineChart.clearSeries();

            this.topProductList.forEach((product, i) => {
                this.topProductsLineChart.addSeries([
                    {
                        type: 'Line',
                        dataSource: this.cumulativeLineData,
                        xName: 'date',
                        yName: String(product),
                        name: String(product),
                        width: 2,
                        marker: this.markerTypes[i],
                        opacity: 1,
                    },
                ]);
            });
        }
    }

    getSalesVsDate(salesData: any) {
        const pointColours = this.getSvdPointColours(salesData);

        return salesData?.reduce((salesVsDate: any, value: any, index: number) => {
            salesVsDate.push({
                sales: value.sales,
                date: new Date(value.date),
                pointColorMapping: pointColours[index],
            });
            return salesVsDate;
        }, []);
    }

    // returns an array of random hex colours
    // @return = ['#3wdr32', '#54sder']
    getSvdPointColours(salesData: any) {
        // Generate a random colour for sales vs date chart
        const generateColour = (): string => {
            let result = '';
            for (let i = 0; i < 6; ++i) {
                const value = Math.floor(16 * Math.random());
                result += value.toString(16);
            }
            return '#' + result;
        };

        return salesData?.reduce((colourMap: any, value: any, index: number) => {
            colourMap.push(generateColour());
            return colourMap;
        }, []);
    }

    getTop5Sales(topSales: any) {
        return topSales?.reduce((topProductList: any, value: any) => {
            const top5SalesObj: { [key: string]: any } = { date: new Date(value.date) };
            const { ...newObject } = { ...value, ...top5SalesObj };
            topProductList.push(newObject);
            return topProductList;
        }, []);
    }

    getFilters() {
        this.commonService.getSalesDashboardFilters(this.getBaseParams()).subscribe(
            (res) => {
                this.productList = res?.responseBody?.data['products'];
                this.storeList = res?.responseBody?.data['stores'];
                this.enterpriseList = res?.responseBody?.data['enterprises'];
                this.businessUnitList = res?.responseBody?.data['enterpriseBusinessUnits'];
            },
            (err) => {
                console.log('Unable to fetch data');
            },
        );
    }

    getBaseParams() {
        const params: { [key: string]: string } = { type: 'buy' };

        if (this.loginService.isEnterprise()) {
            params.enterpriseIds = this.loginService.sessionUser.value.enterprise.enterpriseId;
        }

        return params;
    }

    getDashboardParams() {
        this.processDates();

        const { toDateFilter, fromDateFilter, ...queryParams } = {
            ...this.form.value,
            ...this.getBaseParams(),
        };

        return queryParams;
    }

    processDates() {
        const fromDate = this.form.get('fromDateFilter').value;
        const toDate = this.form.get('toDateFilter').value;

        if (fromDate !== '') this.form.get('fromDate').setValue(transformDateToApiFormat(fromDate));
        if (toDate !== '') this.form.get('toDate').setValue(transformDateToApiFormat(toDate));
    }

    clearFilters() {
        this.form.get('fromDate').setValue('');
        this.form.get('toDate').setValue('');
        this.form.get('fromDateFilter').setValue('');
        this.form.get('toDateFilter').setValue('');
        this.form.get(this.enterpriseFieldKey).setValue([]);
        this.form.get(this.productFieldKey).setValue([]);
        this.form.get(this.userRoleFieldKey).setValue([]);
        this.form.get(this.businessFieldKey).setValue([]);
        this.searchItems = {};
        this.searchItemsObj = {};
        this.getData(false);
    }

    setupDashboard() {
        this.cellSpacing = [10, 10];
        this.columns = '3';
    }

    setupCumulativeLineChart() {
        this.topProductsTooltip = { enable: true };
        this.chartArea = {
            border: {
                width: 0,
            },
        };
        this.cumulativeLineLegend = {
            visible: true,
            //enableHighlight: true,   // Was throwing an error. Disable temporarily
        };
        this.primaryXAxis = {
            valueType: 'DateTime',
            intervalType: 'Days',
            majorGridLines: { width: 0 },
            labelFormat: 'd-MMM',
            title: 'Date',
            rangePadding: 'Round',
            minimum: getFirstDayOfCurrentWeek(),
            maximum: getLastDayOfCurrentWeek(),
        };

        // Set a range for this series of there is no data from the API.
        // if (this.cumulativeLineData.length < 1) {
        //     var dateRangeData: Object = {};
        //     dateRangeData = {
        //         minimum: getFirstDayOfCurrentWeek(),
        //         maximum: getLastDayOfCurrentWeek()
        //     };
        //     this.primaryXAxis = { ...this.primaryXAxis, ...dateRangeData };
        // }

        this.primaryYAxis = {
            title: 'Sales (R)',
            lineStyle: { width: 0 },
            majorTickLines: { width: 0 },
            labelFormat: 'R{value}',
            valueType: 'Double',
            rangePadding: 'Auto',
        };
        this.markerTypes = [
            { visible: true, height: 7, width: 7, shape: 'Circle', isFilled: true },
            { visible: true, height: 6, width: 6, shape: 'Triangle', isFilled: true },
            { visible: true, height: 7, width: 7, shape: 'Diamond', isFilled: true },
            { visible: true, height: 5, width: 5, shape: 'Rectangle', isFilled: true },
            { visible: true, height: 7, width: 7, shape: 'Pentagon', isFilled: true },
        ];
    }

    setupSvdChart(): void {
        this.svdColumnSeriesFillColour = '#de2b6a';
        this.svdLineSeriesFillColour = '#22241a';
        this.svdTooltip = { enable: true };
        this.svdLegendSettings = { visible: false };
        this.lineSeriesMarker = {
            visible: true,
            width: 7,
            height: 7,
            shape: 'Circle',
            isFilled: true,
        };
        this.svdPrimaryXAxis = {
            title: 'Date',
            valueType: 'DateTimeCategory',
            labelFormat: 'd-MMM',
            majorGridLines: { width: 0 },
            intervalType: 'Days',
            edgeLabelPlacement: 'Shift',
            labelIntersectAction: Browser.isDevice ? 'None' : 'Trim',
            labelRotation: Browser.isDevice ? -45 : 0,
            majorTickLines: { width: 0 },
            minorTickLines: { width: 0 },
        };
        this.svdPrimaryYAxis = {
            title: 'Sales (R)',
            labelFormat: 'R{value}',
            lineStyle: { width: 0 },
            majorTickLines: { width: 0 },
            minorTickLines: { width: 0 },
            valueType: 'Double',
            rangePadding: 'Auto',
        };
    }

    setupPieChart(): void {
        this.center = { x: '50%', y: '50%' };
        this.pieChartLegendSettings = { visible: true };
        this.enableSmartLabel = true;
        this.aspectRatio = 100 / 85;
        this.pieDataLabel = {
            connectorStyle: { length: '30px', type: 'Curve' },
            visible: true,
            position: 'Outside',
            name: 'store',
            font: { fontWeight: '600', fontFamily: 'Roboto' },
            textWrap: 'Wrap',
            maxWidth: 100,
            template:
                "<div id='dataLabelTemplate'>${point.x} (R${point.y})<br>" +
                "<div class='text-center'>${point.percentage}%</div></div>",
        };
    }

    chartLoad(args: any): void {
        args.chart.theme = <any>(
            (this.getTheme().charAt(0).toUpperCase() + this.getTheme().slice(1))
                .replace(/-dark/i, 'Dark')
                .replace(/contrast/i, 'Contrast')
        );
    }

    pieChartLoad(args: any): void {
        args.accumulation.theme = <AccumulationTheme>(
            (this.getTheme().charAt(0).toUpperCase() + this.getTheme().slice(1)).replace(
                /-dark/i,
                'Dark',
            )
        );
    }

    getTheme() {
        const selectedTheme: string = location.hash.split('/')[1];
        return selectedTheme ? selectedTheme : 'Material';
    }

    get controls(): any {
        return this.form.controls;
    }

    toggleOption() {
        this.showAdvancedFilters = !this.showAdvancedFilters;
    }
}
