import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Company } from '../model/company';
import { StorageConstant } from '../settings/storage-constants';
import { GenericResponse } from '../model/generic.response';
import { AppSettings } from '../settings/app-settings';
import { MessageConstant } from '../settings/message.constant';
import { Company as CompanyEnum } from 'src/app/enums/company';
import { CountryCode } from 'src/app/enums/country.code';
import { CompanyService } from './company.service';

@Injectable({
    providedIn: 'root',
})
export class CountryService {
    countryCode = CountryCode;

    constructor(
        private http: HttpClient,
        private companyService: CompanyService,
    ) {}

    public getCountryCodeByCompany() {
        if (this.companyService.lovcashCompanyActive()) {
            return this.countryCode.south_africa;
        } else {
            return this.countryCode.zimbabwe;
        }
    }
}
