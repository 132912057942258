import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/model/user';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute } from '@angular/router';
import { Personal } from 'src/app/model/personal';
import { Agent } from 'src/app/model/agent';
import { Merchant } from 'src/app/model/merchant';
import { UserAddress } from 'src/app/model/user.address';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { City } from 'src/app/model/city';
import { TransactionService } from 'src/app/services/transaction.service';
import { Fees } from 'src/app/model/fees';
import { Role } from 'src/app/enums/role';
import { StorageService } from 'src/app/services/storage.service';
import { AppConstants } from 'src/app/settings/app-constants';
import { CommonUtil } from 'src/app/services/common.util';
import { FeeCode } from 'src/app/enums/fee.code';
import { LoginService } from 'src/app/services/login.service';
import { MessageConstant } from 'src/app/settings/message.constant';
import { CompanyService } from 'src/app/services/company.service';
import { AlertService } from 'src/app/services/alert.service';

@Component({
    selector: 'app-user-profile-settings',
    templateUrl: './user-profile-settings.component.html',
    styleUrls: ['./user-profile-settings.component.css'],
})
export class UserProfileSettingsComponent implements OnInit {
    TIMEOUT_AFTER_ALERT = 1500;
    iconInfoMsg = MessageConstant.SUPPLIER_DIRECT_ICON_MSG;
    feeCode = FeeCode;
    amountFormat = AppConstants.AMOUNT_FORMAT;
    display = false;
    displaySuccess = false;
    isVisible = false;
    isSupplierDirect = false;
    isLoading = false;
    user: User = new User();
    eMessage: string;
    sMessage: string;
    id: string;
    form: FormGroup;
    editPersonal = false;
    editBusiness = false;
    editAgent = false;
    statusDropdown: string[] = [];
    province: any[] = [];
    provinceDropdown: string[] = [];
    cityDropdown: string[] = [];
    cityList: City[] = [];
    accountTypeDropdown: string[] = [];
    invalidForm = false;
    businessFeeList: Array<Fees> = [];
    agentFeeList: Array<Fees> = [];
    feeLabel = this.commonUtil.getFeeLabels();
    alertOptions = {
        autoClose: true,
        keepAfterRouteChange: true,
    };
    userLinkedToSafe = false;

    constructor(
        private commonService: CommonService,
        private route: ActivatedRoute,
        private formBuilder: UntypedFormBuilder,
        private transactionService: TransactionService,
        private storageService: StorageService,
        public companyService: CompanyService,
        public loginService: LoginService,
        public commonUtil: CommonUtil,
        private alertService: AlertService,
    ) {
        this.form = this.formBuilder.group({
            personalStatus: [this.user.status || ''],
            businessStatus: [this.user.userBusinessProfileModel.businessStatus || ''],
            agentStatus: [this.user.userAgentProfileModel.agentAccountStatus || ''],
            visibleInPayList: [this.user.userBusinessProfileModel.visibleInPayList || ''],
            supplierDirect: [this.user.userBusinessProfileModel.supplierDirect || ''],
            agentRole: [this.user.userAgentProfileModel.agentRole || ''],
            agentOperationalLocation: [this.user.userAgentProfileModel.operationalLocation || ''],
            agentAddress: this.formBuilder.group({
                type: ['home'],
                addressLineOne: [this.user.userAgentProfileModel.address.addressLineOne || ''],
                addressLineTwo: [this.user.userAgentProfileModel.address.addressLineTwo || ''],
                buildingDetails: [this.user.userAgentProfileModel.address.buildingDetails || ''],
                provinceName: [this.user.userAgentProfileModel.address.provinceName || ''],
                cityName: [this.user.userAgentProfileModel.address.cityName || ''],
                cityId: [this.user.userAgentProfileModel.address.cityId || ''],
                suburb: [this.user.userAgentProfileModel.address.suburb || ''],
                postalCode: [this.user.userAgentProfileModel.address.postalCode || ''],
            }),
        });

        this.route.paramMap.subscribe((params) => {
            this.id = params.get('id');
            this.getData(false);
        });

        this.commonService.getUserStatus().subscribe((res) => {
            this.statusDropdown = res.data;
        });

        this.commonService.getProvinces().subscribe(
            (res) => {
                this.province = res;
                this.provinceDropdown = [];
                this.province.forEach((val) => {
                    this.provinceDropdown.push(val.province);
                });
            },
            (err) => {
                this.isLoading = false;
            },
        );
    }

    ngOnInit() {
        this.createForm();
        this.form.get('agentAddress.provinceName').valueChanges.subscribe((newVal) => {
            if (newVal) {
                this.form.get('agentAddress.cityName').setValue('');

                this.commonService.getCities(this.getProvinceId(newVal)).subscribe((res) => {
                    this.cityList = res.responseBody.data || [];
                    this.createCityDropDown();
                });
            }
        });

        this.form.get('agentAddress.cityName').valueChanges.subscribe((newVal) => {
            const id = this.getCityId(newVal);
            this.form.get('agentAddress.cityId').setValue(id);
        });
    }

    getData(reload: boolean) {
        window.scroll(0, 0);
        this.isLoading = true;

        if (
            !reload &&
            !!this.storageService.getProfileUser() &&
            this.storageService.getProfileUser().id == this.id
        ) {
            this.user = this.storageService.getProfileUser();
            this.initializeUser();
            this.isLoading = false;
            this.createForm();
            this.getFeeList(Role.business);
            this.getFeeList(Role.agent);
        } else {
            this.commonService.getUserProfile(this.id).subscribe(
                (res) => {
                    this.user = res.data;
                    this.initializeUser();
                    this.storageService.setProfileUser(this.user);
                    this.createForm();
                    this.getFeeList(Role.business);
                    this.getFeeList(Role.agent);
                    this.isLoading = false;
                },
                (err) => {
                    this.isLoading = false;
                },
            );
        }

        this.editPersonal = false;
        this.editBusiness = false;
        this.editAgent = false;
    }

    getFeeList(role: string) {
        let userRoleId = '0';
        if (role == Role.business) {
            userRoleId = this.user.userBusinessProfileModel.userRoleId;
        } else {
            userRoleId = this.user.userAgentProfileModel.userRoleId;
        }

        if (userRoleId) {
            this.transactionService.getCustomFee(userRoleId, role).subscribe(
                (res) => {
                    if (res.data.length > 0) {
                        if (role == Role.business) {
                            this.businessFeeList = [];
                        } else {
                            this.agentFeeList = [];
                        }
                        this.setFee(res.data || []);
                    }
                },
                (err) => console.log(err),
            );
        }
    }

    setFee(feeList: Fees[]) {
        feeList.forEach((fee) => {
            fee.percentage = fee.percentage || 0.0;
            fee.amount = fee.amount || 0.0;
            fee.useAmount = fee.useAmount || false;
            fee.percentageValue = fee.percentage || 0.0;
            fee.amountValue = fee.amount || 0.0;
            fee.useAmountValue = fee.useAmount || false;

            if (fee.code.includes(Role.business)) {
                this.businessFeeList.push(fee);
            } else if (fee.code.includes(Role.agent)) {
                this.agentFeeList.push(fee);
            }
        });
    }

    hasSafe() {
        this.userLinkedToSafe =
            this.user.userAgentProfileModel != null &&
            this.user.userAgentProfileModel.roleId != null &&
            this.user.userAgentProfileModel.hasSafe;
    }

    initializeUser() {
        if (this.user.userPersonalProfileModel === null) {
            this.user.userPersonalProfileModel = new Personal();
        }
        if (this.user.userBusinessProfileModel === null) {
            this.user.userBusinessProfileModel = new Merchant();
        } else {
            this.isVisible = this.user.userBusinessProfileModel.visibleInPayList;
            this.isSupplierDirect = this.user.userBusinessProfileModel.supplierDirect;
        }
        if (this.user.userAgentProfileModel === null) {
            this.user.userAgentProfileModel = new Agent();
            if (this.user.userAgentProfileModel.address === null) {
                this.user.userAgentProfileModel.address = new UserAddress();
            }
        } else {
            if (this.user.userAgentProfileModel.address.provinceName) {
                // this.commonService.getCity(this.user.userAgentProfileModel.address.provinceName).subscribe(res => {
                //     this.cityList = res.data;
                //     this.createCityDropDown();
                // });
            }
        }
        this.user.lastLogin = this.user.userPersonalProfileModel.lastLogin;
    }

    closeModel(event: any) {
        this.display = false;
        this.displaySuccess = false;
    }

    changeStatus() {
        this.isVisible = !this.isVisible;
        this.form.controls.visibleInPayList.setValue(this.isVisible);
    }

    // Change status of supplier direct
    changeStatusSD() {
        this.isSupplierDirect = !this.isSupplierDirect;
        this.form.controls.supplierDirect.setValue(this.isSupplierDirect);
    }

    getProvinceId(name: string): string {
        const val = this.province.filter((x) => x.province == name)[0];
        let id = '';

        if (val) {
            id = val.id;
        }
        return id;
    }

    getCityId(val: string) {
        if (this.cityList) {
            for (const city of this.cityList) {
                if (city.city === val) {
                    return city.id;
                }
            }
        }
        return -1;
    }

    createCityDropDown() {
        this.cityDropdown = [];
        if (this.cityList) {
            for (const city of this.cityList) {
                this.cityDropdown.push(city.city);
            }
        }
    }

    createForm() {
        this.form.get('personalStatus').setValue(this.user.status);
        this.form.get('businessStatus').setValue(this.user.userBusinessProfileModel.businessStatus);
        this.form
            .get('visibleInPayList')
            .setValue(this.user.userBusinessProfileModel.visibleInPayList);
        this.form.get('supplierDirect').setValue(this.user.userBusinessProfileModel.supplierDirect);
        this.form.get('agentStatus').setValue(this.user.userAgentProfileModel.agentAccountStatus);
        this.form.get('agentRole').setValue(this.user.userAgentProfileModel.agentRole);
        this.form
            .get('agentOperationalLocation')
            .setValue(this.user.userAgentProfileModel.operationalLocation);
        this.form
            .get('agentAddress.addressLineOne')
            .setValue(this.user.userAgentProfileModel.address.addressLineOne);
        this.form
            .get('agentAddress.addressLineTwo')
            .setValue(this.user.userAgentProfileModel.address.addressLineTwo);
        this.form
            .get('agentAddress.buildingDetails')
            .setValue(this.user.userAgentProfileModel.address.buildingDetails);
        this.form
            .get('agentAddress.provinceName')
            .setValue(this.user.userAgentProfileModel.address.provinceName);
        this.form
            .get('agentAddress.cityName')
            .setValue(this.user.userAgentProfileModel.address.cityName);
        this.form
            .get('agentAddress.cityId')
            .setValue(this.user.userAgentProfileModel.address.cityId);
        this.form
            .get('agentAddress.suburb')
            .setValue(this.user.userAgentProfileModel.address.suburb);
        this.form
            .get('agentAddress.postalCode')
            .setValue(this.user.userAgentProfileModel.address.postalCode);
    }

    changeView(event: any, type: string) {
        switch (type) {
            case 'personal':
                this.editPersonal = !this.editPersonal;
                break;
            case 'business':
                this.hasSafe();
                this.editBusiness = !this.editBusiness;
                this.resetVal(this.businessFeeList);
                break;
            case 'agent':
                this.editAgent = !this.editAgent;
                this.resetVal(this.agentFeeList);
                break;
            default:
                break;
        }
    }

    resetVal(feeList: Fees[]) {
        feeList.forEach((fee) => {
            fee.percentage = fee.percentage || 0.0;
            fee.amount = fee.amount || 0.0;
            fee.useAmount = fee.useAmount || false;
            fee.percentageValue = fee.percentage || 0.0;
            fee.amountValue = fee.amount || 0.0;
            fee.useAmountValue = fee.useAmount || false;
        });
    }

    submit(type: string) {
        this.isLoading = true;

        switch (type) {
            case 'personal':
                this.commonService
                    .changeUserProfileSettings(
                        this.user.id,
                        this.user.userPersonalProfileModel.roleId,
                        this.form.value,
                    )
                    .subscribe(
                        (res) => {
                            this.onSettingsUpdate({ response: res });
                        },
                        (err) => (this.isLoading = false),
                    );
                break;
            case 'business':
                this.sendData(Role.business);
                this.commonService
                    .changeUserProfileSettings(
                        this.user.id,
                        this.user.userBusinessProfileModel.roleId,
                        this.form.value,
                    )
                    .subscribe(
                        (res) => {
                            this.onSettingsUpdate({ response: res });
                        },
                        (err) => (this.isLoading = false),
                    );
                break;
            case 'agent':
                this.sendData(Role.agent);
                this.commonService
                    .changeUserProfileSettings(
                        this.user.id,
                        this.user.userAgentProfileModel.roleId,
                        this.form.value,
                    )
                    .subscribe(
                        (res) => {
                            this.onSettingsUpdate({ response: res });
                        },
                        (err) => (this.isLoading = false),
                    );
                break;
            default:
                break;
        }
    }

    sendData(role: string) {
        const form = [];

        if (Role.business == role) {
            this.businessFeeList.forEach((fee) => {
                const value = {
                    code: fee.code,
                    percentage: fee.percentageValue,
                    amount: fee.amountValue,
                    useAmount: fee.useAmountValue,
                    userRoleId: this.user.userBusinessProfileModel.userRoleId,
                };
                form.push(value);
            });
        } else {
            this.agentFeeList.forEach((fee) => {
                const value = {
                    code: fee.code,
                    percentage: fee.percentageValue,
                    amount: fee.amountValue,
                    useAmount: fee.useAmountValue,
                    userRoleId: this.user.userAgentProfileModel.userRoleId,
                };
                form.push(value);
            });
        }

        this.transactionService.updateCustomFees(form).subscribe(
            (res) => {
                this.isLoading = false;

                if (res.success) {
                    if (role == Role.business) {
                        this.businessFeeList = [];
                    } else {
                        this.agentFeeList = [];
                    }
                    this.setFee(res.data || []);
                } else {
                    this.display = true;
                    this.eMessage = res.status.message;
                }
            },
            (err) => {
                this.isLoading = false;
            },
        );
    }

    resendLink() {
        this.isLoading = true;
        this.loginService.resendCreatePasswordLink(this.user.code).subscribe(
            (response) => {
                this.isLoading = false;
                if (response.success) {
                    this.sMessage = MessageConstant.RESEND_CREATE_PASSWORD_LINK_SUCCESS;
                    this.displaySuccess = true;
                } else {
                    this.eMessage = 'Unable to resend e-mail, Please try again later';
                    this.display = true;
                }
            },
            (error) => {
                this.eMessage = 'Service unavailable, Please try again later.';
                this.isLoading = false;
                this.display = true;
            },
        );
    }

    onSettingsUpdate({ response }) {
        if (response.success) {
            this.getData(true);

            setTimeout(() => {
                if (!this.isLoading) {
                    this.alertService.success(
                        MessageConstant.USER_ADMIN_SETTINGS_UPDATE_SUCCESS,
                        this.alertOptions,
                    );
                }
            }, this.TIMEOUT_AFTER_ALERT);
        } else {
            if (response.status) {
                this.alertService.error(response.status.message, this.alertOptions);
                setTimeout(() => {}, this.TIMEOUT_AFTER_ALERT);
            } else {
                this.alertService.error(
                    'Something wenrt wrong with your update. Try again',
                    this.alertOptions,
                );
                setTimeout(() => {}, this.TIMEOUT_AFTER_ALERT);
            }
        }
    }
}
