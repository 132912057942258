import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TransactionStatus } from 'src/app/enums/transaction.status';
import { Transaction } from 'src/app/model/transaction';

@Component({
    selector: 'app-last-child-admin-ttb',
    templateUrl: './last-child-admin-ttb.component.html',
    styleUrls: ['./last-child-admin-ttb.component.css'],
})
export class LastChildAdminTtbComponent {
    @Input() transaction: Transaction;
    @Input() netcashEnabled: boolean = true;
    @Input() processed = false;
    @Output() event = new EventEmitter<{ type: string; value: Transaction }>();
    transactionStatusEnum = TransactionStatus;

    constructor() { }

    action(type: string) {
        this.event.emit({ type: type, value: this.transaction });
    }
}
