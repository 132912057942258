import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { City } from 'src/app/model/city';
import { CommonService } from 'src/app/services/common.service';
import { ModalService } from 'src/app/services/modal.service';
import { SmartSafeService } from 'src/app/services/smart-safe.service';
import { MessageConstant } from 'src/app/settings/message.constant';
import { URI } from 'src/app/settings/uri-constants';
import { FormUtils } from 'src/app/utils/form.utils';

@Component({
    selector: 'app-cit-add-company',
    templateUrl: './cit-add-company.component.html',
    styleUrls: ['./cit-add-company.component.css'],
})
export class CitAddCompanyComponent implements OnInit {
    formUtils = FormUtils;
    selectedCIT: null;
    invalidForm = false;
    updateErrors: string[] = [];
    provinceList: any[] = [];
    cityList: City[] = [];
    form = this.formBuilder.group({
        name: ['', Validators.required],
        managementPhoneNumber: ['', Validators.required],
        managementEmail: ['', Validators.required],
        contactFirstName: ['', Validators.required],
        contactLastName: ['', Validators.required],
        contactPhone: ['', Validators.required],
        contactEmail: ['', Validators.required],
        operationsLocation: this.formBuilder.group({
            type: ['citOffice', Validators.required],
            addressLineOne: ['', Validators.required],
            addressLineTwo: [''],
            buildingDetails: [''],
            province: [null, Validators.required],
            cityId: [null, Validators.required],
            suburb: ['', Validators.required],
            postalCode: ['', Validators.required],
        }),
    });

    constructor(
        private commonService: CommonService,
        private modalService: ModalService,
        private router: Router,
        private toasterService: ToastrService,
        private formBuilder: FormBuilder,
        private smartSafeService: SmartSafeService,
    ) { }

    ngOnInit() {
        this.getDropdownData();

        // if (this.selectedCIT == null) {
        //     this.createForm();
        // } else {
        //     this.editForm(this.selectedCIT);
        // }
    }

    getDropdownData() {
        const params = { allowedForShop: true };
        this.commonService.getProvinces(params).subscribe(
            (res) => {
                this.provinceList = res;
            },
            (err) => {
                console.log('Unable to fetch data');
            },
        );

        this.form.get('operationsLocation.province').valueChanges.subscribe((newVal) => {
            if (newVal) {
                this.commonService.getCities(newVal).subscribe((res) => {
                    this.cityList = res.responseBody.data || [];
                });
            }
        });
    }

    submit() {
        this.updateErrors = [];

        if (this.form.invalid) {
            FormUtils.validateAllFormFields(this.form);
            return false;
        }

        if (this.selectedCIT === null || this.selectedCIT === undefined) {
            this.smartSafeService.createCIT(this.form.value).subscribe(
                (res) => {
                    if (res.success) {
                        this.toasterService.success(MessageConstant.CIT_CREATE_SUCCESS, 'Success');
                        this.goToIndex();
                    } else {
                        window.scroll(0, 0);
                        this.updateErrors.push(res.status.message);
                    }
                },
                (error) => {
                    window.scroll(0, 0);
                    error?.error?.errors
                        ? (this.updateErrors = error?.error?.errors)
                        : this.updateErrors.push(error?.error?.status?.message);
                },
            );
        } else {
            // TODO: Implement CIT data update
        }
    }

    goToIndex() {
        this.router.navigate([URI.getRouteNavPath('cashInTransitCompany')]);
    }

    get controls(): any {
        return this.form.controls;
    }
}
