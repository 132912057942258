import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
    selector: 'app-edit-pencil',
    templateUrl: './edit-pencil.component.html',
    styleUrls: ['./edit-pencil.component.css'],
})
export class EditPencilComponent {
    @Output() edit = new EventEmitter<boolean>();
    value: boolean;
    @Input() pencilEditStyle = { float: 'right' };

    constructor() {
        this.value = false;
    }

    changeView() {
        this.value = !this.value;
        this.edit.emit(this.value);
    }
}
