import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { Pagination } from 'src/app/model/Pagination';
import { CommonService } from 'src/app/services/common.service';
import { LoginService } from 'src/app/services/login.service';
import { EnterpriseService } from 'src/app/services/enterprise.service';
import { ProductOrder } from 'src/app/model/product.order';
import { Role } from 'src/app/enums/role';
import { MessageConstant } from 'src/app/settings/message.constant';
import { AppConstants } from 'src/app/settings/app-constants';
import { FormUtils } from 'src/app/utils/form.utils';
import { CommonUtil } from 'src/app/services/common.util';
import { ModalService } from 'src/app/services/modal.service';

@Component({
    selector: 'app-product-orders-body',
    templateUrl: './product-orders-body.component.html',
    styleUrls: ['./product-orders-body.component.css'],
})
export class ProductOrdersBodyComponent implements OnInit {
    @Input() form: FormGroup;
    productOrdersList: Array<ProductOrder>;
    selectedProductOrder: ProductOrder;
    displayView = false;
    pagination = new Pagination();
    isLoading = false;
    search = false;
    invalidForm = false;
    progressWidth = 25;
    modelType: string;
    role = Role;
    searchItems: string[] = [];
    itemList = ['paymentStatus', 'fullfilmentStatus'];
    statusDropdown: string[] = [];
    showOptions = false;
    paymentStatus: string[] = [];
    fulfillmentStatus: string[] = [];
    modalId = 'productOrderFulfillModal';
    itemMapping: { [k: string]: string } = { '=0': '0 Items', '=1': '1 Item', other: '# Items' };

    // TODO: Query payment status data from backend
    paymentStatusDropDown = ['paid', 'unpaid'];
    fulfillmentStatusDropDown = { fulfilled: 'Fulfilled', unfulfilled: 'Unfulfilled' };
    fulfillmentStatusValues = Object.values(this.fulfillmentStatusDropDown);

    constructor(
        private formBuilder: UntypedFormBuilder,
        private commonService: CommonService,
        public loginService: LoginService,
        private enterpriseService: EnterpriseService,
        public commonUtil: CommonUtil,
        private toasterService: ToastrService,
        private modalService: ModalService,
    ) {
        // this.form = this.formBuilder.group({
        // 	start: [''],
        // 	length: [''],
        // 	enterpriseId: [''],
        // 	orderNumber: [''],
        // 	paymentStatus: [''],
        // 	fulfilled: [null],
        // 	fullfilmentStatus: [''],
        // });
        // this.commonService.getUserStatus().subscribe(res => {
        // 	this.statusDropdown = res.data;
        // });
    }

    ngOnInit() {
        this.getData();
    }

    getData() {
        this.search = false;
        this.isLoading = true;
        this.setFormValues();

        this.setProgressWidth(50);
        this.enterpriseService.getProductOrders(this.form).subscribe(
            (res) => {
                this.setProgressWidth(100);
                setTimeout(() => {
                    this.productOrdersList = res.responseBody.data; //this.getResponseBody(res).data;
                    this.pagination.total = res.responseBody.total; //this.getResponseBody(res).total;
                    this.isLoading = false;
                    this.search = true;
                    this.progressWidth = 25;
                }, 350);

                this.searchItems = [];
                for (const item of this.itemList) {
                    if (this.form.get(item).value !== '') {
                        this.searchItems.push(this.form.get(item).value);
                    }
                }
            },
            (error) => {
                this.isLoading = false;
            },
        );
    }

    setFormValues() {
        this.form.get('start').setValue(this.pagination.start);
        this.form.get('length').setValue(this.pagination.length);
        if (this.form.get('fullfilmentStatus').value !== '') {
            // transform fulfilled form value to a boolean
            this.form
                .get('fulfilled')
                .setValue(
                    this.form.get('fullfilmentStatus').value ===
                        this.fulfillmentStatusDropDown['fulfilled'],
                );
        } else {
            this.form.get('fulfilled').setValue(null);
        }
    }

    setProgressWidth(i: number) {
        setTimeout(() => {
            this.progressWidth = i;
        }, 300);
    }

    clearText() {
        this.form.get('orderNumber').setValue('');
        this.getData();
    }

    receiveFilter(data: { name: string; value: number }) {}

    changeLength(len: number) {
        this.pagination.length = len;
        this.pagination.start = 0;
        this.getData();
    }

    toggleOption() {
        this.showOptions = !this.showOptions;
    }

    goToUser() {
        //commonUtil.navigateToProfile()
    }

    changePage(page: number) {
        this.pagination.changePage(page);
        this.getData();
    }

    removeSearchItem(value: string) {
        for (const item of this.itemList) {
            if (this.form.get(item).value === value) {
                this.form.get(item).setValue('');
                this.getData();
                break;
            }
        }
    }

    get controls(): any {
        return this.form.controls;
    }

    closeModal() {
        this.modalService.close(this.modalId);
    }

    openModal(productOrder: ProductOrder) {
        this.selectedProductOrder = productOrder;
        this.modalService.open(this.modalId);
    }

    fulfill() {
        if (this.selectedProductOrder !== null) {
            const body = {
                amountRefunded: 0.0,
                isLovcashOrder: this.selectedProductOrder.isLovcashOrder,
            };

            this.enterpriseService
                .fullFillProductOrder(this.selectedProductOrder.id, body)
                .subscribe(
                    (res) => {
                        this.isLoading = false;
                        if (res.success) {
                            this.getData();
                            this.toasterService.success(
                                `Order ${this.selectedProductOrder.shopifyOrder.orderNumber}
	            		for ${this.selectedProductOrder.enterprise.businessName} has been fulfilled`,
                                'Order fulfillment Successful',
                            );
                        } else {
                            if (res.status) {
                                this.toasterService.error(
                                    `Error: ${res.status.message}`,
                                    'Order fulfillment failed',
                                );
                            }
                        }
                    },
                    (error) => {
                        this.isLoading = false; /*this.getBanners()*/
                    },
                );
        } else {
            this.toasterService.error('Select a product order to fulfill', 'Cannot fullfill Order');
        }
    }

    viewDetails(productOrder: ProductOrder) {
        this.selectedProductOrder = productOrder;
        this.openSideViewModal();
    }

    openSideViewModal() {
        this.displayView = true;
    }

    closeSideViewModal() {
        this.displayView = false;
    }

    submit() {
        this.getData();
    }
}
