<div class="wrapper">
    <app-navigation></app-navigation>
    <div class="content-wrapper">
        <app-content-transition></app-content-transition>
        <app-header location="User Account Profile"></app-header>
        <app-edit-links [id]="id"></app-edit-links>
        <section class="add-section">
            <form [formGroup]="form">
                <div class="row">
                    <div class="col-md-12 section-heading">Search</div>
                </div>
                <div class="row pb-3">
                    <div class="col-md-6">
                        <label class="form-label">SEARCH BY SENDER OR BUSINESS OR AMOUNT</label>
                        <div class="input-icons">
                            <i class="fa fa-search icon" aria-hidden="true"></i>
                            <input
                                class="form-input"
                                type="text"
                                placeholder="eg. Zakihiti’s Spaza"
                                formControlName="text" />
                            <a *ngIf="form.get('text').value.length > 0" (click)="clearText()">
                                <img src="assets/images/close-x-purple.png" />
                            </a>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="form-label"></label>
                        <div>
                            <button class="search-button" (click)="submit()">Search</button>
                            <a class="advanced-search" (click)="toggleOption()">
                                <img src="assets/images/dropdown.png" />
                                <u>ADVANCED SEARCH</u>
                            </a>
                        </div>
                    </div>
                </div>
                <hr *ngIf="showOptions" />
                <div class="search-form" *ngIf="showOptions">
                    <div class="row">
                        <div class="col-md-4">
                            <label class="form-label">TRANSACTION TYPE</label>
                            <div>
                                <ng-select
                                    [items]="transactionTypeDropdown"
                                    bindLabel="name"
                                    placeholder="Select Type"
                                    appendTo="body"
                                    multiple="true"
                                    formControlName="transactionTypeListTemp">
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label class="form-label">TRANSACTION STATUS</label>
                            <div>
                                <app-select-box
                                    placeholderValue="Select Status"
                                    [form]="form"
                                    value="status"
                                    [invalidForm]="invalidForm"
                                    [dropDown]="transactionStatusDropdown">
                                </app-select-box>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label class="form-label">ACCOUNT TYPE</label>
                            <div>
                                <app-select-box
                                    placeholderValue="Select Type"
                                    [form]="form"
                                    value="role"
                                    [invalidForm]="invalidForm"
                                    [dropDown]="accountTypeDropdown">
                                </app-select-box>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <label class="form-label">START DATE</label>
                            <div class="datepicker-width-85">
                                <input
                                    matInput
                                    class="datepicker-input"
                                    [matDatepicker]="picker"
                                    name="fromDate"
                                    formControlName="fromDate"
                                    readonly />
                                <mat-datepicker-toggle
                                    matSuffix
                                    [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label class="form-label">END DATE</label>
                            <div class="datepicker-width-85">
                                <input
                                    matInput
                                    class="datepicker-input"
                                    [matDatepicker]="picker1"
                                    name="toDateTemp"
                                    formControlName="toDateTemp"
                                    readonly />
                                <mat-datepicker-toggle
                                    matSuffix
                                    [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label class="form-label"></label>
                            <div>
                                <a class="p-3 text-danger line-height-3" (click)="clearDates()"
                                    ><u>Clear Dates</u></a
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="searched-items">
                    <div *ngIf="searchItems.length > 0">
                        <div *ngFor="let item of searchItems" class="search-item-value">
                            {{ item | humanize }}
                            <a (click)="removeSearchItem(item)">
                                <img src="assets/images/close-x-white.png" />
                            </a>
                        </div>
                    </div>
                </div>
            </form>
        </section>
        <div class="progress-container" *ngIf="isLoading">
            <div class="progress-container-text">
                <div>{{ loaderText }}</div>
            </div>
            <div>
                <div class="custom-progress-bar">
                    <span [style.width.%]="progressWidth"></span>
                </div>
            </div>
        </div>
        <section *ngIf="search">
            <div class="search-result-header">
                Search Results: {{ transactionList.length }} of {{ pagination.total }}
            </div>
            <hr />
            <div class="row mb-3">
                <div class="col-md-6">
                    <a (click)="downLoadExcel()" class="download-transaction">
                        <u>DOWNLOAD ALL TRANSACTIONS</u>
                    </a>
                </div>
                <div class="col-md-3"></div>
                <div class="col-md-3">
                    <form [formGroup]="form">
                        <app-select-box
                            placeholderValue="Order by (NONE)"
                            [form]="form"
                            value="orderByText"
                            [invalidForm]="invalidForm"
                            [dropDown]="orderByList">
                        </app-select-box>
                    </form>
                </div>
            </div>
            <div class="container-fluid tab-pane transaction-table">
                <br />
                <table id="tableData">
                    <app-transaction-body
                        [transactionList]="transactionList"></app-transaction-body>
                    <tfoot *ngIf="pagination.showPagination()">
                        <tr>
                            <td>
                                Rows per page:
                                <span
                                    ><b>{{ pagination.length }}</b></span
                                >
                                <app-page-length-dropdown (emitLength)="changeLength($event)">
                                </app-page-length-dropdown>
                            </td>
                            <td>
                                <app-page-navigation
                                    pageNumber="{{ pagination.getPageNo() }}"
                                    totalPages="{{ pagination.getTotalPages() }}"
                                    (changePage)="changePage($event)">
                                </app-page-navigation>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </section>
    </div>
    <div class="control-sidebar-bg"></div>
</div>
