export class UserAddress {
    public type: string;
    public addressLineOne: string;
    public addressLineTwo: string;
    public buildingDetails: string;
    public province: string;
    public provinceName: string;
    public city: string;
    public cityName: string;
    public cityId: string;
    public suburb: string;
    public postalCode: string;
    public longitude: number;
    public latitude: number;

    constructor() {}
}
