export class Base {
    public createdAt: string;
    public updatedAt: string;

    constructor() {}

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
