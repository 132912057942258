<div class="wrapper">
    <app-error-model
        message="{{ eMessage }}"
        [display]="display"
        (event)="closeModel($event)"></app-error-model>
    <app-success-model
        message="{{ sMessage }}"
        [display]="displaySuccess"
        (event)="closeModel($event)"></app-success-model>
    <app-enterprise-navigation></app-enterprise-navigation>
    <div class="content-wrapper">
        <app-content-transition></app-content-transition>
        <app-loader *ngIf="isLoading" className="custom-loader" text="Processing ..."></app-loader>
        <!-- Content Header (Page header) -->
        <section class="content-header">
            <div class="row">
                <div class="col-md-6">
                    <div class="tab-heading">
                        <span class="tab-heading-title">Cashback Settings</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 section-heading">Set spend levels for cashback rewards</div>
            </div>
        </section>

        <section>
            <form [formGroup]="form">
                <div class="container-fluid tab-pane">
                    <br />
                    <table id="tableData" class="custom-table">
                        <thead>
                            <tr class="form-table-row">
                                <th>SPEND AMOUNT FROM</th>
                                <th>SPEND AMOUNT TO</th>
                                <th>
                                    {{ companyService.getSelectedCompanyName() | uppercase }} FEE
                                </th>
                                <th>CASHBACK % TO CUSTOMER</th>
                                <th>ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container
                                formArrayName="cashbackSettings"
                                *ngFor="let control of formControls.controls; let i = index">
                                <tr
                                    class="tablebox"
                                    *ngIf="control.get('isEditable').value"
                                    class="form-table-row">
                                    <td [formGroupName]="i">
                                        <input type="text" formControlName="minAmount" />
                                    </td>
                                    <td [formGroupName]="i">
                                        <input type="text" formControlName="maxAmount" />
                                    </td>
                                    <td [formGroupName]="i">
                                        {{ control.get('lovcashFee').value }}%
                                    </td>
                                    <td [formGroupName]="i">
                                        <input
                                            type="text"
                                            formControlName="percentage"
                                            appFloatOnly
                                            [maxVal]="1000" />
                                    </td>
                                    <td>
                                        <div class="submit-icon" (click)="submit()"></div>
                                    </td>
                                </tr>

                                <tr
                                    class="tablebox"
                                    *ngIf="!control.get('isEditable').value"
                                    class="form-table-row view-form">
                                    <td>{{ control.get('minAmount').value }}</td>
                                    <td>{{ control.get('maxAmount').value }}</td>
                                    <td>{{ control.get('lovcashFee').value }}%</td>
                                    <td>{{ control.get('percentage').value }}%</td>
                                    <td>
                                        <app-last-child-enterprise-cashback-settings
                                            [cashbackSetting]="control"
                                            (event)="editCashbackSetting($event)"
                                            (deleteEvent)="deleteEvent($event)">
                                        </app-last-child-enterprise-cashback-settings>
                                    </td>
                                </tr>
                            </ng-container>
                            <tr class="tablebox" *ngIf="maxSize < 5">
                                <td>
                                    <div
                                        id="add-spend-level"
                                        (click)="initNewCashbackSetting()"
                                        (mouseover)="mouseOver()"
                                        (mouseout)="mouseOut()">
                                        <img id="add-icon" src="assets/images/add-icon-dark.png" />
                                        Add a spend level
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </form>
        </section>
        <div id="cashbackModal" class="image-modal" [style.display]="modalDisplay">
            <div class="logout-window">
                <div class="section-heading mb-1">Delete Cashback Spend Level</div>
                <div>
                    <button
                        type="submit"
                        class="box-outline submit-btn mb-2"
                        (click)="deleteCashbackSettings()">
                        Delete
                    </button>
                    <a class="deny-window-close" (click)="closeModal()"><u>CANCEL</u></a>
                </div>
            </div>
        </div>
    </div>
</div>
