import { DocumentFile } from './DocumentFile';
import { Agent } from './agent';
import { Merchant } from './merchant';
import { Personal } from './personal';

export class User {
    public id: string;
    public email: string;
    public firstName: string;
    public lastName: string;
    public mobileNumber: string;
    public profiles: string;
    public status: string;
    public staffStatus: string;
    public businessStatus: string;
    public agentStatus: string;
    public enterpriseId: string;
    public enterpriseStatus: string;
    public dateCreated: string;
    public location: string;
    public denyReason: string;
    public lastLogin: string;
    public businessName: string;
    public code: string;
    public gender: string;
    public idType: string;
    public saIdNumber: string;
    public maritalStatus: string;
    public dob: string;
    public profilePicture: string;
    public referredByName: string;
    public referredByCode: string;
    public referredBy: string;
    public referredByUserRoleId: string;
    public documentList: DocumentFile[] = [];
    public userPersonalProfileModel: Personal = new Personal();
    public userBusinessProfileModel: Merchant = new Merchant();
    public userAgentProfileModel: Agent = new Agent();
    constructor() {}

    getFullName(): string {
        return this.firstName + ' ' + this.lastName;
    }
}
