export enum TransactionStatus {
    onhold = 'onhold',
    pending = 'pending',
    'payment pending' = 'payment pending',
    failed = 'failed',
    success = 'success',
    settled = 'settled',
    processing = 'processing',
    cancelled = 'cancelled',
}
