import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-filter-arrow',
    templateUrl: './filter-arrow.html',
    styleUrls: ['./filter-arrow.css'],
})
export class FilterArrowComponent {
    @Input() title: string;
    @Input() filter: string;
    @Output() filterValue = new EventEmitter<{ name: string; value: number }>();
    iconSrc: string;
    state: number;

    constructor() {
        this.iconSrc = 'assets/images/up-selected.png';
        this.state = 0;
    }

    onMouseOver() {
        this.iconSrc = 'assets/images/up-hover.png';
    }

    onMouseOut() {
        this.iconSrc = 'assets/images/up-selected.png';
    }

    changeState() {
        this.state = (this.state + 1) % 3;
        this.filterValue.emit({ name: this.filter, value: this.state });
    }

    upArrowState() {
        if (this.state === 0) {
            return 'none';
        } else {
            return 'inherit';
        }
    }

    upArrowTransform() {
        if (this.state === 2) {
            return 'rotate(180deg)';
        } else {
            return 'unset';
        }
    }
}
