<div class="wrapper">
    <app-navigation></app-navigation>
    <div class="content-wrapper">
        <app-content-transition></app-content-transition>
        <app-loader *ngIf="isLoading" className="custom-loader" text="Processing ..."></app-loader>
        <!-- <section class="content-header">
            <div class="row">
                <div class="col-md-12">
                    <div class="tab-heading">
                        <span class="tab-heading-title">Agent Applications</span>
                        <img
                            class="breadcrumb-separator"
                            src="assets/images/breadcrumb-separator.png" />
                        <span class="breadcrumb-custom">Active Applications</span>
                        <img
                            class="breadcrumb-separator"
                            src="assets/images/breadcrumb-separator.png" />
                        <span class="breadcrumb-custom">{{ getFullName() }}&#39;s files</span>
                    </div>
                </div>
            </div>
        </section> -->
        <app-header title="Agent Applications"></app-header>
        <app-agent-application-link [display]="displayFiles"></app-agent-application-link>
        <section class="add-section">
            <div class="row">
                <div class="col-md-8 section-heading">Identity Verification Files</div>
                <div class="col-md-4 user-status-text" *ngIf="userStatus">
                    {{ userStatus.toUpperCase() | humanize }}
                    <div
                        class="circle circle-green"
                        *ngIf="userStatus === statusEnum.verified"></div>
                    <div
                        class="circle circle-red"
                        *ngIf="userStatus === statusEnum.verification_failed"></div>
                    <div
                        class="circle circle-yello"
                        *ngIf="userStatus === statusEnum.pending_verification"></div>
                </div>
            </div>
            <div class="details-view">
                <div class="row">
                    <div class="col-md-4">
                        <label class="form-label">{{ identityFileName | humanize }}</label>
                        <div class="user-files-view" *ngIf="fileIdentity">
                            <img src="assets/images/jpeg-white.png" />
                            <span>{{ fileIdentity.fileName }}</span>
                            <a (click)="openImageModal(fileIdentity)"><u>View</u></a>
                        </div>
                        <div class="user-files-view-empty" *ngIf="!fileIdentity">No File</div>
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">VERIFICATION IMAGE</label>
                        <div class="user-files-view" *ngIf="fileVerification">
                            <img src="assets/images/jpeg-white.png" />
                            <span>{{ fileVerification.fileName }}</span>
                            <a (click)="openImageModal(fileVerification)"><u>View</u></a>
                        </div>
                        <div class="user-files-view-empty" *ngIf="!fileVerification">No File</div>
                    </div>
                    <div
                        class="col-md-4 pt-3"
                        *ngIf="
                            (fileIdentity || fileVerification) &&
                            userStatus === statusEnum.pending_verification
                        ">
                        <button
                            class="custombtn custombtn-align-right"
                            (click)="openVerifyModal('identity')">
                            Verify
                        </button>
                        <a class="user-files-deny" (click)="openDenyOption('identity')"
                            ><u>DENY</u></a
                        >
                    </div>
                </div>
            </div>
        </section>
        <section class="add-section">
            <div class="row">
                <div class="col-md-8 section-heading">Residence Verification Files</div>
                <div class="col-md-4 user-status-text" *ngIf="agentStatus">
                    {{ agentStatus.toUpperCase() | humanize }}
                    <div
                        class="circle circle-green"
                        *ngIf="agentStatus === statusEnum.verified"></div>
                    <div
                        class="circle circle-red"
                        *ngIf="agentStatus === statusEnum.verification_failed"></div>
                    <div
                        class="circle circle-yello"
                        *ngIf="agentStatus === statusEnum.pending_verification"></div>
                </div>
            </div>
            <div class="details-view">
                <div class="row">
                    <div class="col-md-4">
                        <label class="form-label">Proof of Address</label>
                        <div class="user-files-view" *ngIf="fileResidence">
                            <img src="assets/images/jpeg-white.png" />
                            <span>{{ fileResidence.fileName }}</span>
                            <a (click)="openImageModal(fileResidence)"><u>View</u></a>
                        </div>
                        <div class="user-files-view-empty" *ngIf="!fileResidence">No File</div>
                    </div>
                    <div class="col-md-4"></div>
                    <div
                        class="col-md-4 pt-3"
                        *ngIf="fileResidence && agentStatus === statusEnum.pending_verification">
                        <button
                            class="custombtn custombtn-align-right"
                            (click)="openVerifyModal('residence')">
                            Verify
                        </button>
                        <a class="user-files-deny" (click)="openDenyOption('residence')"
                            ><u>DENY</u></a
                        >
                    </div>
                </div>
            </div>
        </section>
        <div id="imageModal" class="image-modal" [style.display]="verifyImageDisplay">
            <div class="document-window">
                <a (click)="closeVerifyImage()"
                    ><img class="image-close" src="assets/images/close-x-purple.png"
                /></a>
                <div class="document-window-img">
                    <img [src]="imageSrc" />
                </div>
            </div>
        </div>
        <div id="denyModal" class="image-modal" [style.display]="denyModalDisplay">
            <div class="deny-window">
                <div class="section-heading ml-5 mb-3">Reason for review failure?</div>
                <div class="deny-reason">
                    <label class="radio-container">
                        <input type="radio" name="operationType" value="0" checked />
                        <span class="radio-lable">{{ messageList[0] }}</span>
                        <span class="checkmark"></span>
                    </label>
                    <label class="radio-container">
                        <input type="radio" name="operationType" value="1" />
                        <span class="radio-lable">{{ messageList[1] }}</span>
                        <span class="checkmark"></span>
                    </label>
                    <label class="radio-container">
                        <input type="radio" name="operationType" value="2" />
                        <span class="radio-lable">{{ messageList[2] }}</span>
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div>
                    <button
                        type="submit"
                        class="box-outline submit-btn mb-2"
                        (click)="submitReason()">
                        Submit
                    </button>
                    <a class="deny-window-close" (click)="closeDenyOption()"><u>CANCEL</u></a>
                </div>
            </div>
        </div>
        <div id="verifyModal" class="image-modal" [style.display]="verifyModalDisplay">
            <div class="deny-window">
                <div class="section-heading ml-4 mb-3">Are you sure you want to Verify?</div>
                <div class="verify-reason">
                    <div class="col-md-12">
                        <label class="form-label">NAME</label>
                        <div class="user-details-text">
                            {{ user.firstName + ' ' + user.lastName || '-' }}
                        </div>
                    </div>
                    <div class="col-md-12">
                        <label class="form-label">VERIFYING</label>
                        <div class="user-details-text">
                            {{ type.toUpperCase() || '-' }}
                        </div>
                    </div>
                </div>
                <div>
                    <button type="submit" class="box-outline submit-btn mb-2" (click)="submit()">
                        Yes, Verify
                    </button>
                    <a class="deny-window-close" (click)="closeVerifyModal()"><u>CANCEL</u></a>
                </div>
            </div>
        </div>
    </div>
</div>
