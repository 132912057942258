import { Component, OnInit } from '@angular/core';
import { URI } from 'src/app/settings/uri-constants';

@Component({
    selector: 'app-transfer-to-bank-links',
    templateUrl: './transfer-to-bank-links.component.html',
    styleUrls: ['./transfer-to-bank-links.component.css'],
})
export class TransferToBankLinksComponent {
    uri = URI;

    constructor() {}
}
