import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FileStatus } from 'src/app/enums/file.status';
import { IdType } from 'src/app/enums/id.type';
import { VerificationType } from 'src/app/enums/verification.type';
import { DocumentFile } from 'src/app/model/DocumentFile';
import { Agent } from 'src/app/model/agent';
import { Merchant } from 'src/app/model/merchant';
import { Personal } from 'src/app/model/personal';
import { User } from 'src/app/model/user';
import { CommonService } from 'src/app/services/common.service';
import { StorageService } from 'src/app/services/storage.service';
import { StorageConstant } from 'src/app/settings/storage-constants';

@Component({
    selector: 'app-agent-files',
    templateUrl: './agent-files.component.html',
    styleUrls: ['./agent-files.component.css'],
})
export class AgentFilesComponent implements OnInit {
    isLoading = false;
    displayFiles = true;
    denyModalDisplay = 'none';
    verifyModalDisplay = 'none';
    verifyImageDisplay = 'none';
    fileIdentity: DocumentFile | null;
    fileVerification: DocumentFile | null;
    fileResidence: DocumentFile | null;
    identityFileName = 'COPY OF SA ID';
    imageSrc = '';
    type = 'none';
    files: DocumentFile[] = [];
    user: User = new User();
    id: string;
    messageList = ['Image quality poor', 'Incorrect documentation', 'Damaged documents'];
    userStatus = '';
    agentStatus = '';
    statusEnum = FileStatus;

    constructor(
        private commonService: CommonService,
        @Inject(DOCUMENT) private document: Document,
        private storageService: StorageService,
    ) {}

    ngOnInit() {
        this.user = JSON.parse(localStorage.getItem(StorageConstant.AGENT_FILES));
        this.id = this.user.id;
        this.setUser();
    }

    getFullName() {
        if (!this.isLoading && this.user && this.user instanceof User) {
            return this.user.getFullName();
        }
        return '';
    }

    setUser() {
        this.isLoading = true;
        this.commonService.getUserProfile(this.id).subscribe(
            (res) => {
                this.isLoading = false;
                this.user = Object.assign(new User(), res.data);
                this.files = this.user.documentList;
                this.initializeUser();
                this.setFiles();
            },
            (err) => {
                this.isLoading = false;
            },
        );
    }

    initializeUser() {
        if (this.user.userPersonalProfileModel === null) {
            this.user.userPersonalProfileModel = new Personal();
        }
        if (this.user.userBusinessProfileModel === null) {
            this.user.userBusinessProfileModel = new Merchant();
        }
        if (this.user.userAgentProfileModel === null) {
            this.user.userAgentProfileModel = new Agent();
        }
    }

    setFiles() {
        if (this.files.length > 0) {
            for (const file of this.files) {
                if (file.verificationType === VerificationType.residence) {
                    this.fileResidence = file;
                    this.agentStatus = this.fileResidence.status;
                } else if (file.verificationType === VerificationType.identity) {
                    if (file.fileType === IdType.verification_image) {
                        this.fileVerification = file;
                    } else {
                        this.fileIdentity = file;
                        this.identityFileName = 'COPY OF ' + file.fileType.toUpperCase();
                        this.userStatus = file.status;
                    }
                }
            }
        }
    }

    openDenyOption(type: string) {
        this.denyModalDisplay = 'block';
        this.type = type;
    }

    closeDenyOption() {
        this.denyModalDisplay = 'none';
    }

    openVerifyModal(type: string) {
        this.verifyModalDisplay = 'block';
        this.type = type;
    }

    closeVerifyModal() {
        this.verifyModalDisplay = 'none';
    }

    openImageModal(file: DocumentFile) {
        this.verifyImageDisplay = 'block';
        this.imageSrc = file.filePath + '/' + file.fileName;
    }

    closeVerifyImage() {
        this.verifyImageDisplay = 'none';
    }

    getVerifyObject(type: string) {
        if (type === 'identity') {
            return {
                status: FileStatus.verified,
                statusMessage: '',
                verificationType: VerificationType.identity,
            };
        } else if (type === 'business') {
            return {
                status: FileStatus.verified,
                statusMessage: '',
                verificationType: VerificationType.business,
            };
        } else {
            return {
                status: FileStatus.verified,
                statusMessage: '',
                verificationType: VerificationType.residence,
            };
        }
    }

    getDenyObject(type: string) {
        const messageId = this.document
            .querySelector('input[name="operationType"]:checked')
            .attributes.getNamedItem('value').value;

        if (type === VerificationType.identity) {
            return {
                status: FileStatus.verification_failed,
                statusMessage: this.messageList[messageId],
                verificationType: VerificationType.identity,
            };
        } else if (type === VerificationType.business) {
            return {
                status: FileStatus.verification_failed,
                statusMessage: this.messageList[messageId],
                verificationType: VerificationType.business,
            };
        } else {
            return {
                status: FileStatus.verification_failed,
                statusMessage: this.messageList[messageId],
                verificationType: VerificationType.residence,
            };
        }
    }

    submit() {
        const userId = this.user.id;
        const obj = this.getVerifyObject(this.type);

        this.closeVerifyModal();
        this.isLoading = true;

        this.commonService.updateUserDocumnets(obj, userId.toString()).subscribe(
            (res) => {
                this.setUser();
            },
            (err) => {
                this.isLoading = false;
            },
        );
    }

    submitReason() {
        const userId = this.user.id;
        const obj = this.getDenyObject(this.type);

        this.closeDenyOption();
        this.isLoading = true;

        this.commonService.updateUserDocumnets(obj, userId.toString()).subscribe(
            (res) => {
                this.setUser();
            },
            (err) => {
                this.isLoading = false;
            },
        );
    }
}
