import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Bank } from 'src/app/model/bank';
import { CommonService } from 'src/app/services/common.service';
import { EnterpriseService } from 'src/app/services/enterprise.service';
import { MessageConstant } from 'src/app/settings/message.constant';
import { StorageConstant } from 'src/app/settings/storage-constants';
import { URI } from 'src/app/settings/uri-constants';
import { FormUtils } from 'src/app/utils/form.utils';

@Component({
    selector: 'app-enterprise-user',
    templateUrl: './enterprise-user.component.html',
    styleUrls: ['./enterprise-user.component.css'],
})
export class EnterpriseUserComponent implements OnInit {
    formUtils = FormUtils;
    display = false;
    visibleInPayList = true;
    isDirectDistributor = false;
    hasOwnShopifyInstance = false;
    isWholesaler = false;
    invalidForm = false;
    isLoading = false;
    eMessage = '';
    emailExist: boolean;
    emailErrorMsg: string;
    mobileErrorMsg: string;
    mobileExist: boolean;
    kycLevelDropdown: string[] = [];
    statusDropdown: string[] = [];
    form: FormGroup;
    file: any;
    base64String: string;
    accountTypeDropdown: string[] = [];
    bankList: Bank[] = [];
    bankNameList: string[] = [];

    // Dropdown List
    provinceList: string[] = [];
    cityList: string[] = [];

    constructor(
        private formBuilder: UntypedFormBuilder,
        private enterpriseService: EnterpriseService,
        private router: Router,
        private commonService: CommonService,
        private toasterService: ToastrService,
    ) {
        this.emailExist = false;
        this.mobileExist = false;
        this.emailErrorMsg = MessageConstant.INVALID_EMAIL;
        this.mobileErrorMsg = MessageConstant.INVALID_MOBILE;

        this.commonService.getUserStatus().subscribe(
            (res) => {
                this.statusDropdown = res.data;
            },
            (err) => {
                this.isLoading = false;
            },
        );

        this.commonService.getKycLevel().subscribe(
            (res) => {
                this.kycLevelDropdown = res.data;
            },
            (err) => {
                this.isLoading = false;
            },
        );

        this.commonService.getBankAccountType().subscribe((res) => {
            this.accountTypeDropdown = res.data;
        });

        this.commonService.getBanks().subscribe((res) => {
            for (const bank of res) {
                this.bankNameList.push(bank.bankName);
            }
            this.bankList = res;
        });

        this.commonService.getProvinces({ allowedForShop: true }).subscribe((res) => {
            this.provinceList = res;
        });

        this.form = this.formBuilder.group({
            role: ['enterprise'],
            businessLogo: [''],
            logoName: [''],
            businessName: ['', Validators.required],
            status: ['', Validators.required],
            kycLevel: ['', Validators.required],
            productLinkName: ['', Validators.required],
            visibleInPayList: [true, Validators.required],
            directDistributor: [false, Validators.required],
            isWholesaler: [false, Validators.required],
            hasOwnShopifyInstance: [false, Validators.required],
            bank: this.formBuilder.group({
                bankName: ['', Validators.required],
                bankCode: ['', Validators.required],
                branchCode: ['', Validators.required],
                bankLocation: ['', Validators.required],
                accountNumber: ['', Validators.required],
                accountHolderName: ['', Validators.required],
                accountType: ['', Validators.required],
            }),
            primaryContact: this.formBuilder.group({
                firstName: ['', Validators.required],
                lastName: ['', Validators.required],
                email: ['', Validators.required],
                mobileNumber: [
                    '',
                    [
                        Validators.required,
                        Validators.maxLength(11),
                        Validators.minLength(9),
                        Validators.pattern('^[0-9]*$'),
                    ],
                ],
            }),
            address: this.formBuilder.group({
                type: ['office'],
                addressLineOne: ['', Validators.required],
                addressLineTwo: [''],
                buildingDetails: [''],
                province: ['', Validators.required],
                cityId: ['', Validators.required],
                suburb: ['', Validators.required],
                postalCode: ['', Validators.required],
            }),
        });
    }

    ngOnInit() {
        this.form.get('primaryContact.email').valueChanges.subscribe((newVal) => {
            this.emailExist = false;
            this.emailErrorMsg = MessageConstant.INVALID_EMAIL;
        });

        this.form.get('primaryContact.mobileNumber').valueChanges.subscribe((newVal) => {
            this.mobileExist = false;
            this.mobileErrorMsg = MessageConstant.INVALID_MOBILE;
        });

        this.form.get('bank.bankName').valueChanges.subscribe((newVal) => {
            this.setBranchCode(newVal);
            this.setBankCode(newVal);
        });

        this.form.get('address.province').valueChanges.subscribe((provinceSelectionId) => {
            this.form.get('address.cityId').setValue('');
            if (provinceSelectionId) {
                this.commonService.getCities(provinceSelectionId).subscribe((res) => {
                    this.cityList = res.responseBody.data || [];
                });
            }
        });
    }

    changeVisibleInPayList() {
        this.visibleInPayList = !this.visibleInPayList;
        this.form.controls.visibleInPayList.setValue(this.visibleInPayList);
    }

    changeDirectDistributor() {
        this.isDirectDistributor = !this.isDirectDistributor;
        this.form.controls.directDistributor.setValue(this.isDirectDistributor);
    }

    changeIsWholesaler() {
        this.isWholesaler = !this.isWholesaler;
        this.form.controls.isWholesaler.setValue(this.isWholesaler);
    }

    changeHasOwnShopifyInstance() {
        this.hasOwnShopifyInstance = !this.hasOwnShopifyInstance;
        this.form.controls.hasOwnShopifyInstance.setValue(this.hasOwnShopifyInstance);
    }

    setBranchCode(bankName: string) {
        if (bankName !== '') {
            for (const bank of this.bankList) {
                if (bank.bankName === bankName) {
                    this.form.get('bank.branchCode').setValue(bank.branchCode);
                }
            }
        }
    }

    setBankCode(bankName: string) {
        if (bankName !== '') {
            for (const bank of this.bankList) {
                if (bank.bankName === bankName) {
                    this.form.get('bank.bankCode').setValue(bank.bankCode);
                }
            }
        }
    }

    closeModel(val: boolean) {
        this.display = false;
    }

    validFileType(file: File) {
        if (
            file.type === 'image/jpeg' ||
            file.type === 'application/pdf' ||
            file.type === 'image/png'
        ) {
            return true;
        }
        return false;
    }

    setFile(files: any) {
        if (this.validFileType(files[0]) && files[0].size > 0) {
            this.file = files[0];
            const reader = new FileReader();
            reader.readAsDataURL(this.file);

            reader.onload = () => {
                const baseFile = reader.result.toString().split(',')[1];
                this.base64String = baseFile;
                this.form.get('businessLogo').setValue(this.base64String);
                this.form.get('logoName').setValue(this.file.name);
            };
        }
    }

    setBankDetails(bank: FormGroup) {
        if (bank.valid) {
            alert('bank');
        }
        this.form.get('bank').setValue(bank.value);
    }

    submit() {
        this.invalidForm = this.form.invalid;
        if (this.form.invalid) {
            FormUtils.validateAllFormFields(this.form);
            this.toasterService.error(MessageConstant.INCOMPLETE_FORM, 'Error');
            return false;
        }

        this.isLoading = true;

        this.enterpriseService.addEnterprise(this.form).subscribe(
            (res) => {
                this.isLoading = false;
                if (res.success) {
                    this.router.navigate([URI.SLASHED_SEARCH]);
                    localStorage.setItem(StorageConstant.ENTERPRISE_CREATED_MESSAGE, 'success');
                    this.toasterService.success(MessageConstant.ENTERPRISE_CREATED, 'Success');
                } else {
                    this.eMessage = res.status.message;
                    this.display = true;
                    this.toasterService.success(res.status.message, 'Error');
                }
            },
            (error) => {
                this.isLoading = false;
                this.eMessage = error.error;
                this.display = true;
            },
        );
    }
}
