<section class="content">
    <!-- Default box -->
    <ul class="nav nav-tabs">
        <li class="nav-item">
            <a
                class="nav-link"
                routerLinkActive="tab-link-active"
                [routerLinkActiveOptions]="{ exact: matchExactSummaryPath }"
                [routerLink]="uri.getRouteNavPath('adminSmartSafeSummary')">
                Safes
            </a>
        </li>
        <li class="nav-item">
            <a
                class="nav-link"
                routerLinkActive="tab-link-active"
                [routerLink]="uri.getRouteNavPath('cashInTransitCompany')">
                Cash in Transit
            </a>
        </li>
        <li class="nav-item">
            <a
                class="nav-link"
                routerLinkActive="tab-link-active"
                [routerLink]="uri.getRouteNavPath('adminSmartSafeConfiguration')">
                Configuration
            </a>
        </li>
        <li class="nav-item">
            <a
                class="nav-link"
                routerLinkActive="tab-link-active"
                [routerLink]="uri.getRouteNavPath('adminSmartSafeEvents')">
                Events
            </a>
        </li>
    </ul>
</section>
