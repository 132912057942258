<div class="wrapper">
    <app-error-model
        message="{{ eMessage }}"
        [display]="display"
        (event)="closeModel($event)"></app-error-model>
    <app-navigation></app-navigation>
    <div class="content-wrapper">
        <app-content-transition></app-content-transition>
        <app-loader *ngIf="isLoading" className="custom-loader" text="Processing ..."></app-loader>
        <form [formGroup]="form">
            <app-header location="Add User"> </app-header>
            <app-select-user-type type="General Agent User"></app-select-user-type>
            <section class="add-section">
                <div class="row">
                    <div class="col-md-12 section-heading">General Agent User Details</div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label class="form-label">NAME*</label>
                        <div>
                            <input
                                class="form-input"
                                type="text"
                                formControlName="firstName"
                                name="firstName"
                                placeholder="User First Name" />
                        </div>
                        <small *ngIf="controls.firstName.invalid && invalidForm" class="error-text">
                            This field is required
                        </small>
                    </div>
                    <div class="col-md-6">
                        <label class="form-label">SURNAME*</label>
                        <div>
                            <input
                                class="form-input"
                                type="text"
                                formControlName="lastName"
                                name="lastName"
                                placeholder="User Last Name" />
                        </div>
                        <small *ngIf="controls.lastName.invalid && invalidForm" class="error-text">
                            This field is required
                        </small>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label class="form-label">MOBILE NUMBER*</label>
                        <div>
                            <input
                                class="form-input"
                                type="text"
                                formControlName="mobileNumber"
                                name="mobileNumber"
                                placeholder="e.g. 082 566 7865" />
                        </div>
                        <small
                            *ngIf="(controls.mobileNumber.invalid && invalidForm) || mobileExist"
                            class="error-text"
                            >{{ mobileErrorMsg }}
                        </small>
                    </div>
                    <div class="col-md-6">
                        <label class="form-label">EMAIL ADDRESS</label>
                        <div>
                            <input
                                class="form-input"
                                type="email"
                                formControlName="email"
                                email
                                name="email"
                                placeholder="e.g. email@gmail.com" />
                        </div>
                        <small
                            *ngIf="
                                (controls.email.invalid &&
                                    (controls.email.dirty || controls.email.touched)) ||
                                emailExist
                            "
                            class="error-text">
                            {{ emailErrorMsg || 'The email-id is invalid' }}</small
                        >
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label class="form-label">DATE OF BIRTH*</label>
                        <div>
                            <input
                                matInput
                                class="datepicker-input"
                                [matDatepicker]="picker"
                                name="dob"
                                formControlName="dob"
                                readonly />
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </div>
                        <small *ngIf="controls.dob.invalid && invalidForm" class="error-text">
                            This field is required
                        </small>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">ID TYPE*</label>
                        <app-select-box
                            placeholderValue="Set Status"
                            value="idType"
                            [form]="form"
                            [invalidForm]="invalidForm"
                            [dropDown]="idTypeDropdown">
                        </app-select-box>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">ID NUMBER*</label>
                        <div>
                            <input
                                class="form-input"
                                type="text"
                                formControlName="idNumber"
                                name="idNumber"
                                placeholder="Set ID Type" />
                        </div>
                        <small
                            *ngIf="(controls.idNumber.invalid && invalidForm) || invalidIdNum"
                            class="error-text">
                            {{ idNumberErrorMsg || 'The Id number is invalid' }}
                        </small>
                    </div>
                </div>
                <fieldset formGroupName="address">
                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-label">HOME ADDRESS LINE 1*</label>
                            <div>
                                <input
                                    class="form-input"
                                    type="text"
                                    placeholder="Enter Address"
                                    name="addressLineOne"
                                    formControlName="addressLineOne" />
                            </div>
                            <small
                                *ngIf="
                                    controls.address.controls.addressLineOne.invalid && invalidForm
                                "
                                class="error-text">
                                This field is required
                            </small>
                        </div>
                        <div class="col-md-6">
                            <label class="form-label">BUILDING DETAILS</label>
                            <div>
                                <input
                                    class="form-input"
                                    type="text"
                                    placeholder="e.g. floor 3"
                                    name="buildingDetails"
                                    formControlName="buildingDetails" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-label">HOME ADDRESS LINE 2</label>
                            <div>
                                <input
                                    class="form-input"
                                    type="text"
                                    placeholder="Enter Address"
                                    name="addressLineTwo"
                                    formControlName="addressLineTwo" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="form-label">PROVINCE*</label>
                            <app-select-box
                                placeholderValue="Set Province"
                                value="address.provinceName"
                                [form]="form"
                                [invalidForm]="invalidForm"
                                [dropDown]="provinceDropdown">
                            </app-select-box>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-label">CITY*</label>
                            <app-select-box
                                placeholderValue="Set City"
                                value="address.cityName"
                                [form]="form"
                                [invalidForm]="invalidForm"
                                [dropDown]="cityDropdown">
                            </app-select-box>
                        </div>
                        <div class="col-md-6">
                            <label class="form-label">SUBURB</label>
                            <div>
                                <input
                                    class="form-input"
                                    type="text"
                                    placeholder="Suburb"
                                    name="suburb"
                                    formControlName="suburb" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-label">POSTAL CODE*</label>
                            <div>
                                <input
                                    class="form-input"
                                    type="text"
                                    placeholder="e.g. 7708"
                                    name="postalCode"
                                    formControlName="postalCode" />
                            </div>
                            <small
                                *ngIf="controls.address.controls.postalCode.invalid && invalidForm"
                                class="error-text">
                                This field is required
                            </small>
                        </div>
                    </div>
                </fieldset>
            </section>
            <section class="add-section">
                <div class="row">
                    <div class="col-md-12 add-section-heading">Administrative Settings</div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label class="form-label">OPERATIONAL LOCATION*</label>
                        <div>
                            <label class="radio-container">
                                <input
                                    type="radio"
                                    name="operationType"
                                    (change)="changeAddressType()"
                                    value="physical"
                                    formControlName="operationType" />
                                <span class="radio-lable">Store Address</span>
                                <span class="checkmark"></span>
                            </label>
                            <label class="radio-container">
                                <input
                                    type="radio"
                                    name="operationType"
                                    (change)="changeAddressType()"
                                    value="mobile"
                                    formControlName="operationType" />
                                <span class="radio-lable">Working Area</span>
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="form-label">AGENT ACCOUNT STATUS*</label>
                        <app-select-box
                            placeholderValue="Set Status"
                            value="status"
                            [form]="form"
                            [invalidForm]="invalidForm"
                            [dropDown]="statusDropdown">
                        </app-select-box>
                    </div>
                </div>
                <fieldset formGroupName="operationalAddress">
                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-label">ADDRESS LINE 1*</label>
                            <div>
                                <input
                                    class="form-input"
                                    type="text"
                                    placeholder="e.g. abc road"
                                    name="addressLineOne"
                                    formControlName="addressLineOne" />
                            </div>
                            <small
                                *ngIf="
                                    controls.operationalAddress.controls.addressLineOne.invalid &&
                                    invalidForm
                                "
                                class="error-text">
                                This field is required
                            </small>
                        </div>
                        <div class="col-md-6">
                            <label class="form-label">PROVINCE*</label>
                            <app-select-box
                                placeholderValue="Set Province"
                                value="operationalAddress.provinceName"
                                [form]="form"
                                [invalidForm]="invalidForm"
                                [dropDown]="provinceDropdown">
                            </app-select-box>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-label">ADDRESS LINE 2*</label>
                            <div>
                                <input
                                    class="form-input"
                                    type="text"
                                    placeholder="e.g. abc road"
                                    name="addressLineTwo"
                                    formControlName="addressLineTwo" />
                            </div>
                            <small
                                *ngIf="
                                    controls.operationalAddress.controls.addressLineTwo.invalid &&
                                    invalidForm
                                "
                                class="error-text">
                                This field is required
                            </small>
                        </div>
                        <div class="col-md-6">
                            <label class="form-label">CITY*</label>
                            <app-select-box
                                placeholderValue="Set City"
                                value="operationalAddress.cityName"
                                [form]="form"
                                [invalidForm]="invalidForm"
                                [dropDown]="operationalCityDropdown">
                            </app-select-box>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-label">SUBURB</label>
                            <div>
                                <input
                                    class="form-input"
                                    type="text"
                                    placeholder="suburb"
                                    name="suburb"
                                    formControlName="suburb" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="form-label">POSTAL CODE*</label>
                            <div>
                                <input
                                    class="form-input"
                                    type="text"
                                    placeholder="e.g. 7708"
                                    name="postalCode"
                                    formControlName="postalCode" />
                            </div>
                            <small
                                *ngIf="
                                    controls.operationalAddress.controls.postalCode.invalid &&
                                    invalidForm
                                "
                                class="error-text">
                                This field is required
                            </small>
                        </div>
                    </div>
                </fieldset>
                <div class="row">
                    <div class="col-md-6"></div>
                    <div class="col-md-6">
                        <button class="custombtn custombtn-align-right" (click)="submit()">
                            Save
                        </button>
                    </div>
                </div>
            </section>
            <section class="add-section-footer">*Required</section>
        </form>
    </div>
</div>
