import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { ExportService } from '../services/export.service';

@Directive({
    selector: '[appExportExcel]',
})
export class ExportExcelDirective {
    constructor(private exportService: ExportService) {}

    @Input() data: any[];
    @Input() fileName: string;

    @HostListener('click', ['$event']) onClick($event) {
        console.log('clicked: ' + $event);
        this.exportService.exportExcel(this.data, this.fileName);
    }
}
