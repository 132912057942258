import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Company } from '../model/company';
import { CompanyService } from '../services/company.service';
import { URI } from '../settings/uri-constants';

@Component({
    selector: 'app-company',
    templateUrl: './company.component.html',
    styleUrls: ['./company.component.css'],
})
export class CompanyComponent implements OnInit {
    public companies: Company[];
    public selectedCompany: Company;

    constructor(
        public dialogRef: MatDialogRef<CompanyComponent>,
        public companyService: CompanyService,
        private router: Router,
    ) {
        this.companies = this.companyService.getCompanies();
        this.selectedCompany = this.companyService.getSelectedCompany();
    }

    ngOnInit(): void {
        this.companies = this.companyService.getCompanies();
        this.selectedCompany = this.companyService.getSelectedCompany();
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    selectCompany(company: Company) {
        this.selectedCompany = company;
    }

    confirmSelectedCompany() {
        this.companyService.selectCompany(this.selectedCompany);
        this.dialogRef.close();
        this.router.navigate([URI.getRouteNavPath('dashboard')]);
    }

    // https://angular.io/api/forms/SelectControlValueAccessor#customizing-option-selection
    customCompare(c1: Company, c2: Company): boolean {
        return c1 && c2 ? c1.id === c2.id : c1 === c2;
    }
}
