import { Injectable } from '@angular/core';
import { URI } from '../settings/uri-constants';

@Injectable({ providedIn: 'root' })
export class NavigationService {
    constructor() {}

    getNavigationItems(): Array<any> {
        const navigationItems = [
            {
                name: 'Dashboard',
                path: URI.getRouteNavPath('dashboard'),
                iconClass: 'dashboard-image-state',
                children: [],
            },
            {
                name: 'Manage Users',
                path: URI.getRouteNavPath('manageUsers'),
                iconClass: 'user-image-state',
                children: [],
            },
            {
                name: 'Transactions',
                path: URI.getRouteNavPath('adminViewTransactions'),
                iconClass: 'transaction-image-state',
                children: [],
            },
            {
                name: 'Product Orders',
                path: URI.getRouteNavPath('adminProductOrders'),
                iconClass: 'dashboard-image-state',
                children: [],
            },
            {
                name: 'Transfer To Bank Requests',
                path: URI.getRouteNavPath('adminTransferToBank'),
                iconClass: 'admin-ttb-image-state',
                children: [],
            },
            {
                name: 'Settings',
                path: URI.getRouteNavPath('adminSettings'),
                iconClass: 'settings-image-state',
                children: [],
            },
            {
                name: 'EFT and ATM Cash In',
                path: URI.getRouteNavPath('adminEFTCashIn'),
                iconClass: 'admin-ttb-image-state',
                children: [],
            },
            {
                name: 'Smart Safes',
                path: URI.getRouteNavPath('adminSmartSafes'),
                iconClass: 'smart-safe-image-state',
                children: [],
            },
            {
                name: 'Agent Applications',
                path: URI.getRouteNavPath('adminAgentApplications'),
                iconClass: 'agent-image-state',
                children: [],
            },
            {
                name: 'GRV',
                path: URI.getRouteNavPath('adminGRV'),
                iconClass: 'agent-image-state',
                children: [],
            },
            {
                name: 'Banners',
                path: URI.getRouteNavPath('adminBanners'),
                iconClass: 'agent-image-state',
                children: [],
            },
            // {
            //     name: 'stores',
            //     path: './stores',
            //     children: [
            //         {
            //             name: 'books',
            //             path: './books',
            //             children: [
            //                 {
            //                     name: 'THE FELLOWSHIP OF THE RING Details',
            //                     path: './book1',
            //                 },
            //                 {
            //                     name: 'THE RETURN OF THE KING',
            //                     path: './book2',
            //                 },
            //                 {
            //                     name: "Harry Potter and the Philosopher's Stone",
            //                     path: './book3',
            //                 },
            //                 {
            //                     name: 'Harry Potter and the Chamber of Secrets',
            //                     path: './book4',
            //                 },
            //             ],
            //         },
            //     ],
            // },
        ];

        return navigationItems;
    }
}
