import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-page-length-dropdown',
    templateUrl: './page-length-dropdown.html',
    styleUrls: ['./page-length-dropdown.css'],
})
export class PageLengthDropdownComponent {
    @Output() emitLength = new EventEmitter<number>();
    display: string;
    pageLength = [10, 20, 30, 40, 50];

    constructor() {
        this.display = 'none';
    }

    changeLength(len: number) {
        this.emitLength.emit(len);
    }
}
