export class Fees {
    public id: string;
    public feeCodeId: string;
    public transactionTypeId: string;
    public percentage = 0.0;
    public amount = 0.0;
    public useAmount = false;
    public code: string;
    public feePaymentBy: string;
    public description: string;
    public percentageValue = 0.0;
    public amountValue = 0.0;
    public useAmountValue = false;
}
