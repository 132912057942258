import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';
import { URI } from 'src/app/settings/uri-constants';
import { City } from 'src/app/model/city';
import { DatePipe } from '@angular/common';
import { User } from 'src/app/model/user';
import { StorageConstant } from 'src/app/settings/storage-constants';
import { Personal } from 'src/app/model/personal';
import { Merchant } from 'src/app/model/merchant';
import { Agent } from 'src/app/model/agent';
import { UserStatus } from 'src/app/enums/user.status';
import { MessageConstant } from 'src/app/settings/message.constant';

@Component({
    selector: 'app-agent',
    templateUrl: './agent.component.html',
    styleUrls: ['./agent.component.css'],
})
export class AgentComponent implements OnInit {
    display = false;
    invalidForm = false;
    isLoading = false;
    eMessage = '';
    emailExist: boolean;
    mobileExist: boolean;
    invalidIdNum: boolean;
    emailErrorMsg: string;
    mobileErrorMsg: string;
    idNumberErrorMsg: string;
    addressTypePhysical: boolean;
    statusDropdown: string[] = [];
    province: any[] = [];
    provinceDropdown: string[] = [];
    cityDropdown: string[] = [];
    operationalCityDropdown: string[] = [];
    idTypeDropdown: string[] = [];
    cityList: City[] = [];
    operationalCityList: City[] = [];
    form: FormGroup;
    user: User;
    id: string;

    constructor(
        private commonService: CommonService,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private datePipe: DatePipe,
    ) {
        this.user = JSON.parse(localStorage.getItem(StorageConstant.AGENT_APPROVE));
        this.id = this.user.id;
        this.emailExist = false;
        this.mobileExist = false;
        this.invalidIdNum = false;
        this.addressTypePhysical = false;
        this.emailErrorMsg = MessageConstant.INVALID_EMAIL;
        this.mobileErrorMsg = MessageConstant.INVALID_MOBILE;
        this.idNumberErrorMsg = MessageConstant.INVALID_IDNUMBER;

        this.commonService.getUserStatus().subscribe(
            (res) => {
                this.statusDropdown = res.data;
            },
            (err) => {
                this.isLoading = false;
            },
        );

        this.commonService.getProvinces().subscribe(
            (res) => {
                this.province = res;
                this.provinceDropdown = [];
                this.province.forEach((val) => {
                    this.provinceDropdown.push(val.province);
                });
            },
            (err) => {
                this.isLoading = false;
            },
        );

        this.commonService.getIdType().subscribe(
            (res) => {
                this.idTypeDropdown = res.data;
            },
            (err) => {
                this.isLoading = false;
            },
        );

        this.commonService.getUserProfile(this.id).subscribe(
            (res) => {
                this.user = res.data;
                this.initializeUser();
                this.createForm();
                this.isLoading = false;
            },
            (err) => {
                this.isLoading = false;
            },
        );

        this.form = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            mobileNumber: ['', [Validators.required, Validators.pattern('^[0-9]{9,11}$')]],
            email: [null],
            dob: ['', Validators.required],
            idType: ['', Validators.required],
            idNumber: ['', Validators.required],
            operationType: ['', Validators.required],
            status: [UserStatus.active, Validators.required],
            role: ['agent'],
            address: this.formBuilder.group({
                type: ['home'],
                addressLineOne: ['', Validators.required],
                addressLineTwo: [''],
                buildingDetails: [null],
                provinceName: ['', Validators.required],
                cityName: ['', Validators.required],
                cityId: [null],
                suburb: [null],
                postalCode: ['', Validators.required],
            }),
            operationalAddress: this.formBuilder.group({
                type: [''],
                addressLineOne: ['', Validators.required],
                addressLineTwo: ['', Validators.required],
                buildingDetails: [null],
                provinceName: ['', Validators.required],
                cityName: ['', Validators.required],
                cityId: [null],
                suburb: [null],
                postalCode: ['', Validators.required],
            }),
        });

        this.form.controls.operationType.setValue('physical');
        this.changeAddressType();
    }

    ngOnInit() {
        this.form.get('address.provinceName').valueChanges.subscribe((newVal) => {
            this.form.get('address.cityName').setValue('');
            if (newVal) {
                this.commonService.getCities(this.getProvinceId(newVal)).subscribe((res) => {
                    this.cityList = res.responseBody.data || [];
                    this.createCityDropDown();
                });
            }
        });

        this.form.get('operationalAddress.provinceName').valueChanges.subscribe((newVal) => {
            this.form.get('operationalAddress.cityName').setValue('');

            if (newVal) {
                this.commonService.getCities(this.getProvinceId(newVal)).subscribe((res) => {
                    this.operationalCityList = res.responseBody.data || [];
                    this.createCityDropDown();
                });
            }
        });

        this.createCityDropDown();

        this.form.get('address.cityName').valueChanges.subscribe((newVal) => {
            const id = this.getCityId(newVal);
            this.form.get('address.cityId').setValue(id);
        });

        this.form.get('operationalAddress.cityName').valueChanges.subscribe((newVal) => {
            const id = this.getCityId(newVal);
            this.form.get('operationalAddress.cityId').setValue(id);
        });

        this.form.get('email').valueChanges.subscribe((newVal) => {
            this.emailExist = false;
            this.emailErrorMsg = MessageConstant.INVALID_EMAIL;
        });

        this.form.get('mobileNumber').valueChanges.subscribe((newVal) => {
            this.mobileExist = false;
            this.mobileErrorMsg = MessageConstant.INVALID_MOBILE;
        });

        this.form.get('idNumber').valueChanges.subscribe((newVal) => {
            this.invalidIdNum = false;
            this.idNumberErrorMsg = MessageConstant.INVALID_IDNUMBER;
        });
    }

    initializeUser() {
        if (this.user.userPersonalProfileModel === null) {
            this.user.userPersonalProfileModel = new Personal();
        }
        if (this.user.userBusinessProfileModel === null) {
            this.user.userBusinessProfileModel = new Merchant();
        }
        if (this.user.userAgentProfileModel === null) {
            this.user.userAgentProfileModel = new Agent();
        }
    }

    createForm() {
        this.form.get('firstName').setValue(this.user.firstName);
        this.form.get('lastName').setValue(this.user.lastName);
        this.form.get('mobileNumber').setValue(this.user.mobileNumber);
        this.form.get('email').setValue(this.user.email);
        this.form.get('dob').setValue(this.user.userAgentProfileModel.dob);
        this.form.get('idType').setValue(this.user.userAgentProfileModel.idType);
        this.form.get('idNumber').setValue(this.user.userAgentProfileModel.idNumber);
        this.form.get('role').setValue(this.user.userAgentProfileModel.agentRole);
        this.form
            .get('address.addressLineOne')
            .setValue(this.user.userAgentProfileModel.address.addressLineOne);
        this.form
            .get('address.addressLineTwo')
            .setValue(this.user.userAgentProfileModel.address.addressLineTwo);
        this.form
            .get('address.buildingDetails')
            .setValue(this.user.userAgentProfileModel.address.buildingDetails);
        this.form
            .get('address.provinceName')
            .setValue(this.user.userAgentProfileModel.address.provinceName);
        this.form
            .get('address.cityName')
            .setValue(this.user.userAgentProfileModel.address.cityName);
        this.form.get('address.cityId').setValue(this.user.userAgentProfileModel.address.cityId);
        this.form.get('address.suburb').setValue(this.user.userAgentProfileModel.address.suburb);
        this.form
            .get('address.postalCode')
            .setValue(this.user.userAgentProfileModel.address.postalCode);
    }

    getProvinceId(name: string): string {
        const val = this.province.filter((x) => x.province == name)[0];
        let id = '';

        if (val) {
            id = val.id;
        }
        return id;
    }

    createCityDropDown() {
        for (const city of this.cityList) {
            this.cityDropdown.push(city.city);
        }
        for (const city of this.operationalCityList) {
            this.operationalCityDropdown.push(city.city);
        }
    }

    getCityId(val: string) {
        for (const city of this.cityList) {
            if (val && city.city === val) {
                return city.id;
            }
        }
        return -1;
    }

    changeAddressType() {
        const type = this.form.value.operationType;
        this.form.get('operationalAddress.type').setValue(type);
    }

    closeModel(val: boolean) {
        this.display = false;
    }

    formatDate() {
        const date = this.datePipe.transform(this.form.value.dob, 'yyyy-MM-dd');
        this.form.controls.dob.setValue(date);
    }

    submit() {
        this.formatDate();

        this.invalidForm = this.form.invalid;
        if (this.form.invalid) {
            this.display = true;
            this.eMessage = MessageConstant.INCOMPLETE_FORM;
            return false;
        }

        this.isLoading = true;

        this.commonService.addAgent(this.form, this.user.id).subscribe(
            (res) => {
                this.isLoading = false;
                if (res.success) {
                    this.router.navigate([URI.SLASHED_SEARCH]);
                    localStorage.setItem(StorageConstant.AGENT_CREATED_MESSAGE, 'success');
                } else {
                    this.eMessage = 'Failed to approve Agent';
                    this.display = true;
                }
            },
            (error) => {
                this.isLoading = false;
                this.eMessage = 'Failed to approve Agent';
                this.display = true;
            },
        );
    }

    get controls(): any {
        return this.form.controls;
    }
}
