import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DashboardService } from 'src/app/services/dashboard.service';
import { AppConstants } from 'src/app/settings/app-constants';

@Component({
    selector: 'app-wallet-summary',
    templateUrl: './wallet-summary.component.html',
    styleUrls: ['./wallet-summary.component.css'],
})
export class WalletSummaryComponent implements OnInit {
    id: string;
    display = false;
    amountFormat = AppConstants.AMOUNT_FORMAT;
    isLoading = false;
    summary: any;

    constructor(
        private dashboardService: DashboardService,
        private route: ActivatedRoute,
    ) {
        this.route.paramMap.subscribe((params) => {
            this.id = params.get('id');
        });
    }

    ngOnInit() {
        this.getWalletSummary();
    }

    getWalletSummary() {
        this.isLoading = true;

        this.dashboardService.getWalletSummary(this.id).subscribe(
            (res) => {
                this.summary = res;
                this.isLoading = false;

                if (!this.summary.reconciliationPassed) {
                    this.display = true;
                }
            },
            (err) => {
                this.isLoading = false;
            },
        );
    }
}
