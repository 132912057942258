import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Enterprise } from 'src/app/model/enterprise';
import { SessionUser } from 'src/app/model/session.user';
import { User } from 'src/app/model/user';

import { AlertService } from 'src/app/services/alert.service';
import { CommonService } from 'src/app/services/common.service';
import { EnterpriseService } from 'src/app/services/enterprise.service';
import { LoginService } from 'src/app/services/login.service';
import { StorageService } from 'src/app/services/storage.service';
import { URI } from 'src/app/settings/uri-constants';

@Component({
    selector: 'app-enterprise-header',
    templateUrl: './enterprise-header.component.html',
    styleUrls: ['./enterprise-header.component.css'],
})
export class EnterpriseHeaderComponent {
    @Input() save = false;
    @Input() title = 'Manage Users';
    @Input() location: string;
    @Input() buttonText: string;
    @Input() url: string;
    enterprise: Enterprise;
    id: string;

    constructor(
        private router: Router,
        private loginService: LoginService,
        private storageService: StorageService,
        private enterpriseService: EnterpriseService,
    ) {
        this.enterprise = new Enterprise();
        this.id = this.loginService.sessionUser.value.enterprise.enterpriseId;
    }

    ngOnInit(): void {
        this.getEnterpriseProfile();
    }

    getEnterpriseProfile() {
        if (
            !!this.storageService.getEnterpriseProfile() &&
            this.storageService.getEnterpriseProfile()?.id === this.id
        ) {
            this.enterprise = this.storageService.getEnterpriseProfile();
        } else {
            this.enterpriseService.getEnterpriseProfile(this.id).subscribe(
                (res) => {
                    this.enterprise = res.data;
                    this.storageService.setEnterpriseProfile(res.data);
                },
                (err) => {
                    console.log('Failed to get profile data...');
                },
            );
        }
    }

    navigate() {
        if (this.url) {
            this.router.navigate([this.url]);
        } else {
            this.router.navigate([URI.SLASHED_ADD_STAFF]);
        }
    }
}
