export class Employee {
    public role: string;
    public enterpriseId: string;
    public firstName: string;
    public lastName: string;
    public store: string;
    public status: string;
    public isManager: boolean;
    public employeeNumber: string;
    public enterpriseStaffId: string;
    public profilePicture: string;
    public email: string;
    constructor() {}
}
