export enum FeeCode {
    marketplace_pickup_cashback = 'marketplace_pickup_cashback',
    marketplace_margin = 'marketplace_margin',
    marketplace_delivery = 'marketplace_delivery',
    sender_cot = 'sender_cot',
    sender_cin = 'sender_cin',
    sender_scz = 'sender_scz',
    sender_pay = 'sender_pay',
    sender_ttb = 'sender_ttb',
    sender_age = 'sender_age',
    sender_mpb = 'sender_mpb',
    sender_mpb_pkg = 'sender_mpb_pkg',
    sender_svc = 'sender_svc',
    sender_busdv = 'sender_busdv',
    sender_susdv = 'sender_susdv',
    sender_cin_earning = 'sender_cin_earning',
    receiver_cin = 'receiver_cin',
    receiver_cot = 'receiver_cot',
    receiver_scz = 'receiver_scz',
    receiver_pay = 'receiver_pay',
    receiver_cbk = 'receiver_cbk',
    receiver_cdp = 'receiver_cdp',
    receiver_cin_eft = 'receiver_cin_eft',
    receiver_cin_atm = 'receiver_cin_atm',
    receiver_cin_cod = 'receiver_cin_cod',
    receiver_cin_store = 'receiver_cin_store',
    receiver_cin_card = 'receiver_cin_card',
    receiver_cin_instant_eft = 'receiver_cin_instant_eft',
    receiver_susdv = 'receiver_susdv',
    receiver_svc = 'receiver_svc',
    receiver_busdv = 'receiver_busdv',
}
