import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { User } from 'src/app/model/user';
import { Pagination } from 'src/app/model/Pagination';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { UserStatus } from 'src/app/enums/user.status';
import { StorageConstant } from 'src/app/settings/storage-constants';
import { URI } from 'src/app/settings/uri-constants';
import { VerificationType } from 'src/app/enums/verification.type';

@Component({
    selector: 'app-verify-users',
    templateUrl: './verify-users.component.html',
    styleUrls: ['./verify-users.component.css'],
})
export class VerifyUsersComponent implements OnInit {
    userList: Array<User>;
    pagination = new Pagination();
    isLoading = false;
    progressWidth = 25;
    form: FormGroup;
    name = '';
    user: User;
    denyModalDisplay = 'none';
    verifyModalDisplay = 'none';
    dropdown = [];

    constructor(
        private commonService: CommonService,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        @Inject(DOCUMENT) private document: Document,
    ) {
        this.pagination.isAgent = true;
        this.form = this.formBuilder.group({
            status: [UserStatus.pending_review],
            start: ['0'],
            length: ['10'],
            isVerifying: [true],
        });
    }

    ngOnInit() {
        this.getData();
    }

    getData() {
        this.isLoading = true;
        this.form.get('start').setValue(this.pagination.start);
        this.form.get('length').setValue(this.pagination.length);
        this.setProgressWidth(50);
        this.commonService.getFilteredData(this.form).subscribe(
            (user) => {
                this.setProgressWidth(100);
                setTimeout(() => {
                    this.userList = user.data;
                    this.pagination.total = user.total;
                    this.isLoading = false;
                    this.progressWidth = 25;
                }, 350);
            },
            (error) => {
                this.isLoading = false;
            },
        );
    }

    setProgressWidth(i: number) {
        setTimeout(() => {
            this.progressWidth = i;
        }, 300);
    }

    receiveFilter(data: { name: string; value: number }) {}

    changeLength(len: number) {
        this.pagination.length = len;
        this.pagination.start = 0;
        this.getData();
    }

    changePage(page: number) {
        this.pagination.changePage(page);
        this.getData();
    }

    get controls(): any {
        return this.form.controls;
    }
}
