import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard.service';
import { LoginService } from 'src/app/services/login.service';
import { AppConstants } from 'src/app/settings/app-constants';

@Component({
    selector: 'app-enterprise-wallet-summary',
    templateUrl: './enterprise-wallet-summary.component.html',
    styleUrls: ['./enterprise-wallet-summary.component.css'],
})
export class EnterpriseWalletSummaryComponent implements OnInit {
    id: string;
    userId: string;
    display = false;
    amountFormat = AppConstants.AMOUNT_FORMAT;
    isLoading = false;
    summary: any;

    constructor(
        private dashboardService: DashboardService,
        public loginService: LoginService,
    ) {
        this.id = this.loginService.sessionUser.value.enterprise.enterpriseId;
        this.userId = this.loginService.sessionUser.value.enterprise.userId;
    }

    ngOnInit() {
        this.getWalletSummary();
    }

    getWalletSummary() {
        this.isLoading = true;

        this.dashboardService.getWalletSummary(this.userId).subscribe(
            (res) => {
                this.summary = res;
                this.isLoading = false;

                if (!this.summary.reconciliationPassed) {
                    this.display = true;
                }
            },
            (err) => {
                this.isLoading = false;
            },
        );
    }
}
