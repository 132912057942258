import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/model/user';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute } from '@angular/router';
import { Personal } from 'src/app/model/personal';
import { Merchant } from 'src/app/model/merchant';
import { Agent } from 'src/app/model/agent';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { StorageService } from 'src/app/services/storage.service';
import { MessageConstant } from 'src/app/settings/message.constant';
import { Role } from 'src/app/enums/role';
import { DatePipe } from '@angular/common';
import { IdType } from 'src/app/enums/id.type';
import { CommonUtil } from 'src/app/services/common.util';
import { AppConstants } from 'src/app/settings/app-constants';

@Component({
    selector: 'app-user-personal-profile',
    templateUrl: './user-personal-profile.component.html',
    styleUrls: ['./user-personal-profile.component.css'],
})
export class UserPersonalProfileComponent implements OnInit {
    display = false;
    isLoading = false;
    user: User;
    role = Role;
    id: string;
    roleId: string;
    displaySuccess = false;
    editProfile = false;
    sMessage = '';
    eMessage = '';
    form: FormGroup;
    idTypeDropdown: string[] = [];
    genderDropdown: string[] = [];
    idNumberErrorMsg: string;
    dobErrorMsg: string;
    invalidForm = false;
    invalidIdNum = false;
    invalidDOB = false;
    idNumPlaceHolder = 'YYMMDDSSSSCAZ - 8409114567658';

    constructor(
        private commonService: CommonService,
        private route: ActivatedRoute,
        private storageService: StorageService,
        private formBuilder: UntypedFormBuilder,
        private datePipe: DatePipe,
        private commonUtil: CommonUtil,
    ) {
        this.user = new User();

        this.form = this.formBuilder.group({
            dob: ['', Validators.required],
            idType: ['', Validators.required],
            idNumber: ['', Validators.required],
            gender: [''],
            updateProfile: [true],
        });
    }

    ngOnInit() {
        this.route.paramMap.subscribe((params) => {
            this.id = params.get('id');
            this.getData();
        });

        this.commonService.getIdType().subscribe(
            (res) => {
                this.idTypeDropdown = res.data;
            },
            (err) => {
                this.isLoading = false;
            },
        );

        this.commonService.getGender().subscribe(
            (res) => {
                this.genderDropdown = res.data;
            },
            (err) => {
                this.isLoading = false;
            },
        );

        this.form.get('idType').valueChanges.subscribe((newVal) => {
            this.validateDOBvsID();
            switch (newVal) {
                case IdType.foreign_passport:
                    this.idNumPlaceHolder = '7700225VH';
                    break;
                case IdType.sa_passport:
                    this.idNumPlaceHolder = '7700225VH';
                    break;
                case IdType.asylum_permit:
                    this.idNumPlaceHolder = 'CTRIND123456789';
                    break;
                default:
                    this.idNumPlaceHolder = 'YYMMDDSSSSCAZ - 8409114567658';
            }
        });

        this.form.get('idNumber').valueChanges.subscribe((newVal) => {
            this.validateDOBvsID();
        });

        this.form.get('dob').valueChanges.subscribe((newVal) => {
            this.validateDOBvsID();
        });
    }

    initializeUser() {
        if (this.user.userPersonalProfileModel === null) {
            this.user.userPersonalProfileModel = new Personal();
        } else {
            this.user.lastLogin = this.user.userPersonalProfileModel.lastLogin;
            this.user.profilePicture = this.user.userPersonalProfileModel.profilePicture;
            this.roleId == null
                ? (this.roleId = this.user.userPersonalProfileModel.roleId)
                : this.roleId;
        }
        if (this.user.userBusinessProfileModel === null) {
            this.user.userBusinessProfileModel = new Merchant();
        } else {
            this.roleId == null
                ? (this.roleId = this.user.userBusinessProfileModel.roleId)
                : this.roleId;
        }

        if (this.user.userAgentProfileModel === null) {
            this.user.userAgentProfileModel = new Agent();
        } else {
            this.roleId == null
                ? (this.roleId = this.user.userAgentProfileModel.roleId)
                : this.roleId;
        }
    }

    getData() {
        this.isLoading = true;
        if (
            !!this.storageService.getProfileUser() &&
            this.storageService.getProfileUser().id == this.id
        ) {
            this.user = this.storageService.getProfileUser();
            this.initializeUser();
            this.isLoading = false;
            this.createForm();
        } else {
            this.getProfile();
        }
    }

    getProfile() {
        this.isLoading = true;
        this.commonService.getUserProfile(this.id).subscribe(
            (res) => {
                this.isLoading = false;
                this.user = res.data;
                this.initializeUser();
                this.storageService.setProfileUser(this.user);
                this.createForm();
            },
            (err) => {
                this.isLoading = false;
            },
        );
    }

    closeModel(val: boolean) {
        this.display = false;
        this.displaySuccess = false;
    }

    updateBank(bank: FormGroup) {
        this.isLoading = true;
        this.closeModel(true);

        bank.get('userRoleId').setValue(this.user.userPersonalProfileModel.userRoleId);

        this.commonService
            .updateBank(bank.value, this.user.id, this.user.userPersonalProfileModel.roleId)
            .subscribe(
                (res) => {
                    this.isLoading = false;
                    if (res.success) {
                        this.displaySuccess = true;
                        this.sMessage = MessageConstant.BANK_UPDATE_SUCCESS;
                        this.refreshBankList(bank.value);
                    } else {
                        this.eMessage = res.status.message;
                        this.display = true;
                    }
                },
                (err) => {
                    if (err.error.errors) {
                        this.eMessage = err.error.errors[0];
                    } else {
                        this.eMessage = err.error.error;
                    }
                    this.display = true;
                    this.isLoading = false;
                },
            );
    }

    refreshBankList(bank: any) {
        const list = this.user.userPersonalProfileModel.bankList;

        for (let i = 0; i < list.length; i++) {
            if (list[i].bankDetailId == bank.bankDetailId) {
                list[i] = bank;
            }
        }

        this.user.userPersonalProfileModel.bankList = list;
    }

    createForm() {
        this.form.get('dob').setValue(this.user.dob || '');
        this.form.get('idType').setValue(this.user.idType || '');
        this.form.get('idNumber').setValue(this.user.saIdNumber || '');
        this.form.get('gender').setValue(this.user.gender || '');
    }

    changeView() {
        this.editProfile = !this.editProfile;
    }

    formatDate() {
        const date = this.datePipe.transform(this.form.value.dob, 'yyyy-MM-dd');
        this.form.controls.dob.setValue(date);
    }

    validateDOBvsID() {
        const idType = this.form.get('idType').value;

        if (idType != IdType.asylum_permit && idType != IdType.foreign_passport) {
            let idNum: string = this.form.get('idNumber').value;
            let dob: string = this.datePipe.transform(this.form.get('dob').value, 'yyyy-MM-dd');

            if (!this.commonUtil.validateSaIdNumber(idNum)) {
                this.idNumberErrorMsg = MessageConstant.INVALID_SA_IDNUMBER;
                this.dobErrorMsg = MessageConstant.INVALID_IDNUMBER_DOB;
                this.invalidIdNum = true;
                return false;
            }

            if (idNum && idNum != '') {
                idNum = idNum.slice(0, 6);
            }

            if (dob && dob != '') {
                dob = dob.replace(/-/g, '').slice(2);
            }

            if (dob != idNum) {
                this.idNumberErrorMsg = MessageConstant.INVALID_IDNUMBER_DOB;
                this.dobErrorMsg = MessageConstant.INVALID_IDNUMBER_DOB;
                this.invalidIdNum = true;
                this.invalidDOB = true;
                return false;
            }
        }
        this.invalidIdNum = false;
        this.invalidDOB = false;
        this.idNumberErrorMsg = null;
        return true;
    }

    submit() {
        this.formatDate();

        if (this.invalidIdNum) {
            return false;
        }

        this.isLoading = true;
        this.commonService.changeUserProfile(this.user.id, this.roleId, this.form.value).subscribe(
            (res) => {
                this.isLoading = false;
                if (res.success) {
                    this.getProfile();
                    this.changeView();
                } else {
                    this.eMessage = res.status.message;
                    this.display = true;
                }
            },
            (error) => {
                this.isLoading = false;
                this.eMessage = MessageConstant.PROFILE_UPDATE_REQUEST_FAILED;
                this.display = true;
            },
        );
    }

    updateReferralCode(code: string) {
        if (code.length < AppConstants.REFERRAL_CODE_MIN_LEGTH) {
            this.eMessage = 'Invalid referral code.';
            this.display = true;
        } else {
            this.isLoading = true;
            this.commonService.updateReferralCode(this.user.id, code).subscribe(
                (res) => {
                    this.isLoading = false;
                    if (res.success) {
                        this.getProfile();
                    } else {
                        this.eMessage = res.status.message;
                        this.display = true;
                    }
                },
                (error) => {
                    this.isLoading = false;
                    this.eMessage = MessageConstant.REFERRAL_CODE_UPDATE_REQUEST_FAILED;
                    this.display = true;
                },
            );
        }
    }

    get controls(): any {
        return this.form.controls;
    }
}
