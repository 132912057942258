<!-- Content Wrapper. Contains page content -->
<div class="content-wrapper">
    <app-navigation></app-navigation>
    <app-content-transition></app-content-transition>
    <app-safes-header></app-safes-header>
    <app-safes-link></app-safes-link>

    <router-outlet (activate)="onActivate()" (deactivate)="onDeactivate()"> </router-outlet>

    @if (showParent) {
        <section class="add-section">
            <form [formGroup]="form">
                <div class="row">
                    <div class="col-md-12 section-heading">Search</div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="input-icons">
                            <i class="fa fa-search icon" aria-hidden="true"></i>
                            <input
                                class="form-input"
                                type="text"
                                placeholder="Enter a Name"
                                formControlName="text" />
                            @if (!isEmpty('text')) {
                                <a (click)="clearText()">
                                    <img src="assets/images/close-x-purple.png" />
                                </a>
                            }
                        </div>
                    </div>
                    <div class="col-md-6">
                        <button class="search-button" (click)="submit()">Search</button>
                        <button type="button" class="custombtn" (click)="addCompany()">
                            <i class="fa fa-plus-circle pr-1" aria-hidden="true"></i>Add Company
                        </button>
                    </div>
                </div>
            </form>
        </section>

        @if (!isLoading && citList?.length !== null && citList?.length > 0) {
            <div class="row">
                <div class="search-result-header">
                    <div class="col-md-12">
                        Search Results: {{ citList.length }} of
                        {{ pagination.total }}
                    </div>
                </div>
            </div>
        }

        <hr />

        @if (isLoading) {
            <div class="progress-container">
                <div class="progress-container-text">
                    <div>{{ loaderText }}</div>
                </div>
                <div>
                    <div class="custom-progress-bar">
                        <span [style.width.%]="progressWidth"></span>
                    </div>
                </div>
            </div>
        }

        <section>
            <div class="container-fluid tab-pane custom-table smart-safes">
                <br />
                <table>
                    <thead>
                        <tr class="form-table-row">
                            <th>
                                <app-filter-arrow
                                    filter="serialNumber"
                                    title="COMPANY NAME/USER"
                                    (filterValue)="receiveFilter($event)">
                                </app-filter-arrow>
                            </th>
                            <th>
                                <app-filter-arrow
                                    filter="managementPhoneNumber"
                                    title="MANAGEMENT PHONE NUMBER"
                                    (filterValue)="receiveFilter($event)">
                                </app-filter-arrow>
                            </th>
                            <th>
                                <app-filter-arrow
                                    filter="managementEmail"
                                    title="MANAGEMENT EMAIL"
                                    (filterValue)="receiveFilter($event)">
                                </app-filter-arrow>
                            </th>
                            <th>
                                <app-filter-arrow
                                    filter="contactFullName"
                                    title="CONTACT PERSON"
                                    (filterValue)="receiveFilter($event)">
                                </app-filter-arrow>
                            </th>
                            <th>
                                <app-filter-arrow
                                    filter="location"
                                    title="LOCATION"
                                    (filterValue)="receiveFilter($event)">
                                </app-filter-arrow>
                            </th>
                            <th>
                                <app-filter-arrow title="ACTIONS"> </app-filter-arrow>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        @for (cit of citList; track cit.managementPhoneNumber) {
                            <ng-container>
                                <tr class="form-table-row view-form configration">
                                    <td>
                                        <img
                                            class="table-user-img"
                                            src="{{ 'assets/images/placeholder.png' }}" />
                                        <a
                                            (click)="
                                                commonUtil.navigateToProfile('profile', cit.user)
                                            ">
                                            <strong>
                                                <u>
                                                    <span>{{ cit.user.name | titlecase }}</span>
                                                </u>
                                            </strong>
                                        </a>
                                    </td>
                                    <td>{{ cit.managementPhoneNumber }}</td>
                                    <td>{{ cit.managementEmail }}</td>
                                    <td>{{ cit.contactFullName | titlecase }}</td>
                                    <td>
                                        <div
                                            data-toggle="tooltip"
                                            data-placement="bottom"
                                            data-html="true"
                                            title="
                                    <em>Address</em>
                                    <div>
                                        <div class=&quot;row&quot;>
                                            <div class=&quot;pl-3&quot;>
                                                Street: {{ cit.operationsLocation.addressLineOne }}
                                            </div>
                                        </div>
                                        <div class=&quot;row&quot;>
                                            <div class=&quot;pl-3&quot;>
                                            Street 2: {{ cit.operationsLocation.addressLineTwo }}
                                            </div>
                                        </div>
                                        <div class=&quot;row&quot;>
                                            <div class=&quot;pl-3&quot;>
                                                Building Details: {{
                                                cit.operationsLocation.buildingDetails
                                            }}
                                            </div>
                                        </div>
                                        <div class=&quot;row&quot;>
                                            <div class=&quot;pl-3&quot;>
                                                City: {{ cit.operationsLocation.cityName }}
                                            </div>
                                        </div>
                                        <div class=&quot;row&quot;>
                                            <div class=&quot;pl-3&quot;>
                                                Province: {{ cit.operationsLocation.provinceName }}
                                            </div>
                                        </div>
                                        <div class=&quot;row&quot;>
                                            <div class=&quot;pl-3&quot;>
                                                Postal Code: {{ cit.operationsLocation.postalCode }}
                                            </div>
                                        </div>
                                    </div>">
                                            {{ cit?.operationsLocation?.cityName | titlecase }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="edit-link">
                                            <a data-toggle="dropdown">
                                                <div class="dot-icon"></div>
                                            </a>
                                            <!-- <ul class="dropdown-menu" aria-labelledby="menu">
                                        <li>
                                            <a class="dropdown-menu-item" data-toggle="modal" data-backdrop="false">
                                                <img src="assets/images/control-icon-view-details.png" />
                                                Fulfill
                                            </a>
                                        </li>
                                        <li>
                                            <a class="dropdown-menu-item">
                                                <img src="assets/images/control-icon-view-details.png" />
                                                View Details
                                            </a>
                                        </li>
                                    </ul> -->
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                        } @empty {
                            <ng-container>
                                <tr>
                                    <div class="data-empty">
                                        <div class="col-md-12">No data to display</div>
                                    </div>
                                </tr>
                            </ng-container>
                        }
                    </tbody>
                    @if (pagination.showPagination()) {
                        <tfoot>
                            <tr>
                                <td>
                                    Rows per page:
                                    <span>
                                        <b>{{ pagination.length }}</b>
                                    </span>
                                    <app-page-length-dropdown (emitLength)="changeLength($event)">
                                    </app-page-length-dropdown>
                                </td>
                                <td>
                                    <app-page-navigation
                                        pageNumber="{{ pagination.getPageNo() }}"
                                        totalPages="{{ pagination.getTotalPages() }}"
                                        (changePage)="changePage($event)">
                                    </app-page-navigation>
                                </td>
                            </tr>
                        </tfoot>
                    }
                </table>
            </div>
        </section>
    }
</div>
