<div class="wrapper">
    <app-navigation></app-navigation>
    <div class="content-wrapper">
        <app-content-transition></app-content-transition>
        <app-loader *ngIf="isLoading" className="custom-loader" text="Processing ..."></app-loader>
        <app-header title="Transfer To Bank"></app-header>
        <app-transfer-to-bank-links></app-transfer-to-bank-links>
        <section *ngIf="search">
            <div class="search-result-header mt-3">SagePay Exports Log</div>
            <hr />
            <div class="container-fluid tab-pane custom-table">
                <br />
                <table id="tableData">
                    <thead>
                        <tr>
                            <th style="width: 10%">
                                <app-filter-arrow
                                    filter="type"
                                    title="TYPE"
                                    (filterValue)="receiveFilter($event)">
                                </app-filter-arrow>
                            </th>
                            <th>
                                <app-filter-arrow
                                    filter="status"
                                    title="STATUS"
                                    (filterValue)="receiveFilter($event)">
                                </app-filter-arrow>
                            </th>
                            <th>
                                <app-filter-arrow
                                    filter="by"
                                    title="BY"
                                    (filterValue)="receiveFilter($event)">
                                </app-filter-arrow>
                            </th>
                            <th>
                                <app-filter-arrow
                                    filter="exportDate&Time"
                                    title="EXPORT DATE & TIME"
                                    (filterValue)="receiveFilter($event)">
                                </app-filter-arrow>
                            </th>
                            <th style="width: 7%">
                                <app-filter-arrow
                                    filter="rows"
                                    title="ROWS"
                                    (filterValue)="receiveFilter($event)">
                                </app-filter-arrow>
                            </th>
                            <th style="width: 25%">
                                <app-filter-arrow
                                    filter="sagePayKey"
                                    title="SAGEPAY KEY"
                                    (filterValue)="receiveFilter($event)">
                                </app-filter-arrow>
                            </th>
                            <th>
                                <app-filter-arrow
                                    filter="amount"
                                    title="AMOUNT"
                                    (filterValue)="receiveFilter($event)">
                                </app-filter-arrow>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="tablebox" *ngFor="let log of logList">
                            <td style="width: 9%">{{ log?.type | uppercase }}</td>
                            <td>
                                <app-account-status
                                    type="sagePayExportLog"
                                    status="{{ log?.batchReportStatus }}">
                                </app-account-status>
                            </td>
                            <td>{{ log?.exportedBy }}</td>
                            <td>{{ log?.exportDate }}</td>
                            <td style="width: 7%">{{ log?.rows }}</td>
                            <td style="width: 25%; color: #ee8031">
                                {{ log?.batchFileUploadToken }}
                            </td>
                            <td>CZAR {{ log?.amount }}</td>
                        </tr>
                    </tbody>
                    <tfoot *ngIf="pagination.showPagination()">
                        <tr>
                            <td>
                                Rows per page:
                                <span
                                    ><b>{{ pagination.length }}</b></span
                                >
                                <app-page-length-dropdown (emitLength)="changeLength($event)">
                                </app-page-length-dropdown>
                            </td>
                            <td>
                                <app-page-navigation
                                    pageNumber="{{ pagination.getPageNo() }}"
                                    totalPages="{{ pagination.getTotalPages() }}"
                                    (changePage)="changePage($event)">
                                </app-page-navigation>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </section>
    </div>
</div>
