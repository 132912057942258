import { Component, OnInit, Input } from '@angular/core';
import { URI } from 'src/app/settings/uri-constants';

@Component({
    selector: 'app-agent-application-link',
    templateUrl: './agent-application-link.component.html',
    styleUrls: ['./agent-application-link.component.css'],
})
export class AgentApplicationLinkComponent {
    @Input() display = false;
    uri = URI;

    constructor() {}
}
