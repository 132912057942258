import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { Pagination } from 'src/app/model/Pagination';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { DatePipe } from '@angular/common';
import { TransactionService } from 'src/app/services/transaction.service';
import { Transaction } from 'src/app/model/transaction';
import { TransactionStatus } from 'src/app/enums/transaction.status';
import { CommonUtil } from 'src/app/services/common.util';
import { ExportService } from 'src/app/services/export.service';
import { HumanizePipe } from 'src/app/pipes/humanize.pipe';

@Component({
    selector: 'app-transactions',
    templateUrl: './transactions.component.html',
    styleUrls: ['./transactions.component.css'],
})
export class TransactionsComponent implements OnInit {
    id: string;
    loaderText = 'Searching...';
    accountTypeDropdown: string[] = [];
    transactionStatusDropdown: string[] = [];
    transactionTypeDropdown: string[] = [];
    transactionTypeDropdownMapper: any[] = [];
    form: FormGroup;
    invalidForm = false;
    transactionList: Array<Transaction>;
    excelList = new Array<any>();
    pagination = new Pagination();
    isLoading = false;
    showOptions = false;
    search = false;
    progressWidth = 25;
    searchItems: string[] = [];
    itemList = ['role', 'status'];
    orderByList = ['Time and Date', 'Status', 'Transaction Type', 'Amount'];
    orderByValue = ['date', 'status', 'type', 'amount'];
    status = TransactionStatus;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private commonService: CommonService,
        private route: ActivatedRoute,
        private transactionService: TransactionService,
        public commonUtil: CommonUtil,
        private exportService: ExportService,
        private datePipe: DatePipe,
        private humanize: HumanizePipe,
    ) {
        this.form = this.formBuilder.group({
            userId: [''],
            text: [''],
            role: [''],
            transactionTypeList: [[]],
            transactionTypeListTemp: [[]],
            status: [''],
            orderBy: [''],
            fromDate: [''],
            toDate: [''],
            toDateTemp: [''],
            orderByText: [''],
            start: [''],
            length: [''],
            transactionStatus: [[]],
        });
    }

    ngOnInit() {
        this.route.paramMap.subscribe((params) => {
            this.id = params.get('id');
        });

        this.commonService.getAccountType().subscribe((res) => {
            this.accountTypeDropdown = res.data;
        });

        this.transactionService.getTransactionType().subscribe((res) => {
            this.transactionTypeDropdown = res.data;
            this.transactionTypeDropdownMapper = [];
            for (let i = 0; i < this.transactionTypeDropdown.length; i = i + 1) {
                const map = {
                    value: this.transactionTypeDropdown[i],
                    humanisedValue: this.humanize.transform(this.transactionTypeDropdown[i]),
                };
                this.transactionTypeDropdownMapper.push(map);
                this.transactionTypeDropdown[i] = this.humanize.transform(
                    this.transactionTypeDropdown[i],
                );
            }
        });

        this.transactionService.getTransactionStatus().subscribe((res) => {
            this.transactionStatusDropdown = res.data;
        });

        this.form.get('orderByText').valueChanges.subscribe((newVal) => {
            for (let i = 0; i < this.orderByList.length; i++) {
                if (this.orderByList[i] === newVal) {
                    this.form.get('orderBy').setValue(this.orderByValue[i]);
                    this.getData();
                }
            }
        });

        this.form.get('transactionTypeListTemp').valueChanges.subscribe((newVal) => {
            const list = [];

            for (const val of newVal) {
                for (const map of this.transactionTypeDropdownMapper) {
                    if (map.humanisedValue == val) {
                        list.push(map.value);
                    }
                }
            }
            this.form.get('transactionTypeList').setValue(list);
        });
    }

    getData(start = 0, length = 0, download = false) {
        if (download) {
            this.loaderText = 'Downloading...';
        } else {
            length = this.pagination.length;
            start = this.pagination.start;
        }

        this.search = false;
        this.isLoading = true;
        this.form.get('userId').setValue(this.id);
        this.form.get('start').setValue(start);
        this.form.get('length').setValue(length);
        this.setProgressWidth(50);
        this.addTransactionStatus();
        this.checkForDateRange();

        this.commonService.getUserTransactionHistory(this.form).subscribe(
            (res) => {
                this.setProgressWidth(100);
                setTimeout(() => {
                    this.transactionList = res.data;
                    this.excelList = new Array<any>();

                    if (res.data && download) {
                        for (const tran of res.data) {
                            this.excelList.push(this.commonUtil.getExcelofTransaction(tran));
                        }
                        this.exportService.exportExcel(this.excelList, 'TransactionList');
                    } else {
                        this.transactionList = res.data.map((t) =>
                            new Transaction().deserialize(t),
                        );
                        this.pagination.total = !res.total ? 0 : res.total;
                    }

                    this.isLoading = false;
                    this.search = true;
                    this.progressWidth = 25;
                    this.loaderText = 'Searching...';
                }, 350);

                this.searchItems = [];
                for (const item of this.itemList) {
                    if (this.form.get(item).value !== '') {
                        this.searchItems.push(this.form.get(item).value);
                    }
                }
            },
            (error) => {
                this.isLoading = false;
            },
        );
    }

    checkForDateRange() {
        let fromDate = this.form.get('fromDate').value;
        let toDate = this.form.get('toDateTemp').value;

        if (fromDate != '' || toDate != '') {
            if (fromDate == '') {
                fromDate = '2020-01-01';
            }

            if (toDate == '') {
                toDate = new Date();
            }

            let date = this.datePipe.transform(fromDate, 'yyyy-MM-dd');
            this.form.get('fromDate').setValue(date);

            const newDate = new Date(toDate);
            newDate.setDate(newDate.getDate() + 1);
            date = this.datePipe.transform(newDate, 'yyyy-MM-dd');
            this.form.get('toDate').setValue(date);
        }
    }

    clearDates() {
        this.form.get('fromDate').setValue('');
        this.form.get('toDateTemp').setValue('');
        this.form.get('toDate').setValue('');
    }

    addTransactionStatus() {
        const val: string = this.form.get('status').value;
        if (val.length) {
            this.form.get('transactionStatus').setValue([val]);
        } else {
            this.form.get('transactionStatus').setValue([]);
        }
    }

    setProgressWidth(i: number) {
        setTimeout(() => {
            this.progressWidth = i;
        }, 300);
    }

    searchUsers(text: string) {
        this.pagination.text = text;
        this.getData();
    }

    removeSearchItem(value: string) {
        for (const item of this.itemList) {
            if (this.form.get(item).value === value) {
                this.form.get(item).setValue('');
                this.getData();
                break;
            }
        }
    }

    clearText() {
        this.form.get('text').setValue('');
    }

    receiveFilter(data: { name: string; value: number }) {}

    changeLength(len: number) {
        this.pagination.length = len;
        this.pagination.start = 0;
        this.getData();
    }

    changePage(page: number) {
        this.pagination.changePage(page);
        this.getData();
    }

    get controls(): any {
        return this.form.controls;
    }

    toggleOption() {
        this.showOptions = !this.showOptions;
    }

    submit() {
        this.getData();
    }

    downLoadExcel() {
        this.getData(0, 10000, true);
    }
}
