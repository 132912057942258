<div class="control-section">
    <!-- main content declaration -->
    <div id="target">
        <div class="sidebar-content">
            <section class="add-section mt-0 p-0">
                <form [formGroup]="form">
                    <div class="row">
                        <div class="col-md-3">
                            <div>
                                <label class="form-label date-label m-0">START DATE</label>
                            </div>
                            <div class="datepicker">
                                <input
                                    matInput
                                    class="datepicker-input"
                                    [matDatepicker]="fromDatePicker"
                                    name="fromDate"
                                    formControlName="fromDateFilter"
                                    readonly />
                                <mat-datepicker-toggle
                                    matSuffix
                                    [for]="fromDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #fromDatePicker></mat-datepicker>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div>
                                <label class="form-label date-label m-0">END DATE</label>
                            </div>
                            <div class="datepicker">
                                <input
                                    matInput
                                    class="datepicker-input"
                                    [matDatepicker]="toDatePicker"
                                    name="toDate"
                                    formControlName="toDateFilter"
                                    readonly />
                                <mat-datepicker-toggle
                                    matSuffix
                                    [for]="toDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #toDatePicker></mat-datepicker>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <label class="form-label"></label>
                            <div>
                                <button class="search-button" (click)="submit()">Search</button>
                                <a class="advanced-search" (click)="toggleOption()">
                                    <u
                                        ><img
                                            class="mr-2"
                                            src="assets/images/dropdown.png" />ADVANCED SEARCH</u
                                    >
                                </a>
                                <a class="clear-filters" (click)="clearFilters()"
                                    ><u>Clear Filters</u></a
                                >
                            </div>
                        </div>
                    </div>
                    <hr *ngIf="showAdvancedFilters" />
                    <div class="search-form" *ngIf="showAdvancedFilters">
                        <div class="row">
                            <div class="col-md-4">
                                <label class="form-label">STORES</label>
                                <div>
                                    <ng-select
                                        [items]="storeList"
                                        bindLabel="name"
                                        placeholder="Select Stores"
                                        multiple="true"
                                        [formControlName]="userRoleFieldKey"
                                        bindValue="userRoleId"
                                        (change)="onChange($event, userRoleFieldKey)"
                                        (clear)="onClear(userRoleFieldKey)">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label class="form-label">PRODUCTS</label>
                                <div>
                                    <ng-select
                                        [items]="productList"
                                        bindLabel="name"
                                        placeholder="Select Products"
                                        multiple="true"
                                        [formControlName]="productFieldKey"
                                        bindValue="id"
                                        (change)="onChange($event, productFieldKey)"
                                        (clear)="onClear(productFieldKey)">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label class="form-label">BUSINESS UNIT</label>
                                <div>
                                    <ng-select
                                        [items]="businessUnitList"
                                        [bindLabel]="
                                            loginService.isAdmin() ? 'displayName' : 'name'
                                        "
                                        placeholder="Select Business Units"
                                        multiple="true"
                                        [formControlName]="businessFieldKey"
                                        bindValue="id"
                                        (change)="onChange($event, businessFieldKey)"
                                        (clear)="onClear(businessFieldKey)">
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="searched-items">
                        <div *ngFor="let key of searchItems | keyvalue">
                            <div *ngIf="key.value?.length > 0">
                                <div *ngFor="let item of key.value" class="search-item-value">
                                    {{ item | humanize }}
                                    <a (click)="removeSearchItem(key.key, item)">
                                        <img src="assets/images/close-x-white.png" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="search-form" *ngIf="showAdvancedFilters && loginService.isAdmin()">
                        <div class="row">
                            <div class="col-md-4">
                                <label class="form-label">ENTERPRISES</label>
                                <div>
                                    <ng-select
                                        [items]="enterpriseList"
                                        bindLabel="businessName"
                                        placeholder="Select Enterprises"
                                        multiple="true"
                                        [formControlName]="enterpriseFieldKey"
                                        bindValue="id"
                                        (change)="onChange($event, enterpriseFieldKey)"
                                        (clear)="onClear(enterpriseFieldKey)">
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </section>

            <div class="dashboardParent">
                <ejs-dashboardlayout
                    id="salesDashboardLayout"
                    #defaultLayout
                    [cellSpacing]="cellSpacing"
                    [columns]="columns"
                    [allowDragging]="allowDragging">
                    <e-panels>
                        <e-panel [sizeX]="3" [sizeY]="1" [row]="0" [col]="0">
                            <ng-template #header>
                                <div>Sales vs Date</div>
                            </ng-template>

                            <ng-template #content>
                                <div
                                    class="syncfusion-progressbar"
                                    *ngIf="loaderService.isLoading; else svdChartContainer">
                                    <ejs-progressbar
                                        id="svdChartProgressBar"
                                        [type]="progressBarType"
                                        [height]="progressBarHeight"
                                        [value]="progressBarValue"
                                        [progressColor]="progressColor"
                                        [isIndeterminate]="isIndeterminate"
                                        [animation]="animation">
                                    </ejs-progressbar>
                                </div>

                                <ng-template #svdChartContainer>
                                    <ejs-chart
                                        #svdChart
                                        id="svdChartContainer"
                                        [width]="widthValue"
                                        [height]="heightValue"
                                        [primaryXAxis]="svdPrimaryXAxis"
                                        [primaryYAxis]="svdPrimaryYAxis"
                                        [tooltip]="svdTooltip"
                                        [legendSettings]="svdLegendSettings"
                                        (load)="chartLoad($event)">
                                        <e-series-collection>
                                            <e-series
                                                [dataSource]="svdChartData"
                                                type="Column"
                                                columnFacet="Cylinder"
                                                columnSpacing="0.1"
                                                xName="date"
                                                [fill]="svdColumnSeriesFillColour"
                                                name="Sales"
                                                width="2"
                                                yName="sales">
                                            </e-series>
                                            <e-series
                                                [dataSource]="svdChartData"
                                                type="Line"
                                                xName="date"
                                                yName="sales"
                                                name="Sales"
                                                width="2"
                                                [marker]="lineSeriesMarker"
                                                [fill]="svdLineSeriesFillColour">
                                            </e-series>
                                        </e-series-collection>
                                    </ejs-chart>
                                </ng-template>
                            </ng-template>
                        </e-panel>
                        <e-panel [sizeX]="3" [sizeY]="1" [row]="1" [col]="0">
                            <ng-template #header>
                                <div>Sales per Store</div>
                            </ng-template>
                            <ng-template #content>
                                <div
                                    class="syncfusion-progressbar"
                                    *ngIf="loaderService.isLoading; else salesPerStoreContainer">
                                    <ejs-progressbar
                                        id="salesPerStoreProgressBar"
                                        [type]="progressBarType"
                                        [height]="progressBarHeight"
                                        [value]="progressBarValue"
                                        [progressColor]="progressColor"
                                        [isIndeterminate]="isIndeterminate"
                                        [animation]="animation">
                                    </ejs-progressbar>
                                </div>

                                <ng-template #salesPerStoreContainer>
                                    <ejs-accumulationchart
                                        id="salesPerStore"
                                        (load)="pieChartLoad($event)"
                                        #salesPerStorePieChart
                                        [width]="widthValue"
                                        [height]="heightValue"
                                        [legendSettings]="pieChartLegendSettings"
                                        [enableSmartLabels]="enableSmartLabel"
                                        [center]="center">
                                        <e-accumulation-series-collection>
                                            <e-accumulation-series
                                                name="SalesPerStore"
                                                [dataSource]="pieChartData"
                                                xName="store"
                                                yName="sales"
                                                [startAngle]="startAngle"
                                                [endAngle]="endAngle"
                                                innerRadius="0%"
                                                radius="80%"
                                                [dataLabel]="pieDataLabel"
                                                [explode]="explode"
                                                explodeOffset="10%"
                                                [explodeIndex]="2">
                                            </e-accumulation-series>
                                        </e-accumulation-series-collection>
                                    </ejs-accumulationchart>
                                </ng-template>
                            </ng-template>
                        </e-panel>
                        <e-panel [sizeX]="3" [sizeY]="1" [row]="2" [col]="0">
                            <ng-template #header>
                                <div>Top weekly sale</div>
                            </ng-template>
                            <ng-template #content>
                                <div
                                    class="syncfusion-progressbar"
                                    *ngIf="loaderService.isLoading; else topProductsContainer">
                                    <ejs-progressbar
                                        id="topProductsProgressBar"
                                        [type]="progressBarType"
                                        [height]="progressBarHeight"
                                        [value]="progressBarValue"
                                        [progressColor]="progressColor"
                                        [isIndeterminate]="isIndeterminate"
                                        [animation]="animation">
                                    </ejs-progressbar>
                                </div>

                                <ng-template #topProductsContainer>
                                    <ejs-chart
                                        #chart
                                        style="display: block"
                                        align="center"
                                        id="topProductsLineChart"
                                        [title]="
                                            topProductList.length
                                                | i18nPlural: topProductsTitleMapping
                                        "
                                        [primaryXAxis]="primaryXAxis"
                                        [primaryYAxis]="primaryYAxis"
                                        [tooltip]="topProductsTooltip"
                                        (load)="chartLoad($event)"
                                        [chartArea]="chartArea"
                                        [width]="widthValue"
                                        [height]="heightValue"
                                        [legendSettings]="cumulativeLineLegend">
                                        <e-series-collection>
                                            <e-series
                                                *ngFor="
                                                    let topProduct of topProductList;
                                                    let i = index
                                                "
                                                [dataSource]="cumulativeLineData"
                                                type="Line"
                                                xName="date"
                                                [yName]="topProduct"
                                                [name]="topProduct"
                                                width="2"
                                                [marker]="markerTypes[i]"
                                                opacity="1">
                                            </e-series>
                                        </e-series-collection>
                                    </ejs-chart>
                                </ng-template>
                            </ng-template>
                        </e-panel>
                    </e-panels>
                </ejs-dashboardlayout>
            </div>
        </div>
    </div>
</div>
