<div class="wrapper">
    <app-error-model
        message="{{ eMessage }}"
        [display]="display"
        (event)="closeModel($event)"></app-error-model>
    <app-navigation></app-navigation>
    <div class="content-wrapper">
        <app-content-transition></app-content-transition>
        <app-loader *ngIf="isLoading" className="custom-loader" text="Processing ..."></app-loader>
        <form [formGroup]="form">
            <app-header location="Add User"> </app-header>
            <app-select-user-type type="Enterprise"></app-select-user-type>
            <section class="add-section">
                <fieldset>
                    <legend>Enterprise Details</legend>
                    <div class="row">
                        <div class="col-md-6 m-0">
                            <label class="form-label">BUSINESS NAME*</label>
                            <div>
                                <input
                                    class="form-input"
                                    type="text"
                                    formControlName="businessName"
                                    name="businessName"
                                    [ngClass]="formUtils.displayFieldCss(form, 'businessName')"
                                    placeholder="eg. My Spaza" />
                            </div>
                            <app-field-error-display
                                [displayError]="formUtils.isFieldInValid(form, 'businessName')"
                                errorMsg="Business name is required">
                            </app-field-error-display>
                        </div>
                        <div class="col-md-6 m-0">
                            <label class="form-label">BUSINESS IMAGE OR LOGO</label>
                            <div>
                                <label class="form-input-file">
                                    Choose File<input
                                        type="file"
                                        (change)="setFile($event.target.files)"
                                        hidden />
                                </label>
                                <div class="input-file-name">
                                    <span *ngIf="file">{{ file.name }}</span>
                                    <span *ngIf="!file">No File Chosen</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </section>

            <section class="add-section">
                <fieldset formGroupName="address">
                    <legend>Store Address</legend>
                    <div class="row">
                        <div class="col-md-6 m-0">
                            <label for="address.addressLineOne" class="form-label">
                                ADDRESS LINE ONE*
                            </label>
                            <div>
                                <input
                                    class="form-input"
                                    type="text"
                                    placeholder="e.g. abc road"
                                    name="addressLineOne"
                                    formControlName="addressLineOne"
                                    [ngClass]="
                                        formUtils.displayFieldCss(form, 'address.addressLineOne')
                                    " />
                            </div>
                            <app-field-error-display
                                [displayError]="
                                    formUtils.isFieldInValid(form, 'address.addressLineOne')
                                "
                                errorMsg="Address line one is required">
                            </app-field-error-display>
                        </div>

                        <div class="col-md-6 m-0">
                            <label for="address.addressLineTwo" class="form-label">
                                ADDRESS LINE TWO
                            </label>
                            <div>
                                <input
                                    class="form-input"
                                    type="text"
                                    placeholder="e.g. abc road"
                                    name="addressLineTwo"
                                    formControlName="addressLineTwo" />
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-label">BUILDING DETAILS</label>
                            <div>
                                <input
                                    class="form-input"
                                    type="text"
                                    placeholder="e.g. abc road"
                                    name="buildingDetails"
                                    formControlName="buildingDetails" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="address.province" class="form-label">PROVINCE*</label>
                            <div>
                                <ng-select
                                    [items]="provinceList"
                                    bindLabel="province"
                                    [multiple]="false"
                                    [clearable]="false"
                                    placeholder="Select Province"
                                    formControlName="province"
                                    bindValue="id"
                                    [ngClass]="formUtils.displayFieldCss(form, 'address.province')">
                                </ng-select>
                            </div>
                            <app-field-error-display
                                [displayError]="formUtils.isFieldInValid(form, 'address.province')"
                                errorMsg="Select a province">
                            </app-field-error-display>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label for="address.cityId" class="form-label">CITY*</label>
                            <ng-select
                                [items]="cityList"
                                bindLabel="city"
                                placeholder="Select City"
                                [multiple]="false"
                                [clearable]="false"
                                formControlName="cityId"
                                bindValue="id"
                                [ngClass]="formUtils.displayFieldCss(form, 'address.cityId')">
                            </ng-select>
                            <app-field-error-display
                                [displayError]="formUtils.isFieldInValid(form, 'address.cityId')"
                                errorMsg="Select a city">
                            </app-field-error-display>
                        </div>

                        <div class="col-md-6">
                            <label for="address.suburb" class="form-label">SUBURB</label>
                            <div>
                                <input
                                    class="form-input"
                                    type="text"
                                    placeholder="suburb"
                                    name="suburb"
                                    formControlName="suburb"
                                    [ngClass]="formUtils.displayFieldCss(form, 'address.suburb')" />
                            </div>
                            <app-field-error-display
                                [displayError]="formUtils.isFieldInValid(form, 'address.suburb')"
                                errorMsg="Suburb is required">
                            </app-field-error-display>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label for="address.postalCode" class="form-label">POSTAL CODE*</label>
                            <div>
                                <input
                                    class="form-input"
                                    type="text"
                                    placeholder="e.g. 7708"
                                    name="postalCode"
                                    formControlName="postalCode"
                                    [ngClass]="
                                        formUtils.displayFieldCss(form, 'address.postalCode')
                                    " />
                            </div>
                            <app-field-error-display
                                [displayError]="
                                    formUtils.isFieldInValid(form, 'address.postalCode')
                                "
                                errorMsg="Postal Code is required">
                            </app-field-error-display>
                        </div>
                    </div>
                </fieldset>
            </section>

            <!-- <app-bank (bankDetails)="setBankDetails($event)"></app-bank> -->
            <section class="add-section">
                <fieldset formGroupName="bank">
                    <legend>Bank Details</legend>
                    <div class="row">
                        <div class="col-md-6 m-0">
                            <label class="form-label">BANK*</label>
                            <app-select-box
                                placeholderValue="Select Bank"
                                value="bank.bankName"
                                [form]="form"
                                [invalidForm]="invalidForm"
                                [dropDown]="bankNameList"
                                [ngClass]="formUtils.displayFieldCss(form, 'bank.bankName')">
                            </app-select-box>
                            <!-- TODO: Use ng-select for this field and uncomment the code below
                            <app-field-error-display
                                [displayError]="formUtils.isFieldInValid(form, 'bank.bankName')"
                                errorMsg="Select a bank">
                            </app-field-error-display> -->
                        </div>
                        <div class="col-md-6 m-0">
                            <label class="form-label">BRANCH CODE*</label>
                            <div>
                                <input
                                    class="form-input"
                                    type="text"
                                    formControlName="branchCode"
                                    name="branchCode"
                                    [ngClass]="formUtils.displayFieldCss(form, 'bank.branchCode')"
                                    placeholder="Bank Branch Code"
                                    readonly />
                            </div>
                            <app-field-error-display
                                [displayError]="formUtils.isFieldInValid(form, 'bank.branchCode')"
                                errorMsg="Branch code is required">
                            </app-field-error-display>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-label">LOCATION*</label>
                            <div>
                                <input
                                    class="form-input"
                                    type="text"
                                    formControlName="bankLocation"
                                    name="bankLocation"
                                    [ngClass]="formUtils.displayFieldCss(form, 'bank.bankLocation')"
                                    placeholder="e.g. South Africa" />
                            </div>
                            <app-field-error-display
                                [displayError]="formUtils.isFieldInValid(form, 'bank.bankLocation')"
                                errorMsg="Bank location is required">
                            </app-field-error-display>
                        </div>
                        <div class="col-md-6">
                            <label class="form-label">ACCOUNT HOLDER FULL NAME*</label>
                            <div>
                                <input
                                    class="form-input"
                                    type="text"
                                    formControlName="accountHolderName"
                                    name="accountHolderName"
                                    [ngClass]="
                                        formUtils.displayFieldCss(form, 'bank.accountHolderName')
                                    "
                                    placeholder="Full Name" />
                            </div>
                            <app-field-error-display
                                [displayError]="
                                    formUtils.isFieldInValid(form, 'bank.accountHolderName')
                                "
                                errorMsg="Account holder name is required">
                            </app-field-error-display>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-label">ACCOUNT TYPE*</label>
                            <app-select-box
                                placeholderValue="Select Type"
                                value="bank.accountType"
                                [form]="form"
                                [invalidForm]="invalidForm"
                                [ngClass]="formUtils.displayFieldCss(form, 'bank.accountType')"
                                [dropDown]="accountTypeDropdown">
                            </app-select-box>
                            <!-- TODO: Use ng-select for this field and uncomment the code below
                            <app-field-error-display
                                [displayError]="formUtils.isFieldInValid(form, 'bank.accountType')"
                                errorMsg="Account type is required">
                            </app-field-error-display> -->
                        </div>
                        <div class="col-md-6">
                            <label class="form-label">ACCOUNT NUMBER*</label>
                            <div>
                                <input
                                    class="form-input"
                                    type="text"
                                    formControlName="accountNumber"
                                    name="accountNumber"
                                    [ngClass]="
                                        formUtils.displayFieldCss(form, 'bank.accountNumber')
                                    "
                                    placeholder="e.g. 746837462556" />
                            </div>
                            <app-field-error-display
                                [displayError]="
                                    formUtils.isFieldInValid(form, 'bank.accountNumber')
                                "
                                errorMsg="Account number is required">
                            </app-field-error-display>
                        </div>
                    </div>
                </fieldset>
            </section>

            <section class="add-section">
                <fieldset formGroupName="primaryContact">
                    <legend>Primary Contact Details</legend>
                    <div class="row">
                        <div class="col-md-6 m-0">
                            <label class="form-label">NAME*</label>
                            <div>
                                <input
                                    class="form-input"
                                    type="text"
                                    formControlName="firstName"
                                    name="firstName"
                                    [ngClass]="
                                        formUtils.displayFieldCss(form, 'primaryContact.firstName')
                                    "
                                    placeholder="User First Name" />
                            </div>
                            <app-field-error-display
                                [displayError]="
                                    formUtils.isFieldInValid(form, 'primaryContact.firstName')
                                "
                                errorMsg="Firt name is required">
                            </app-field-error-display>
                        </div>
                        <div class="col-md-6 m-0">
                            <label class="form-label">LAST NAME*</label>
                            <div>
                                <input
                                    class="form-input"
                                    type="text"
                                    formControlName="lastName"
                                    name="lastName"
                                    [ngClass]="
                                        formUtils.displayFieldCss(form, 'primaryContact.lastName')
                                    "
                                    placeholder="User Last Name" />
                            </div>
                            <app-field-error-display
                                [displayError]="
                                    formUtils.isFieldInValid(form, 'primaryContact.lastName')
                                "
                                errorMsg="Last name is required">
                            </app-field-error-display>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-label">EMAIL ADDRESS*</label>
                            <div>
                                <input
                                    class="form-input"
                                    type="email"
                                    placeholder="e.g. email@gmail.com"
                                    name="email"
                                    [ngClass]="
                                        formUtils.displayFieldCss(form, 'primaryContact.email')
                                    "
                                    formControlName="email"
                                    email />
                            </div>
                            <small *ngIf="emailExist" class="error-text">
                                {{ emailErrorMsg }}
                            </small>
                            <app-field-error-display
                                [displayError]="
                                    formUtils.isFieldInValid(form, 'primaryContact.email')
                                "
                                errorMsg="Email address is required">
                            </app-field-error-display>
                        </div>
                        <div class="col-md-6">
                            <label class="form-label">MOBILE NUMBER*</label>
                            <div>
                                <input
                                    class="form-input"
                                    type="text"
                                    placeholder="e.g. 8978978999"
                                    name="mobileNumber"
                                    [ngClass]="
                                        formUtils.displayFieldCss(
                                            form,
                                            'primaryContact.mobileNumber'
                                        )
                                    "
                                    formControlName="mobileNumber" />
                            </div>
                            <small *ngIf="mobileExist" class="error-text">
                                {{ mobileErrorMsg }}
                            </small>
                            <app-field-error-display
                                [displayError]="
                                    formUtils.isFieldInValid(form, 'primaryContact.mobileNumber')
                                "
                                errorMsg="Mobile number is required">
                            </app-field-error-display>
                        </div>
                    </div>
                </fieldset>
            </section>

            <section class="add-section">
                <fieldset>
                    <legend>Administrative Settings</legend>
                    <div class="row">
                        <div class="col-md-3 m-0">
                            <label class="form-label pr-3">ADD ACCOUNT TO PAY LIST</label>
                            <label class="switch-container">
                                <input
                                    type="checkbox"
                                    (change)="changeVisibleInPayList()"
                                    [checked]="visibleInPayList" />
                                <span class="slider round"></span>
                            </label>
                        </div>
                        <div class="col-md-3 m-0">
                            <label class="form-label pr-3">DIRECT DISTRIBUTOR</label>
                            <label class="switch-container">
                                <input
                                    type="checkbox"
                                    (change)="changeDirectDistributor()"
                                    [checked]="isDirectDistributor" />
                                <span class="slider round"></span>
                            </label>
                        </div>
                        <div class="col-md-3 m-0">
                            <label class="form-label pr-3">WHOLESALER</label>
                            <label class="switch-container">
                                <input
                                    type="checkbox"
                                    (change)="changeIsWholesaler()"
                                    [checked]="isWholesaler" />
                                <span class="slider round"></span>
                            </label>
                        </div>
                        <div class="col-md-3 m-0">
                            <label class="form-label pr-3">HAS OWN SHOPIFY INSTANCE</label>
                            <label class="switch-container">
                                <input
                                    type="checkbox"
                                    (change)="changeHasOwnShopifyInstance()"
                                    [checked]="hasOwnShopifyInstance" />
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-label">STATUS*</label>
                            <app-select-box
                                placeholderValue="Set Status"
                                value="status"
                                [form]="form"
                                [invalidForm]="invalidForm"
                                [dropDown]="statusDropdown">
                            </app-select-box>
                        </div>
                        <div class="col-md-6">
                            <label class="form-label">KYC LEVEL*</label>
                            <app-select-box
                                placeholderValue="Set Level"
                                value="kycLevel"
                                [form]="form"
                                [invalidForm]="invalidForm"
                                [dropDown]="kycLevelDropdown">
                            </app-select-box>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-label">SHOPIFY PRODUCT LINK NAME*</label>
                            <div>
                                <input
                                    class="form-input"
                                    type="text"
                                    formControlName="productLinkName"
                                    name="productLinkName"
                                    [ngClass]="formUtils.displayFieldCss(form, 'productLinkName')"
                                    placeholder="Shopify Product Link Name" />
                            </div>
                            <app-field-error-display
                                [displayError]="formUtils.isFieldInValid(form, 'productLinkName')"
                                errorMsg="Shopify product link name is required">
                            </app-field-error-display>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <button class="custombtn custombtn-align-right" (click)="submit()">
                                Save
                            </button>
                        </div>
                    </div>
                </fieldset>
            </section>
            <section class="add-section-footer">*Required</section>
        </form>
    </div>
</div>
