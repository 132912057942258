<div class="wrapper">
    <app-navigation></app-navigation>
    <div class="content-wrapper">
        <app-content-transition></app-content-transition>
        <app-loader *ngIf="isLoading" className="custom-loader" text="Processing ..."></app-loader>
        <form [formGroup]="form">
            <app-header title="Banners" location="New Banner"> </app-header>

            <section class="add-section">
                <div class="row">
                    <div class="col-md-8 section-heading">Banner Details</div>
                    <div class="col-md-4">
                        <button class="custombtn custombtn-align-right" (click)="submit()">
                            Save
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6" [ngClass]="displayFieldCss('name')">
                        <label for="name" class="form-label control-label required">NAME</label>
                        <div>
                            <input
                                class="form-input"
                                [ngClass]="displayFieldCss('name')"
                                type="text"
                                formControlName="name"
                                name="name"
                                placeholder="Banner Name" />
                        </div>
                        <app-field-error-display
                            [displayError]="isFieldInValid('name')"
                            errorMsg="Name is required">
                        </app-field-error-display>
                    </div>
                    <div class="col-md-6">
                        <label class="form-label required">IMAGE</label>
                        <div>
                            <input
                                id="bannerImage"
                                class="form-input pt-1"
                                [ngClass]="displayFieldCss('image')"
                                type="file"
                                name="image"
                                placeholder="Image"
                                (change)="onImageSelect($event)" />
                        </div>
                        <app-field-error-display
                            [displayError]="isFieldInValid('image')"
                            errorMsg="{{ imageErrorMsg }}">
                        </app-field-error-display>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6" [ngClass]="displayFieldCss('startDate')">
                        <label class="form-label">START DATE</label>
                        <div class="datepicker-width-85">
                            <input
                                matInput
                                class="datepicker-input"
                                [matDatepicker]="picker"
                                [min]="now"
                                name="startDate"
                                [ngClass]="displayFieldCss('startDate')"
                                (dateChange)="validateDate()"
                                formControlName="startDate"
                                required
                                readonly />
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </div>
                        <app-field-error-display
                            [displayError]="isInvalidStartDate()"
                            errorMsg="{{ startDateErrorMsg }}">
                        </app-field-error-display>
                    </div>
                    <div class="col-md-6" [ngClass]="displayFieldCss('endDate')">
                        <label class="form-label">END DATE</label>
                        <div class="datepicker-width-85">
                            <input
                                matInput
                                class="datepicker-input"
                                [matDatepicker]="picker1"
                                [min]="form.value.startDate"
                                name="endDate"
                                formControlName="endDate"
                                (dateChange)="validateDate()"
                                [ngClass]="displayFieldCss('endDate')"
                                required
                                readonly />
                            <mat-datepicker-toggle
                                matSuffix
                                [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="row text-center mt-3" *ngIf="imageSrc">
                        <div class="col-md-6 offset-md-3">
                            <img src="{{ imageSrc }}" class="card-img-top img-fluid" />
                        </div>
                    </div>
                </div>
            </section>
            <section class="add-section-footer">*Required</section>
        </form>
    </div>
</div>
