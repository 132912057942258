import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { URI } from 'src/app/settings/uri-constants';
import { User } from 'src/app/model/user';
import { StorageConstant } from 'src/app/settings/storage-constants';
import { CommonUtil } from 'src/app/services/common.util';

@Component({
    selector: 'app-last-child',
    templateUrl: './last-child.html',
    styleUrls: ['./last-child.css'],
})
export class LastChildComponent {
    @Input() user: User;

    constructor(
        private router: Router,
        public commonUtil: CommonUtil,
    ) {}

    navigateChangeMobile() {
        localStorage.setItem(StorageConstant.CHANGE_MOBILE, JSON.stringify(this.user));
        this.router.navigate([URI.SLASHED_CHANGE_MOBILE]);
    }
}
