<div class="edit-link">
    <a data-toggle="dropdown">
        <div class="dot-icon"></div>
    </a>
    <ul class="dropdown-menu" aria-labelledby="menu1">
        <li *ngIf="grv.price_difference_claim != null">
            <a class="dropdown-menu-item" (click)="action(appConstants.PDC)">
                <img />
                Price Difference Claim
            </a>
        </li>
        <li *ngIf="grv.short_delivery_claim != null">
            <a class="dropdown-menu-item" (click)="action(appConstants.SDC)">
                <img />
                Short Delivery Claim
            </a>
        </li>
    </ul>
</div>
