import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup } from '@angular/forms';
import { TransferToBank } from 'src/app/model/transfer-to-bank';
import { TransactionStatus } from 'src/app/enums/transaction.status';
import { Pagination } from 'src/app/model/Pagination';
import { Transaction } from 'src/app/model/transaction';
import { CommonService } from 'src/app/services/common.service';
import { LoginService } from 'src/app/services/login.service';
import { TransactionService } from 'src/app/services/transaction.service';
import { TransactionType } from 'src/app/enums/transaction.type';
declare let $: any;

@Component({
    selector: 'app-transfer-to-bank-manual-export',
    templateUrl: './transfer-to-bank-manual-export.component.html',
    styleUrls: ['./transfer-to-bank-manual-export.component.css'],
})
export class TransferToBankManualExportComponent implements OnInit {
    processingComplete = false;
    processing = false;
    isLoading = false;
    sageFile = '';
    todayDate = '';
    rows = 0;
    totalAmount = 0;
    id: string;
    form: FormGroup;
    TTB: TransferToBank = new TransferToBank();
    transactionList: Array<Transaction>;
    transaction: Transaction = new Transaction();
    pagination = new Pagination();
    progressWidth = 25;
    display = false;
    displaySuccess = false;
    eMessage = '';
    sMessage = '';

    constructor(
        private formBuilder: UntypedFormBuilder,
        private commonService: CommonService,
        private transactionService: TransactionService,
        private datePipe: DatePipe,
        private loginService: LoginService,
    ) {
        const date = new Date();
        this.todayDate = this.datePipe.transform(date, 'yyyy-MM-dd');

        this.form = this.formBuilder.group({
            text: [''],
            start: [''],
            length: [''],
            userId: [''],
            role: [''],
            status: [''],
            transactionType: [TransactionType.transfer_to_bank],
            transactionStatus: [[TransactionStatus.pending]],
        });
    }

    ngOnInit() {
        this.getData();
    }

    getData() {
        this.isLoading = true;
        this.form.get('start').setValue(this.pagination.start);
        this.form.get('length').setValue(this.pagination.length);
        this.setProgressWidth(50);
        this.commonService.getUserTransactionHistory(this.form).subscribe(
            (res) => {
                this.setProgressWidth(100);
                setTimeout(() => {
                    this.transactionList = res.data;
                    this.TTB = res.settlementData || new TransferToBank();
                    this.pagination.total = !res.total ? 0 : res.total;
                    this.isLoading = false;
                    this.progressWidth = 25;
                    this.rows = this.pagination.total;
                }, 350);
            },
            (error) => {
                this.isLoading = false;
            },
        );
    }

    setProgressWidth(i: number) {
        setTimeout(() => {
            this.progressWidth = i;
        }, 300);
    }

    get controls(): any {
        return this.form.controls;
    }

    closeExportModel() {
        this.processing = false;
        this.processingComplete = false;
        this.getData();
    }

    closeModel(event: any) {
        this.display = false;
        this.displaySuccess = false;
    }

    noSettlementToExport() {
        if (!this.isLoading && this.rows === 0) {
            this.disableManualExportButton();
            return true;
        } else {
            return false;
        }
    }

    disableManualExportButton() {
        setTimeout(() => {
            $('#ttb-exportbtn').css('opacity', 0.5);
        }, 300);
    }

    process() {
        if (this.rows < 1) {
            return false;
        }

        this.processing = true;
        this.setProgressWidth(25);

        setTimeout(() => {
            this.setProgressWidth(50);
        }, 200);

        this.closeModel(true);
        this.transactionService.runSettlementBatch().subscribe(
            (res) => {
                setTimeout(() => {
                    this.setProgressWidth(100);
                    this.processing = false;
                    this.setProgressWidth(25);
                }, 350);

                if (res.success) {
                    this.sMessage = res.status.message;
                    this.displaySuccess = true;
                } else {
                    this.eMessage = res.status.message;
                    this.display = true;
                }
                this.processingComplete = true;
                this.disableManualExportButton();
            },
            (err) => {
                this.processing = false;
                this.setProgressWidth(25);
                this.eMessage = err.error.error;
                this.display = true;
                this.processingComplete = true;
            },
        );
    }
}
