<section class="content-header">
    <div class="row">
        <div class="col-md-12">
            <div class="tab-heading">
                <span class="tab-heading-title">Smart Safe</span>
                <img
                    alt="Breadcrumb Arrow"
                    class="breadcrumb-separator"
                    src="assets/images/breadcrumb-separator.png" />
                <app-breadcrumb></app-breadcrumb>
            </div>
        </div>
    </div>
    <app-alert></app-alert>
</section>
