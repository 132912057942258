<html>
    <!-- <img src="assets/images/policy.jpg" height="100px" width="100%"> -->
    <h3 class="mt-3 pl-3 pb-3" style="color: #ee8031; background-color: white">
        {{ companyService.getSelectedCompanyName() }} DATA PROTECTION AND PRIVACY POLICY
    </h3>
    <body class="pr-5" style="background-color: #f3f4f9">
        <div class="pl-3 pt-4">
            <p>
                <strong><u>TABLE OF CONTENTS</u></strong>
            </p>
            <table width="100%">
                <tbody>
                    <tr>
                        <td>
                            <p>1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;DEFINITION</p>
                            <p>
                                2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;INTRODUCTION
                            </p>
                            <p>3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PURPOSE</p>
                            <p>
                                4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;APPLICATION
                            </p>
                            <p>
                                5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; NOTIFYING
                                DATA SUBJECTS
                            </p>
                            <p>
                                6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; PROCESS OF
                                COLLECTING PERSONAL INFORMATION
                            </p>
                            <p>
                                7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; LAWFUL
                                PROCESSING OF PERSONAL INFORMATION
                            </p>
                            <p>
                                8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; STORAGE OF
                                PERSONAL INFORMATION
                            </p>
                            <p>
                                9&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; USE OF
                                PERSONAL INFORMATION
                            </p>
                            <p>
                                10&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; PERSONAL INFORMATION
                                FOR DIRECT MARKETING PURPOSES
                            </p>
                            <p>
                                11&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SPECIAL PERSONAL
                                INFORMATION AND PERSONAL INFORMATION OF A CHILD
                            </p>
                            <p>
                                12&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; RETENTION OF PERSONAL
                                INFORMATION
                            </p>
                            <p>
                                13&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; FAILURE TO PROVIDE
                                PERSONAL INFORMATION
                            </p>
                            <p>
                                14&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SAFE-KEEPING OF
                                PERSONAL INFORMATION
                            </p>
                            <p>
                                15&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; PROVISION OF PERSONAL
                                INFORMATION TO THIRD PARTIES
                            </p>
                            <p>
                                16&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ACCESS TO PERSONAL
                                INFORMATION
                            </p>
                            <p>
                                17&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; KEEPING PERSONAL
                                INFORMATION ACCURATE
                            </p>
                            <p>
                                18&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; COSTS TO ACCESS TO
                                PERSONAL INFORMATION
                            </p>
                            <p>
                                19&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; CHANGES TO THIS POLICY
                            </p>
                            <p>20&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; CONTACTING US</p>
                            <p>&nbsp;</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ul>
            <li>
                <a name="_Toc43472593"></a> <strong><u>DEFINITIONS</u></strong>
            </li>
            <p>
                In this Policy (as defined below), unless the context requires otherwise, the
                following capitalised terms shall have the meanings given to them&nbsp;&ndash;
            </p>
            <ul>
                <li>
                    "<strong>Child</strong>" means any natural person under the age of 18 (eighteen)
                    years;
                </li>
                <li>
                    "<strong>Data Subject</strong>" means
                    {{ companyService.getSelectedCompanyName() }}'s clients, agents, business
                    partners and suppliers who may be natural or juristic persons or any other
                    person(s) in respect of whom Cash Processes Personal Information;
                </li>
                <li>"<strong>Employees</strong>" means any employee of Cash;</li>
                <li>
                    "<strong>Cash"</strong> means
                    {{ companyService.getSelectedCompanyName() }} Proprietary Limited, a limited
                    liability private company (registration number&nbsp;2008/027698/07) duly
                    incorporated in accordance with the laws of the Republic of South Africa;
                </li>
                <li>
                    <strong
                        >"{{ companyService.getSelectedCompanyName() }} General Terms and Conditions
                        of Use"</strong
                    >
                    means the terms and conditions regulating the accessing, downloading or
                    otherwise interacting with the
                    {{ companyService.getSelectedCompanyName() }} platform, including the
                    {{ companyService.getSelectedCompanyName() }} website and
                    {{ companyService.getSelectedCompanyName() }} mobile applications, or any
                    associated service offered by {{ companyService.getSelectedCompanyName() }} on
                    any device;
                </li>
                <li>
                    "<strong>Operator</strong>" means a person or entity who Processes Personal
                    Information for a Responsible Party in terms of a contract or mandate, without
                    coming under the direct authority of that Responsible Party;
                </li>
                <li>
                    "<strong>Personal Information</strong>" means information relating to any Data
                    Subject, including but not limited to (i) views or opinions of another
                    individual about the Data Subject; and (ii)&nbsp;information relating to such
                    Data Subject's&nbsp;&ndash;
                    <ul>
                        <li>
                            race, sex, gender, sexual orientation, pregnancy, marital status,
                            nationality, ethnic or social origin, colour, age, physical or mental
                            health, well-being, disability, religion, conscience, belief, cultural
                            affiliation, language and birth;
                        </li>
                        <li>education, medical, financial, criminal or employment history;</li>
                        <li>
                            names, identity number and/or any other personal identifier, including
                            any number(s), which may uniquely identify a Data Subject, account or
                            client number, password, pin code, customer or Data Subject code or
                            number, numeric, alpha, or alpha-numeric design or configuration of any
                            nature, symbol, email address, domain name or IP&nbsp;address, physical
                            address, cellular phone number, telephone number or other particular
                            assignment;
                        </li>
                        <li>blood type, fingerprint or any other biometric information;</li>
                        <li>personal opinions, views or preferences;</li>
                    </ul>
                    <ul>
                        <li>
                            correspondence that is implicitly or expressly of a personal, private or
                            confidential nature (or further correspondence that would reveal the
                            contents of the original correspondence); and
                        </li>
                        <li>
                            corporate structure, composition and business operations (in
                            circumstances where the Data Subject is a juristic person) irrespective
                            of whether such information is in the public domain or not;
                        </li>
                    </ul>
                    <ul>
                        <li>
                            "<strong>Policy</strong>" means this Data Protection and Privacy Policy;
                        </li>
                        <li>
                            "<strong>POPIA</strong>" or "<strong>Act</strong>" means the Protection
                            of Personal Information Act No. 4 of 2013;
                        </li>
                        <li>
                            "<strong>Processing</strong>" means any operation or activity or any set
                            of operations, whether or not by automatic means, concerning Personal
                            Information, including&nbsp;&ndash;
                            <ul>
                                <li>
                                    the collection, receipt, recording, organisation, collation,
                                    storage, updating or modification, retrieval, alteration,
                                    consultation or use;
                                </li>
                                <li>
                                    dissemination by means of transmission, distribution or making
                                    available in any other form by electronic communications or
                                    other means; or
                                </li>
                                <li>
                                    merging, linking, blocking, degradation, erasure or destruction.
                                    For the purposes of this definition, "<strong>Process</strong>"
                                    has a corresponding meaning;
                                </li>
                            </ul>
                        </li>
                        <li>
                            "<strong>Regulator</strong>" means the information regulator established
                            in terms of the Act;
                        </li>
                        <li>
                            "<strong>Responsible Party</strong>" means a public or private body or
                            any other person which alone or in conjunction with others, determines
                            the purpose of and means for Processing Personal Information;
                        </li>
                        <li>
                            "<strong>Special Personal Information</strong>" means Personal
                            Information concerning a Data Subject's religious or philosophical
                            beliefs, race or ethnic origin, trade union membership, political
                            opinions, health, sexual life, biometric information or criminal
                            behaviour; and
                        </li>
                        <li>
                            "<strong>Third-Party</strong>" means an independent contractor, agent,
                            consultant, sub-contractor or other representative of Cash.
                        </li>
                    </ul>
                </li>
            </ul>
        </ul>
        <ul>
            <li>
                <a name="_Toc43472594"></a> <strong><u>INTRODUCTION</u></strong>
                <ul>
                    <li>
                        This Policy regulates the use and protection of Personal Information that
                        Cash Processes.
                    </li>
                    <li>
                        Cash acknowledges the need to ensure that Personal Information is handled
                        with care and is committed to ensuring that it complies with the
                        requirements of the Act for the Processing of Personal Information.
                    </li>
                </ul>
            </li>
        </ul>
        <ul>
            <li>
                <a name="_Toc43472595"></a> <strong><u>PURPOSE</u></strong>
                <ul>
                    <li>
                        POPIA imposes obligations on both public and private bodies for the
                        processing of Personal Information.
                    </li>
                    <li>
                        The purpose of this Policy is to inform Data Subjects about how
                        {{ companyService.getSelectedCompanyName() }} Processes their Personal
                        Information by, <em>inter alia</em>, collecting or collating, receiving,
                        recording, storing, updating, distributing, erasing or destroying,
                        disclosing and/or generally using the Data Subject's Personal Information.
                    </li>
                </ul>
            </li>
        </ul>
        <ul>
            <li>
                <a name="_Toc43472596"></a> <strong><u>APPLICATION</u></strong>
                <ul>
                    <li>
                        Cash, in its capacity as Responsible Party and/or Operator, shall strive to
                        observe, and comply with, its obligations under POPIA as well as
                        internationally accepted information protection principles, practices and
                        guidelines when it Processes Personal Information from or in respect of a
                        Data Subject.
                    </li>
                    <li>
                        This Policy applies to Personal Information collected by Cash in connection
                        with the services offered. This includes information collected offline
                        through our consumer call centres, direct marketing campaigns, sweepstakes
                        and competitions, and online through our websites, branded pages on
                        Third-Party platforms and applications accessed or used through such
                        websites or Third-Party platforms which are operated by or on behalf of
                        {{ companyService.getSelectedCompanyName() }}. This Policy is hereby
                        incorporated into and forms part of the
                        {{ companyService.getSelectedCompanyName() }} General Terms and Conditions
                        of Use. This Privacy Policy does not apply to the information practices of
                        Third Party companies (including, without limitation, their websites,
                        platforms and/or applications) which we do not own or control; or to
                        individuals that {{ companyService.getSelectedCompanyName() }} does not
                        manage or employ. These Third-Party sites may have their own privacy
                        policies and terms and conditions, and we encourage you to read them before
                        using those Third-Party sites.
                    </li>
                </ul>
            </li>
        </ul>
        <ul>
            <li>
                <a name="_Toc43472597"></a> <strong><u>NOTIFYING DATA SUBJECTS</u></strong>
                <ul>
                    <li>
                        Cash will make Data Subjects aware of the fact that it is Processing their
                        Personal Information and inform them of the specific purpose for which
                        {{ companyService.getSelectedCompanyName() }} will be Processing such
                        Personal Information, including making the Data Subject aware of any
                        Third-Party recipients of the Personal Information (which may also include
                        cross-border transfers of Personal Information).
                    </li>
                    <li>
                        Cash will not use the Personal Information of a Data Subject for any purpose
                        other than the disclosed purpose without the consent of the Data Subject,
                        unless {{ companyService.getSelectedCompanyName() }} is permitted or
                        required to do so by law.
                    </li>
                </ul>
            </li>
        </ul>
        <ul>
            <li>
                <a name="_Toc43472598"></a>
                <strong><u>PROCESS OF COLLECTING PERSONAL INFORMATION</u></strong>
                <ul>
                    <li>
                        Cash will always collect Personal Information in a fair, lawful and
                        reasonable manner to ensure that it protects the Data Subject's privacy, and
                        will Process the Personal Information based on legitimate grounds in a
                        manner that does not adversely affect the Data Subject in question.
                    </li>
                    <li>
                        Cash often collects Personal Information directly from the Data Subject
                        and/or from Third-Parties, and where
                        {{ companyService.getSelectedCompanyName() }} obtains Personal Information
                        from Third-Parties, {{ companyService.getSelectedCompanyName() }} will
                        ensure that it obtains the consent of the Data Subject to do so or will only
                        Process the Personal Information without the Data Subject's consent where
                        {{ companyService.getSelectedCompanyName() }} is permitted to do so in terms
                        of applicable laws.
                    </li>
                    <li>
                        An example of such Third Parties include other
                        {{ companyService.getSelectedCompanyName() }} entities; our clients when{{
                            companyService.getSelectedCompanyName()
                        }}
                        handles Personal Information on their behalf; regulatory bodies; credit
                        reference agencies; other companies providing services to
                        {{ companyService.getSelectedCompanyName() }} and where
                        {{ companyService.getSelectedCompanyName() }} makes use of publicly
                        available sources of information.
                    </li>
                </ul>
            </li>
        </ul>
        <ul>
            <li>
                <a name="_Toc43472599"></a>
                <strong><u>LAWFUL PROCESSING OF PERSONAL INFORMATION</u></strong>
                <ul>
                    <li>
                        In terms of POPIA, where Cash is the Responsible Party, it can only Process
                        a Data Subject's Personal Information where&nbsp;&ndash;
                        <ul>
                            <li>
                                consent of the Data Subject (or a competent person where the Data
                                Subject is a Child) is obtained;
                            </li>
                            <li>
                                Processing is necessary to carry out the actions for conclusion of a
                                contract to which a Data Subject is party;
                            </li>
                            <li>
                                Processing complies with an obligation imposed by law on
                                {{ companyService.getSelectedCompanyName() }};
                            </li>
                            <li>Processing protects a legitimate interest of the Data Subject;</li>
                            <li>
                                Processing is necessary for pursuing the legitimate interests of
                                {{ companyService.getSelectedCompanyName() }} or of a third party to
                                whom the information is supplied.
                            </li>
                        </ul>
                    </li>
                    <li>
                        Cash will only Process Personal Information where one of the legal basis
                        referred to in paragraph 7.1 above are present.
                    </li>
                    <li>
                        Where required (i.e. where we are not relying on a legal ground listed in
                        paragraphs&nbsp;1.2 to&nbsp;7.1.5 above),
                        {{ companyService.getSelectedCompanyName() }} will obtain the Data Subject's
                        consent prior to collecting, and in any case prior to using or disclosing,
                        the Personal Information for any purpose.
                    </li>
                    <li>
                        Cash will make the manner and reason for which the Personal Information will
                        be Processed clear to the Data Subject.
                    </li>
                    <li>
                        Where {{ companyService.getSelectedCompanyName() }} is relying on a Data
                        Subject's consent as the legal basis for Processing Personal Information,
                        the Data Subject may withdraw his/her/its consent or may object to
                        {{ companyService.getSelectedCompanyName() }}'s Processing of the Personal
                        Information at any time. This will not affect the lawfulness of any
                        Processing done prior to the withdrawal of consent or any Processing
                        justified by a legal ground set out in paragraphs 1.2 to 7.1.5 above.
                    </li>
                    <li>
                        If the consent is withdrawn or if there is otherwise a justified objection
                        against the use or the Processing of such Personal Information,{{
                            companyService.getSelectedCompanyName()
                        }}
                        will ensure that the Personal Information is no longer Processed<strong
                            >.
                        </strong>
                    </li>
                </ul>
            </li>
        </ul>
        <ul>
            <li>
                <a name="_Toc43472600"></a> <strong><u>STORAGE OF PERSONAL INFORMATION</u></strong>
                <ul>
                    <li>
                        Cash will keep the Personal Information that it Processes on behalf of Data
                        Subjects at its offices in Cape Town.
                    </li>
                    <li>
                        Cash's Third-Party service providers, including data storage and processing
                        providers, may from time to time also have access to a Data Subject's
                        Personal Information in connection with purposes for which the Personal
                        Information was initially collected to be Processed.
                    </li>
                    <li>
                        Cash will ensure that such Third-Party service providers will process the
                        Personal Information in accordance with the provisions of this Policy, all
                        other relevant internal policies and procedures and the Act.
                    </li>
                    <li>
                        Cash may store your Personal Information using its own secure on-site
                        servers or other internally hosted technology. Your personal data may also
                        be stored by Third Parties, via cloud services or other technology, to whom
                        {{ companyService.getSelectedCompanyName() }} has contracted with, to
                        support {{ companyService.getSelectedCompanyName() }}'s business operations.
                    </li>
                    <li>
                        These Third Parties do not use or have access to your Personal Information
                        other than for cloud storage and retrieval, and
                        {{ companyService.getSelectedCompanyName() }} requires such parties to
                        employ at least the same level of security that
                        {{ companyService.getSelectedCompanyName() }} uses to protect your personal
                        data.
                    </li>
                    <li>
                        Your Personal Information may be stored and processed in South Africa or
                        another country where{{ companyService.getSelectedCompanyName() }}, its
                        affiliates and their service providers maintain servers and facilities and
                        {{ companyService.getSelectedCompanyName() }} will take steps, including by
                        way of contracts, to ensure that it continues to be protected regardless of
                        its location in a manner consistent with the standards of protection
                        required under applicable law.
                    </li>
                </ul>
            </li>
        </ul>
        <ul>
            <li>
                <a name="_Toc43472601"></a> <strong><u>USE OF PERSONAL INFORMATION</u></strong>
                <ul>
                    <li>
                        Cash will only Process a Data Subject's Personal Information for a specific,
                        lawful and clear purpose (or for specific, lawful and clear purposes) and
                        will ensure that it makes that Data Subject aware of such purpose(s) as far
                        as possible.
                    </li>
                    <li>
                        It will ensure that there is a legal basis for the Processing of any
                        Personal Information. Further,
                        {{ companyService.getSelectedCompanyName() }}
                        will ensure that Processing will relate only to the purpose for and of which
                        the Data Subject has been made aware (and where relevant, consented to) and
                        will not Process any Personal Information for any other purpose(s).
                    </li>
                    <li>
                        Cash may use Personal Information for the following purposes&nbsp;&ndash;
                        <ul>
                            <li>
                                for the purpose of providing any legal or advisory services to the
                                Data Subject from time to time;
                            </li>
                            <li>
                                for the purposes of receiving services or products provided by the
                                Data Subject to {{ companyService.getSelectedCompanyName() }} from
                                time to time;
                            </li>
                            <li>
                                to respond to any correspondence that the Data Subject may send to{{
                                    companyService.getSelectedCompanyName()
                                }}, including via email or by telephone;
                            </li>
                            <li>
                                to contact the Data Subject from time to time, where specific
                                consent has been given to follow-up contacts by
                                {{ companyService.getSelectedCompanyName() }} or to be put on a
                                {{ companyService.getSelectedCompanyName() }} mailing list;
                            </li>
                            <li>
                                for such other purposes to which the Data Subject may consent from
                                time to time; and
                            </li>
                            <li>for such other purposes authorised in terms of applicable law.</li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
        <ul>
            <li>
                <a name="_Toc43472602"></a>
                <strong><u>PERSONAL INFORMATION FOR DIRECT MARKETING PURPOSES</u></strong>
                <ul>
                    <li>
                        Cash acknowledges that it may only use Personal Information to contact the
                        Data Subject for purposes of direct marketing from time to time where it is
                        permissible to do so.
                    </li>
                    <li>
                        It may use Personal Information to contact any Data Subject and/or market
                        {{ companyService.getSelectedCompanyName() }}'s services directly to the
                        Data Subject(s) if (i) the Data Subject is one of
                        {{ companyService.getSelectedCompanyName() }}' existing clients; (ii) the
                        Data Subject has requested to receive marketing material from
                        {{ companyService.getSelectedCompanyName() }}; or (iii)
                        {{ companyService.getSelectedCompanyName() }} has the Data Subject's consent
                        to market its services directly to the Data Subject.
                    </li>
                    <li>
                        If the Data Subject is an existing client,
                        {{ companyService.getSelectedCompanyName() }} will only use his/her/its
                        Personal Information if it had obtained the Personal Information through the
                        provision of a service to the Data Subject and only in relation to similar
                        services to the ones
                        {{ companyService.getSelectedCompanyName() }} previously provided to the
                        Data Subject.
                    </li>
                    <li>
                        Cash will ensure that a reasonable opportunity is given to the Data Subject
                        to object to the use of their Personal Information for
                        {{ companyService.getSelectedCompanyName() }}'s marketing purposes when
                        collecting the Personal Information and on the occasion of each
                        communication to the Data Subject for purposes of direct marketing.
                    </li>
                    <li>
                        Cash will not use your Personal Information to send you marketing materials
                        unless you have consented to receive them. If you request that we stop
                        Processing your Personal Information for marketing purposes,
                        {{ companyService.getSelectedCompanyName() }} shall do so. We encourage that
                        such requests to opt-out of marketing be made via forms and links provided
                        for that purpose in the marketing materials sent to you.
                    </li>
                </ul>
            </li>
        </ul>
        <ul>
            <li>
                <a name="_Toc43472603"></a>
                <strong
                    ><u>SPECIAL PERSONAL INFORMATION AND PERSONAL INFORMATION OF A CHILD</u></strong
                >
                <ul>
                    <li>
                        Special Personal Information is sensitive Personal Information of a Data
                        Subject.
                    </li>
                    <li>
                        Cash acknowledges that it is not allowed to Process Special Personal
                        Information unless:
                        <ul>
                            <li>
                                Processing is carried out in accordance with the Data Subject's
                                express consent;
                            </li>
                            <li>
                                Processing is necessary for the establishment, exercise or defence
                                of a right or obligation in law;
                            </li>
                            <li>
                                Processing is necessary to comply with an obligation of
                                international public law;
                            </li>
                            <li>
                                Processing is for historical, statistical or research purposes,
                                subject to stipulated safeguards;
                            </li>
                            <li>
                                information has deliberately been made public by the Data Subject;
                                or
                            </li>
                            <li>specific authorisation has been obtained in terms of POPIA.</li>
                        </ul>
                    </li>
                    <li>
                        Cash acknowledges that it may not Process any Personal Information
                        concerning a Child and will only do so where it has obtained the consent of
                        the parent or guardian of that Child or where it is permitted to do so in
                        accordance with applicable laws.
                    </li>
                </ul>
            </li>
        </ul>
        <ul>
            <li>
                <a name="_Toc43472604"></a>
                <strong><u>RETENTION of PERSONAL INFORMATION</u></strong>
                <ul>
                    <li>
                        Cash may keep records of the Personal Information it has collected,
                        corresponded using or commented on in an electronic or hardcopy file format.
                    </li>
                    <li>
                        Cash will retain Personal Information for as long as necessary to fulfil the
                        purposes for which that Personal Information was collected and/or as
                        permitted or required by applicable law.
                    </li>
                    <li>
                        Cash may retain Personal Information for longer periods for statistical,
                        historical or research purposes, and should this occur,
                        {{ companyService.getSelectedCompanyName() }} will ensure that appropriate
                        safeguards have been put in place to ensure that all recorded Personal
                        Information will continue to be Processed in accordance with this Policy and
                        the applicable laws.
                    </li>
                    <li>
                        Once the purpose for which the Personal Information was initially collected
                        and Processed no longer applies or becomes obsolete,
                        {{ companyService.getSelectedCompanyName() }} will ensure that the Personal
                        Information is deleted, destroyed or de-identified sufficiently so that a
                        person cannot re-identify such Personal Information.
                    </li>
                    <li>
                        In instances where we anonymise your Personal Information for research or
                        statistical purposes, {{ companyService.getSelectedCompanyName() }} may use
                        such anonymised information indefinitely without further notice to you.
                    </li>
                </ul>
            </li>
        </ul>
        <ul>
            <li>
                <a name="_Toc43472605"></a
                ><strong><u>FAILURE TO PROVIDE PERSONAL INFORMATION</u></strong>
                <ul>
                    <li>
                        Should {{ companyService.getSelectedCompanyName() }} need to collect
                        Personal Information by law, such as in-relation to anti-money laundering or
                        under the terms of a contract that
                        {{ companyService.getSelectedCompanyName() }} may have with you and you fail
                        to provide the personal data when requested, we may be unable to perform the
                        contract we have or are attempting to enter into with you.
                    </li>
                    <li>
                        In such a case, {{ companyService.getSelectedCompanyName() }} may have to
                        decline to provide or receive the relevant services, and you will be
                        notified where this is the case.
                    </li>
                </ul>
            </li>
        </ul>
        <ul>
            <li>
                <a name="_Toc43472606"></a
                ><strong><u>SAFE-KEEPING OF PERSONAL INFORMATION</u></strong>
                <ul>
                    <li>
                        Cash has implemented physical, organisational, contractual and technological
                        security measures to keep all Personal Information secure, including
                        measures protecting any Personal Information from loss or theft, and
                        unauthorised access, disclosure, copying, use or modification.
                    </li>
                    <li>
                        Cash will notify the Regulator and the affected Data Subject (unless the law
                        requires that we delay notification to the Data Subject) in writing in the
                        event of a security breach (or a reasonable belief of a security breach) in
                        respect of that Data Subject's Personal Information.
                    </li>
                    <li>
                        Cash will provide such notification as soon as reasonably possible after it
                        has become aware of any security breach of such Data Subject's Personal
                        Information.
                    </li>
                </ul>
            </li>
        </ul>
        <ul>
            <li>
                <a name="_Toc43472607"></a
                ><strong><u>PROVISION OF PERSONAL INFORMATION TO THIRD PARTIES</u></strong>
                <ul>
                    <li>
                        Cash may disclose Personal Information to Third-Party service providers and
                        will enter into written agreements with such Third-Party service providers
                        to ensure that they Process any Personal Information in accordance with the
                        provisions of this Policy and the Act.
                    </li>
                    <li>
                        Cash notes that such Third Parties may assist
                        {{ companyService.getSelectedCompanyName() }} with the purposes listed in
                        paragraph 9.3 above &ndash; for example, service providers may be used,
                        <em>inter alia</em>, to: provide telephone support, assist in the provision
                        of IT or marketing products or services, notify the Data Subjects of any
                        pertinent information concerning
                        {{ companyService.getSelectedCompanyName() }}, and/or for data storage.
                    </li>
                    <li>
                        Cash will disclose Personal Information with the consent of the Data Subject
                        or if {{ companyService.getSelectedCompanyName() }} is permitted to do so
                        without such consent in accordance with the applicable laws.
                    </li>
                    <li>
                        Further, {{ companyService.getSelectedCompanyName() }} may also send
                        Personal Information to a foreign jurisdiction outside of the Republic of
                        South Africa in order to achieve the purpose(s) for which the Personal
                        Information was collected and Processed, including for Processing and
                        storage by Third-Party service providers.
                    </li>
                    <li>
                        When Personal Information is transferred to a jurisdiction outside of the
                        Republic of South Africa,
                        {{ companyService.getSelectedCompanyName() }}
                        will obtain the necessary consent to transfer the Personal Information to
                        such foreign jurisdiction or may transfer the Personal Information without
                        the necessary consent where {{ companyService.getSelectedCompanyName() }} is
                        permitted to do so in accordance with the laws applicable to the
                        trans-border flows of Personal Information under the Act.
                    </li>
                    <li>
                        The Data Subject should also take note that the Processing of Personal
                        Information in a foreign jurisdiction may be subject to the laws of the
                        country in which the Personal Information is held, and may be subject to
                        disclosure to the governments, courts of law, enforcement or regulatory
                        agencies of such other country, pursuant to the laws of such country.
                    </li>
                </ul>
            </li>
        </ul>
        <ul>
            <li>
                <a name="_Toc43472608"></a><strong><u>ACCESS TO PERSONAL INFORMATION</u></strong>
                <ul>
                    <li>
                        Cash may request the Data Subject to provide sufficient identification to
                        permit access to, or provide information regarding the existence, use or
                        disclosure of the Data Subject's Personal Information.
                    </li>
                    <li>
                        Any such identifying information shall only be used for the purpose of
                        facilitating access to or information regarding the Personal Information.
                    </li>
                    <li>
                        The Data Subject can, request in writing, to review any Personal Information
                        about the Data Subject that
                        {{ companyService.getSelectedCompanyName() }} holds including Personal
                        Information that {{ companyService.getSelectedCompanyName() }} has
                        collected, utilised or disclosed.
                    </li>
                    <li>
                        Cash will provide the Data Subject with any such Personal Information to the
                        extent required by law and any of
                        {{ companyService.getSelectedCompanyName() }}'s policies and procedures
                        which apply in terms of the Promotion of Access to Information Act, No 2 of
                        2000 ("<strong>PAIA</strong>").
                    </li>
                    <li>
                        The Data Subject can challenge the accuracy or completeness of his/her/its
                        Personal Information in {{ companyService.getSelectedCompanyName() }}'s
                        records at any time.
                    </li>
                    <li>
                        If a Data Subject successfully demonstrates that their Personal Information
                        in our records is inaccurate or incomplete,
                        {{ companyService.getSelectedCompanyName() }} will ensure that such Personal
                        Information is amended or deleted as required (including by any Third
                        Parties).
                    </li>
                </ul>
            </li>
        </ul>
        <ul>
            <li>
                <a name="_Toc43472609"></a
                ><strong><u>KEEPING PERSONAL INFORMATION ACCURATE</u></strong>
                <ul>
                    <li>
                        Cash will take reasonable steps to ensure that all Personal Information is
                        kept as accurate, complete and up-to-date as reasonably possible.
                    </li>
                    <li>
                        Cash may not always expressly request the Data Subject to verify and update
                        his/her/its Personal Information, unless this process is specifically
                        necessary.
                    </li>
                    <li>
                        Cash, however, expects that the Data Subject will notify
                        {{ companyService.getSelectedCompanyName() }} from time to time in writing
                        of any updates required in respect of his/her/its Personal Information.
                    </li>
                </ul>
            </li>
        </ul>
        <ul>
            <li>
                <a name="_Toc43472610"></a
                ><strong><u>COSTS TO ACCESS TO PERSONAL INFORMATION</u></strong>
                <ul>
                    <li>
                        The prescribed fees to be paid for copies of the Data Subject's Personal
                        Information are listed in Cash's PAIA Manual.
                    </li>
                </ul>
            </li>
        </ul>
        <ul>
            <li>
                <a name="_Toc43472611"></a><strong><u>CHANGES TO THIS POLICY</u></strong>
                <ul>
                    <li>
                        Cash reserves the right to make amendments to this Policy from time to time
                        and will use reasonable efforts to notify Data Subjects of such amendments.
                        <strong>OR</strong>
                    </li>
                    <li>
                        19.2 [{{ companyService.getSelectedCompanyName() }} will update this Policy
                        from time to time. Any changes will be posted on this page with an updated
                        revision date. If {{ companyService.getSelectedCompanyName() }} makes any
                        material changes, {{ companyService.getSelectedCompanyName() }} will provide
                        notice through the service itself or by other means.<strong>OR</strong>
                    </li>
                    <li>
                        19.3 {{ companyService.getSelectedCompanyName() }} may regularly amend or
                        add new terms to this Policy - please review it regularly to make sure you
                        are familiar with the content. Any changes shall come into effect
                        immediately and automatically. <strong>OR</strong>
                    </li>
                    <li>
                        19.4 {{ companyService.getSelectedCompanyName() }} may amend this Policy
                        from time to time and we will give you notice of any material changes within
                        a reasonable time, however, we recommend that you familiarise yourself with
                        this Policy regularly. The current version of this Policy will govern the
                        respective rights and obligations between you and
                        {{ companyService.getSelectedCompanyName() }} each time that you access and
                        use our site.]
                    </li>
                </ul>
            </li>
        </ul>
        <ul>
            <li>
                <a name="_Toc43472612"></a><strong><u>CONTACTING US</u></strong>
                <ul>
                    <li>
                        All comments, questions, concerns or complaints regarding your Personal
                        Information or this Policy, should be forwarded to us as
                        follows&nbsp;&mdash;
                    </li>
                    <li>
                        <p><strong>Johannesburg</strong>:</p>
                        <p>Tel. <strong>[</strong><strong>●]</strong></p>
                        <p>Fax. <strong>[</strong><strong>●]</strong></p>
                        <p>Address: <strong>[</strong><strong>●]</strong></p>
                        <p>Postal:&nbsp;&nbsp;&nbsp; <strong>[</strong><strong>●]</strong></p>
                        <p>Docex:&nbsp;&nbsp;&nbsp; <strong>[</strong><strong>●]</strong></p>
                    </li>
                    <li>
                        <p><strong>Cape Town</strong>:</p>
                        <p>Tel. <strong>[</strong><strong>●]</strong></p>
                        <p>Fax. <strong>[</strong><strong>●]</strong></p>
                        <p>Address: <strong>[</strong><strong>●]</strong></p>
                        <p>Postal:&nbsp;&nbsp;&nbsp; <strong>[</strong><strong>●]</strong></p>
                        <p>Docex:&nbsp;&nbsp;&nbsp; <strong>[</strong><strong>●]</strong></p>
                    </li>
                </ul>
                <ul>
                    <li>
                        If a Data Subject is unsatisfied with the manner in which
                        {{ companyService.getSelectedCompanyName() }} addresses any complaint with
                        regard to {{ companyService.getSelectedCompanyName() }}' Processing of
                        Personal Information, the Data Subject can contact the office of the
                        Regulator, the details of which are set out below&nbsp;&ndash;
                    </li>
                    <li>
                        <p>Website: http://justice.gov.za/inforeg/</p>
                        <p>Tel: 012 406 4818</p>
                        <p>Fax: 086 500 3351</p>
                        <p>
                            Email:
                            <a href="mailto:inforeg@justice.gov.za"
                                ><strong>inforeg&#64;justice.gov.za</strong></a
                            >
                        </p>
                    </li>
                </ul>
            </li>
        </ul>
    </body>
</html>
