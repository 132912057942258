import { Injectable } from '@angular/core';

declare let window: any;

@Injectable({ providedIn: 'root' })
export class ModalService {
    private modal: any;

    constructor() { }

    // call this function to open modal by passing modal id - WIP
    open(id: string) {
        this.modal = new window.bootstrap.Modal(document.getElementById(id));
        this.modal._config.backdrop = false;
        this.modal.show();
    }

    close(id: string) {
        this.modal.hide();
    }
}
