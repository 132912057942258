<div *ngIf="type === 'user'">
    <div *ngIf="status" class="status-div">
        <div class="circle-left circle-green" *ngIf="status === userStatusEnum.active"></div>
        <div class="circle-left circle-red" *ngIf="status === userStatusEnum.disabled"></div>
        <div
            class="circle-left circle-yello"
            *ngIf="status === userStatusEnum.pending_review"></div>
        {{ 'Personal User' | humanize }}
    </div>
    <div *ngIf="businessStatus" class="status-div">
        <div
            class="circle-left circle-green"
            *ngIf="businessStatus === userStatusEnum.active"></div>
        <div
            class="circle-left circle-red"
            *ngIf="businessStatus === userStatusEnum.disabled"></div>
        <div
            class="circle-left circle-yello"
            *ngIf="businessStatus === userStatusEnum.pending_review"></div>
        {{ 'Business User' | humanize }}
    </div>
    <div *ngIf="agentStatus" class="status-div">
        <div class="circle-left circle-green" *ngIf="agentStatus === userStatusEnum.active"></div>
        <div class="circle-left circle-red" *ngIf="agentStatus === userStatusEnum.disabled"></div>
        <div
            class="circle-left circle-yello"
            *ngIf="agentStatus === userStatusEnum.pending_review"></div>
        {{ 'Agent' | humanize }}
    </div>
    <div *ngIf="enterpriseStatus" class="status-div">
        <div
            class="circle-left circle-green"
            *ngIf="enterpriseStatus === userStatusEnum.active"></div>
        <div
            class="circle-left circle-red"
            *ngIf="enterpriseStatus === userStatusEnum.disabled"></div>
        <div
            class="circle-left circle-yello"
            *ngIf="enterpriseStatus === userStatusEnum.pending_review"></div>
        {{ 'Enterprise' | humanize }}
    </div>
    <div *ngIf="employeeStatus" class="status-div">
        <div
            class="circle-left circle-green"
            *ngIf="employeeStatus === userStatusEnum.active"></div>
        <div
            class="circle-left circle-red"
            *ngIf="employeeStatus === userStatusEnum.disabled"></div>
        <div class="circle-left circle-red" *ngIf="employeeStatus === 'deactivated'"></div>
        <div
            class="circle-left circle-yello"
            *ngIf="employeeStatus === userStatusEnum.pending_review"></div>
        {{ (employeeStatus === 'disabled' ? 'deactivated' : employeeStatus) | humanize }}
    </div>
    <div *ngIf="staffStatus" class="status-div">
        <div class="circle-left circle-green" *ngIf="staffStatus === userStatusEnum.active"></div>
        <div class="circle-left circle-red" *ngIf="staffStatus === userStatusEnum.disabled"></div>
        <div
            class="circle-left circle-yello"
            *ngIf="staffStatus === userStatusEnum.pending_review"></div>
        {{ 'Staff User' | humanize }}
    </div>
    <div *ngIf="smartSafeStatus" class="status-div">
        <div class="circle-left circle-green" *ngIf="smartSafeStatus === 'active'"></div>
        <div class="circle-left circle-red" *ngIf="smartSafeStatus === 'deactivated'"></div>
        <!-- {{ smartSafeStatus | humanize }} -->
    </div>
</div>

<div *ngIf="type === 'file'">
    <div *ngIf="status" class="status-div">
        <div class="circle-left circle-green" *ngIf="status === fileStatusEnum.verified"></div>
        <div
            class="circle-left circle-red"
            *ngIf="status === fileStatusEnum.verification_failed"></div>
        <div
            class="circle-left circle-yello"
            *ngIf="status === fileStatusEnum.pending_verification"></div>
        {{ 'Personal User Identity' | humanize }}
    </div>
    <div *ngIf="businessStatus" class="status-div">
        <div
            class="circle-left circle-green"
            *ngIf="businessStatus === fileStatusEnum.verified"></div>
        <div
            class="circle-left circle-red"
            *ngIf="businessStatus === fileStatusEnum.verification_failed"></div>
        <div
            class="circle-left circle-yello"
            *ngIf="businessStatus === fileStatusEnum.pending_verification"></div>
        {{ 'Business User Identity' | humanize }}
    </div>
    <div *ngIf="agentStatus" class="status-div">
        <div class="circle-left circle-green" *ngIf="agentStatus === fileStatusEnum.verified"></div>
        <div
            class="circle-left circle-red"
            *ngIf="agentStatus === fileStatusEnum.verification_failed"></div>
        <div
            class="circle-left circle-yello"
            *ngIf="agentStatus === fileStatusEnum.pending_verification"></div>
        {{ status | humanize }}
    </div>
</div>
<div *ngIf="type === 'transaction'">
    <div *ngIf="transactionStatus" class="status-div">
        <div
            class="circle-left circle-green"
            *ngIf="transactionStatus === transactionStatusEnum.success"></div>
        <div
            class="circle-left circle-blue"
            *ngIf="transactionStatus === transactionStatusEnum.onhold"></div>
        <div
            class="circle-left circle-red"
            *ngIf="transactionStatus === transactionStatusEnum.failed"></div>
        <div
            class="circle-left circle-red"
            *ngIf="transactionStatus === transactionStatusEnum.cancelled"></div>
        <div
            class="circle-left circle-yello"
            *ngIf="transactionStatus === transactionStatusEnum.pending"></div>
        <div
            class="circle-left circle-orange"
            *ngIf="transactionStatus === transactionStatusEnum.processing"></div>
        {{ transactionStatus === 'onhold' ? 'On Hold' : (transactionStatus | humanize) }}
    </div>
</div>
<div *ngIf="type === 'bank_transfer'">
    <div *ngIf="transactionStatus" class="status-div">
        <div
            class="circle-left circle-green"
            *ngIf="transactionStatus === transactionStatusEnum.success"></div>
        <div
            class="circle-left circle-green"
            *ngIf="transactionStatus === transactionStatusEnum.settled"></div>
        <div
            class="circle-left circle-red"
            *ngIf="transactionStatus === transactionStatusEnum.failed"></div>
        <div
            class="circle-left circle-red"
            *ngIf="transactionStatus === transactionStatusEnum.cancelled"></div>
        <div
            class="circle-left circle-yello"
            *ngIf="transactionStatus === transactionStatusEnum.pending"></div>
        <div class="circle-left circle-yello" *ngIf="transactionStatus === 'payment pending'"></div>
        <div
            class="circle-left circle-orange"
            *ngIf="transactionStatus === transactionStatusEnum.processing"></div>
        {{ transactionStatus === 'success' ? 'received' : (transactionStatus | humanize) }}
    </div>
</div>
<div *ngIf="type === 'sagePayExportLog'">
    <div *ngIf="status" class="status-div">
        <div
            class="circle-left circle-green"
            *ngIf="status === sagepayExportLogStatus.successful"></div>
        <div
            class="circle-left circle-red"
            *ngIf="status === sagepayExportLogStatus.successfulWithErrors"></div>
        <div
            class="circle-left circle-red"
            *ngIf="status === sagepayExportLogStatus.unsuccessful"></div>
        {{ status | humanize }}
    </div>
</div>

<div *ngIf="type === 'banner'" class="status-div">
    <div class="circle-left circle-green" *ngIf="status === 'true'"></div>
    <div class="circle-left circle-red" *ngIf="status !== 'true'"></div>
    {{ status | humanize }}
</div>

<!-- Verify Shopify Financial status =>> pending, paid, unpaid, canceled -->
<div *ngIf="type === 'productOrder'" class="status-div {{ cssClass }}">
    <div class="circle-left circle-green" *ngIf="status === 'paid'"></div>
    <div class="circle-left circle-green" *ngIf="status === 'fulfilled'"></div>
    <div class="circle-left circle-yello" *ngIf="status === 'unfulfilled'"></div>
    <div class="circle-left circle-yello" *ngIf="status === 'partially_refunded'"></div>
    <div class="circle-left circle-yello" *ngIf="status === 'refunded'"></div>
    {{ status | humanize }}
</div>

<!-- <div *ngIf="type === 'banner'" class="status-div">
    <div class="circle-left circle-green" *ngIf="status === 'true'"></div>
    <div class="circle-left circle-red" *ngIf="status !== 'true'"></div>
    {{status | humanize}}
</div>
 -->
