import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-content-transition',
    templateUrl: './content-transition.component.html',
    styleUrls: ['./content-transition.component.css'],
})
export class ContentTransitionComponent {
    imgArrowSrc: string;

    constructor() {
        this.imgArrowSrc = 'assets/images/left.png';
    }

    onArrowMouseOver() {
        this.imgArrowSrc = 'assets/images/left-rollover.png';
    }

    onArrowMouseOut() {
        this.imgArrowSrc = 'assets/images/left.png';
    }
}
