import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';

@Component({
    selector: 'app-product-orders',
    templateUrl: './product-orders.component.html',
    styleUrls: ['./product-orders.component.css'],
})
export class ProductOrdersComponent {
    form: FormGroup;

    constructor(private formBuilder: UntypedFormBuilder) {
        this.form = this.formBuilder.group({
            start: [''],
            length: [''],
            enterpriseId: [],
            orderNumber: [''],
            paymentStatus: [''],
            fulfilled: [null],
            fullfilmentStatus: [''],
        });
    }
}
