export class DocumentFile {
    public fileType: string;
    public filePath: string;
    public fileName: string;
    public verificationType: string;
    public status: string;
    public file: string;
    public modified = false;

    constructor() {}

    public setFields(fileType: string, file: File, base64File: string) {
        this.fileName = file.name;
        this.fileType = fileType;
        this.file = base64File;
        this.filePath = '';
        this.modified = true;
    }
}
