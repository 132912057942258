<section class="content">
    <!-- Default box -->
    <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item">
            <a
                class="nav-link"
                routerLinkActive="tab-link-active"
                [routerLink]="['/manage-users/view/profile/', id, 'summary']">
                Summary</a
            >
        </li>
        <li class="nav-item">
            <a
                class="nav-link"
                routerLinkActive="tab-link-active"
                [routerLink]="['/manage-users/view/profile/', id, 'personal']">
                Personal Details</a
            >
        </li>
        <li class="nav-item" *ngIf="user.businessStatus !== null">
            <a
                class="nav-link"
                routerLinkActive="tab-link-active"
                [routerLink]="['/manage-users/view/profile/', id, 'business']">
                Business Details</a
            >
        </li>
        <li class="nav-item" *ngIf="user.agentStatus !== null">
            <a
                class="nav-link"
                routerLinkActive="tab-link-active"
                [routerLink]="['/manage-users/view/profile/', id, 'agent']">
                Agent Details</a
            >
        </li>
        <li class="nav-item">
            <a
                class="nav-link"
                routerLinkActive="tab-link-active"
                [routerLink]="['/manage-users/view/profile/', id, 'files']">
                Files</a
            >
        </li>
        <li class="nav-item">
            <a
                class="nav-link"
                routerLinkActive="tab-link-active"
                [routerLink]="['/manage-users/view/profile/', id, 'settings']">
                Administrative Settings</a
            >
        </li>
        <li class="nav-item">
            <a
                class="nav-link"
                routerLinkActive="tab-link-active"
                [routerLink]="['/manage-users/view/profile/', id, 'transactions']">
                Transactions</a
            >
        </li>
    </ul>
</section>
