<div>
    @if (updateErrors?.length > 0) {
        <div class="row mt-3 mb-0">
            <div class="col">
                <ul class="errors-list">
                    @for (error of updateErrors; track $index) {
                        <li>
                            {{ error }}
                        </li>
                    }
                </ul>
            </div>
        </div>
    }
    <form [formGroup]="form">
        <section class="add-section">
            <div class="row">
                <div class="col-md-12">
                    <button
                        type="button"
                        class="custombtn-cancel custombtn-align-right"
                        (click)="goToIndex()">
                        Cancel
                    </button>
                    <button
                        type="submit"
                        class="custombtn custombtn-align-right"
                        (click)="submit()">
                        Save
                    </button>
                </div>
            </div>
            <fieldset>
                <legend>Company Details</legend>

                <div class="row">
                    <div class="col-md-6" [ngClass]="formUtils.displayFieldCss(form, 'name')">
                        <label class="form-label control-label required">COMPANY NAME</label>
                        <div>
                            <input
                                class="form-input"
                                placeholder="Company Name"
                                [ngClass]="formUtils.displayFieldCss(form, 'name')"
                                type="text"
                                formControlName="name" />
                        </div>
                        <app-field-error-display
                            [displayError]="formUtils.isFieldInValid(form, 'name')"
                            errorMsg="Company name is required">
                        </app-field-error-display>
                    </div>

                    <div
                        class="col-md-6"
                        [ngClass]="formUtils.displayFieldCss(form, 'managementPhoneNumber')">
                        <label class="form-label control-label required">MANAGEMENT PHONE</label>
                        <div>
                            <input
                                class="form-input"
                                placeholder="08601010100"
                                [ngClass]="formUtils.displayFieldCss(form, 'managementPhoneNumber')"
                                type="text"
                                formControlName="managementPhoneNumber" />
                        </div>
                        <app-field-error-display
                            [displayError]="formUtils.isFieldInValid(form, 'managementPhoneNumber')"
                            errorMsg="Management phone number is required">
                        </app-field-error-display>
                    </div>
                </div>
                <div class="row">
                    <div
                        class="col-md-6"
                        [ngClass]="formUtils.displayFieldCss(form, 'managementEmail')">
                        <label class="form-label control-label required"
                            >MANAGEMENT EMAIL ADDRESS</label
                        >
                        <div>
                            <input
                                class="form-input"
                                placeholder="cit@example.com"
                                [ngClass]="formUtils.displayFieldCss(form, 'managementEmail')"
                                type="text"
                                formControlName="managementEmail" />
                        </div>
                        <app-field-error-display
                            [displayError]="formUtils.isFieldInValid(form, 'managementEmail')"
                            errorMsg="Management email address is required">
                        </app-field-error-display>
                    </div>
                    <div
                        class="col-md-6"
                        [ngClass]="formUtils.displayFieldCss(form, 'contactFirstName')">
                        <label class="form-label control-label required"
                            >CONTACT PERSON FIRST NAME</label
                        >
                        <div>
                            <input
                                class="form-input"
                                placeholder="Contact First Name"
                                [ngClass]="formUtils.displayFieldCss(form, 'contactFirstName')"
                                type="text"
                                formControlName="contactFirstName" />
                        </div>
                        <app-field-error-display
                            [displayError]="formUtils.isFieldInValid(form, 'contactFirstName')"
                            errorMsg="Contact first name is required">
                        </app-field-error-display>
                    </div>
                </div>
                <div class="row">
                    <div
                        class="col-md-6"
                        [ngClass]="formUtils.displayFieldCss(form, 'contactLastName')">
                        <label class="form-label control-label required"
                            >CONTACT PERSON LAST NAME</label
                        >
                        <div>
                            <input
                                class="form-input"
                                placeholder="Contact Last Name"
                                [ngClass]="formUtils.displayFieldCss(form, 'contactLastName')"
                                type="text"
                                formControlName="contactLastName" />
                        </div>
                        <app-field-error-display
                            [displayError]="formUtils.isFieldInValid(form, 'contactLastName')"
                            errorMsg="Contact last name is required">
                        </app-field-error-display>
                    </div>
                    <div
                        class="col-md-6"
                        [ngClass]="formUtils.displayFieldCss(form, 'contactEmail')">
                        <label class="form-label control-label required"
                            >CONTACT PERSON EMAIL</label
                        >
                        <div>
                            <input
                                class="form-input"
                                placeholder="contact@example.com"
                                [ngClass]="formUtils.displayFieldCss(form, 'contactEmail')"
                                type="text"
                                formControlName="contactEmail" />
                        </div>
                        <app-field-error-display
                            [displayError]="formUtils.isFieldInValid(form, 'contactEmail')"
                            errorMsg="Contact email address is required">
                        </app-field-error-display>
                    </div>
                </div>
                <div class="row">
                    <div
                        class="col-md-6"
                        [ngClass]="formUtils.displayFieldCss(form, 'contactPhone')">
                        <label class="form-label control-label required"
                            >CONTACT PERSON PHONE</label
                        >
                        <div>
                            <input
                                class="form-input"
                                placeholder="e.g. 0860000000"
                                [ngClass]="formUtils.displayFieldCss(form, 'contactPhone')"
                                type="text"
                                formControlName="contactPhone" />
                        </div>
                        <app-field-error-display
                            [displayError]="formUtils.isFieldInValid(form, 'contactPhone')"
                            errorMsg="Contact phone number is required">
                        </app-field-error-display>
                    </div>
                </div>
            </fieldset>
        </section>
        <section class="add-section" formGroupName="operationsLocation">
            <fieldset>
                <legend>Operations Location</legend>
                <div class="row">
                    <!-- TODO: NESTED FIELDS VALIDATION NOT WORKING CURRENTLY -->
                    <div class="col-md-6 form-group">
                        <label class="form-label control-label required">ADDRESS LINE 1</label>
                        <div>
                            <input
                                class="form-input"
                                placeholder="e.g. abc road"
                                [ngClass]="
                                    formUtils.displayFieldCss(
                                        form,
                                        'operationsLocation.addressLineOne'
                                    )
                                "
                                type="text"
                                formControlName="addressLineOne" />
                        </div>
                        <app-field-error-display
                            [displayError]="
                                formUtils.isFieldInValid(form, 'operationsLocation.addressLineOne')
                            "
                            errorMsg="Address line 1 is required">
                        </app-field-error-display>
                    </div>
                    <div class="col-md-6">
                        <label class="form-label control-label">ADDRESS LINE 2</label>
                        <div>
                            <input
                                class="form-input"
                                placeholder="e.g. abc road"
                                type="text"
                                formControlName="addressLineTwo" />
                        </div>
                        <app-field-error-display
                            [displayError]="
                                formUtils.isFieldInValid(form, 'operationsLocation.addressLineTwo')
                            "
                            errorMsg="Address line 2 is required">
                        </app-field-error-display>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label class="form-label control-label required">PROVINCE</label>
                        <div>
                            <div>
                                <ng-select
                                    [items]="provinceList"
                                    bindLabel="province"
                                    placeholder="Select Province"
                                    [multiple]="false"
                                    formControlName="province"
                                    bindValue="id"
                                    [ngClass]="
                                        formUtils.displayFieldCss(
                                            form,
                                            'operationsLocation.province'
                                        )
                                    ">
                                </ng-select>
                            </div>
                            <app-field-error-display
                                [displayError]="
                                    formUtils.isFieldInValid(form, 'operationsLocation.province')
                                "
                                errorMsg="Province is required">
                            </app-field-error-display>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="form-label control-label required">CITY</label>
                        <div>
                            <div>
                                <ng-select
                                    [items]="cityList"
                                    bindLabel="city"
                                    placeholder="Select City"
                                    [multiple]="false"
                                    formControlName="cityId"
                                    bindValue="id"
                                    [ngClass]="
                                        formUtils.displayFieldCss(form, 'operationsLocation.cityId')
                                    ">
                                </ng-select>
                            </div>
                            <app-field-error-display
                                [displayError]="
                                    formUtils.isFieldInValid(form, 'operationsLocation.cityId')
                                "
                                errorMsg="City is required">
                            </app-field-error-display>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label class="form-label control-label">BUILDING DETAILS</label>
                        <div>
                            <input
                                class="form-input"
                                placeholder="e.g. Vericred building"
                                type="text"
                                formControlName="buildingDetails" />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="form-label control-label required">SUBURB</label>
                        <div>
                            <input
                                class="form-input"
                                placeholder="e.g. Waterfront"
                                [ngClass]="
                                    formUtils.displayFieldCss(form, 'operationsLocation.suburb')
                                "
                                type="text"
                                formControlName="suburb" />
                        </div>
                        <app-field-error-display
                            [displayError]="
                                formUtils.isFieldInValid(form, 'operationsLocation.suburb')
                            "
                            errorMsg="Suburb is required">
                        </app-field-error-display>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label class="form-label control-label required">POSTAL CODE</label>
                        <div>
                            <input
                                class="form-input"
                                placeholder="e.g. 7708"
                                [ngClass]="
                                    formUtils.displayFieldCss(form, 'operationsLocation.postalCode')
                                "
                                type="text"
                                formControlName="postalCode" />
                        </div>
                        <app-field-error-display
                            [displayError]="
                                formUtils.isFieldInValid(form, 'operationsLocation.postalCode')
                            "
                            errorMsg="Postal code is required">
                        </app-field-error-display>
                    </div>
                </div>
            </fieldset>
            <div class="row">
                <div class="col-md-12">
                    <button
                        type="button"
                        class="custombtn-cancel custombtn-align-right"
                        (click)="goToIndex()">
                        Cancel
                    </button>
                    <button
                        type="submit"
                        class="custombtn custombtn-align-right"
                        (click)="submit()">
                        Save
                    </button>
                </div>
            </div>
        </section>
    </form>
</div>
