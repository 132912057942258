<section class="content">
    <!-- Default box -->
    <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item">
            <a
                class="nav-link"
                routerLinkActive="tab-link-active"
                [routerLink]="uri.getRouteNavPath('adminTransferToBankPending')">
                Pending & Processing
            </a>
        </li>
        <li class="nav-item">
            <a
                class="nav-link"
                routerLinkActive="tab-link-active"
                [routerLink]="uri.getRouteNavPath('adminTransferToBankProcessed')">
                Processed
            </a>
        </li>
        <li class="nav-item">
            <a
                class="nav-link"
                routerLinkActive="tab-link-active"
                [routerLink]="uri.getRouteNavPath('adminTransferToBankManualExport')">
                Manual Export
            </a>
        </li>
        <li class="nav-item">
            <a
                class="nav-link"
                routerLinkActive="tab-link-active"
                [routerLink]="uri.getRouteNavPath('adminTransferToBankExportLog')">
                Export Log
            </a>
        </li>
    </ul>
</section>
