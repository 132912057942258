import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { GenericResponse } from '../model/generic.response';
import { Banner } from '../model/banner';
import { AppSettings } from '../settings/app-settings';
import { CompanyService } from './company.service';

@Injectable({ providedIn: 'root' })
export class BannerService {
    constructor(
        private http: HttpClient,
        private companyService: CompanyService,
    ) {}

    getBanners(formValue) {
        formValue.companyId = this.companyService.getSelectedCompany().id;
        const params = new HttpParams({ fromObject: formValue });

        return this.http.get<GenericResponse<any>>(AppSettings.API_ENDPOINT + AppSettings.BANNERS, {
            params,
        });
    }

    updateBanner(banner: Banner) {
        const path = AppSettings.UPDATE_BANNER.replace('@@@', banner.id.toString());
        const body = {
            active: !banner.active,
            companyId: this.companyService.getSelectedCompany().id,
        };

        return this.http.patch<GenericResponse<any>>(AppSettings.API_ENDPOINT + path, body);
    }

    createBanner(body: any) {
        body.companyId = this.companyService.getSelectedCompany().id;

        return this.http.post<GenericResponse<any>>(
            AppSettings.API_ENDPOINT + AppSettings.BANNERS,
            body,
        );
    }
}
