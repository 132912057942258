import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-error-popup',
    templateUrl: './error-popup.component.html',
    styleUrls: ['./error-popup.component.css'],
})
export class ErrorPopupComponent {
    @Input() width = '96';
    @Input() errorMessage = '';
    @Output() event = new EventEmitter<boolean>();

    constructor() {}

    closeModel() {
        this.event.emit(false);
        this.errorMessage = '';
    }
}
