import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericResponse } from '../model/generic.response';
import { AppSettings } from '../settings/app-settings';

@Injectable({ providedIn: 'root' })
export class GrvService {
    constructor(private http: HttpClient) {}

    getGrvByPurchaseOrderId(orderId: string) {
        const path = AppSettings.GET_GRV_BY_PURCHASE_ORDER_ID.replace('@1@', orderId);
        return this.http.get<any>(AppSettings.API_ENDPOINT + path);
    }

    getGrvList(body: any) {
        let params = new HttpParams();
        params = params.append('start', body.start);
        params = params.append('length', body.length);
        if (body.text != '') params = params.append('text', body.text);
        if (body.dateCreated != '') params = params.append('dateCreated', body.dateCreated);
        if (body.hasPriceDifferenceClaim)
            params = params.append('hasPriceDifferenceClaim', body.hasPriceDifferenceClaim);
        if (body.hasShortDeliveryClaim)
            params = params.append('hasShortDeliveryClaim', body.hasShortDeliveryClaim);

        return this.http.get<GenericResponse<any>>(
            AppSettings.API_ENDPOINT + AppSettings.GET_GRV_LIST,
            { params: params },
        );
    }

    createGrv(body: any) {
        return this.http.post<any>(AppSettings.API_ENDPOINT + AppSettings.CREATE_GRV, body);
    }
}
