<div class="wrapper">
    <app-navigation></app-navigation>
    <div class="content-wrapper">
        <app-content-transition></app-content-transition>
        <app-loader *ngIf="isLoading" className="custom-loader" text="Processing ..."></app-loader>
        <app-error-popup *ngIf="display" errorMessage="{{ eMessage }}" (event)="closeModel($event)">
        </app-error-popup>
        <section class="content-header">
            <div class="row">
                <div class="col-md-12">
                    <div class="tab-heading">
                        <span class="tab-heading-title">Manage Users</span>
                        <img
                            class="breadcrumb-separator"
                            src="assets/images/breadcrumb-separator.png" />
                        <span class="bread-crumb">Search Users</span>
                        <img
                            class="breadcrumb-separator"
                            src="assets/images/breadcrumb-separator.png" />
                        <span class="bread-crumb">Results</span>
                        <img
                            class="breadcrumb-separator"
                            src="assets/images/breadcrumb-separator.png" />
                        <span class="bread-crumb">Change Mobile Number</span>
                    </div>
                </div>
            </div>
        </section>
        <form [formGroup]="form">
            <section class="add-section">
                <div class="row">
                    <div class="col-md-12 section-heading">Change Mobile Number</div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label class="form-label">USER NAME</label>
                        <div>
                            <input class="form-input" type="text" [value]="name" readonly />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label class="form-label">CURRENT MOBILE NUMBER</label>
                        <div>
                            <input class="form-input" type="text" [value]="oldMobile" readonly />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="form-label">NEW MOBILE NUMBER*</label>
                        <div>
                            <input
                                class="form-input"
                                type="text"
                                placeholder="e.g. 8978978999"
                                name="mobileNumber"
                                formControlName="mobileNumber" />
                        </div>
                        <small
                            *ngIf="
                                form.get('mobileNumber').invalid && form.get('mobileNumber').dirty
                            "
                            class="error-text"
                            >The mobile number is invalid</small
                        >
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6"></div>
                    <div class="col-md-6">
                        <button
                            class="custombtn custombtn-align-right"
                            (click)="verify()"
                            [disabled]="form.invalid">
                            Verify
                        </button>
                        <a class="user-files-deny" (click)="cancel()"><u>CANCEL</u></a>
                    </div>
                </div>
            </section>
        </form>
        <section class="add-section-footer">*Required</section>
    </div>
</div>
