<ng-container *ngFor="let breadcrumb of breadcrumbs$ | async; let last = last">
    <ng-container *ngIf="!last">
        <a class="breadcrumb-custom" [routerLink]="breadcrumb.path">{{ breadcrumb.label }}</a>
        <img
            alt="Breadcrumb Arrow"
            class="breadcrumb-separator"
            src="assets/images/breadcrumb-separator.png" />
    </ng-container>

    <ng-container *ngIf="last">
        <a [ngClass]="{ 'breadcrumb-custom-last': last }" [routerLink]="breadcrumb.path">{{
            breadcrumb.label
        }}</a>
    </ng-container>
</ng-container>
