import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-page-navigation',
    templateUrl: './page-navigation.html',
    styleUrls: ['./page-navigation.css'],
})
export class PageNavigationComponent implements OnChanges {
    @Input() pageNumber: number;
    @Input() totalPages: number;
    @Output() changePage = new EventEmitter<number>();

    nextSrc: string;
    lastSrc: string;
    prevSrc: string;
    firstSrc: string;

    constructor() {}

    ngOnChanges(changes: SimpleChanges): void {
        if (this.pageNumber === this.totalPages) {
            this.nextSrc = 'assets/images/next-page-disabled.png';
            this.lastSrc = 'assets/images/last-page-disabled.png';
        } else {
            this.nextSrc = 'assets/images/next-page.png';
            this.lastSrc = 'assets/images/last-page.png';
        }

        if (this.pageNumber < 2) {
            this.prevSrc = 'assets/images/previous-page-disabled.png';
            this.firstSrc = 'assets/images/first-page-disabled.png';
        } else {
            this.prevSrc = 'assets/images/previous-page.png';
            this.firstSrc = 'assets/images/first-page.png';
        }
    }

    onPrevMouseOver() {
        this.prevSrc = 'assets/images/previous-page-hover.png';
    }
    onPreMouseOut() {
        this.prevSrc = 'assets/images/previous-page.png';
    }
    onFirstMouseOver() {
        this.firstSrc = 'assets/images/first-page-hover.png';
    }
    onFirstMouseOut() {
        this.firstSrc = 'assets/images/first-page.png';
    }

    onNextMouseOver() {
        this.nextSrc = 'assets/images/next-page-hover.png';
    }
    onNextMouseOut() {
        this.nextSrc = 'assets/images/next-page.png';
    }
    onLastMouseOver() {
        this.lastSrc = 'assets/images/last-page-hover.png';
    }
    onLastMouseOut() {
        this.lastSrc = 'assets/images/last-page.png';
    }

    navigate(page: number) {
        this.changePage.emit(page);
    }
}
