<div id="adminTransactionViewModal" class="transaction-details-window">
    <div class="transaction-details-header">
        <div class="section-heading">
            Transaction Details
            <a (click)="closeViewModel()"
                ><img class="float-right" src="assets/images/close-x-purple.png"
            /></a>
        </div>
        <hr />
        <div class="transaction-details-header-body">
            <div class="row">
                <div class="col-md-6">
                    <label class="form-label">CUSTOMER</label>
                    <ng-container>
                        <div class="user-details-text">
                            <strong>{{ safeSummary.assignedTo }}</strong>
                        </div>
                    </ng-container>
                </div>
                <div class="col-md-6">
                    <label class="form-label">Notes</label>
                    <div class="user-details-text">
                        {{ safeSummary.noteCapacity }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label class="form-label">LAST UPDATED</label>
                    <div class="user-details-text">
                        {{ safeSummary.lastCashDroppedAt | date: dateFormat }}
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="form-label">LAST CIT</label>
                    <div class="user-details-text">
                        {{ safeSummary.lastCIT | date: dateFormat }}
                    </div>
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-md-12">
                    <table class="last-cash-drop-table">
                        <thead>
                            <th>Type</th>
                            <th>Count</th>
                            <th>Amount</th>
                        </thead>
                        <tbody>
                            @for (
                                denomination of safeSummary.currentTotal.denominations;
                                track $index
                            ) {
                                <tr>
                                    <td class="pb-2">
                                        {{ safeSummary.currency | currencyFormat }}
                                        {{ denomination.value || '0.00' | number: amountFormat }}
                                    </td>
                                    <td class="pb-2">
                                        {{ denomination.count }}
                                    </td>
                                    <td class="pb-2">
                                        {{ safeSummary.currency | currencyFormat }}
                                        {{ denomination.amount || '0.00' | number: amountFormat }}
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    <hr />
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <table class="last-cash-drop-table">
                        <tbody>
                            <tr>
                                <td>Total:</td>
                                <td></td>
                                <td>
                                    <b
                                        >{{ safeSummary.currency | currencyFormat }}
                                        {{
                                            safeSummary.currentTotal.aggregateTotal || '0.00'
                                                | number: amountFormat
                                        }}</b
                                    >
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
