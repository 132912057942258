import { Component, OnInit, Input } from '@angular/core';
import { User } from 'src/app/model/user';
import { StorageConstant } from 'src/app/settings/storage-constants';

@Component({
    selector: 'app-edit-links',
    templateUrl: './edit-links.component.html',
    styleUrls: ['./edit-links.component.css'],
})
export class EditLinksComponent implements OnInit {
    @Input() id: string;
    user: User;

    constructor() {}

    ngOnInit() {
        this.user = JSON.parse(localStorage.getItem(StorageConstant.USER_PROFILE + this.id));
    }
}
