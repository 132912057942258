import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { User } from 'src/app/model/user';
import { Pagination } from 'src/app/model/Pagination';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { StorageConstant } from 'src/app/settings/storage-constants';
import { Role } from 'src/app/enums/role';
import { MessageConstant } from 'src/app/settings/message.constant';
import { StorageService } from 'src/app/services/storage.service';
import { CommonUtil } from 'src/app/services/common.util';
import { DatePipe } from '@angular/common';
import { ExportService } from 'src/app/services/export.service';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.css'],
})
export class SearchComponent implements OnInit {
    accountTypeDropdown: string[] = [
        Role.personal,
        Role.business,
        Role.staff,
        Role.agent,
        Role.enterprise,
    ];
    statusDropdown: string[] = [];
    idTypeDropdown: string[] = [];
    form: FormGroup;
    invalidForm = false;
    userList = new Array<User>();
    excelList = new Array<any>();
    pagination = new Pagination();
    isLoading = false;
    showOptions = false;
    search = false;
    progressWidth = 25;
    searchItems: string[] = [];
    itemList = ['businessName', 'role', 'status', 'idType', 'idNumber', 'suburb', 'referredBy'];
    mobileChanged = false;
    userCreated = false;
    createdMessage = '';
    loaderText = 'Searching...';

    constructor(
        private router: Router,
        private formBuilder: UntypedFormBuilder,
        private commonService: CommonService,
        private storageService: StorageService,
        public commonUtil: CommonUtil,
        private datePipe: DatePipe,
        private exportService: ExportService,
    ) {
        this.storageService.initializeProfileUser();
        const item = localStorage.getItem(StorageConstant.CHANGE_MOBILE_SUCCESS);
        localStorage.removeItem(StorageConstant.CHANGE_MOBILE_SUCCESS);

        if (item && item === 'success') {
            this.mobileChanged = true;
        }

        if (localStorage.getItem(StorageConstant.STAFF_CREATED_MESSAGE)) {
            localStorage.removeItem(StorageConstant.STAFF_CREATED_MESSAGE);
            this.createdMessage = MessageConstant.STAFF_CREATED;
            this.userCreated = true;
        } else if (localStorage.getItem(StorageConstant.AGENT_CREATED_MESSAGE)) {
            localStorage.removeItem(StorageConstant.AGENT_CREATED_MESSAGE);
            this.createdMessage = MessageConstant.AGENT_CREATED;
            this.userCreated = true;
        } else if (localStorage.getItem(StorageConstant.ENTERPRISE_CREATED_MESSAGE)) {
            localStorage.removeItem(StorageConstant.ENTERPRISE_CREATED_MESSAGE);
            this.createdMessage = MessageConstant.ENTERPRISE_CREATED;
            this.userCreated = true;
        }

        this.form = this.formBuilder.group({
            text: [''],
            businessName: [''],
            role: [''],
            idNumber: [''],
            idType: [''],
            status: [''],
            start: [''],
            length: [''],
            fromDate: [''],
            toDate: [''],
            suburb: [''],
            toDateTemp: [''],
            referredBy: [''],
        });
    }

    ngOnInit() {
        this.commonService.getUserStatus().subscribe((res) => {
            this.statusDropdown = res.data;
        });
        // this.commonService.getAccountType().subscribe(res => {
        //     this.accountTypeDropdown = res.data;
        // });
        this.commonService.getIdType().subscribe((res) => {
            this.idTypeDropdown = res.data;
        });
    }

    getData(start = 0, length = 0, download = false) {
        if (download) {
            this.loaderText = 'Downloading...';
        } else {
            length = this.pagination.length;
            start = this.pagination.start;
        }

        this.search = false;
        this.isLoading = true;
        this.form.get('start').setValue(start);
        this.form.get('length').setValue(length);
        this.setProgressWidth(50);
        this.checkForDateRange();

        this.commonService.getFilteredData(this.form).subscribe(
            (res) => {
                this.setProgressWidth(100);
                setTimeout(() => {
                    if (res.data && download) {
                        for (const user of res.data) {
                            this.excelList.push(this.commonUtil.getExcelOfUsers(user));
                        }
                        this.exportService.exportExcel(this.excelList, 'User List');
                    } else {
                        this.userList = res.data || [];
                        this.pagination.total = !res.total ? 0 : res.total;
                    }

                    this.isLoading = false;
                    this.search = true;
                    this.progressWidth = 25;
                    this.loaderText = 'Searching...';
                }, 350);

                this.searchItems = [];
                for (const item of this.itemList) {
                    if (this.form.get(item).value !== '') {
                        this.searchItems.push(this.form.get(item).value);
                    }
                }
            },
            (error) => {
                this.isLoading = false;
            },
        );
    }

    setProgressWidth(i: number) {
        setTimeout(() => {
            this.progressWidth = i;
        }, 300);
    }

    searchUsers(text: string) {
        this.pagination.text = text;
        this.getData();
    }

    removeSearchItem(value: string) {
        for (const item of this.itemList) {
            if (this.form.get(item).value === value) {
                this.form.get(item).setValue('');
                this.getData();
                break;
            }
        }
    }

    clearText() {
        this.form.get('text').setValue('');
    }

    closeModel(event: any) {
        this.mobileChanged = false;
        this.userCreated = false;
    }

    receiveFilter(data: { name: string; value: number }) {}

    changeLength(len: number) {
        this.pagination.length = len;
        this.pagination.start = 0;
        this.getData();
    }

    changePage(page: number) {
        this.pagination.changePage(page);
        this.getData();
    }

    get controls(): any {
        return this.form.controls;
    }

    toggleOption() {
        this.showOptions = !this.showOptions;
    }

    clearDates() {
        this.form.get('fromDate').setValue('');
        this.form.get('toDateTemp').setValue('');
        this.form.get('toDate').setValue('');
    }

    checkForDateRange() {
        let fromDate = this.form.get('fromDate').value;
        let toDate = this.form.get('toDateTemp').value;

        if (fromDate != '' || toDate != '') {
            if (fromDate == '') {
                fromDate = '2020-01-01';
            }

            if (toDate == '') {
                toDate = new Date();
            }

            let date = this.datePipe.transform(fromDate, 'yyyy-MM-dd');
            this.form.get('fromDate').setValue(date);

            const newDate = new Date(toDate);
            newDate.setDate(newDate.getDate() + 1);
            date = this.datePipe.transform(newDate, 'yyyy-MM-dd');
            this.form.get('toDate').setValue(date);
        }
    }

    submit() {
        this.getData();
    }

    downLoadExcel() {
        this.getData(0, 10000, true);
    }
}
