import { Component } from '@angular/core';

@Component({
    selector: 'app-safe-events',
    templateUrl: './safe-events.component.html',
    styleUrls: ['./safe-events.component.css'],
})
export class SafeEventsComponent {
    constructor() {}
}
