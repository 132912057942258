<div class="edit-link">
    <a data-toggle="dropdown">
        <div class="dot-icon"></div>
    </a>
    <ul class="dropdown-menu" aria-labelledby="menu1">
        <li>
            <a class="dropdown-menu-item" (click)="changeStatus('files')">
                <img src="assets/images/verify-documents.png" />
                Verify Documents
            </a>
        </li>
        <li>
            <a class="dropdown-menu-item" (click)="changeStatus('deny')">
                <img src="assets/images/deny-application.png" />
                Deny Application
            </a>
        </li>
        <li>
            <a class="dropdown-menu-item" (click)="changeStatus('verify')">
                <img src="assets/images/approve-application.png" />
                Approve Application
            </a>
        </li>
    </ul>
</div>
