import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { throwError } from 'rxjs';
import { catchError, exhaustMap, take, tap } from 'rxjs/operators';
import { ErrorHandlerService } from './error-handler.service';
import { LoginService } from './login.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
    constructor(
        private loginService: LoginService,
        private logger: NGXLogger,
        private errorHandlerService: ErrorHandlerService,
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        return this.loginService.sessionUser.pipe(
            take(1),
            exhaustMap((user) => {
                if (!user) {
                    return next.handle(req).pipe(
                        catchError((error) => {
                            console.log('Intercept error');
                            console.error(error);
                            //this.errorHandlerService.handleError(error);
                            return throwError(error);
                        }),
                    );
                }

                const modifiedReq = req.clone({
                    setHeaders: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'X-Auth-Key': user['X-Auth-Key'],
                        source: 'portal',
                    },
                });

                return next.handle(modifiedReq).pipe(
                    tap(
                        () => { },
                        (err: any) => {
                            if (err instanceof HttpErrorResponse) {
                                this.errorHandlerService.handleError(err);
                                if (err.status !== 401) {
                                    return;
                                }
                                this.loginService.logout();
                            }
                        },
                    ),
                );
            }),
        );
    }
}
