<div class="content-wrapper">
    <app-navigation></app-navigation>
    <app-content-transition></app-content-transition>
    <app-safes-header></app-safes-header>
    <app-safes-link></app-safes-link>
    <section class="add-section">
        <form [formGroup]="form">
            <div class="row">
                <div class="col-md-12 section-heading">Search</div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-icons">
                        <i class="fa fa-search icon" aria-hidden="true"></i>
                        <input
                            class="form-input"
                            type="text"
                            placeholder="Enter a Safe ID, Name, or Mac Address"
                            formControlName="text" />
                        @if (!isEmpty('text')) {
                            <a (click)="clearText()">
                                <img src="assets/images/close-x-purple.png" />
                            </a>
                        }
                    </div>
                </div>
                <div class="col-md-6">
                    <button class="search-button" (click)="submit()">Search</button>
                    <button class="custombtn" (click)="addSmartSafe()">
                        <i class="fa fa-plus-circle pr-1" aria-hidden="true"></i>Add Smart Safe
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <label for="archivedSafes" class="form-label pr-3">ARCHIVED SAFES</label>
                    <label class="switch-container">
                        <input
                            name="archivedSafes"
                            type="checkbox"
                            (change)="onSwitchChange()"
                            [checked]="switchStateOn" />
                        <span class="slider round"></span>
                    </label>
                </div>
            </div>
        </form>
    </section>

    @if (!isLoading && smartSafeList?.length !== null && smartSafeList?.length > 0) {
        <div class="d-flex justify-content-between">
            <div class="search-result-header">
                Search Results: {{ smartSafeList.length }} of {{ pagination.total }}
            </div>
            <!-- <div class="d-flex align-items-baseline"></div> -->
            @if (this.selectedItems.length > 0) {
                <button class="custombtn m-0" (click)="archiveSmartSafes()">
                    <i class="fa fa-archive pr-1" aria-hidden="true"></i>Archive
                </button>
            }
        </div>
    }

    @if (isLoading) {
        <div class="progress-container">
            <div class="progress-container-text">
                <div>{{ loaderText }}</div>
            </div>
            <div>
                <div class="custom-progress-bar">
                    <span [style.width.%]="progressWidth"></span>
                </div>
            </div>
        </div>
    }

    <section>
        <div class="container-fluid tab-pane custom-table smart-safes configuration">
            <br />
            <table>
                <thead>
                    <tr class="form-table-row">
                        <th></th>
                        <th>
                            <app-filter-arrow
                                filter="safeId"
                                title="ID"
                                (filterValue)="receiveFilter($event)">
                            </app-filter-arrow>
                        </th>
                        <th>
                            <app-filter-arrow
                                filter="safeMacAddress"
                                title="MAC ADDRESS"
                                (filterValue)="receiveFilter($event)">
                            </app-filter-arrow>
                        </th>
                        <th>
                            <app-filter-arrow
                                filter="assignedTo"
                                title="NAME"
                                (filterValue)="receiveFilter($event)">
                            </app-filter-arrow>
                        </th>
                        <th>
                            <app-filter-arrow
                                filter="model"
                                title="MODEL"
                                (filterValue)="receiveFilter($event)">
                            </app-filter-arrow>
                        </th>
                        <th>
                            <app-filter-arrow
                                filter="softwareVersion"
                                title="SOFTWARE VERSION"
                                (filterValue)="receiveFilter($event)">
                            </app-filter-arrow>
                        </th>
                        <th>
                            <app-filter-arrow
                                filter="noteCapacity"
                                title="NOTE CAPACITY"
                                (filterValue)="receiveFilter($event)">
                            </app-filter-arrow>
                        </th>

                        <th>
                            <app-filter-arrow
                                filter="status"
                                title="STATUS"
                                (filterValue)="receiveFilter($event)">
                            </app-filter-arrow>
                        </th>
                        <th>
                            <app-filter-arrow title="ACTIONS"> </app-filter-arrow>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    @for (safe of smartSafeList; track safe.safeId; let i = $index) {
                        <ng-container>
                            <tr
                                [ngClass]="changeBackground[i] ? 'table-row-selected' : ''"
                                class="form-table-row view-form">
                                <td>
                                    <input
                                        type="checkbox"
                                        value="{{ safe.safeId }}"
                                        (change)="OnChange(safe.safeId, $event.target.checked, i)"
                                        [(ngModel)]="safe.checked" />
                                </td>
                                <td>{{ safe.safeId }}</td>
                                <td>{{ safe.safeMacAddress }}</td>
                                <td>{{ safe.assignedTo }}</td>
                                <td>{{ safe.model }}</td>
                                <td>{{ safe.softwareVersion }}</td>
                                <td>{{ safe.noteCapacity }}</td>
                                <td [ngClass]="changeBackground[i] ? 'table-row-selected' : ''">
                                    <app-account-status smartSafeStatus="{{ safe.status }}">
                                    </app-account-status>
                                </td>
                                <td>
                                    <app-edit-pencil
                                        [pencilEditStyle]="pencilEditStyle"
                                        (edit)="editSmartSafe({ type: 'view', value: safe })">
                                    </app-edit-pencil>
                                </td>
                            </tr>
                        </ng-container>
                    } @empty {
                        <ng-container>
                            <tr>
                                <div class="data-empty">
                                    <div class="col-md-12">No data to display</div>
                                </div>
                            </tr>
                        </ng-container>
                    }
                </tbody>
                @if (pagination.showPagination()) {
                    <tfoot>
                        <tr>
                            <td>
                                Rows per page:
                                <span>
                                    <b>{{ pagination.length }}</b>
                                </span>
                                <app-page-length-dropdown (emitLength)="changeLength($event)">
                                </app-page-length-dropdown>
                            </td>
                            <td>
                                <app-page-navigation
                                    pageNumber="{{ pagination.getPageNo() }}"
                                    totalPages="{{ pagination.getTotalPages() }}"
                                    (changePage)="changePage($event)">
                                </app-page-navigation>
                            </td>
                        </tr>
                    </tfoot>
                }
            </table>
        </div>
    </section>

    @if (displayView) {
        <ng-container>
            <app-smart-safe-modal
                [selectedSafe]="selectedSmartSafe"
                [title]="sideViewWindowTitle"
                (event)="closeViewModel()"
                (fetchDataList)="getData()"></app-smart-safe-modal>
        </ng-container>
    }
</div>
