import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Data, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { get } from 'lodash-es';

import { Breadcrumb } from 'src/app/model/breadcrumb.model';

@Injectable({ providedIn: 'root' })
export class BreadcrumbService {
    // Subject emitting the breadcrumb hierarchy
    private readonly _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);

    // Observable exposing the breadcrumb hierarchy
    readonly breadcrumbs$ = this._breadcrumbs$.asObservable();

    constructor(private router: Router) {
        this.router.events
            .pipe(
                // Filter the NavigationEnd events as the breadcrumb is updated only when the route reaches its end
                filter((event) => event instanceof NavigationEnd),
            )
            .subscribe((event) => {
                // Construct the breadcrumb hierarchy
                const root = this.router.routerState.snapshot.root;
                const breadcrumbs: Breadcrumb[] = [];
                this.addBreadcrumb(root, [], breadcrumbs);

                // Emit the new hierarchy
                this._breadcrumbs$.next(breadcrumbs);
            });
    }

    private addBreadcrumb(
        route: ActivatedRouteSnapshot,
        parentUrl: string[],
        breadcrumbs: Breadcrumb[],
    ) {
        if (route) {
            // Construct the route URL
            const routeUrl = parentUrl.concat(route.url.map((url) => url.path));

            // Add an element for the current route part
            if (route.data.breadcrumb) {
                // Add a parent breadcrumb if the current one is a child of the previous one
                if (route.data.parent) {
                    // Get the previous navigation
                    const previousNav = this.router.getCurrentNavigation()?.previousNavigation;

                    if (previousNav) {
                        const parentTargetRoot = get(
                            previousNav,
                            'targetRouterState.snapshot.root.children',
                        ).filter((x) => x.routeConfig.path === route.data.parent)[0];

                        if (parentTargetRoot) {
                            const breadcrumb = {
                                label: this.getLabel(parentTargetRoot.data),
                                path: '/' + parentTargetRoot.routeConfig.path + '/',
                            };
                            breadcrumbs.push(breadcrumb);
                        }
                    }
                }

                const breadcrumb = {
                    label: this.getLabel(route.data),
                    path: '/' + routeUrl.join('/'),
                };
                breadcrumbs.push(breadcrumb);
            }

            // Add another element for the next route part
            this.addBreadcrumb(route.firstChild, routeUrl, breadcrumbs);
        }
    }

    private getLabel(data: Data) {
        // The breadcrumb can be defined as a static string or as a function to construct the breadcrumb element out of the route data
        return typeof data.breadcrumb === 'function' ? data.breadcrumb(data) : data.breadcrumb;
    }
}
