<div class="wrapper">
    <app-navigation></app-navigation>
    <div class="content-wrapper">
        <app-content-transition></app-content-transition>
        <app-loader *ngIf="isLoading" className="custom-loader" text="Processing ..."></app-loader>
        <app-header
            buttonText="Add New Banner"
            location="all"
            title="Banners"
            [showHeaderButton]="true"
            url="{{ uri.getRouteNavPath('adminBannersNew') }}">
        </app-header>

        <div>
            <section class="add-section">
                <form [formGroup]="form">
                    <div class="row">
                        <div class="col-md-12 section-heading">Search</div>
                    </div>
                    <div class="row pb-3">
                        <div class="col-md-6">
                            <label for="text" class="form-label">SEARCH BY NAME</label>
                            <div class="input-icons">
                                <i class="fa fa-search icon" aria-hidden="true"></i>
                                <input
                                    class="form-input"
                                    type="text"
                                    placeholder="e.g banner name"
                                    name="text"
                                    formControlName="text" />
                                <a *ngIf="form.get('text').value.length > 0" (click)="clearText()">
                                    <img src="assets/images/close-x-purple.png" />
                                </a>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="form-label"></label>
                            <div>
                                <button class="search-button" (click)="submit()">Search</button>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <label for="startDate" class="form-label">START DATE</label>
                            <div class="datepicker-width-85">
                                <input
                                    matInput
                                    class="datepicker-input"
                                    [matDatepicker]="startDatePicker"
                                    name="startDate"
                                    formControlName="startDate"
                                    readonly />
                                <mat-datepicker-toggle
                                    matSuffix
                                    [for]="startDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #startDatePicker></mat-datepicker>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label for="endDate" class="form-label">END DATE</label>
                            <div class="datepicker-width-85">
                                <input
                                    matInput
                                    class="datepicker-input"
                                    [matDatepicker]="endDatePicker"
                                    name="endDate"
                                    formControlName="endDate"
                                    readonly />
                                <mat-datepicker-toggle
                                    matSuffix
                                    [for]="endDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #endDatePicker></mat-datepicker>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
            <div class="progress-container" *ngIf="isLoading">
                <div class="progress-container-text">
                    <div>Searching...</div>
                </div>
                <div>
                    <div class="custom-progress-bar">
                        <span [style.width.%]="progressWidth"></span>
                    </div>
                </div>
            </div>
            <section *ngIf="search">
                <div class="search-result-header">
                    Search Results: {{ bannerList.length }} of {{ pagination.total }}
                </div>
                <hr />
                <div class="container-fluid tab-pane custom-table custom-last-row">
                    <br />
                    <table id="tableData">
                        <thead>
                            <tr>
                                <th width="20%">
                                    <app-filter-arrow
                                        filter="name"
                                        title="NAME"
                                        (filterValue)="receiveFilter($event)">
                                    </app-filter-arrow>
                                </th>

                                <th>
                                    <app-filter-arrow
                                        filter="active"
                                        title="ACTIVE"
                                        (filterValue)="receiveFilter($event)">
                                    </app-filter-arrow>
                                </th>
                                <th>
                                    <app-filter-arrow
                                        filter="startDate"
                                        title="START DATE"
                                        (filterValue)="receiveFilter($event)">
                                    </app-filter-arrow>
                                </th>
                                <th>
                                    <app-filter-arrow
                                        filter="endDate"
                                        title="END DATE"
                                        (filterValue)="receiveFilter($event)">
                                    </app-filter-arrow>
                                </th>
                                <th>
                                    <app-filter-arrow title="ACTIONS"> </app-filter-arrow>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="tablebox" *ngFor="let banner of bannerList">
                                <td width="20%">
                                    <img
                                        class="table-user-img"
                                        src="{{
                                            banner.path || 'assets/images/placeholder.png'
                                        }}" />
                                    <a target="_blank" href="{{ banner.path }}">
                                        <strong
                                            ><u
                                                ><span>{{ banner.name | humanize }}</span></u
                                            ></strong
                                        >
                                    </a>
                                </td>
                                <td>
                                    <app-account-status type="banner" status="{{ banner.active }}">
                                    </app-account-status>
                                </td>
                                <td>{{ banner.startDate | date: 'd/M/yyyy' }}</td>
                                <td>{{ banner.endDate | date: 'd/M/yyyy' }}</td>
                                <td>
                                    <app-last-child-admin-banner
                                        [banner]="banner"
                                        (event)="action($event)">
                                    </app-last-child-admin-banner>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot *ngIf="pagination.showPagination()">
                            <tr>
                                <td>
                                    Rows per page:
                                    <span
                                        ><b>{{ pagination.length }}</b></span
                                    >
                                    <app-page-length-dropdown (emitLength)="changeLength($event)">
                                    </app-page-length-dropdown>
                                </td>
                                <td>
                                    <app-page-navigation
                                        pageNumber="{{ pagination.getPageNo() }}"
                                        totalPages="{{ pagination.getTotalPages() }}"
                                        (changePage)="changePage($event)">
                                    </app-page-navigation>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </section>
        </div>
    </div>
    <div class="control-sidebar-bg"></div>
</div>
