import { DatePipe } from '@angular/common';
import { AppConstants } from 'src/app/settings/app-constants';

export function transformDateToApiFormat(value: any): string {
    if (value) {
        const datePipe = new DatePipe('en-ZA');
        return value instanceof Date
            ? datePipe.transform(value, AppConstants.API_DATE_FORMAT)
            : value;
    }
}

export function getFirstDayOfCurrentWeek(): Date {
    const today = new Date();
    const diff = today.getDate() - today.getDay();
    return new Date(today.setDate(diff));
}

export function getLastDayOfCurrentWeek(): Date {
    const today = new Date();
    const diff = today.getDate() - today.getDay() + 6;
    return new Date(today.setDate(diff));
}
