import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { GenericResponse } from '../model/generic.response';
import { AppSettings } from '../settings/app-settings';
import { CompanyService } from './company.service';

@Injectable({ providedIn: 'root' })
export class SmartSafeService {
    constructor(
        private http: HttpClient,
        private companyService: CompanyService,
    ) { }

    getUnAssignedUserList() {
        const paramObject = { companyId: this.companyService.getSelectedCompany().id.toString() };
        const params = new HttpParams({ fromObject: paramObject });

        return this.http.get<any[]>(
            AppSettings.API_ENDPOINT + AppSettings.GET_SMART_SAFE_UNASSIGNED_USERS,
            { params },
        );
    }

    getSafeModelList() {
        return this.http.get<any[]>(AppSettings.API_ENDPOINT + AppSettings.GET_SMART_SAFE_MODELS);
    }

    getSmartSafeStatusList() {
        return this.http.get<any[]>(AppSettings.API_ENDPOINT + AppSettings.GET_SMART_SAFE_STATUSES);
    }

    getSmartSafeList(body: any) {
        body.companyId = this.companyService.getSelectedCompany().id;
        return this.http.post<GenericResponse<any[]>>(
            AppSettings.API_ENDPOINT + AppSettings.GET_SMART_SAFE,
            body,
        );
    }

    createSmartSafe(body: any) {
        body.companyId = this.companyService.getSelectedCompany().id;

        return this.http.post<GenericResponse<any>>(
            AppSettings.API_ENDPOINT + AppSettings.SAVE_SMART_SAFE,
            body,
        );
    }

    updateSmartSafe(body: any) {
        return this.http.patch<GenericResponse<any>>(
            AppSettings.API_ENDPOINT + AppSettings.SAVE_SMART_SAFE,
            body,
        );
    }

    getSmartSafesSummary(formValue: any) {
        formValue.companyId = this.companyService.getSelectedCompany().id;
        const params = new HttpParams({ fromObject: formValue });

        return this.http.get<GenericResponse<any>>(
            AppSettings.API_ENDPOINT + AppSettings.SMART_SAFES_SUMMARY,
            {
                params,
            },
        );
    }

    getSmartSafesList(body: any) {
        body.companyId = this.companyService.getSelectedCompany().id;
        return this.http.post<GenericResponse<any[]>>(
            AppSettings.API_ENDPOINT + AppSettings.GET_SMART_SAFES,
            body,
        );
    }

    getSmartSafesTransaction(formValue) {
        formValue.companyId = this.companyService.getSelectedCompany().id;
        const params = new HttpParams({ fromObject: formValue });

        return this.http.get<GenericResponse<any>>(
            AppSettings.API_ENDPOINT + AppSettings.GET_SMART_SAFE_TRANSACTION,
            {
                params,
            },
        );
    }

    getCITList() {
        return this.http.get<GenericResponse<any[]>>(
            AppSettings.API_ENDPOINT + AppSettings.GET_CIT_COMPANIES,
        );
    }

    createCIT(body: any) {
        return this.http.post<GenericResponse<any>>(
            AppSettings.API_ENDPOINT + AppSettings.CRETAE_CIT,
            body,
        );
    }

    archiveSmartSafes(payload: any) {
        return this.http.post<GenericResponse<any>>(
            'api/users/' + AppSettings.ARCHIVE_SMART_SAFES,
            payload,
        );
    }
}
