import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { Breadcrumb } from 'src/app/model/breadcrumb.model';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrl: './breadcrumb.component.css',
})
export class BreadcrumbComponent {
    breadcrumbs$: Observable<Breadcrumb[]>;

    constructor(private readonly breadcrumbService: BreadcrumbService) {
        this.breadcrumbs$ = this.breadcrumbService.breadcrumbs$;
    }
}
