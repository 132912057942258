import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { FeeCode } from 'src/app/enums/fee.code';
import { Role } from 'src/app/enums/role';
import { Bank } from 'src/app/model/bank';
import { Enterprise } from 'src/app/model/enterprise';
import { Fees } from 'src/app/model/fees';
import { CommonService } from 'src/app/services/common.service';
import { CommonUtil } from 'src/app/services/common.util';
import { EnterpriseService } from 'src/app/services/enterprise.service';
import { LoginService } from 'src/app/services/login.service';
import { TransactionService } from 'src/app/services/transaction.service';
import { AppConstants } from 'src/app/settings/app-constants';
import { MessageConstant } from 'src/app/settings/message.constant';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-enterprise-settings',
    templateUrl: './enterprise-settings.component.html',
    styleUrls: ['./enterprise-settings.component.css'],
})
export class EnterpriseSettingsComponent implements OnInit {
    feeCode = FeeCode;
    amountFormat = AppConstants.AMOUNT_FORMAT;
    display = false;
    isLoading = false;
    displaySuccess = false;
    isVisible = false;
    isDirectDistributor = false;
    sMessage = '';
    role = Role;
    user: Enterprise;
    id: string;
    form: FormGroup;
    invalidForm = false;
    eMessage = '';
    editEnterprise: boolean;
    statusDropdown: string[] = [];
    kycLevelDropdown: string[] = [];
    enterpriseFeeList: Array<Fees> = [];

    constructor(
        private enterpriseService: EnterpriseService,
        public loginService: LoginService,
        private formBuilder: UntypedFormBuilder,
        @Inject(DOCUMENT) private document: Document,
        private commonService: CommonService,
        private transactionService: TransactionService,
        public commonUtil: CommonUtil,
        private toasterService: ToastrService,
    ) {
        this.user = new Enterprise();
        this.id = this.loginService.sessionUser.value.enterprise.enterpriseId;

        this.form = this.formBuilder.group({
            kycLevel: [this.user.kycLevel || ''],
            enterpriseStatus: [this.user.status || ''],
            visibleInPayList: [this.user.visibleInPayList || ''],
            directDistributor: [this.user.directDistributor || ''],
        });
    }

    ngOnInit() {
        this.commonService.getUserStatus().subscribe((res) => {
            this.statusDropdown = res.data;
        });

        this.commonService.getKycLevel().subscribe((res) => {
            this.kycLevelDropdown = res.data;
        });

        this.getData();
    }

    getData() {
        this.isLoading = true;

        this.enterpriseService.getEnterpriseProfile(this.id).subscribe(
            (res) => {
                this.isLoading = false;
                this.user = res.data;
                if (!this.user.bank) {
                    this.user.bank = new Bank();
                }
                this.setForm();
                this.getFeeList();
                this.isLoading = false;
            },
            (err) => {
                this.isLoading = false;
            },
        );
    }

    setForm() {
        this.isVisible = this.user.visibleInPayList;
        this.isDirectDistributor = this.user.directDistributor;
        this.form.get('kycLevel').setValue(this.user.kycLevel);
        this.form.get('enterpriseStatus').setValue(this.user.status);
        this.form.get('visibleInPayList').setValue(this.user.visibleInPayList);
        this.form.get('directDistributor').setValue(this.user.directDistributor);
    }

    getFeeList() {
        this.transactionService
            .getCustomFee(this.user.userRoleId, Role.enterprise)
            .subscribe((res) => {
                this.setFee(res.data || []);
            });
    }

    setFee(feeList: Fees[]) {
        this.enterpriseFeeList = [];

        feeList.forEach((fee) => {
            fee.percentage = fee.percentage || 0.0;
            fee.amount = fee.amount || 0.0;
            fee.useAmount = fee.useAmount || false;
            fee.percentageValue = fee.percentage || 0.0;
            fee.amountValue = fee.amount || 0.0;
            fee.useAmountValue = fee.useAmount || false;
            this.enterpriseFeeList.push(fee);
        });
    }

    changeStatus() {
        this.isVisible = !this.isVisible;
        this.form.controls.visibleInPayList.setValue(this.isVisible);
    }

    changeDirectDistributor() {
        this.isDirectDistributor = !this.isDirectDistributor;
        this.form.controls.directDistributor.setValue(this.isDirectDistributor);
    }

    resetVal(feeList: Fees[]) {
        feeList.forEach((fee) => {
            fee.percentage = fee.percentage || 0.0;
            fee.amount = fee.amount || 0.0;
            fee.useAmount = fee.useAmount || false;
            fee.percentageValue = fee.percentage || 0.0;
            fee.amountValue = fee.amount || 0.0;
            fee.useAmountValue = fee.useAmount || false;
        });
    }

    changeView(event: any, type: string) {
        this.editEnterprise = !this.editEnterprise;
        this.resetVal(this.enterpriseFeeList);
        this.setForm();
    }

    closeModel(val: boolean) {
        this.display = false;
        this.displaySuccess = false;
    }

    submit() {
        this.invalidForm = this.form.invalid;
        if (this.form.invalid) {
            this.display = true;
            this.eMessage = 'Please fill all required fields.';
            return false;
        }

        this.isLoading = true;
        this.sendData();

        this.commonService
            .changeUserProfileSettings(this.user.userId, this.user.roleId, this.form.value)
            .subscribe(
                (res) => {
                    this.getData();
                    this.changeView('', '');
                    this.toasterService.success(
                        MessageConstant.USER_ADMIN_SETTINGS_UPDATE_SUCCESS,
                        'Successful',
                    );
                    this.isLoading = false;
                },
                (err) => (this.isLoading = false),
            );
    }

    sendData() {
        const form = [];

        this.enterpriseFeeList.forEach((fee) => {
            const value = {
                code: fee.code,
                percentage: fee.percentageValue,
                amount: fee.amountValue,
                useAmount: fee.useAmountValue,
                userRoleId: this.user.userRoleId,
            };
            form.push(value);
        });

        this.transactionService.updateCustomFees(form).subscribe(
            (res) => {
                //this.isLoading = false;

                if (res.success) {
                    this.setFee(res.data || []);
                } else {
                    this.display = true;
                    this.eMessage = res.status.message;
                }
            },
            (err) => {
                this.isLoading = false;
            },
        );
    }

    resendLink() {
        this.isLoading = true;
        this.loginService.resendCreatePasswordLink(this.user.code).subscribe(
            (response) => {
                this.isLoading = false;
                if (response.success) {
                    this.sMessage = MessageConstant.RESEND_CREATE_PASSWORD_LINK_SUCCESS;
                    this.displaySuccess = true;
                } else {
                    this.eMessage = 'Unable to resend e-mail, Please try again later';
                    this.display = true;
                }
            },
            (error) => {
                this.eMessage = 'Service unavailable, Please try again later.';
                this.isLoading = false;
                this.display = true;
            },
        );
    }
}
