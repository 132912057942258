<div class="wrapper">
    <app-error-model
        message="{{ eMessage }}"
        [display]="display"
        (event)="closeModel($event)"></app-error-model>
    <app-success-model
        message="{{ sMessage }}"
        [display]="displaySuccess"
        (event)="closeModel($event)"></app-success-model>
    <app-navigation></app-navigation>
    <div class="content-wrapper">
        <app-content-transition></app-content-transition>
        <app-loader *ngIf="isLoading" className="custom-loader" text="Processing ..."></app-loader>
        <app-header location="User Account Profile"></app-header>
        <app-edit-links [id]="id"></app-edit-links>
        <form [formGroup]="form">
            <section class="add-section" *ngIf="user.code">
                <a class="text-danger" (click)="resendLink()"
                    ><u>Resend Create Password Email Link</u></a
                >
            </section>
            <section class="add-section" *ngIf="user.userPersonalProfileModel.roleId">
                <div class="row">
                    <div class="col-md-8 section-heading">General User Settings</div>
                    <div class="col-md-4 section-heading">
                        <app-edit-pencil (edit)="changeView($event, 'personal')"></app-edit-pencil>
                    </div>
                </div>
                <div *ngIf="!editPersonal">
                    <div class="row">
                        <div class="col-md-3">
                            <label class="form-label">GENERAL USER ACCOUNT STATUS</label>
                            <div class="user-details-text">
                                {{ user.status || '-' | humanize }}
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="editPersonal">
                    <div class="row">
                        <div class="col-md-3">
                            <label class="form-label">GENERAL USER ACCOUNT STATUS</label>
                            <app-select-box
                                placeholderValue="Set Status"
                                value="personalStatus"
                                [form]="form"
                                [invalidForm]="invalidForm"
                                [dropDown]="statusDropdown">
                            </app-select-box>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6"></div>
                        <div class="col-md-6">
                            <button
                                class="custombtn custombtn-align-right"
                                (click)="submit('personal')">
                                SAVE
                            </button>
                            <a class="user-files-deny" (click)="changeView($event, 'personal')"
                                ><u>CANCEL</u></a
                            >
                        </div>
                    </div>
                </div>
            </section>
            <section class="add-section" *ngIf="user.userBusinessProfileModel.roleId">
                <div class="row">
                    <div class="col-md-8 section-heading">BUSINESS Settings</div>
                    <div class="col-md-4 add-section-heading">
                        <app-edit-pencil (edit)="changeView($event, 'business')"></app-edit-pencil>
                    </div>
                </div>
                <div *ngIf="!editBusiness">
                    <div class="row">
                        <div class="col-md-3">
                            <label class="form-label">BUSINESS ACCOUNT STATUS</label>
                            <div class="user-details-text">
                                {{ user.userBusinessProfileModel.businessStatus || '-' | humanize }}
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label">ADD ACCOUNT TO PAY LIST</label>
                            <div class="user-details-text">
                                {{
                                    user.userBusinessProfileModel.visibleInPayList
                                        ? 'YES'
                                        : 'No' || '-'
                                }}
                            </div>
                        </div>
                        <!--                         <div class="col-md-3">
                            <label class="form-label pr-2">SUPPLIER DIRECT?</label>
                            <i class="bi bi-info-circle"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="{{iconInfoMsg}}"></i>
                            <div class="user-details-text">
                                {{user.userBusinessProfileModel.supplierDirect ? 'YES' : 'No' || '-'}}
                            </div>
                        </div> -->
                    </div>
                    <section class="mt-4">
                        <app-business-fees
                            title="Fees Paid by Business User"
                            [showEditPencil]="false"
                            [parentToUpdate]="true"
                            [editBusiness]="editBusiness"
                            [businessFeeList]="businessFeeList"
                            (updateFee)="submit('business')">
                        </app-business-fees>
                    </section>
                </div>
                <div *ngIf="editBusiness">
                    <div class="row">
                        <div class="col-md-3">
                            <label class="form-label">BUSINESS ACCOUNT STATUS</label>
                            <app-select-box
                                placeholderValue="Set Status"
                                value="businessStatus"
                                [form]="form"
                                [invalidForm]="invalidForm"
                                [dropDown]="statusDropdown">
                            </app-select-box>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label pr-3">ADD ACCOUNT TO PAY LIST</label>
                            <br />
                            <label class="switch-container">
                                <input
                                    type="checkbox"
                                    (change)="changeStatus()"
                                    [checked]="isVisible" />
                                <span class="slider round"></span>
                            </label>
                        </div>
                        <!--                         <div class="col-md-3">
                            <label class="form-label pr-2">SUPPLIER DIRECT?</label>
                            <i class="bi bi-info-circle"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="{{iconInfoMsg}}"></i>
                            <br>
                            <label class="switch-container" *ngIf="userLinkedToSafe">
                                <input type="checkbox"
                                    (change)="changeStatusSD()"
                                    [checked]="isSupplierDirect">
                                <span class="slider round"></span>
                            </label>
                        </div> -->
                    </div>
                    <section class="mt-4">
                        <app-business-fees
                            title="Fees Paid by Business User"
                            [showEditPencil]="false"
                            [parentToUpdate]="true"
                            [editBusiness]="editBusiness"
                            [businessFeeList]="businessFeeList"
                            (updateFee)="submit('business')">
                        </app-business-fees>
                    </section>
                    <div class="row">
                        <div class="col-md-6"></div>
                        <div class="col-md-6">
                            <button
                                class="custombtn custombtn-align-right"
                                (click)="submit('business')">
                                SAVE
                            </button>
                            <a class="user-files-deny" (click)="changeView($event, 'business')"
                                ><u>CANCEL</u></a
                            >
                        </div>
                    </div>
                </div>
            </section>
            <section class="add-section" *ngIf="user.userAgentProfileModel.roleId">
                <div class="row">
                    <div class="col-md-8 section-heading">Agent Settings</div>
                    <div class="col-md-4 section-heading">
                        <app-edit-pencil (edit)="changeView($event, 'agent')"></app-edit-pencil>
                    </div>
                </div>
                <div *ngIf="!editAgent">
                    <div class="row">
                        <div class="col-md-3">
                            <label class="form-label">AGENT ACCOUNT STATUS</label>
                            <div class="user-details-text">
                                {{
                                    user.userAgentProfileModel.agentAccountStatus || '-' | humanize
                                }}
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label">AGENT ROLE</label>
                            <div class="user-details-text">
                                {{ user.userAgentProfileModel.agentRole || '-' | humanize }}
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label">OPERATIONAL LOCATION</label>
                            <div class="user-details-text">
                                {{ user.userAgentProfileModel.operationalLocation || '-' }}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <label class="form-label">ADDRESS LINE 1</label>
                            <div class="user-details-text">
                                {{ user.userAgentProfileModel.address.addressLineOne || '-' }}
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label">ADDRESS LINE 2</label>
                            <div class="user-details-text">
                                {{ user.userAgentProfileModel.address.addressLineTwo || '-' }}
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label">BUILDING DETAILS</label>
                            <div class="user-details-text">
                                {{ user.userAgentProfileModel.address.buildingDetails || '-' }}
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label">SUBURB</label>
                            <div class="user-details-text">
                                {{ user.userAgentProfileModel.address.suburb || '-' }}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <label class="form-label">PROVINCE</label>
                            <div class="user-details-text">
                                {{ user.userAgentProfileModel.address.provinceName || '-' }}
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label">CITY</label>
                            <div class="user-details-text">
                                {{ user.userAgentProfileModel.address.cityName || '-' }}
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label">POSTAL CODE</label>
                            <div class="user-details-text">
                                {{ user.userAgentProfileModel.address.postalCode || '-' }}
                            </div>
                        </div>
                    </div>
                    <section class="mt-4">
                        <app-agent-fees
                            title="Fees Paid by Agent User"
                            [showEditPencil]="false"
                            [parentToUpdate]="true"
                            [editAgent]="editAgent"
                            [agentFeeList]="agentFeeList"
                            (updateFee)="submit('agent')">
                        </app-agent-fees>
                    </section>
                </div>
                <div *ngIf="editAgent">
                    <div class="row">
                        <div class="col-md-3">
                            <label class="form-label">AGENT ACCOUNT STATUS</label>
                            <app-select-box
                                placeholderValue="Set Status"
                                value="agentStatus"
                                [form]="form"
                                [invalidForm]="invalidForm"
                                [dropDown]="statusDropdown">
                            </app-select-box>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label">AGENT ROLE</label>
                            <app-select-box
                                placeholderValue="Set Role"
                                value="agentRole"
                                [form]="form"
                                [invalidForm]="invalidForm"
                                [dropDown]="accountTypeDropdown">
                            </app-select-box>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label">OPERATIONAL LOCATION</label>
                            <div>
                                <input
                                    class="form-input"
                                    type="text"
                                    placeholder="e.g. physical or mobile"
                                    name="agentOperationalLocation"
                                    formControlName="agentOperationalLocation" />
                            </div>
                        </div>
                    </div>
                    <fieldset formGroupName="agentAddress">
                        <div class="row">
                            <div class="col-md-3">
                                <label class="form-label">ADDRESS LINE 1</label>
                                <div>
                                    <input
                                        class="form-input"
                                        type="text"
                                        placeholder="Enter Address"
                                        name="addressLineOne"
                                        formControlName="addressLineOne" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <label class="form-label">ADDRESS LINE 2</label>
                                <div>
                                    <input
                                        class="form-input"
                                        type="text"
                                        placeholder="Enter Address"
                                        name="addressLineTwo"
                                        formControlName="addressLineTwo" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <label class="form-label">BUILDING DETAILS</label>
                                <div>
                                    <input
                                        class="form-input"
                                        type="text"
                                        placeholder="e.g. floor 3"
                                        name="buildingDetails"
                                        formControlName="buildingDetails" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <label class="form-label">SUBURB</label>
                                <div>
                                    <input
                                        class="form-input"
                                        type="text"
                                        placeholder="Suburb"
                                        name="suburb"
                                        formControlName="suburb" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <label class="form-label">PROVINCE</label>
                                <app-select-box
                                    placeholderValue="Set Province"
                                    value="agentAddress.provinceName"
                                    [form]="form"
                                    [invalidForm]="invalidForm"
                                    [dropDown]="provinceDropdown">
                                </app-select-box>
                            </div>
                            <div class="col-md-3">
                                <label class="form-label">CITY</label>
                                <app-select-box
                                    placeholderValue="Set City"
                                    value="agentAddress.cityName"
                                    [form]="form"
                                    [invalidForm]="invalidForm"
                                    [dropDown]="cityDropdown">
                                </app-select-box>
                            </div>
                            <div class="col-md-3">
                                <label class="form-label">POSTAL CODE</label>
                                <div>
                                    <input
                                        class="form-input"
                                        type="text"
                                        placeholder="e.g. 7708"
                                        name="postalCode"
                                        formControlName="postalCode" />
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <section class="mt-4">
                        <app-agent-fees
                            title="Fees Paid by Agent User"
                            [showEditPencil]="false"
                            [parentToUpdate]="true"
                            [editAgent]="editAgent"
                            [agentFeeList]="agentFeeList"
                            (updateFee)="submit('agent')">
                        </app-agent-fees>
                    </section>
                    <div class="row">
                        <div class="col-md-6"></div>
                        <div class="col-md-6">
                            <button
                                class="custombtn custombtn-align-right"
                                (click)="submit('agent')">
                                SAVE
                            </button>
                            <a class="user-files-deny" (click)="changeView($event, 'agent')"
                                ><u>CANCEL</u></a
                            >
                        </div>
                    </div>
                </div>
            </section>
        </form>
    </div>
</div>
