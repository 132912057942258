import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Pagination } from 'src/app/model/Pagination';
import { CommonService } from 'src/app/services/common.service';
import { LoginService } from 'src/app/services/login.service';
import { EnterpriseService } from 'src/app/services/enterprise.service';
import { ProductOrder } from 'src/app/model/product.order';
import { Role } from 'src/app/enums/role';
import { isThisTypeNode } from 'typescript';
import { MessageConstant } from 'src/app/settings/message.constant';
import { AppConstants } from 'src/app/settings/app-constants';
import { FormUtils } from 'src/app/utils/form.utils';

@Component({
    selector: 'app-enterprise-product-orders',
    templateUrl: './enterprise-product-orders.component.html',
    styleUrls: ['./enterprise-product-orders.component.css'],
})
export class EnterpriseProductOrdersComponent {
    form: FormGroup;
    pagination = new Pagination();
    enterpriseId: string;
    isLoading = false;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private commonService: CommonService,
        public loginService: LoginService,
        private enterpriseService: EnterpriseService,
    ) {
        this.enterpriseId = this.loginService.sessionUser.value.enterprise.enterpriseId;

        this.form = this.formBuilder.group({
            start: [''],
            length: [''],
            enterpriseId: [this.enterpriseId],
            orderNumber: [''],
            paymentStatus: [''],
            fulfilled: [null],
            fullfilmentStatus: [''],
        });
    }
}
