<div class="edit-link">
    <a data-toggle="dropdown">
        <div class="dot-icon"></div>
    </a>
    <ul class="dropdown-menu" aria-labelledby="menu1">
        <li>
            <a class="dropdown-menu-item" (click)="commonUtil.navigate('profile', user)">
                <img src="assets/images/edit.png" />
                Edit Profile
            </a>
        </li>
        <li>
            <a class="dropdown-menu-item" (click)="commonUtil.navigate('transaction', user)">
                <img src="assets/images/transactions.png" />
                View Transactions
            </a>
        </li>
        <li>
            <a class="dropdown-menu-item" (click)="navigateChangeMobile()">
                <img src="assets/images/change-mobile-no.png" />
                Change Mobile No.
            </a>
        </li>
    </ul>
</div>
