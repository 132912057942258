import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'humanize' })
export class HumanizePipe implements PipeTransform {
    transform(value: string): any {
        if (!value) {
            return value;
        }

        let finalString = value
            .replace(/^[\s_]+|[\s_]+$/g, '')
            .replace(/[_\s]+/g, ' ')
            .replace(/^[a-z]/, (m) => {
                return m.toUpperCase();
            });

        const str = finalString.split(' ');
        finalString = '';

        str.forEach((s) => {
            finalString += s.charAt(0).toUpperCase() + s.slice(1) + ' ';
        });

        if (finalString.length > 0) {
            finalString = finalString.slice(0, finalString.length - 1);
        }

        return finalString;
    }
}
