<div id="smartsafe-config-view-modal" class="sideview-modal-window">
    <div class="sideview-modal-header">
        {{ title }}
        <a (click)="closeModal()"
            ><img class="text-right" src="assets/images/close-x-purple.png"
        /></a>
    </div>
    <hr />
    <div class="sideview-modal-body">
        @if (updateErrors?.length > 0) {
            <div class="row">
                <div class="col">
                    <ul class="errors-list">
                        @for (error of updateErrors; track $index) {
                            <li>
                                {{ error }}
                            </li>
                        }
                    </ul>
                </div>
            </div>
        }
        <form [formGroup]="safeForm">
            <div class="row">
                <div class="col-md-6" [ngClass]="displayFieldCss('safeId')">
                    <label class="form-label control-label required">SAFE ID</label>
                    <div>
                        <input
                            class="form-input"
                            placeholder="Safe ID"
                            [ngClass]="displayFieldCss('safeId')"
                            type="text"
                            formControlName="safeId"
                            [readonly]="safeIdShouldReadonly" />
                    </div>
                    <app-field-error-display
                        [displayError]="isFieldInValid('safeId')"
                        errorMsg="Safe ID is required">
                    </app-field-error-display>
                </div>
                <div class="col-md-6" [ngClass]="displayFieldCss('safeMacAddress')">
                    <label class="form-label control-label required">MAC ADDRESS</label>
                    <div>
                        <input
                            class="form-input"
                            placeholder="e.g 00:2A:99:4B:33:11"
                            [ngClass]="displayFieldCss('safeMacAddress')"
                            type="text"
                            formControlName="safeMacAddress" />
                    </div>
                    <app-field-error-display
                        [displayError]="isFieldInValid('safeMacAddress')"
                        errorMsg="Mac Address is required">
                    </app-field-error-display>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label class="form-label control-label required">USER</label>
                    <div>
                        <app-select-box
                            placeholderValue="Assign User"
                            value="user"
                            [form]="safeForm"
                            [invalidForm]="invalidForm"
                            [dropDown]="userNames">
                        </app-select-box>
                        <app-field-error-display
                            [displayError]="isFieldInValid('user')"
                            errorMsg="User must be assigned">
                        </app-field-error-display>
                    </div>
                </div>

                <div class="col-md-6">
                    <label class="form-label control-label required">MODEL</label>
                    <div>
                        <app-select-box
                            placeholderValue="Model"
                            value="model"
                            [form]="safeForm"
                            [invalidForm]="invalidForm"
                            [dropDown]="safeModelList">
                        </app-select-box>
                        <app-field-error-display
                            [displayError]="isFieldInValid('model')"
                            errorMsg="Model must be selected">
                        </app-field-error-display>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <label class="form-label control-label required">NOTE CAPACITY</label>
                    <div>
                        <input
                            class="form-input"
                            placeholder="Note Capacity"
                            [ngClass]="displayFieldCss('noteCapacity')"
                            type="text"
                            formControlName="noteCapacity" />
                    </div>
                    <app-field-error-display
                        [displayError]="isFieldInValid('noteCapacity')"
                        errorMsg="Note Capacity is required">
                    </app-field-error-display>
                </div>
                <div class="col-md-6">
                    <label class="form-label control-label required">CURRENCY</label>
                    <app-select-box
                        placeholderValue="Currency"
                        value="currency"
                        [form]="safeForm"
                        [invalidForm]="invalidForm"
                        [dropDown]="currencyDropdown">
                    </app-select-box>
                    <app-field-error-display
                        [displayError]="isFieldInValid('currency')"
                        errorMsg="currency must selected">
                    </app-field-error-display>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label class="form-label control-label required">STATUS</label>
                    <app-select-box
                        placeholderValue="Status"
                        value="status"
                        [form]="safeForm"
                        [invalidForm]="invalidForm"
                        [dropDown]="statusDropdown">
                    </app-select-box>
                    <app-field-error-display
                        [displayError]="isFieldInValid('status')"
                        errorMsg="status must selected">
                    </app-field-error-display>
                </div>
                <div class="col-md-6">
                    <label class="form-label control-label">SOFTWARE VERSION</label>
                    <div>
                        <div>
                            <input
                                class="form-input"
                                placeholder="Software Version"
                                [ngClass]="displayFieldCss('softwareVersion')"
                                type="text"
                                formControlName="softwareVersion" />
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <hr />
        <div class="row">
            <div class="col-md-12">
                <button class="secondary-button" (click)="closeModal()">Cancel</button>
                <button class="primary-button" (click)="submit()">Save</button>
            </div>
        </div>
    </div>
</div>
