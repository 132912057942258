<button
    class="page-arrow"
    [disabled]="pageNumber < 2"
    (click)="navigate(-2)"
    (mouseover)="onFirstMouseOver()"
    (mouseout)="onFirstMouseOut()">
    <img [src]="firstSrc" />
</button>
<button
    class="page-arrow"
    [disabled]="pageNumber < 2"
    (click)="navigate(-1)"
    (mouseover)="onPrevMouseOver()"
    (mouseout)="onPreMouseOut()">
    <img [src]="prevSrc" />
</button>
Page {{ pageNumber }} of {{ totalPages }}
<button
    class="page-arrow"
    [disabled]="pageNumber === totalPages"
    (click)="navigate(1)"
    (mouseover)="onNextMouseOver()"
    (mouseout)="onNextMouseOut()">
    <img [src]="nextSrc" />
</button>
<button
    class="page-arrow"
    [disabled]="pageNumber === totalPages"
    (click)="navigate(2)"
    (mouseover)="onLastMouseOver()"
    (mouseout)="onLastMouseOut()">
    <img [src]="lastSrc" />
</button>
