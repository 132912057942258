import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-enterprise-links',
    templateUrl: './enterprise-links.component.html',
    styleUrls: ['./enterprise-links.component.css'],
})
export class EnterpriseLinksComponent {
    @Input() id: string;
    @Input() isAdmin = false;

    constructor() {}

    getTransactionsRouterLink() {
        if (this.isAdmin) {
            return '/manage-users/enterprise/transactions';
        }
        return '/enterprise/transactions';
    }
}
