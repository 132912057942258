import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoaderService {
    private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    set isLoading(value: boolean) {
        this._loading.next(value);
    }

    get isLoading(): boolean {
        return this._loading.getValue();
    }
}
