import { Bank } from './bank';
import { DocumentFile } from './DocumentFile';
import { UserAddress } from './user.address';
import { AvailabilityDetails } from './availability.details';
import { Wallet } from './wallet';

export class Merchant {
    public roleId: string;
    public userRoleId: string;
    public businessName: string;
    public businsessCategoryName: string;
    public storeTypeName: string;
    public businessDescription: string;
    public businessStatus: string;
    public emergencyFirstName: string;
    public emergencyLastName: string;
    public emergencyMobileNumber: string;
    public businessEmail: string;
    public address: UserAddress = new UserAddress();
    public walletBalance: Wallet;
    public bankList: Bank[] = [];
    public businessLogo: string;
    public profilePicture: string;
    public storeTypeId: number;
    public visibleInPayList: boolean;
    public supplierDirect: boolean;
    public lastLogin: string;
    public referralCode: string;
    constructor() {}
}
