import { Component, OnInit, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from 'src/app/services/company.service';
import { MessageConstant } from 'src/app/settings/message.constant';
import { environment } from 'src/environments/environment';
import { LoginForm } from '../../model/login.form';
import { LoginService } from '../../services/login.service';
import { StorageConstant } from '../../settings/storage-constants';
import { URI } from '../../settings/uri-constants';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
    show: boolean;
    display: boolean;
    displaySuccess: boolean;
    forgotPassword: boolean;
    isLoading: boolean;
    forgotText: string;
    submitText: string;
    loginForm: LoginForm;
    errorMessage: string;
    emailFocused: boolean;
    passwordFocused: boolean;
    showPasswordImage: string;
    successMessage: string;
    placeholder: string;

    constructor(
        private loginService: LoginService,
        private companyService: CompanyService,
        private router: Router,
        private toasterService: ToastrService,
    ) {
        const forgotPass = localStorage.getItem(StorageConstant.CHANGE_PASSWORD_SUCCESS);
        const createPass = localStorage.getItem(StorageConstant.CREATE_PASSWORD_SUCCESS);
        localStorage.clear();

        if (forgotPass && forgotPass === 'success') {
            this.displaySuccess = true;
            this.successMessage = 'Password Updated Successfully!';
        } else if (createPass && createPass === 'success') {
            this.displaySuccess = true;
            this.successMessage = 'Password Created Successfully!';
        }

        this.show = true;
        this.forgotPassword = false;
        this.isLoading = false;
        this.emailFocused = false;
        this.passwordFocused = false;
        this.display = false;
        this.forgotText = 'Forgot Password?';
        this.submitText = 'Sign In';
        this.loginForm = new LoginForm();
        this.errorMessage = '';
        this.showPasswordImage = 'show-password';
        this.placeholder = 'Email Address or Mobile Number';
    }

    ngOnInit() {
        this.loginService.sessionUser.subscribe((user) => {
            if (user) {
                this.router.navigate([URI.getRouteNavPath('dashboard')]);
            }
        });
    }

    changePasswordDisplayType() {
        this.show = !this.show;
        this.showPasswordImage =
            this.showPasswordImage === 'show-password' ? 'show-password-active' : 'show-password';
    }

    forgetPassChange() {
        this.forgotPassword = !this.forgotPassword;

        if (this.forgotPassword) {
            this.forgotText = 'Back to Sign In';
            this.submitText = 'Request Reset';
            this.placeholder = 'Enter Email Address';
        } else {
            this.forgotText = 'Forgot Password?';
            this.submitText = 'Sign In';
            this.placeholder = 'Email Address or Mobile Number';
        }
    }

    openModel() {
        this.display = true;
    }

    openSuccessModel() {
        this.displaySuccess = true;
    }

    closeModel() {
        this.display = false;
        this.displaySuccess = false;
    }

    passwordFocusFunction() {
        this.passwordFocused = !this.passwordFocused;
    }

    emailFocusFunction() {
        this.emailFocused = !this.emailFocused;
    }

    signIn() {
        this.isLoading = true;
        this.loginService.signIn(this.loginForm).subscribe(
            async (response) => {
                this.isLoading = false;
                this.loginForm.password = '';

                if (response.success) {
                    this.loginService.routeByRoleOnLogin(response.role);
                } else {
                    this.isLoading = false;
                }
            },
            (error) => {
                this.isLoading = false;
                this.loginForm.password = '';
            },
        );
    }

    validateCredentials() {
        this.isLoading = true;
        const body = {
            userName: this.loginForm.email,
            password: this.loginForm.password,
        };

        this.loginService.validateCredentials(body).subscribe(
            (response) => {
                this.isLoading = false;

                if (response.success) {
                    localStorage.setItem(
                        StorageConstant.LOGIN_CREDENTIALS,
                        JSON.stringify(this.loginForm),
                    );
                    localStorage.setItem(StorageConstant.TFA_UUID, response.uuid);
                    this.router.navigate([URI.SLASHED_LOGIN_TFA]);
                } else {
                    this.isLoading = false;
                    if (response.status.message.length > 0) {
                        this.toasterService.error(response.error, response.status.message);
                    }
                }
            },
            (error) => {
                this.isLoading = false;
            },
        );
    }

    onSubmit() {
        if (this.forgotPassword) {
            this.isLoading = true;
            this.loginService.forgotPassword(this.loginForm.email).subscribe(
                (response) => {
                    this.isLoading = false;
                    this.successMessage = MessageConstant.RECOVER_PASSWORD_REQUEST;
                    this.openSuccessModel();
                },
                (error) => {
                    this.isLoading = false;
                },
            );
            this.loginForm.password = '';
            this.loginForm.email = '';
        } else {
            // If we are in Dev mode, skip MFA
            if (!environment.production || isDevMode()) {
                this.signIn();
            } else {
                this.validateCredentials();
            }
        }
    }
}
