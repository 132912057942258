import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { TransactionStatus } from '../enums/transaction.status';
import { DailyLimit } from '../model/daily.limit';
import { Fees } from '../model/fees';
import { GenericResponse } from '../model/generic.response';
import { KycLimit } from '../model/kyc.limit';
import { AppSettings } from '../settings/app-settings';
import { CompanyService } from './company.service';

@Injectable({ providedIn: 'root' })
export class TransactionService {
    constructor(
        private http: HttpClient,
        private companyService: CompanyService,
    ) { }

    updateTransactionStatus(body: any) {
        const path = AppSettings.UPDATE_TRANSACTION_STATUS;
        return this.http.put<GenericResponse<any>>(AppSettings.API_ENDPOINT + path, body);
    }

    updateTransactionAmount(body: any) {
        const path = AppSettings.UPDATE_TRANSACTION_AMOUNT;
        return this.http.put<GenericResponse<any>>(AppSettings.API_ENDPOINT + path, body);
    }

    getFees() {
        let params;

        if (this.companyService.getSelectedCompany()) {
            params = new HttpParams().set(
                'companyId',
                this.companyService.getSelectedCompany().id.toString(),
            );
        }

        return this.http.get<GenericResponse<Fees[]>>(
            AppSettings.TRANSACTION_API_ENDPOINT + AppSettings.GET_FEES,
            { params },
        );
    }

    getFeeByCode(feeCode: string) {
        const path = AppSettings.GET_FEE_BY_CODE.replace('@1@', feeCode);

        return this.http.get<GenericResponse<Fees>>(AppSettings.TRANSACTION_API_ENDPOINT + path);
    }

    updateFees(body: any) {
        return this.http.post<GenericResponse<Fees[]>>(
            AppSettings.TRANSACTION_API_ENDPOINT + AppSettings.UPDATE_FEES,
            body,
        );
    }

    updateCustomFees(body: any) {
        return this.http.post<GenericResponse<Fees[]>>(
            AppSettings.TRANSACTION_API_ENDPOINT + AppSettings.UPDATE_CUSTOM_FEES,
            body,
        );
    }

    getKycLimit() {
        return this.http.get<GenericResponse<KycLimit[]>>(
            AppSettings.TRANSACTION_API_ENDPOINT + AppSettings.GET_KYC_LIMIT,
        );
    }

    updateKycLimit(body: any) {
        return this.http.post<GenericResponse<KycLimit[]>>(
            AppSettings.TRANSACTION_API_ENDPOINT + AppSettings.UPDATE_KYC_LIMIT,
            body,
        );
    }

    getDailyLimit() {
        return this.http.get<GenericResponse<DailyLimit[]>>(
            AppSettings.TRANSACTION_API_ENDPOINT + AppSettings.GET_DAILY_LIMIT,
        );
    }

    updateDailyLimit(body: any) {
        return this.http.post<GenericResponse<DailyLimit[]>>(
            AppSettings.TRANSACTION_API_ENDPOINT + AppSettings.UPDATE_DAILY_LIMIT,
            body,
        );
    }

    getTransactionType() {
        return this.http
            .get<
                GenericResponse<string[]>
            >(AppSettings.TRANSACTION_API_ENDPOINT + AppSettings.DROPDOWN_TRANSACTION_TYPE)
            .pipe(
                tap((response) => {
                    if (response.success) {
                        response.data.forEach((element, index) => {
                            if (element == 'cashback') {
                                response.data.splice(index, 1);
                            }
                        });
                    }
                }),
            );
    }

    getTransactionStatus() {
        return this.http.get<GenericResponse<string[]>>(
            AppSettings.TRANSACTION_API_ENDPOINT + AppSettings.DROPDOWN_TRANSACTION_STATUS,
        );
    }

    updateTransferToBank(body: any, id: string) {
        const path = AppSettings.CANCEL_TRANSFER_TO_BANK_REQUEST.replace('@1@', id);
        return this.http.put<GenericResponse<any>>(
            AppSettings.TRANSACTION_API_ENDPOINT + path,
            body,
        );
    }

    transferWithoutNetcash(body: any) {
        const path = AppSettings.TRANSFER_TO_BANK_WITHOUT_NETCASH;
        return this.http.post<GenericResponse<any>>(AppSettings.API_ENDPOINT + path, body);
    }

    updateBankTransfer(body: any) {
        const path = AppSettings.CANCEL_BANK_TRANSFER;
        return this.http.put<GenericResponse<any>>(AppSettings.API_ENDPOINT + path, body);
    }

    getCustomFee(userRoleId: string, role: string) {
        let path = AppSettings.GET_CUSTOM_FEE.replace('@1@', userRoleId);
        path = path.replace('@2@', role);
        return this.http.get<GenericResponse<Fees[]>>(AppSettings.TRANSACTION_API_ENDPOINT + path);
    }

    runSettlementBatch() {
        const path = AppSettings.SETTLEMENT_BATCH_RUN;
        return this.http.get<GenericResponse<any>>(AppSettings.API_ENDPOINT + path);
    }

    // Send message from an admin to a user who requested an EFT Cash In
    sendEFTComms(transactionCode: string, message: string) {
        const params = new HttpParams()
            .set('transactionCode', transactionCode)
            .set('message', message);

        return this.http.get<GenericResponse<string[]>>(
            AppSettings.API_ENDPOINT + AppSettings.SEND_EFT_COMMS,
            { params },
        );
    }

    // Non Http call methods

    txnNotOngoing(txnStatus: string) {
        // Accept both array of TransactionStatus and string else compiler will compain
        const inProcessStatuses: TransactionStatus[] & string[] = [
            TransactionStatus.pending,
            TransactionStatus.onhold,
            TransactionStatus.cancelled,
        ];
        return inProcessStatuses.includes(txnStatus);
    }

    txnComplete(txnStatus: string) {
        // Accept both array of TransactionStatus and string else compiler will compain
        const completeTxnStatuses: TransactionStatus[] & string[] = [
            TransactionStatus.cancelled,
            TransactionStatus.success,
            TransactionStatus.failed,
        ];
        return completeTxnStatuses.includes(txnStatus);
    }
}
