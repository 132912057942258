import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppConstants } from 'src/app/settings/app-constants';
import { ModalService } from 'src/app/services/modal.service';

@Component({
    selector: 'app-safe-summary-details',
    templateUrl: './safe-summary-details.component.html',
    styleUrls: ['./safe-summary-details.component.css'],
})
export class SafeSummaryDetailsComponent {
    @Input() safeSummary: any;
    @Output() event = new EventEmitter<boolean>();
    amountFormat = AppConstants.AMOUNT_FORMAT;
    dateFormat = AppConstants.DATE_FORMAT;

    constructor(private modalService: ModalService) {}

    closeViewModel() {
        this.event.emit(false);
    }

    openModal(id: string) {
        this.modalService.open(id);
    }
}
