import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { UserStatus } from 'src/app/enums/user.status';
import { VerificationType } from 'src/app/enums/verification.type';
import { Pagination } from 'src/app/model/Pagination';
import { User } from 'src/app/model/user';
import { CommonService } from 'src/app/services/common.service';
import { StorageConstant } from 'src/app/settings/storage-constants';
import { URI } from 'src/app/settings/uri-constants';

@Component({
    selector: 'app-active-applications',
    templateUrl: './active-applications.component.html',
    styleUrls: ['./active-applications.component.css'],
})
export class ActiveApplicationsComponent implements OnInit {
    userList: Array<User>;
    pagination = new Pagination();
    isLoading = false;
    progressWidth = 25;
    form: FormGroup;
    name = '';
    user: User;
    denyModalDisplay = 'none';
    verifyModalDisplay = 'none';
    messageList = ['Failed interview', 'Cannot verify user documents', 'Failed training'];

    constructor(
        private commonService: CommonService,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        @Inject(DOCUMENT) private document: Document,
    ) {
        this.pagination.isAgent = true;
        this.form = this.formBuilder.group({
            role: ['agent'],
            status: [UserStatus.pending_review],
            start: ['0'],
            length: ['10'],
            isAgent: [true],
        });
    }

    ngOnInit() {
        this.getData();
    }

    getData() {
        this.isLoading = true;
        this.form.get('start').setValue(this.pagination.start);
        this.form.get('length').setValue(this.pagination.length);
        this.setProgressWidth(50);
        this.commonService.getFilteredData(this.form).subscribe(
            (user) => {
                this.setProgressWidth(100);
                setTimeout(() => {
                    this.userList = user.data;
                    this.pagination.total = user.total;
                    this.isLoading = false;
                    this.progressWidth = 25;
                }, 350);
            },
            (error) => {
                this.isLoading = false;
            },
        );
    }

    setProgressWidth(i: number) {
        setTimeout(() => {
            this.progressWidth = i;
        }, 300);
    }

    receiveFilter(data: { name: string; value: number }) {}

    changeLength(len: number) {
        this.pagination.length = len;
        this.pagination.start = 0;
        this.getData();
    }

    changePage(page: number) {
        this.pagination.changePage(page);
        this.getData();
    }

    openDenyOption() {
        this.denyModalDisplay = 'block';
    }

    closeDenyOption() {
        this.denyModalDisplay = 'none';
    }

    openVerifyModal() {
        this.verifyModalDisplay = 'block';
    }

    closeVerifyModal() {
        this.verifyModalDisplay = 'none';
    }

    changeStatus(val: { type: string; user: User }) {
        this.user = val.user;
        if (val.type === 'deny') {
            this.openDenyOption();
        } else if (val.type === 'verify') {
            this.name = val.user.firstName + ' ' + val.user.lastName;
            this.openVerifyModal();
        } else {
            localStorage.setItem(StorageConstant.AGENT_FILES, JSON.stringify(val.user));
            this.router.navigate([URI.getRouteNavPath('adminAgentApplicationsActiveFiles')]);
        }
    }

    denyApplication() {
        const messageId = this.document
            .querySelector('input[name="operationType"]:checked')
            .attributes.getNamedItem('value').value;
        this.commonService
            .updateUserDocumnets(
                {
                    status: UserStatus.disabled,
                    statusMessage: this.messageList[messageId],
                    verificationType: VerificationType.residence,
                },
                this.user.id,
            )
            .subscribe(
                (res) => {
                    if (res.success === true) {
                        this.getData();
                    }
                    this.closeDenyOption();
                },
                (err) => {
                    this.closeDenyOption();
                },
            );
    }

    approve() {
        localStorage.setItem(StorageConstant.AGENT_APPROVE, JSON.stringify(this.user));
        this.router.navigate([URI.SLASHED_ADD_AGENT]);
    }

    get controls(): any {
        return this.form.controls;
    }
}
