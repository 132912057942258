export enum SmartSafeStatus {
    active = 'Active',
    deactivated = 'Deactivated',
}

export const SmartSafeStatusList = Object.keys(SmartSafeStatus).map((name) => {
    return {
        name,
        value: SmartSafeStatus[name as keyof typeof SmartSafeStatus],
    };
});
