<div class="wrapper">
    <app-error-model
        message="{{ eMessage }}"
        [display]="display"
        (event)="closeModel($event)"></app-error-model>
    <app-success-model
        message="{{ sMessage }}"
        [display]="displaySuccess"
        (event)="closeModel($event)"></app-success-model>
    <app-navigation></app-navigation>
    <div class="content-wrapper">
        <app-content-transition></app-content-transition>
        <app-loader *ngIf="isLoading" className="custom-loader" text="Processing ..."></app-loader>
        <app-header title="Transfer To Bank"></app-header>
        <app-transfer-to-bank-links></app-transfer-to-bank-links>

        <div>
            <section class="add-section export-ttb">
                <div class="row">
                    <div class="col-md-12 section-heading">Export to Sage</div>
                </div>
                <div class="row" *ngIf="!processingComplete">
                    <div class="col-md-6">
                        <strong>{{ rows }}</strong> requests ready for export.<br />
                    </div>
                    <div class="col-md-6">
                        <button
                            type="submit"
                            class="ttb-export-custombtn"
                            [disabled]="noSettlementToExport()"
                            id="ttb-exportbtn"
                            (click)="process()">
                            Manual Export
                        </button>
                    </div>
                </div>
                <div class="row mt-4 mb-3" *ngIf="processing">
                    <div class="col-md-4">
                        <div>
                            <div class="custom-progress-bar">
                                <span [style.width.%]="progressWidth"></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div *ngIf="processing">Exporting {{ progressWidth }}%...</div>
                    </div>
                </div>
                <div class="row mb-3" *ngIf="processingComplete">
                    <div class="col-md-6">
                        <strong>{{ rows }}</strong> Rows successfully sent to Sage<br />
                        CZAR <strong>{{ TTB.totalAmount }}</strong> to be settled to users<br />
                    </div>
                    <div class="col-md-6">
                        <button
                            type="submit"
                            class="ttb-export-custombtn"
                            id="ttb-exportbtn"
                            [disabled]="noSettlementToExport()">
                            Manual Export
                        </button>
                        <a class="user-files-deny" (click)="closeExportModel()"
                            ><u>RUN NEW EXPORT</u></a
                        >
                    </div>
                </div>
                <div *ngIf="TTB?.lastExportOn">
                    <div class="row mt-1">
                        <div class="col-md-12 section-heading">Last Export</div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-12">
                            Last export was made on
                            <strong>{{ TTB.lastExportOn | date: 'd/M/yyyy - h:mm a' }}</strong
                            ><br />
                            by <strong>{{ TTB.exportedBy }}.</strong>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <div class="control-sidebar-bg"></div>
</div>
