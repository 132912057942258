import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replace',
})
export class ReplacePipe implements PipeTransform {
    transform(value: string, replaceWith = ''): string {
        if (value !== undefined && value !== null) {
            return value.replace(/,/g, replaceWith);
        } else {
            return '';
        }
    }
}
