import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { User } from 'src/app/model/user';

@Component({
    selector: 'app-last-child-agent',
    templateUrl: './last-child-agent.component.html',
    styleUrls: ['./last-child-agent.component.css'],
})
export class LastChildAgentComponent {
    @Input() user: User;
    @Output() status = new EventEmitter<{ type: string; user: User }>();

    constructor() {}

    changeStatus(val: string) {
        this.status.emit({ type: val, user: this.user });
    }
}
