import { Bank } from './bank';
import { DocumentFile } from './DocumentFile';
import { UserAddress } from './user.address';
import { Wallet } from './wallet';

export class Agent {
    public idType: string;
    public idNumber: string;
    public roleId: string;
    public userRoleId: string;
    public agentRole: string;
    public dob: string;
    public operationalLocation: string;
    public agentAccountStatus: string;
    public lastLogin: string;
    public referralCode: string;
    public address: UserAddress = new UserAddress();
    public operationalAddress: UserAddress = new UserAddress();
    public walletBalance: Wallet;
    public hasSafe = false;
    constructor() {}
}
