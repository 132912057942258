<div class="wrapper">
    <app-error-model
        message="{{ eMessage }}"
        [display]="display"
        (event)="closeModel($event)"></app-error-model>
    <app-navigation></app-navigation>
    <div class="content-wrapper">
        <app-content-transition></app-content-transition>
        <app-loader *ngIf="isLoading" className="custom-loader" text="Processing ..."></app-loader>
        <app-header location="Fees & Earnings" title="Settings"></app-header>
        <app-settings-link></app-settings-link>

        <section class="add-section">
            <div class="row">
                <div class="col-md-8 section-heading">Marketplace Fees</div>
                <div class="col-md-4 section-heading">
                    <app-edit-pencil (edit)="switchMarketFeeView()"></app-edit-pencil>
                </div>
            </div>
            <div *ngIf="!editMarketFee">
                <div class="row">
                    <div class="col-md-12">
                        <table class="fees-table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>% FEE</th>
                                    <th>USE %</th>
                                    <th>AMOUNT</th>
                                    <th>USE AMOUNT</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngIf="
                                        commonUtil.showFeeRow(
                                            marketplaceFeeList,
                                            feeCode.marketplace_pickup_cashback
                                        )
                                    ">
                                    <td>
                                        <span
                                            data-toggle="tooltip"
                                            data-placement="right"
                                            title="{{
                                                commonUtil.getFee(
                                                    marketplaceFeeList,
                                                    feeCode.marketplace_pickup_cashback
                                                ).description
                                            }}">
                                            {{ feeLabel[feeCode.marketplace_pickup_cashback] }}
                                        </span>
                                    </td>
                                    <td>
                                        {{
                                            commonUtil.getFee(
                                                marketplaceFeeList,
                                                feeCode.marketplace_pickup_cashback
                                            ).percentage | number: amountFormat
                                        }}{{ ' %' }}
                                    </td>
                                    <td>
                                        <input
                                            type="radio"
                                            name="viewOnly_marketplace_{{
                                                feeCode.marketplace_pickup_cashback
                                            }}"
                                            [checked]="
                                                !commonUtil.getFee(
                                                    marketplaceFeeList,
                                                    feeCode.marketplace_pickup_cashback
                                                ).useAmount
                                            "
                                            disabled />
                                    </td>
                                    <td>
                                        {{
                                            commonUtil.getFee(
                                                marketplaceFeeList,
                                                feeCode.marketplace_pickup_cashback
                                            ).amount | number: amountFormat
                                        }}
                                    </td>
                                    <td>
                                        <input
                                            type="radio"
                                            name="viewOnly_marketplace_{{
                                                feeCode.marketplace_pickup_cashback
                                            }}"
                                            [checked]="
                                                commonUtil.getFee(
                                                    marketplaceFeeList,
                                                    feeCode.marketplace_pickup_cashback
                                                ).useAmount
                                            "
                                            disabled />
                                    </td>
                                </tr>
                                <tr
                                    *ngIf="
                                        commonUtil.showFeeRow(
                                            marketplaceFeeList,
                                            feeCode.marketplace_margin
                                        )
                                    ">
                                    <td>
                                        <span
                                            data-toggle="tooltip"
                                            data-placement="right"
                                            title="{{
                                                commonUtil.getFee(
                                                    marketplaceFeeList,
                                                    feeCode.marketplace_margin
                                                ).description
                                            }}">
                                            {{ feeLabel[feeCode.marketplace_margin] }}
                                        </span>
                                    </td>
                                    <td>
                                        {{
                                            commonUtil.getFee(
                                                marketplaceFeeList,
                                                feeCode.marketplace_margin
                                            ).percentage | number: amountFormat
                                        }}{{ ' %' }}
                                    </td>
                                    <td>
                                        <input
                                            type="radio"
                                            name="viewOnly_marketplace_{{
                                                feeCode.marketplace_margin
                                            }}"
                                            [checked]="
                                                !commonUtil.getFee(
                                                    marketplaceFeeList,
                                                    feeCode.marketplace_margin
                                                ).useAmount
                                            "
                                            disabled />
                                    </td>
                                    <td>
                                        {{
                                            commonUtil.getFee(
                                                marketplaceFeeList,
                                                feeCode.marketplace_margin
                                            ).amount | number: amountFormat
                                        }}
                                    </td>
                                    <td>
                                        <input
                                            type="radio"
                                            name="viewOnly_marketplace_{{
                                                feeCode.marketplace_margin
                                            }}"
                                            [checked]="
                                                commonUtil.getFee(
                                                    marketplaceFeeList,
                                                    feeCode.marketplace_margin
                                                ).useAmount
                                            "
                                            disabled />
                                    </td>
                                </tr>
                                <tr
                                    *ngIf="
                                        commonUtil.showFeeRow(
                                            marketplaceFeeList,
                                            feeCode.marketplace_delivery
                                        )
                                    ">
                                    <td>
                                        <span
                                            data-toggle="tooltip"
                                            data-placement="right"
                                            title="{{
                                                commonUtil.getFee(
                                                    marketplaceFeeList,
                                                    feeCode.marketplace_delivery
                                                ).description
                                            }}">
                                            {{ feeLabel[feeCode.marketplace_delivery] }}
                                        </span>
                                    </td>
                                    <td>
                                        {{
                                            commonUtil.getFee(
                                                marketplaceFeeList,
                                                feeCode.marketplace_delivery
                                            ).percentage | number: amountFormat
                                        }}{{ ' %' }}
                                    </td>
                                    <td>
                                        <input
                                            type="radio"
                                            name="viewOnly_marketplace_{{
                                                feeCode.marketplace_delivery
                                            }}"
                                            [checked]="
                                                !commonUtil.getFee(
                                                    marketplaceFeeList,
                                                    feeCode.marketplace_delivery
                                                ).useAmount
                                            "
                                            disabled />
                                    </td>
                                    <td>
                                        {{
                                            commonUtil.getFee(
                                                marketplaceFeeList,
                                                feeCode.marketplace_delivery
                                            ).amount | number: amountFormat
                                        }}
                                    </td>
                                    <td>
                                        <input
                                            type="radio"
                                            name="viewOnly_marketplace_{{
                                                feeCode.marketplace_delivery
                                            }}"
                                            [checked]="
                                                commonUtil.getFee(
                                                    marketplaceFeeList,
                                                    feeCode.marketplace_delivery
                                                ).useAmount
                                            "
                                            disabled />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div *ngIf="editMarketFee">
                <div class="row">
                    <div class="col-md-12">
                        <table class="fees-table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>% FEE</th>
                                    <th>USE %</th>
                                    <th>AMOUNT</th>
                                    <th>USE AMOUNT</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngIf="
                                        commonUtil.showFeeRow(
                                            marketplaceFeeList,
                                            feeCode.marketplace_pickup_cashback
                                        )
                                    ">
                                    <td>
                                        <span
                                            data-toggle="tooltip"
                                            data-placement="right"
                                            title="{{
                                                commonUtil.getFee(
                                                    marketplaceFeeList,
                                                    feeCode.marketplace_pickup_cashback
                                                ).description
                                            }}">
                                            {{ feeLabel[feeCode.marketplace_pickup_cashback] }}
                                        </span>
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            appFloatOnly
                                            [maxVal]="100"
                                            [(ngModel)]="
                                                commonUtil.getFee(
                                                    marketplaceFeeList,
                                                    feeCode.marketplace_pickup_cashback
                                                ).percentageValue
                                            " />
                                    </td>
                                    <td>
                                        <input
                                            type="radio"
                                            name="edit_marketplace_{{
                                                feeCode.marketplace_pickup_cashback
                                            }}"
                                            [checked]="
                                                !commonUtil.getFee(
                                                    marketplaceFeeList,
                                                    feeCode.marketplace_pickup_cashback
                                                ).useAmount
                                            "
                                            [(ngModel)]="
                                                commonUtil.getFee(
                                                    marketplaceFeeList,
                                                    feeCode.marketplace_pickup_cashback
                                                ).useAmountValue
                                            "
                                            [value]="false" />
                                    </td>

                                    <td>
                                        <input
                                            type="text"
                                            appFloatOnly
                                            [(ngModel)]="
                                                commonUtil.getFee(
                                                    marketplaceFeeList,
                                                    feeCode.marketplace_pickup_cashback
                                                ).amountValue
                                            " />
                                    </td>
                                    <td>
                                        <input
                                            type="radio"
                                            name="edit_marketplace_{{
                                                feeCode.marketplace_pickup_cashback
                                            }}"
                                            [checked]="
                                                commonUtil.getFee(
                                                    marketplaceFeeList,
                                                    feeCode.marketplace_pickup_cashback
                                                ).useAmount
                                            "
                                            [(ngModel)]="
                                                commonUtil.getFee(
                                                    marketplaceFeeList,
                                                    feeCode.marketplace_pickup_cashback
                                                ).useAmountValue
                                            "
                                            [value]="true" />
                                    </td>
                                </tr>
                                <tr
                                    *ngIf="
                                        commonUtil.showFeeRow(
                                            marketplaceFeeList,
                                            feeCode.marketplace_margin
                                        )
                                    ">
                                    <td>
                                        <span
                                            data-toggle="tooltip"
                                            data-placement="right"
                                            title="{{
                                                commonUtil.getFee(
                                                    marketplaceFeeList,
                                                    feeCode.marketplace_margin
                                                ).description
                                            }}">
                                            {{ feeLabel[feeCode.marketplace_margin] }}
                                        </span>
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            appFloatOnly
                                            [maxVal]="100"
                                            [(ngModel)]="
                                                commonUtil.getFee(
                                                    marketplaceFeeList,
                                                    feeCode.marketplace_margin
                                                ).percentageValue
                                            " />
                                    </td>
                                    <td>
                                        <input
                                            type="radio"
                                            name="edit_marketplace_{{ feeCode.marketplace_margin }}"
                                            [checked]="
                                                !commonUtil.getFee(
                                                    marketplaceFeeList,
                                                    feeCode.marketplace_margin
                                                ).useAmount
                                            "
                                            [(ngModel)]="
                                                commonUtil.getFee(
                                                    marketplaceFeeList,
                                                    feeCode.marketplace_margin
                                                ).useAmountValue
                                            "
                                            [value]="false" />
                                    </td>

                                    <td>
                                        <input
                                            type="text"
                                            appFloatOnly
                                            [(ngModel)]="
                                                commonUtil.getFee(
                                                    marketplaceFeeList,
                                                    feeCode.marketplace_margin
                                                ).amountValue
                                            " />
                                    </td>
                                    <td>
                                        <input
                                            type="radio"
                                            name="edit_marketplace_{{ feeCode.marketplace_margin }}"
                                            [checked]="
                                                commonUtil.getFee(
                                                    marketplaceFeeList,
                                                    feeCode.marketplace_margin
                                                ).useAmount
                                            "
                                            [(ngModel)]="
                                                commonUtil.getFee(
                                                    marketplaceFeeList,
                                                    feeCode.marketplace_margin
                                                ).useAmountValue
                                            "
                                            [value]="true" />
                                    </td>
                                </tr>
                                <tr
                                    *ngIf="
                                        commonUtil.showFeeRow(
                                            marketplaceFeeList,
                                            feeCode.marketplace_delivery
                                        )
                                    ">
                                    <td>
                                        <span
                                            data-toggle="tooltip"
                                            data-placement="right"
                                            title="{{
                                                commonUtil.getFee(
                                                    marketplaceFeeList,
                                                    feeCode.marketplace_delivery
                                                ).description
                                            }}">
                                            {{ feeLabel[feeCode.marketplace_delivery] }}
                                        </span>
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            appFloatOnly
                                            [maxVal]="100"
                                            [(ngModel)]="
                                                commonUtil.getFee(
                                                    marketplaceFeeList,
                                                    feeCode.marketplace_delivery
                                                ).percentageValue
                                            " />
                                    </td>
                                    <td>
                                        <input
                                            type="radio"
                                            name="edit_marketplace_{{
                                                feeCode.marketplace_delivery
                                            }}"
                                            [checked]="
                                                !commonUtil.getFee(
                                                    marketplaceFeeList,
                                                    feeCode.marketplace_delivery
                                                ).useAmount
                                            "
                                            [(ngModel)]="
                                                commonUtil.getFee(
                                                    marketplaceFeeList,
                                                    feeCode.marketplace_delivery
                                                ).useAmountValue
                                            "
                                            [value]="false" />
                                    </td>

                                    <td>
                                        <input
                                            type="text"
                                            appFloatOnly
                                            [(ngModel)]="
                                                commonUtil.getFee(
                                                    marketplaceFeeList,
                                                    feeCode.marketplace_delivery
                                                ).amountValue
                                            " />
                                    </td>
                                    <td>
                                        <input
                                            type="radio"
                                            name="edit_marketplace_{{
                                                feeCode.marketplace_delivery
                                            }}"
                                            [checked]="
                                                commonUtil.getFee(
                                                    marketplaceFeeList,
                                                    feeCode.marketplace_delivery
                                                ).useAmount
                                            "
                                            [(ngModel)]="
                                                commonUtil.getFee(
                                                    marketplaceFeeList,
                                                    feeCode.marketplace_delivery
                                                ).useAmountValue
                                            "
                                            [value]="true" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6"></div>
                    <div class="col-md-6">
                        <button
                            class="custombtn custombtn-align-right"
                            (click)="submit('marketplace')">
                            SAVE
                        </button>
                        <a class="user-files-deny" (click)="switchMarketFeeView()"><u>CANCEL</u></a>
                    </div>
                </div>
            </div>
        </section>

        <section class="add-section" *ngIf="personalFeeList.length > 0">
            <app-personal-user-fees
                title="Personal User"
                [showEditPencil]="true"
                [editPersonal]="editPersonal"
                [personalFeeList]="personalFeeList"
                (updateFee)="submit('personal')">
            </app-personal-user-fees>
        </section>

        <section class="add-section" *ngIf="businessFeeList.length > 0">
            <app-business-fees
                title="Business User"
                [showEditPencil]="true"
                [editBusiness]="editBusiness"
                [businessFeeList]="businessFeeList"
                (updateFee)="submit('business')">
            </app-business-fees>
        </section>

        <section class="add-section" *ngIf="enterpriseFeeList.length > 0">
            <app-enterprise-fees
                title="Enterprise User"
                [showEditPencil]="true"
                [editEnterprise]="editEnterprise"
                [enterpriseFeeList]="enterpriseFeeList"
                (updateFee)="submit('enterprise')">
            </app-enterprise-fees>
        </section>

        <section class="add-section" *ngIf="agentFeeList.length > 0">
            <app-agent-fees
                title="Agent User"
                [showEditPencil]="true"
                [editAgent]="editAgent"
                [agentFeeList]="agentFeeList"
                (updateFee)="submit('agent')">
            </app-agent-fees>
        </section>
    </div>
</div>
