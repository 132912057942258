<div class="wrapper">
    <app-error-model
        message="{{ eMessage }}"
        [display]="display"
        (event)="closeModel($event)"></app-error-model>
    <app-success-model
        message="{{ sMessage }}"
        [display]="displaySuccess"
        (event)="closeModel($event)"></app-success-model>
    <app-navigation></app-navigation>
    <div class="content-wrapper">
        <app-content-transition></app-content-transition>
        <app-loader *ngIf="isLoading" className="custom-loader" text="Processing ..."></app-loader>
        <app-header location="User Account Profile"></app-header>
        <app-edit-links [id]="id"></app-edit-links>
        <app-user-profile-data
            [user]="user"
            [userType]="role.personal"
            (updateCode)="updateReferralCode($event)">
        </app-user-profile-data>
        <app-user-wallet-data tabName="personal" [user]="user"> </app-user-wallet-data>
        <section class="add-section">
            <div class="row">
                <div class="col-md-8 section-heading">User Details</div>
                <div class="col-md-4 add-section-heading">
                    <app-edit-pencil (edit)="changeView()"></app-edit-pencil>
                </div>
            </div>
            <div class="details-view" *ngIf="!editProfile">
                <div class="row">
                    <div class="col-md-3">
                        <label class="form-label">NAME</label>
                        <div class="user-details-text">
                            {{ user.firstName || '-' }}
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">SURNAME</label>
                        <div class="user-details-text">
                            {{ user.lastName || '-' }}
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">EMAIL ADDRESS</label>
                        <div class="user-details-text">
                            {{ user.email || '-' }}
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">MOBILE NUMBER</label>
                        <div class="user-details-text">
                            {{ user.mobileNumber || '-' }}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <label class="form-label">GENDER</label>
                        <div class="user-details-text">
                            {{ user.gender || '-' | humanize }}
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">MARITAL STATUS</label>
                        <div class="user-details-text">
                            {{ user.maritalStatus || '-' }}
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">{{
                            user.idType || 'SA ID NUMBER' | uppercase | humanize
                        }}</label>
                        <div class="user-details-text">
                            {{ user.saIdNumber || '-' }}
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">DATE OF BIRTH</label>
                        <div class="user-details-text">
                            {{ user.dob || '-' }}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <label class="form-label">JOB TITLE</label>
                        <div class="user-details-text">
                            {{ user.userPersonalProfileModel.jobTitle || '-' }}
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">HOME ADDRESS LINE 1</label>
                        <div class="user-details-text">
                            {{ user.userPersonalProfileModel.address.addressLineOne || '-' }}
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">HOME ADDRESS LINE 2</label>
                        <div class="user-details-text">
                            {{ user.userPersonalProfileModel.address.addressLineTwo || '-' }}
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">BUILDING DETAILS</label>
                        <div class="user-details-text">
                            {{ user.userPersonalProfileModel.address.buildingDetails || '-' }}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <label class="form-label">CITY</label>
                        <div class="user-details-text">
                            {{ user.userPersonalProfileModel.address.cityName || '-' }}
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">PROVINCE</label>
                        <div class="user-details-text">
                            {{ user.userPersonalProfileModel.address.provinceName || '-' }}
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">POSTAL CODE</label>
                        <div class="user-details-text">
                            {{ user.userPersonalProfileModel.address.postalCode || '-' }}
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">SUBURB</label>
                        <div class="user-details-text">
                            {{ user.userPersonalProfileModel.address.suburb || '-' }}
                        </div>
                    </div>
                </div>
            </div>
            <form [formGroup]="form" *ngIf="editProfile">
                <div class="row">
                    <div class="col-md-6">
                        <label class="form-label">ID TYPE*</label>
                        <app-select-box
                            placeholderValue="Set ID TYPE"
                            value="idType"
                            [form]="form"
                            [invalidForm]="invalidForm"
                            [dropDown]="idTypeDropdown">
                        </app-select-box>
                    </div>
                    <div class="col-md-6">
                        <label class="form-label">ID NUMBER*</label>
                        <div>
                            <input
                                class="form-input"
                                type="text"
                                formControlName="idNumber"
                                name="idNumber"
                                placeholder="{{ idNumPlaceHolder }}" />
                        </div>
                        <small
                            *ngIf="(controls.idNumber.invalid && invalidForm) || invalidIdNum"
                            class="error-text">
                            {{ idNumberErrorMsg || 'The Id Number is invalid' }}
                        </small>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label class="form-label">DATE OF BIRTH*</label>
                        <div>
                            <input
                                matInput
                                class="datepicker-input"
                                [matDatepicker]="picker"
                                name="dob"
                                formControlName="dob"
                                readonly />
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </div>
                        <small
                            *ngIf="(controls.dob.invalid && invalidForm) || invalidDOB"
                            class="error-text">
                            {{ dobErrorMsg || 'The DOB is invalid' }}
                        </small>
                    </div>
                    <div class="col-md-6">
                        <label class="form-label">GENDER</label>
                        <div>
                            <app-select-box
                                placeholderValue="Set Gender"
                                value="gender"
                                [form]="form"
                                [invalidForm]="invalidForm"
                                [dropDown]="genderDropdown">
                            </app-select-box>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6"></div>
                    <div class="col-md-6">
                        <button
                            class="custombtn custombtn-align-right"
                            [disabled]="invalidIdNum || form.invalid"
                            (click)="submit()">
                            SAVE
                        </button>
                        <a class="user-files-deny" (click)="changeView()"><u>CANCEL</u></a>
                    </div>
                </div>
            </form>
        </section>
        <ng-container *ngFor="let bank of user.userPersonalProfileModel.bankList">
            <app-user-bank-data [bank]="bank" (update)="updateBank($event)" [hideEdit]="true">
            </app-user-bank-data>
        </ng-container>
    </div>
</div>
