import { Injectable } from '@angular/core';
import { Company } from '../model/company';
import { Observable, throwError } from 'rxjs';
import { StorageConstant } from '../settings/storage-constants';
import { HttpClient } from '@angular/common/http';
import { GenericResponse } from '../model/generic.response';
import { AppSettings } from '../settings/app-settings';
import { MessageConstant } from '../settings/message.constant';
import { Company as CompanyEnum } from 'src/app/enums/company';

@Injectable({
    providedIn: 'root',
})
export class CompanyService {
    private companies: Company[] = [];
    private selectedCompany: Company;
    private companyEnum = CompanyEnum;

    constructor(private http: HttpClient) {}

    public setCompanies(companies: Company[]): void {
        localStorage.setItem(StorageConstant.COMPANIES, JSON.stringify(companies));
        this.selectCompany(companies.find((company) => company.code == this.companyEnum.lovcash));
        this.companies = companies;
    }

    public getCompanies(): Company[] {
        if (this.isEmpty(this.companies)) {
            this.companies = JSON.parse(localStorage.getItem(StorageConstant.COMPANIES));
        }
        return this.companies;
    }

    public selectCompany(company: Company): void {
        if (this.isEmpty(company)) {
            return;
        }

        localStorage.setItem(StorageConstant.SELECTED_COMPANY, JSON.stringify(company));
        this.selectedCompany = company;
    }

    public getSelectedCompany(): Company {
        if (this.isEmpty(this.selectedCompany)) {
            this.selectedCompany = JSON.parse(
                localStorage.getItem(StorageConstant.SELECTED_COMPANY),
            );
        }
        return this.selectedCompany;
    }

    public retrieveCompanies() {
        return this.http.get<GenericResponse<any>>(
            AppSettings.API_ENDPOINT + AppSettings.GET_COMPANIES,
        );
    }

    public isEmpty(obj): boolean {
        return obj === undefined || obj == null || (Array.isArray(obj) && obj.length <= 0)
            ? true
            : false;
    }

    public getSelectedCompanyCode() {
        const company = this.getSelectedCompany();
        return !this.isEmpty(company) ? company.code : '';
    }

    public getSelectedCompanyName() {
        const company = this.getSelectedCompany();
        return !this.isEmpty(company) ? company.name : '';
    }

    public lovcashCompanyActive() {
        return this.getSelectedCompanyCode() === this.companyEnum.lovcash;
    }

    public klikklakCompanyActive() {
        return this.getSelectedCompanyCode() === this.companyEnum.klikklak;
    }
}
